import React, { useEffect, useState, useRef, memo } from 'react';
import { css, useTheme } from '@emotion/react';
import { globals } from 'common-ui';
import { useScreenLayout } from 'common-ui/src/hooks/useScreenLayout';

import type { MultiCaseSelectorEntryType, ProductProps } from 'tsconfig/types';
import useProductData from '../../../hooks/useProductData';
import { CmsImage, Rating } from 'common-ui';
import { formatCurrency } from '../../../utils/formatCurrency';

import { CartButton } from '../../AddToCart/Button';
import { CaseComponents } from '../../CaseComponents/CaseComponents';
import { CmsRichText } from '../CmsRichText/CmsRichText';
import { CaseSelectors } from './CaseSelectors';
import { CaseSelector } from './CaseSelector';
export interface CmsMultiCaseSelectorProps {
  contentItem: MultiCaseSelectorEntryType;
}

type cartItemType = {
  itemCode: string;
  quantity: number;
};

export const CmsMultiCaseSelector = memo(({ contentItem }: CmsMultiCaseSelectorProps) => {
  const { image, productCodes, upsellProductCode, eyebrow, headline, subheadline, paragraph } =
    contentItem.fields || {};
  const [mobileview, setMobileView] = useState(false);
  const { screen } = useScreenLayout();

  const products: ProductProps[] = [];
  const [activeProduct, setActiveProduct] = useState<ProductProps | null>(null);
  const [upsellProduct, setUpsellProduct] = useState<ProductProps | null>(null);
  const [upsellSelected, setUpsellSelected] = useState(false);
  const [cartItems, setCartItems] = useState<[cartItemType] | any>([]);
  const [activeCase, setActiveCase] = useState(0);
  const [activeBottomCase, setActiveBottomCase] = useState(0);
  const targetRef = useRef<HTMLDivElement>(null);

  const [referencedSkus, setReferencedSkus] = useState(
    Array<{
      itemId: string;
      quantity: number;
      expandedItem: ProductProps;
    }>,
  );

  const theme = useTheme();

  const cssMain = css`
    ${theme.widgets?.CmsMultiCaseSelector?.main}
  `;

  const cssInfo = css`
    ${theme.widgets?.CmsMultiCaseSelector?.info}
  `;

  const caseCss = css`
    ${theme.widgets.Product?.case}
  `;

  const { product: upsellProductData } = useProductData(upsellProductCode);
  if (upsellProductData && !upsellProduct) {
    setUpsellProduct(upsellProductData);
  }

  productCodes?.forEach((code, index) => {
    const { product: productData } = useProductData(code);
    if (productData) {
      if (index === 0 && activeProduct === null) {
        setActiveProduct(productData);

        if (globals?.useNewCartAPI) {
          setCartItems([{ itemCode: productData?.itemCode, quantity: 1 }]);
        } else {
          setCartItems({
            cartItems: [{ itemCode: productData?.itemCode, quantity: 1 }],
          });
        }
      }
      products.push(productData);
    }
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const match = window.matchMedia(`(max-width: ${theme.breakpointWidths?.md}px)`).matches;
      setMobileView(typeof match === 'boolean' ? match : false);
    }
  }, [screen]);

  useEffect(() => {
    if (activeProduct) {
      setReferencedSkus(activeProduct?.skus[0]?.referencedSkus ? activeProduct.skus[0].referencedSkus : []);
    }
  }, [activeProduct]);

  const updateCartItem = (product: ProductProps | null, isUpsellSelected: boolean) => {
    const newCartItems = [{ itemCode: product?.itemCode, quantity: 1 }];

    if (upsellProduct && isUpsellSelected) {
      newCartItems.push({ itemCode: upsellProduct?.itemCode, quantity: 1 });
    }

    if (globals?.useNewCartAPI) {
      setCartItems(newCartItems);
    } else {
      setCartItems({
        cartItems: newCartItems,
      });
    }
  };

  const handleCaseClick = (index: number) => {
    setActiveCase(index);
    setActiveBottomCase(index);

    if (products[index]) {
      const product = products[index];
      setActiveProduct(product);
      setReferencedSkus(product?.skus[0]?.referencedSkus ? product.skus[0].referencedSkus : []);
      updateCartItem(product, upsellSelected);
    }
  };

  const handleUpsellClick = (index: number) => {
    if (!upsellSelected) {
      // If previous state is false
      setActiveBottomCase(index);
      setReferencedSkus(upsellProduct?.skus[0]?.referencedSkus ? upsellProduct.skus[0].referencedSkus : []);
    } else {
      const product = products[activeCase];
      setActiveBottomCase(activeCase);
      setReferencedSkus(product?.skus[0]?.referencedSkus ? product.skus[0].referencedSkus : []);
    }

    setUpsellSelected(selected => !selected);
    updateCartItem(activeProduct, !upsellSelected);
  };

  const handleViewTabPanelClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (targetRef?.current) {
      const totalOffsetTop = (el: any): number => {
        if (el.offsetParent === document.body) {
          return el.offsetTop;
        }

        return el.offsetTop + totalOffsetTop(el.offsetParent);
      };

      const targetOffset = totalOffsetTop(targetRef.current) - 100;

      window.scrollTo({ top: targetOffset, behavior: 'smooth' });
    }
  };

  const getBottleCount = (product: ProductProps) => {
    const bottles = product?.skus[0].numberOfBottles;
    if (bottles > 0) {
      return `(${bottles} btl${bottles > 1 ? 's' : ''})`;
    }
    return false;
  };

  return (
    <div className="mcs">
      <div className="mcs-main" css={cssMain}>
        <CmsImage fill={true} contentItem={image} />
        <div className="mcs-main__details">
          <div className="mcs-main-row">
            {eyebrow && <span className="eyebrowRegular">{eyebrow}</span>}
            {headline ? <h1>{headline}</h1> : <h1>{activeProduct?.name}</h1>}
            {subheadline && <div className="mcs-main__sub-headline">{subheadline}</div>}
            {paragraph && (
              <div className="mcs-main__desc">
                <CmsRichText contentItem={paragraph} />
              </div>
            )}
          </div>

          {products && (
            <CaseSelectors
              id="cms-multi-case-selector-list"
              target="#panel-case-selector"
              activeCase={activeCase}
              products={products}
              handleCaseClick={handleCaseClick}
              handleViewTabPanelClick={handleViewTabPanelClick}
            />
          )}

          {/* Bundle Product */}
          {upsellProduct && (
            <div className="mcs-main-row mcs-main-row--upsell">
              <span>Make it a bundle</span>
              <div>
                <input
                  type="checkbox"
                  name="mcs-bundle-upsell"
                  id={`mcs-bundle-upsell-${upsellProduct.itemCode}`}
                  checked={upsellSelected}
                />
                <label htmlFor={`mcs-bundle-upsell-${upsellProduct.itemCode}`}>
                  <CaseSelector
                    type={'upsell'}
                    item={upsellProduct}
                    index={products.length}
                    handleClick={handleUpsellClick}
                    upsellSelected={upsellSelected}
                  />
                </label>
              </div>
            </div>
          )}

          {/* Active Product info */}
          {activeProduct && (
            <div className="mcs-main-row mcs-main-row--active-info">
              <div>
                {activeProduct?.name} {getBottleCount(activeProduct)}{' '}
                {upsellProduct && upsellSelected && `+ ${upsellProduct?.name}`} :{' '}
              </div>

              {activeProduct?.averageOverallRating > 0 && (
                <div className="product-rating">
                  <Rating rating={activeProduct?.averageOverallRating} />
                  {activeProduct?.totalReviewCount > 0 && <span>({activeProduct?.totalReviewCount})</span>}
                </div>
              )}

              <div>
                {activeProduct?.skus[0].salePrice !== null && activeProduct?.skus[0].salePrice > 0 && (
                  <>
                    {activeProduct?.skus[0].buyersRRP > 0 && (
                      <s className="product-price__original">
                        {upsellProduct && upsellSelected
                          ? formatCurrency(upsellProduct?.skus[0].buyersRRP + activeProduct?.skus[0].buyersRRP)
                          : formatCurrency(activeProduct?.skus[0].buyersRRP)}
                      </s>
                    )}
                    <span className="body1 bold">
                      {upsellProduct && upsellSelected
                        ? formatCurrency(upsellProduct?.skus[0].salePrice + activeProduct?.skus[0].salePrice)
                        : formatCurrency(activeProduct?.skus[0].salePrice)}
                    </span>
                  </>
                )}
              </div>
            </div>
          )}

          {/* Add to cart Button */}
          {activeProduct && (
            <div className="mcs-main-row">
              <CartButton
                itemCode={cartItems.cartItems[0].itemCode}
                buttonText="ADD TO CART"
                cartItems={cartItems}
                method={cartItems.cartItems?.length > 1 ? 'batch' : undefined}
                qty={'1'}
                fontWeight="600"
              />
            </div>
          )}
        </div>
      </div>

      {/* Referenced Case Tab Panel */}
      <div className="mcs-case-info" css={[caseCss, cssInfo]}>
        <div id="panel-case-selector" ref={targetRef}>
          <CaseSelectors
            from={'target-panel'}
            activeBottomCase={activeBottomCase}
            products={products}
            upsellProduct={upsellProduct}
            upsellSelected={upsellSelected}
            handleCaseClick={handleCaseClick}
            handleUpsellClick={handleUpsellClick}
          />
        </div>

        {referencedSkus && (
          <CaseComponents
            referencedSkus={referencedSkus}
            upsellProduct={upsellProduct}
            view={mobileview ? 'carousel' : ''}
          />
        )}
      </div>
    </div>
  );
});
