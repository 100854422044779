import type { SyntheticEvent } from 'react';

export const navigateToBannerLink = (link?: string) => {
  if (link) {
    window.location.assign(link);
  }
};

export const stopBannerContentEventPropagation = (event: SyntheticEvent) => {
  if ((event.nativeEvent.target as HTMLInputElement).tagName === 'A') {
    event.stopPropagation();
  }
};
