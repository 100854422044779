import { useEffect, useState } from 'react';
import {
  RecoilInit,
  // useActionAddToCart,
  useCartGetAddedProduct,
  useInitCartState,
  miniCartEnabledAtom,
  stickyHeaderEnabledAtom,
} from 'common-state';
import type { RecoilInitProps } from 'common-state';
import { logger, globals, useEffectOnce } from 'common-ui';
import { useRecoilValue } from 'recoil';
import type { CallbackFunction } from 'tsconfig/types';

export const RecoilInitEmbeddedWrapper = ({ children }: RecoilInitProps) => {
  const { showProductAdded, showProductRemoved } = useCartGetAddedProduct();
  const [isStickyHeaderVisible, setIsStickyHeaderVisible] = useState(false);
  const getUpdatedCart = useInitCartState();
  const isMiniCartEnabled = useRecoilValue(miniCartEnabledAtom);
  const isStickyHeaderEnabled = useRecoilValue(stickyHeaderEnabledAtom);
  const EVENT_REMOVE_CART_ITEM = 'event-remove-cart-item';
  const preventDefault = (event: { preventDefault: CallbackFunction }) => {
    event.preventDefault();
  };

  const handleCartBtnClick = () => {
    if (window.miniCart && typeof window.miniCart.toggleMiniCart === 'function') {
      window.miniCart.toggleMiniCart();
    } else {
      logger.error('miniCart.toggleMiniCart() is not defined.');
    }
  };

  // This useEffect integrates New Header cart icon with JSP MiniCart Drawer
  useEffect(() => {
    if (window) {
      window.isMiniCartEnabled = isMiniCartEnabled;
    }
    if (
      window.location.pathname !== '/jsp/checkout/common/shoppingcart.jsp' &&
      isMiniCartEnabled &&
      !globals?.enableNewMiniCart
    ) {
      const anchorTag = document.querySelector('.header-cart-link');
      const cartBtn = document.querySelector('.header-cart-count-container');

      anchorTag?.addEventListener('click', preventDefault);
      cartBtn?.addEventListener('click', handleCartBtnClick);

      return () => {
        anchorTag?.removeEventListener('click', preventDefault);
        cartBtn?.removeEventListener('click', handleCartBtnClick);
      };
    }
  }, [isMiniCartEnabled]);

  // Below two useEffect looks for the sticky header and add click event listener
  useEffect(() => {
    const mainHeader = document.querySelector('#mainHeaderNavBar') as HTMLElement;

    const handleScroll = () => {
      if (window.scrollY >= mainHeader?.offsetHeight + 10) {
        setIsStickyHeaderVisible(true);
      } else {
        setIsStickyHeaderVisible(false);
      }
    };
    if (
      window.location.pathname !== '/jsp/checkout/common/shoppingcart.jsp' &&
      isMiniCartEnabled &&
      isStickyHeaderEnabled
    ) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMiniCartEnabled, isStickyHeaderEnabled]);

  useEffect(() => {
    const anchorTag = document.querySelector('.cart > .header-cart-link');
    const cartBtn = document.querySelector('.cart');

    if (isStickyHeaderVisible) {
      anchorTag?.addEventListener('click', preventDefault);
      cartBtn?.addEventListener('click', handleCartBtnClick);
    }

    return () => {
      anchorTag?.removeEventListener('click', preventDefault);
      cartBtn?.removeEventListener('click', handleCartBtnClick);
    };
  }, [isStickyHeaderVisible]);

  useEffectOnce(() => {
    document.addEventListener('item-added-into-cart-successful', showProductAdded);
    document.addEventListener(EVENT_REMOVE_CART_ITEM, showProductRemoved);
    document.addEventListener('event-removeUpdate-cart-item', showProductRemoved);
    document.addEventListener('event-update-cart-item', () => getUpdatedCart());
    document.addEventListener(EVENT_REMOVE_CART_ITEM, () => getUpdatedCart());

    return () => {
      document.removeEventListener('item-added-into-cart-successful', showProductAdded);
      document.removeEventListener(EVENT_REMOVE_CART_ITEM, showProductRemoved);
      document.removeEventListener('event-removeUpdate-cart-item', showProductRemoved);
      document.removeEventListener('event-update-cart-item', () => getUpdatedCart());
      document.removeEventListener(EVENT_REMOVE_CART_ITEM, () => getUpdatedCart());
    };
  }, []);

  return <RecoilInit> {children} </RecoilInit>;
};
