import { globals } from 'common-ui';
import { getQueryFilters, typeaheadCheck, resultsCustomConfig } from '../utils';
import type { GetSourcesParams, OnSelectParams } from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import { productLinkBuilder } from 'common-next/src/components/common-functions';
import { ProductItem } from '../productCard/productItem';
import type { SearchClient } from 'algoliasearch/lite';
import type { BaseItem, ExtendedAlgoliaSearchResult } from './types';

interface GetItemsParams {
  setContext: (context: any) => void;
  query: string;
}

const ProductPluginHeader = () => <span className="aa-SourceHeaderTitle"> Products </span>;
const ProductPluginItem = ({ item, components }: any) => <ProductItem hit={item} components={components} />;
const ProductPluginNoResults = () => 'No products for this query.';

export function createProductsPlugin(searchClient: SearchClient, currentState: string) {
  function transformResponseHandler({ hits, results }: any, setContext: (ctx: any) => void) {
    let products = hits[0] as ExtendedAlgoliaSearchResult[];

    if (products.length > 1) {
      // Remove the first product if there are more than one result
      products = products.slice(1);
    }

    const firstHit = products[0] as ExtendedAlgoliaSearchResult;

    if (firstHit) {
      const { skuItemCodes = [], productName = '', vintage = '', giftFlag = false } = firstHit;
      setContext({ skuItemCodes, productName, vintage, giftFlag });
    } else {
      setContext({ skuItemCodes: [], productName: '', vintage: '', giftFlag: false });
    }

    setContext({ queryID: results[0].queryID });

    const limit = resultsCustomConfig().hitsPerPage;
    return !globals?.algoliaNewView && products.length > limit ? products.slice(0, limit) : products;
  }

  return {
    getSources({ query: sourceQuery }: GetSourcesParams<BaseItem>) {
      if (!sourceQuery || !typeaheadCheck(sourceQuery)) {
        return [];
      }
      return [
        {
          sourceId: 'products',
          getItems({ setContext, query: searchQuery }: GetItemsParams) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: globals.algoliaIndexName as string,
                  query: searchQuery,
                  params: {
                    clickAnalytics: true,
                    hitsPerPage: resultsCustomConfig().hitsPerPage,
                    attributesToSnippet: ['name:10', 'Description:20'],
                    snippetEllipsisText: '…',
                    filters: getQueryFilters(currentState),
                  },
                },
              ],
              transformResponse: response => transformResponseHandler(response, setContext),
            });
          },
          onSelect({ item, state }: OnSelectParams<BaseItem>) {
            window.location.href = productLinkBuilder(
              item?.productName as string,
              item?.vintage as string,
              item?.itemCode as string,
              state.context.queryID?.toString() as string,
            );
          },
          templates: {
            header: ProductPluginHeader,
            item: ProductPluginItem,
            noResults: ProductPluginNoResults,
          },
        },
      ];
    },
  };
}
