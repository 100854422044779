import { RelatedProducts } from '@algolia/recommend-react';
import recommend from '@algolia/recommend';
import { css, useTheme } from '@emotion/react';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { selectedStateAtom } from 'common-state';
import { globals, ItemCard } from 'common-ui';
import { ListView } from './ListView';

const RelatedItem = React.memo(({ item, showRating }: { item: any; showRating: boolean }) => (
  <ItemCard product={item} showRating={showRating ?? true} showImageZoom={true} />
));

const recommendClient = recommend(
  `${process.env.NEXT_PUBLIC_INSTANT_SEARCH_APP_ID}`,
  `${process.env.NEXT_PUBLIC_INSTANT_SEARCH_SEARCH_KEY}`,
);

export const RelatedItems = ({
  currentObjectID,
  targetRef = null,
  fallbackParameters = null,
}: {
  currentObjectID: string;
  targetRef?: any;
  fallbackParameters?: any;
}) => {
  const indexName = `${process.env.NEXT_PUBLIC_INSTANT_SEARCH_INDEX_NAME}`;

  const theme = useTheme();

  const related = css`
    ${theme.widgets.Product?.related}
  `;

  const currentState = useRecoilValue(selectedStateAtom);

  const queryFilters =
    globals.country === 'us'
      ? `inStock:true AND (threeTierStates:${currentState} OR wineryDirectStates:${currentState})`
      : 'inStock:true';

  const fallbackFilters = () => {
    // Return early if fallbackParameters is not defined
    if (!fallbackParameters) {
      return '';
    }

    const isUS = globals.country === 'us';

    // Case-specific logic
    if (fallbackParameters.productType === 'Case') {
      if (isUS) {
        return `colour:"${fallbackParameters.colourName}" AND (threeTierStates:${currentState} OR wineryDirectStates:${currentState}) AND inStock:true`;
      }
      return `colour:"${fallbackParameters.colourName}" AND productType:"${fallbackParameters.productType}" AND inStock:true`;
    }

    // General logic for non-case products
    if (isUS) {
      return `country:"${fallbackParameters.countryName}" AND (threeTierStates:${currentState} OR wineryDirectStates:${currentState}) AND inStock:true`;
    }
    return `country:"${fallbackParameters.countryName}" AND productWebType:"${fallbackParameters.productWebType}" AND inStock:true`;
  };

  const HeaderComponent = () => <h3 className="text-center">You Might Like</h3>;

  return (
    <div ref={targetRef} css={related} className="no-print">
      <RelatedProducts
        recommendClient={recommendClient}
        indexName={indexName}
        objectIDs={[currentObjectID]}
        itemComponent={props => <RelatedItem {...props} showRating={true} />}
        headerComponent={() => <HeaderComponent />}
        view={props => <ListView {...props} cmsCarousel={theme.widgets.CmsCarousel ?? {}} />}
        maxRecommendations={5}
        threshold={30}
        fallbackParameters={{
          filters: fallbackFilters(),
        }}
        queryParameters={{
          filters: queryFilters,
        }}
      />
    </div>
  );
};
