import React, { memo, useEffect, useState } from 'react';
import { isBrowser } from '../../utils/isBrowser';
import { css } from '@emotion/react';
import TrustpilotSkeleton from '../Skeleton/TrustPilotWidget/TrustPilotWidget';

export interface TrustPilotWidgetProps {
  tags?: string;
  locale: string;
  businessUnitId?: string;
  dwDomain: string;
  showMiniCarousel?: boolean;
}

export interface CmsCustomerReviewData {
  businessUnitId: string;
  url: string;
}

const fullWithTamplateID = '53aa8912dec7e10d38f59f36';
const miniTemplateID = '539ad0ffdec7e10e686debd7';

export const TrustPilotWidget = memo(
  ({ tags = 'Adviser', locale, businessUnitId, dwDomain, showMiniCarousel }: TrustPilotWidgetProps) => {
    const ref = React.useRef(null);
    const [templateID, setTemplateID] = useState<string>(fullWithTamplateID);
    const [height, setHeight] = useState<number>(140);
    const [loading] = useState(true);

    const wrapperStyling = css`
      padding-top: 80px;
      padding-bottom: 80px;
    `;

    useEffect(() => {
      setTemplateID(showMiniCarousel ? miniTemplateID : fullWithTamplateID);
      setHeight(showMiniCarousel ? 320 : 140);
    }, [showMiniCarousel]);

    useEffect(() => {
      if (isBrowser() && (window as any).Trustpilot) {
        (window as any).Trustpilot.loadFromElement(ref.current, true);
      }
    }, [height, templateID]);

    return (
      <div
        css={wrapperStyling}
        ref={ref}
        className="trustpilot-widget"
        data-locale={locale}
        data-template-id={templateID}
        data-businessunit-id={businessUnitId}
        data-style-height={`${height}px`}
        data-style-width="100%"
        data-theme="light"
        data-tags={tags}
        data-stars="1,2,3,4,5"
        data-review-languages="en"
      >
        {loading ? (
          <TrustpilotSkeleton />
        ) : (
          <a href={`https://uk.trustpilot.com/review/${dwDomain}`} target="_blank" rel="noopener noreferrer">
            Trustpilot
          </a>
        )}
      </div>
    );
  },
);
