import { Carousel } from '@mantine/carousel';
import React, { useState, useEffect } from 'react';

type ListViewProps = {
  items: any[];
  itemComponent: any;
  cmsCarousel: object;
};

export const ListView = ({ items, itemComponent, cmsCarousel }: ListViewProps) => {
  const [slidestoShow, setSlidestoShow] = useState(1);
  const showControls = Array.isArray(items) && items.length > slidestoShow;

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      let noOfSlides: number;
      switch (true) {
        case windowWidth < 576:
          noOfSlides = 1;
          break;
        case windowWidth < 992:
          noOfSlides = 2;
          break;
        case windowWidth < 1200:
          noOfSlides = 3;
          break;
        case windowWidth < 1408:
          noOfSlides = 4;
          break;
        default:
          noOfSlides = 5;
      }
      setSlidestoShow(noOfSlides);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="container">
      {items.length > 0 ? (
        <Carousel
          withIndicators={showControls}
          withControls={showControls}
          loop={showControls}
          draggable={showControls}
          slidesToScroll={1}
          slideSize="20%"
          align="start"
          slideGap="md"
          controlsOffset="md"
          className="trending-products-carousel"
          styles={cmsCarousel}
          breakpoints={[
            { maxWidth: 'xl', slideSize: '25%' },
            { maxWidth: 'lg', slideSize: '33.33333%' },
            { maxWidth: 'md', slideSize: '50%', slideGap: 'sm' },
            { maxWidth: 'xs', slideSize: '100%', slideGap: 0 },
          ]}
        >
          {items.map(item => (
            <Carousel.Slide key={item.objectID}>{React.createElement(itemComponent, { item })}</Carousel.Slide>
          ))}
        </Carousel>
      ) : null}
    </div>
  );
};
