import { initAtgLegacyMiscApi } from 'common-api/src/directWinesApis/atgLegacyMiscApi';
import type { LoginType } from 'tsconfig/types';
import { logger } from 'common-ui';

export const allLoginType = ['hard_logged', 'soft_logged', 'unidentified'];

type UserStatus = {
  loginStatus: string;
  loginType: LoginType;
};

export const userStatus = (securityStatus?: number): UserStatus => {
  switch (securityStatus) {
    case 5:
    case 4:
      return {
        loginStatus: 'logged in',
        loginType: 'hard_logged',
      };
    case 3:
    case 2:
      return {
        loginStatus: 'logged in',
        loginType: 'soft_logged',
      };
    default:
      return {
        loginStatus: 'not logged in',
        loginType: 'unidentified',
      };
  }
};

export const logout = (type: LoginType) => {
  const commerceApi = initAtgLegacyMiscApi();
  const logoutMethod = type === 'soft_logged' ? commerceApi.User.softLogout : commerceApi.User.hardLogout;
  logoutMethod()
    .then(data => {
      const [, url] = data.split('"');
      window.location.href = url;
    })
    .catch(err => {
      if (err.response) {
        logger.warn(err);
      }
    });
};

export const trackCIDTags = () => {
  const commerceApi = initAtgLegacyMiscApi();
  const trackTag = commerceApi.User.trackCIDTags;
  trackTag()
    .then(data => {
      logger.log('CID tags are tracked', data);
    })
    .catch(err => {
      if (err.response) {
        logger.warn(err);
      }
    });
};
