import type { LineItem, SkuItem } from 'tsconfig/types';

export const getMaxLimitCheck = (itemsSelected: LineItem[]) =>
  itemsSelected?.some(
    (item: LineItem) =>
      item.quantity > 99 ||
      item?.product?.skus?.some(
        (sku: SkuItem) =>
          !sku?.preReleaseItem &&
          !sku?.preSellItem &&
          !item?.product?.inventoryInfo?.happyToWaitEnabled &&
          item?.product?.productType !== 'Case' &&
          (item?.product?.productType === 'Wine' || item?.product?.productType?.toLowerCase() === 'nonwine') &&
          item?.quantity * item?.sku?.numberOfBottles > item?.product?.inventoryInfo?.stockQuantity,
      ),
  );

export const getInventoryLimitCheck = (itemsSelected: LineItem[]): boolean =>
  itemsSelected?.some((item: LineItem) =>
    item?.product?.skus?.some(
      (sku: SkuItem) =>
        sku !== undefined &&
        !sku?.preReleaseItem &&
        !sku?.preSellItem &&
        !item?.product?.inventoryInfo?.happyToWaitEnabled &&
        item?.product?.productType !== 'Service' &&
        item?.product?.productType !== 'Literature' &&
        item?.product?.productType !== 'Packaging' &&
        item?.product?.productType !== 'Case' &&
        item?.product?.productType !== 'Voucher' &&
        item?.product?.inventoryInfo?.availabilityStatus !== 'substitue_stock' &&
        item.quantity > (item.product?.inventoryInfo?.stockQuantity ?? 0),
    ),
  );
