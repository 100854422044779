export type IconsId =
  | 'abv'
  | 'add'
  | 'advanced-notes'
  | 'alcohol'
  | 'alert-circle-filled'
  | 'alert-circle'
  | 'alert-triangle'
  | 'amex'
  | 'app-subscription'
  | 'apple-pay'
  | 'armoa'
  | 'arrow-left'
  | 'award-winning'
  | 'award'
  | 'back'
  | 'biodynamic'
  | 'birthday-bonus'
  | 'blend-type'
  | 'bottle-glass'
  | 'bottle'
  | 'boxes'
  | 'calendar-clock'
  | 'calendar'
  | 'camera'
  | 'caps-lock'
  | 'cart'
  | 'case'
  | 'check'
  | 'Checkbox'
  | 'cheers'
  | 'chevron-down'
  | 'chevron-left'
  | 'chevron-right'
  | 'chevron-up'
  | 'chocolate'
  | 'choice-check'
  | 'choice-uncheck'
  | 'circle-chevron-left'
  | 'circle-chevron-right'
  | 'clear-circle'
  | 'close'
  | 'convenient-delivery'
  | 'cork-screw'
  | 'coupons'
  | 'credit-card'
  | 'customer-services'
  | 'customize'
  | 'customized-selections'
  | 'delete'
  | 'diamond'
  | 'discover'
  | 'dot-menu'
  | 'dots-menu'
  | 'droplet'
  | 'edit'
  | 'email'
  | 'envelope-box'
  | 'envelope'
  | 'expand'
  | 'eye'
  | 'facebook'
  | 'file-user'
  | 'filter'
  | 'flexibility'
  | 'free-delivery'
  | 'gift'
  | 'glass'
  | 'glasses'
  | 'google-pay'
  | 'grape'
  | 'green-tick'
  | 'hamburger-menu'
  | 'hand-truck'
  | 'heart-solid'
  | 'heart'
  | 'help-circle'
  | 'hide-password'
  | 'home'
  | 'homepage-customisation'
  | 'information-circle'
  | 'instagram'
  | 'kosher'
  | 'label-scanner'
  | 'last-sold-bg'
  | 'ligntning-solid'
  | 'linked-in'
  | 'live-chat'
  | 'location'
  | 'mastercard'
  | 'menu'
  | 'minus'
  | 'money-back-apac'
  | 'money-back-us'
  | 'money-back'
  | 'mute'
  | 'no-obligation'
  | 'offset-carbon'
  | 'organic'
  | 'packaging-pioneer'
  | 'pad-lock-benefits'
  | 'padlock'
  | 'pause'
  | 'paypal'
  | 'phone-arrow'
  | 'phone-solid'
  | 'phone'
  | 'pioneer'
  | 'placeholder'
  | 'play-video'
  | 'play'
  | 'print'
  | 'question-circle'
  | 'radio-selected'
  | 'radio'
  | 'red-alert-circle-filled'
  | 'restaurant-dishes'
  | 'rossette'
  | 'sale-tag-benefits'
  | 'sale-tag'
  | 'sale'
  | 'satisfaction-guarantee'
  | 'saving'
  | 'savings'
  | 'search'
  | 'share'
  | 'star-solid'
  | 'star'
  | 'stwc-blog'
  | 'subscribe-save'
  | 'sustainable'
  | 'target'
  | 'thumbs-down-solid'
  | 'thumbs-down'
  | 'thumbs-up-solid'
  | 'thumbs-up'
  | 'tick-circle-solid'
  | 'tick-circle'
  | 'ticket'
  | 'trending'
  | 'truck-benefits'
  | 'truck-solid'
  | 'truck'
  | 'trust'
  | 'twitter'
  | 'unidentified-country'
  | 'units'
  | 'unlimited-sale'
  | 'unlimited-save'
  | 'unlimited-truck'
  | 'unlimited'
  | 'unltCheckIcon'
  | 'user'
  | 'vegan'
  | 'vegetarian'
  | 'views'
  | 'vinny'
  | 'visa'
  | 'warning'
  | 'wine-colour'
  | 'wine-glass'
  | 'world'
  | 'x-twitter'
  | 'youtube'
  | 'zoom';

export type IconsKey =
  | 'Abv'
  | 'Add'
  | 'AdvancedNotes'
  | 'Alcohol'
  | 'AlertCircleFilled'
  | 'AlertCircle'
  | 'AlertTriangle'
  | 'Amex'
  | 'AppSubscription'
  | 'ApplePay'
  | 'Armoa'
  | 'ArrowLeft'
  | 'AwardWinning'
  | 'Award'
  | 'Back'
  | 'Biodynamic'
  | 'BirthdayBonus'
  | 'BlendType'
  | 'BottleGlass'
  | 'Bottle'
  | 'Boxes'
  | 'CalendarClock'
  | 'Calendar'
  | 'Camera'
  | 'CapsLock'
  | 'Cart'
  | 'Case'
  | 'Check'
  | 'Checkbox'
  | 'Cheers'
  | 'ChevronDown'
  | 'ChevronLeft'
  | 'ChevronRight'
  | 'ChevronUp'
  | 'Chocolate'
  | 'ChoiceCheck'
  | 'ChoiceUncheck'
  | 'CircleChevronLeft'
  | 'CircleChevronRight'
  | 'ClearCircle'
  | 'Close'
  | 'ConvenientDelivery'
  | 'CorkScrew'
  | 'Coupons'
  | 'CreditCard'
  | 'CustomerServices'
  | 'Customize'
  | 'CustomizedSelections'
  | 'Delete'
  | 'Diamond'
  | 'Discover'
  | 'DotMenu'
  | 'DotsMenu'
  | 'Droplet'
  | 'Edit'
  | 'Email'
  | 'EnvelopeBox'
  | 'Envelope'
  | 'Expand'
  | 'Eye'
  | 'Facebook'
  | 'FileUser'
  | 'Filter'
  | 'Flexibility'
  | 'FreeDelivery'
  | 'Gift'
  | 'Glass'
  | 'Glasses'
  | 'GooglePay'
  | 'Grape'
  | 'GreenTick'
  | 'HamburgerMenu'
  | 'HandTruck'
  | 'HeartSolid'
  | 'Heart'
  | 'HelpCircle'
  | 'HidePassword'
  | 'Home'
  | 'HomepageCustomisation'
  | 'InformationCircle'
  | 'Instagram'
  | 'Kosher'
  | 'LabelScanner'
  | 'LastSoldBg'
  | 'LigntningSolid'
  | 'LinkedIn'
  | 'LiveChat'
  | 'Location'
  | 'Mastercard'
  | 'Menu'
  | 'Minus'
  | 'MoneyBackApac'
  | 'MoneyBackUs'
  | 'MoneyBack'
  | 'Mute'
  | 'NoObligation'
  | 'OffsetCarbon'
  | 'Organic'
  | 'PackagingPioneer'
  | 'PadLockBenefits'
  | 'Padlock'
  | 'Pause'
  | 'Paypal'
  | 'PhoneArrow'
  | 'PhoneSolid'
  | 'Phone'
  | 'Pioneer'
  | 'Placeholder'
  | 'PlayVideo'
  | 'Play'
  | 'Print'
  | 'QuestionCircle'
  | 'RadioSelected'
  | 'Radio'
  | 'RedAlertCircleFilled'
  | 'RestaurantDishes'
  | 'Rossette'
  | 'SaleTagBenefits'
  | 'SaleTag'
  | 'Sale'
  | 'SatisfactionGuarantee'
  | 'Saving'
  | 'Savings'
  | 'Search'
  | 'Share'
  | 'StarSolid'
  | 'Star'
  | 'StwcBlog'
  | 'SubscribeSave'
  | 'Sustainable'
  | 'Target'
  | 'ThumbsDownSolid'
  | 'ThumbsDown'
  | 'ThumbsUpSolid'
  | 'ThumbsUp'
  | 'TickCircleSolid'
  | 'TickCircle'
  | 'Ticket'
  | 'Trending'
  | 'TruckBenefits'
  | 'TruckSolid'
  | 'Truck'
  | 'Trust'
  | 'Twitter'
  | 'UnidentifiedCountry'
  | 'Units'
  | 'UnlimitedSale'
  | 'UnlimitedSave'
  | 'UnlimitedTruck'
  | 'Unlimited'
  | 'UnltCheckIcon'
  | 'User'
  | 'Vegan'
  | 'Vegetarian'
  | 'Views'
  | 'Vinny'
  | 'Visa'
  | 'Warning'
  | 'WineColour'
  | 'WineGlass'
  | 'World'
  | 'XTwitter'
  | 'Youtube'
  | 'Zoom';

export enum Icons {
  Abv = 'abv',
  Add = 'add',
  AdvancedNotes = 'advanced-notes',
  Alcohol = 'alcohol',
  AlertCircleFilled = 'alert-circle-filled',
  AlertCircle = 'alert-circle',
  AlertTriangle = 'alert-triangle',
  Amex = 'amex',
  AppSubscription = 'app-subscription',
  ApplePay = 'apple-pay',
  Armoa = 'armoa',
  ArrowLeft = 'arrow-left',
  AwardWinning = 'award-winning',
  Award = 'award',
  Back = 'back',
  Biodynamic = 'biodynamic',
  BirthdayBonus = 'birthday-bonus',
  BlendType = 'blend-type',
  BottleGlass = 'bottle-glass',
  Bottle = 'bottle',
  Boxes = 'boxes',
  CalendarClock = 'calendar-clock',
  Calendar = 'calendar',
  Camera = 'camera',
  CapsLock = 'caps-lock',
  Cart = 'cart',
  Case = 'case',
  Check = 'check',
  Checkbox = 'Checkbox',
  Cheers = 'cheers',
  ChevronDown = 'chevron-down',
  ChevronLeft = 'chevron-left',
  ChevronRight = 'chevron-right',
  ChevronUp = 'chevron-up',
  Chocolate = 'chocolate',
  ChoiceCheck = 'choice-check',
  ChoiceUncheck = 'choice-uncheck',
  CircleChevronLeft = 'circle-chevron-left',
  CircleChevronRight = 'circle-chevron-right',
  ClearCircle = 'clear-circle',
  Close = 'close',
  ConvenientDelivery = 'convenient-delivery',
  CorkScrew = 'cork-screw',
  Coupons = 'coupons',
  CreditCard = 'credit-card',
  CustomerServices = 'customer-services',
  Customize = 'customize',
  CustomizedSelections = 'customized-selections',
  Delete = 'delete',
  Diamond = 'diamond',
  Discover = 'discover',
  DotMenu = 'dot-menu',
  DotsMenu = 'dots-menu',
  Droplet = 'droplet',
  Edit = 'edit',
  Email = 'email',
  EnvelopeBox = 'envelope-box',
  Envelope = 'envelope',
  Expand = 'expand',
  Eye = 'eye',
  Facebook = 'facebook',
  FileUser = 'file-user',
  Filter = 'filter',
  Flexibility = 'flexibility',
  FreeDelivery = 'free-delivery',
  Gift = 'gift',
  Glass = 'glass',
  Glasses = 'glasses',
  GooglePay = 'google-pay',
  Grape = 'grape',
  GreenTick = 'green-tick',
  HamburgerMenu = 'hamburger-menu',
  HandTruck = 'hand-truck',
  HeartSolid = 'heart-solid',
  Heart = 'heart',
  HelpCircle = 'help-circle',
  HidePassword = 'hide-password',
  Home = 'home',
  HomepageCustomisation = 'homepage-customisation',
  InformationCircle = 'information-circle',
  Instagram = 'instagram',
  Kosher = 'kosher',
  LabelScanner = 'label-scanner',
  LastSoldBg = 'last-sold-bg',
  LigntningSolid = 'ligntning-solid',
  LinkedIn = 'linked-in',
  LiveChat = 'live-chat',
  Location = 'location',
  Mastercard = 'mastercard',
  Menu = 'menu',
  Minus = 'minus',
  MoneyBackApac = 'money-back-apac',
  MoneyBackUs = 'money-back-us',
  MoneyBack = 'money-back',
  Mute = 'mute',
  NoObligation = 'no-obligation',
  OffsetCarbon = 'offset-carbon',
  Organic = 'organic',
  PackagingPioneer = 'packaging-pioneer',
  PadLockBenefits = 'pad-lock-benefits',
  Padlock = 'padlock',
  Pause = 'pause',
  Paypal = 'paypal',
  PhoneArrow = 'phone-arrow',
  PhoneSolid = 'phone-solid',
  Phone = 'phone',
  Pioneer = 'pioneer',
  Placeholder = 'placeholder',
  PlayVideo = 'play-video',
  Play = 'play',
  Print = 'print',
  QuestionCircle = 'question-circle',
  RadioSelected = 'radio-selected',
  Radio = 'radio',
  RedAlertCircleFilled = 'red-alert-circle-filled',
  RestaurantDishes = 'restaurant-dishes',
  Rossette = 'rossette',
  SaleTagBenefits = 'sale-tag-benefits',
  SaleTag = 'sale-tag',
  Sale = 'sale',
  SatisfactionGuarantee = 'satisfaction-guarantee',
  Saving = 'saving',
  Savings = 'savings',
  Search = 'search',
  Share = 'share',
  StarSolid = 'star-solid',
  Star = 'star',
  StwcBlog = 'stwc-blog',
  SubscribeSave = 'subscribe-save',
  Sustainable = 'sustainable',
  Target = 'target',
  ThumbsDownSolid = 'thumbs-down-solid',
  ThumbsDown = 'thumbs-down',
  ThumbsUpSolid = 'thumbs-up-solid',
  ThumbsUp = 'thumbs-up',
  TickCircleSolid = 'tick-circle-solid',
  TickCircle = 'tick-circle',
  Ticket = 'ticket',
  Trending = 'trending',
  TruckBenefits = 'truck-benefits',
  TruckSolid = 'truck-solid',
  Truck = 'truck',
  Trust = 'trust',
  Twitter = 'twitter',
  UnidentifiedCountry = 'unidentified-country',
  Units = 'units',
  UnlimitedSale = 'unlimited-sale',
  UnlimitedSave = 'unlimited-save',
  UnlimitedTruck = 'unlimited-truck',
  Unlimited = 'unlimited',
  UnltCheckIcon = 'unltCheckIcon',
  User = 'user',
  Vegan = 'vegan',
  Vegetarian = 'vegetarian',
  Views = 'views',
  Vinny = 'vinny',
  Visa = 'visa',
  Warning = 'warning',
  WineColour = 'wine-colour',
  WineGlass = 'wine-glass',
  World = 'world',
  XTwitter = 'x-twitter',
  Youtube = 'youtube',
  Zoom = 'zoom',
}

export const ICONS_CODEPOINTS: { [key in Icons]: string } = {
  [Icons.Abv]: '61697',
  [Icons.Add]: '61698',
  [Icons.AdvancedNotes]: '61699',
  [Icons.Alcohol]: '61700',
  [Icons.AlertCircleFilled]: '61701',
  [Icons.AlertCircle]: '61702',
  [Icons.AlertTriangle]: '61703',
  [Icons.Amex]: '61704',
  [Icons.AppSubscription]: '61705',
  [Icons.ApplePay]: '61706',
  [Icons.Armoa]: '61707',
  [Icons.ArrowLeft]: '61708',
  [Icons.AwardWinning]: '61709',
  [Icons.Award]: '61710',
  [Icons.Back]: '61711',
  [Icons.Biodynamic]: '61712',
  [Icons.BirthdayBonus]: '61713',
  [Icons.BlendType]: '61714',
  [Icons.BottleGlass]: '61715',
  [Icons.Bottle]: '61716',
  [Icons.Boxes]: '61717',
  [Icons.CalendarClock]: '61718',
  [Icons.Calendar]: '61719',
  [Icons.Camera]: '61720',
  [Icons.CapsLock]: '61721',
  [Icons.Cart]: '61722',
  [Icons.Case]: '61723',
  [Icons.Check]: '61724',
  [Icons.Checkbox]: '61725',
  [Icons.Cheers]: '61726',
  [Icons.ChevronDown]: '61727',
  [Icons.ChevronLeft]: '61728',
  [Icons.ChevronRight]: '61729',
  [Icons.ChevronUp]: '61730',
  [Icons.Chocolate]: '61731',
  [Icons.ChoiceCheck]: '61732',
  [Icons.ChoiceUncheck]: '61733',
  [Icons.CircleChevronLeft]: '61734',
  [Icons.CircleChevronRight]: '61735',
  [Icons.ClearCircle]: '61736',
  [Icons.Close]: '61737',
  [Icons.ConvenientDelivery]: '61738',
  [Icons.CorkScrew]: '61739',
  [Icons.Coupons]: '61740',
  [Icons.CreditCard]: '61741',
  [Icons.CustomerServices]: '61742',
  [Icons.Customize]: '61743',
  [Icons.CustomizedSelections]: '61744',
  [Icons.Delete]: '61745',
  [Icons.Diamond]: '61746',
  [Icons.Discover]: '61747',
  [Icons.DotMenu]: '61748',
  [Icons.DotsMenu]: '61749',
  [Icons.Droplet]: '61750',
  [Icons.Edit]: '61751',
  [Icons.Email]: '61752',
  [Icons.EnvelopeBox]: '61753',
  [Icons.Envelope]: '61754',
  [Icons.Expand]: '61755',
  [Icons.Eye]: '61756',
  [Icons.Facebook]: '61757',
  [Icons.FileUser]: '61758',
  [Icons.Filter]: '61759',
  [Icons.Flexibility]: '61760',
  [Icons.FreeDelivery]: '61761',
  [Icons.Gift]: '61762',
  [Icons.Glass]: '61763',
  [Icons.Glasses]: '61764',
  [Icons.GooglePay]: '61765',
  [Icons.Grape]: '61766',
  [Icons.GreenTick]: '61767',
  [Icons.HamburgerMenu]: '61768',
  [Icons.HandTruck]: '61769',
  [Icons.HeartSolid]: '61770',
  [Icons.Heart]: '61771',
  [Icons.HelpCircle]: '61772',
  [Icons.HidePassword]: '61773',
  [Icons.Home]: '61774',
  [Icons.HomepageCustomisation]: '61775',
  [Icons.InformationCircle]: '61776',
  [Icons.Instagram]: '61777',
  [Icons.Kosher]: '61778',
  [Icons.LabelScanner]: '61779',
  [Icons.LastSoldBg]: '61780',
  [Icons.LigntningSolid]: '61781',
  [Icons.LinkedIn]: '61782',
  [Icons.LiveChat]: '61783',
  [Icons.Location]: '61784',
  [Icons.Mastercard]: '61785',
  [Icons.Menu]: '61786',
  [Icons.Minus]: '61787',
  [Icons.MoneyBackApac]: '61788',
  [Icons.MoneyBackUs]: '61789',
  [Icons.MoneyBack]: '61790',
  [Icons.Mute]: '61791',
  [Icons.NoObligation]: '61792',
  [Icons.OffsetCarbon]: '61793',
  [Icons.Organic]: '61794',
  [Icons.PackagingPioneer]: '61795',
  [Icons.PadLockBenefits]: '61796',
  [Icons.Padlock]: '61797',
  [Icons.Pause]: '61798',
  [Icons.Paypal]: '61799',
  [Icons.PhoneArrow]: '61800',
  [Icons.PhoneSolid]: '61801',
  [Icons.Phone]: '61802',
  [Icons.Pioneer]: '61803',
  [Icons.Placeholder]: '61804',
  [Icons.PlayVideo]: '61805',
  [Icons.Play]: '61806',
  [Icons.Print]: '61807',
  [Icons.QuestionCircle]: '61808',
  [Icons.RadioSelected]: '61809',
  [Icons.Radio]: '61810',
  [Icons.RedAlertCircleFilled]: '61811',
  [Icons.RestaurantDishes]: '61812',
  [Icons.Rossette]: '61813',
  [Icons.SaleTagBenefits]: '61814',
  [Icons.SaleTag]: '61815',
  [Icons.Sale]: '61816',
  [Icons.SatisfactionGuarantee]: '61817',
  [Icons.Saving]: '61818',
  [Icons.Savings]: '61819',
  [Icons.Search]: '61820',
  [Icons.Share]: '61821',
  [Icons.StarSolid]: '61822',
  [Icons.Star]: '61823',
  [Icons.StwcBlog]: '61824',
  [Icons.SubscribeSave]: '61825',
  [Icons.Sustainable]: '61826',
  [Icons.Target]: '61827',
  [Icons.ThumbsDownSolid]: '61828',
  [Icons.ThumbsDown]: '61829',
  [Icons.ThumbsUpSolid]: '61830',
  [Icons.ThumbsUp]: '61831',
  [Icons.TickCircleSolid]: '61832',
  [Icons.TickCircle]: '61833',
  [Icons.Ticket]: '61834',
  [Icons.Trending]: '61835',
  [Icons.TruckBenefits]: '61836',
  [Icons.TruckSolid]: '61837',
  [Icons.Truck]: '61838',
  [Icons.Trust]: '61839',
  [Icons.Twitter]: '61840',
  [Icons.UnidentifiedCountry]: '61841',
  [Icons.Units]: '61842',
  [Icons.UnlimitedSale]: '61843',
  [Icons.UnlimitedSave]: '61844',
  [Icons.UnlimitedTruck]: '61845',
  [Icons.Unlimited]: '61846',
  [Icons.UnltCheckIcon]: '61847',
  [Icons.User]: '61848',
  [Icons.Vegan]: '61849',
  [Icons.Vegetarian]: '61850',
  [Icons.Views]: '61851',
  [Icons.Vinny]: '61852',
  [Icons.Visa]: '61853',
  [Icons.Warning]: '61854',
  [Icons.WineColour]: '61855',
  [Icons.WineGlass]: '61856',
  [Icons.World]: '61857',
  [Icons.XTwitter]: '61858',
  [Icons.Youtube]: '61859',
  [Icons.Zoom]: '61860',
};
