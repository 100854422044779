import { globals } from 'common-ui';
import { getAlgoliaFacets } from '@algolia/autocomplete-js';
import { typeaheadCheck } from '../utils';
import type { GetSourcesParams, OnSelectParams } from '@algolia/autocomplete-core';
import type { SearchClient } from 'algoliasearch/lite';
import type { BaseItem } from './types';

const CollectionPluginHeader = () => <span className="aa-SourceHeaderTitle"> Collections </span>;
const formatLabel = (label: string) => label
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
const CollectionPluginItem = ({ item }: any) => {
  const displayLabel = formatLabel(item.label);
  return (
    <a href={item.url} rel="noopener noreferrer">
      {displayLabel}
    </a>
  );
};

export function createCollectionPlugin(searchClient: SearchClient) {
  return {
    getSources({ query: sourceQuery }: GetSourcesParams<BaseItem>) {
      if (!sourceQuery || !typeaheadCheck(sourceQuery)) {
        return [];
      }
      return [
        {
          sourceId: 'collectionPlugin',
          getItems() {
            return getAlgoliaFacets({
              searchClient,
              queries: [
                {
                  indexName: globals.algoliaIndexName as string,
                  facet: 'categoryPageId',
                  params: { facetQuery: sourceQuery, maxFacetHits: 3 },
                },
              ],
            });
          },
          onSelect({ item }: OnSelectParams<BaseItem>) {
            window.location.href = `/wine/${item.label}`;
          },
          templates: {
            header: CollectionPluginHeader,
            item: CollectionPluginItem,
          },
        },
      ];
    },
  };
}
