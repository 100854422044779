import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches';

const RecentSearchPluginHeader = () => <span className="aa-SourceHeaderTitle"> Recent searches </span>;

export const createRecentSearchesPlugin = (updateUiState: (state: any) => void) =>
  createLocalStorageRecentSearchesPlugin({
    key: 'RECENT_SEARCH',
    limit: 5,
    transformSource({ source, state }) {
      if (state.query) {
        return {
          ...source,
          getItems() {
            return [];
          },
        };
      }

      return {
        ...source,
        onSelect({ item }) {
          if (!state.query) {
            updateUiState({
              query: item.label,
              category: item.category,
            } as any);
          }
        },
        templates: {
          ...source.templates,
          header: RecentSearchPluginHeader,
        },
      };
    },
  });
