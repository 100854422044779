import { css, useTheme } from '@emotion/react';
import React, { memo } from 'react';
import type {
  SiteEntryType,
  NavigationEntryType,
  ImageEntryType,
  LinkEntryType,
  GuideLinePolicyEntryType,
} from 'tsconfig/types';
import ContainerLayout from '../../layouts/ContainerLayout';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import Grid from '../grid/Grid';
import { Icon } from '../Icon/Icon';
import NavItem from './NavItem';
import { CmsImage } from '../contentful/CmsImage/CmsImage';
import Image from 'next/image';
import { inspectorMode, Link } from 'common-ui';
import { handleDLClickEvent } from '../../data-layer/handleDLClickEvent';
import { globals } from '../../utils/globals';

export type GlobalFooterProps = {
  cmsSiteConfig?: SiteEntryType;
};

export const GlobalFooter = memo((props: GlobalFooterProps) => {
  const theme = useTheme();
  const wrapper = css`
    ${theme.widgets.Footer?.default}
  `;
  const {
    footerNavBar,
    copyright,
    guidelinePolicy,
    badgeImages,
    socialLinks = [],
  } = props?.cmsSiteConfig?.fields || {};

  //To get the DL attributes and pass it to the HandleDLEvent
  const dlClickEventHandler = (data: string, clickType?: string) => {
    let type = clickType;
    const phonePattern = /[0-9-]/;
    if (globals.country === 'us') {
      if (phonePattern.test(data)) {
        type = 'phoneClick';
      } else if (data.toLowerCase().includes('live chat')) {
        type = 'liveChatInteraction';
      } else if (data.toLowerCase().includes('email')) {
        type = 'emailClick';
      }
    }

    if (type) {
      handleDLClickEvent(data, type);
    }
  };

  return (
    <div css={wrapper} data-test="footer" className="no-print">
      <ContainerLayout>
        {footerNavBar && (
          <section className="footer-nav-links" aria-label="Footer links" data-test="links-section">
            <Grid className="wrapper" justify={'between'}>
              {(footerNavBar as NavigationEntryType[])?.map(({ fields }) => (
                <NavItem key={`nav-${fields?.title}`} fields={fields} dlClickEventHandler={dlClickEventHandler} />
              ))}
            </Grid>
            {/* <!--BE IXF: For your website, the location is   --> */}
            <div className="be-ix-link-block"></div> {/*<!--Link Block Target Div-->*/}
            {/* <!--BE IXF: end --> */}
          </section>
        )}
        {guidelinePolicy && (
          <section className="guidelines-policies" data-test="guidelines-section">
            <Grid className="wrapper" align={'center'} justify="around">
              {(guidelinePolicy as GuideLinePolicyEntryType[]).map(({ fields, sys }) => (
                <Grid.Col key={`col-${sys.id}`}>
                  {fields?.image && (
                    <Image
                      src={fields.image.fields?.file?.url || ''}
                      alt={fields.image.fields?.description || ''}
                      width={fields.image.fields?.file?.details?.image?.width}
                      height={fields.image.fields?.file?.details?.image?.height}
                      {...inspectorMode(sys.id, 'image')}
                    />
                  )}
                  <div {...inspectorMode(sys.id, 'title')}>
                    <p className="title">{fields?.title}</p>
                    <p className="desc">{fields?.description}</p>
                  </div>
                </Grid.Col>
              ))}
            </Grid>
          </section>
        )}
        {socialLinks?.length > 0 && (
          <section className="social-connections" data-test="social-section">
            <Grid className="wrapper" align={'center'} justify={'center'} noGutter>
              {socialLinks?.length < 3 && (
                <Grid.Col xs="auto" className="social-connection-heading">
                  <p>Follow Us</p>
                </Grid.Col>
              )}
              {(socialLinks as LinkEntryType[]).map(({ fields }) => (
                <Grid.Col xs="auto" key={`social-${fields?.title}`}>
                  <Link
                    prefetch={false}
                    href={fields?.url ?? ''}
                    onClick={() => globals.country === 'us' && handleDLClickEvent(fields?.title, 'socialShare')}
                  >
                    <Icon kind={fields?.title} size="l" data-test={fields?.title} />
                  </Link>
                </Grid.Col>
              ))}
            </Grid>
          </section>
        )}
        <section className="footer-bottom-section" data-test="bottom-section">
          <Grid className="wrapper" align={'center'} justify={'between'} noGutter>
            <Grid.Col lg={4} className="copyrights">
              <div {...inspectorMode(props?.cmsSiteConfig?.sys?.id, 'copyright')}>
                {documentToReactComponents(copyright as Document)}
              </div>
            </Grid.Col>
            <Grid.Col lg={4} className="brand">
              <a href="/" className="footer-logo">
                <picture>
                  <Image
                    src={theme.assets.footerLogoImgUrl || ''}
                    alt="Brand Logo"
                    sizes="(min-width: 1230px) 10em, 13.5em"
                    width={91}
                    height={91}
                    layout="responsive"
                    objectFit="cover"
                    className="brand-footer-img"
                  />
                </picture>
              </a>
            </Grid.Col>
            <Grid.Col lg={4} className="bedrinkaware-group">
              {(badgeImages as LinkEntryType[])?.map(({ fields }, index) => {
                const iconImage = fields?.icon && (
                  <CmsImage
                    contentItem={fields?.icon as ImageEntryType}
                    disableAutoSize={false}
                    isNextImg={true}
                    key={`bedrinkaware-${fields.title}-${index + 1}`}
                    sizes="120px"
                    width={120}
                    height={120}
                  />
                );
                if (fields.url) {
                  return (
                    <Link key={`bedrinkaware-${fields.url}`} prefetch={false} href={fields?.url ?? ''}>
                      {iconImage}
                    </Link>
                  );
                }
                return iconImage;
              })}
            </Grid.Col>
          </Grid>
        </section>
      </ContainerLayout>
    </div>
  );
});
