import { initCommerceApiWithGlobals } from 'common-api';
import { useState, useEffect, useRef } from 'react';
import type { ProductProps } from 'tsconfig/types';

type ProductCache = {
  [key: string]: ProductProps;
};

const cache: ProductCache = {};
const pendingRequests: Map<string, Promise<ProductProps[]>> = new Map();

const useProductData = (productId: string) => {
  const productCardAPI: any = initCommerceApiWithGlobals();
  const [product, setProduct] = useState<ProductProps | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const cacheRef = useRef<ProductCache>(cache);

  useEffect(() => {
    const fetchProductData = async () => {
      setLoading(true);
      if (cacheRef.current[productId]) {
        setProduct(cacheRef.current[productId]);
        setLoading(false);
        return;
      }
      if (pendingRequests.has(productId)) {
        const existingRequest = pendingRequests.get(productId);
        if (existingRequest) {
          const productData = await existingRequest;
          setProduct(productData?.[0]);
          setLoading(false);
          return;
        }
      }
      const request = productCardAPI.Product.getAllProductData(productId)
        .then((response: string) => response)
        .then((productData: ProductProps[]) => {
          const [firstProduct] = productData;
          cacheRef.current[productId] = firstProduct;
          setProduct(firstProduct);
          pendingRequests.delete(productId);
          return productData;
        })
        .catch(() => {
          pendingRequests.delete(productId);
        });
      pendingRequests.set(productId, request);
      const productData = await request;
      setProduct(productData?.[0]);
      setLoading(false);
    };
    fetchProductData();
  }, [productId]);
  return { product, loading };
};

export default useProductData;
