import React, { useState, useEffect } from 'react';
import { brandConfig } from '../brandConfig';
import { globals } from '../../../utils/globals';
import type { AutocompleteComponents } from '@algolia/autocomplete-js';

export type ProductItemProps = {
  components: AutocompleteComponents;
  hit: any;
  isRecommended?: boolean;
};

const nonWineTypes = ['Beer', 'Cider', 'Spirits & Liqueur', 'Food & Drink'];

export const ProductCard = ({ hit, components, isRecommended = false }: ProductItemProps) => {
  const brand = brandConfig();
  const [imageSrc, setImageSrc] = useState('');

  const handleImage = (itemCode: string) => {
    if (globals.country === 'uk') {
      return `${globals.publicHost}/images/uk/en/law/product/${itemCode}.png`;
    }
    if (hit.productType === 'Case') {
      return `${brand?.imagePathForCases}/product/${itemCode}${brand?.imageTypeForCases}`;
    }
    return `${brand?.imagePathForProducts}/product/${itemCode}${brand?.imageType}`;
  };

  useEffect(() => {
    setImageSrc(handleImage(hit.itemCode));
  }, [hit.itemCode]);

  const handleImageError = () => {
    setImageSrc(
      hit.productType === 'Case' ? `${brand?.defaultImagePathForCases}` : `${brand?.defaultImagePathForProducts}`,
    );
  };

  const renderCountryInfo = () => {
    const { productType, productWebType, country, grape } = hit;
    if (productType.toLowerCase() === 'wine') {
      return [country, grape].filter(Boolean).join(' | ');
    }
    if (
      (productType === 'Case' && productWebType === 'Beer') ||
      (productType === 'NonWine' && nonWineTypes.includes(productWebType))
    ) {
      return country || null;
    }
    return null;
  };

  const countryInfo = renderCountryInfo();

  // Price logic
  const [firstSku] = hit.skus;
  const { enPrimeurPrice, presellPayments, lowestPricePerBottle, buyersRRP, vppPrice, salePrice } = firstSku;

  const presellPrice = presellPayments?.[0]?.paymentAmount ?? vppPrice ?? lowestPricePerBottle;
  const enPrimeurDisplayPrice = enPrimeurPrice ?? vppPrice ?? lowestPricePerBottle;
  const defaultPrice = vppPrice ?? lowestPricePerBottle;

  let displayPrice;
  let overrideFromText = false;
  let hideBuyersRRP = false;

  if (hit.preSell) {
    displayPrice = presellPrice;
    overrideFromText = true;
    hideBuyersRRP = true;
  } else if (hit.enPrimeur) {
    displayPrice = enPrimeurDisplayPrice;
    overrideFromText = true;
    hideBuyersRRP = true;
  } else if (hit.productType.toLowerCase() === 'case' && salePrice) {
    displayPrice = salePrice;
    overrideFromText = true;
  } else if (nonWineTypes.includes(hit.productWebType) && salePrice) {
    displayPrice = salePrice;
    overrideFromText = true;
    hideBuyersRRP = true;
  } else {
    displayPrice = defaultPrice;
  }

  const showFromText = !overrideFromText;
  const showBuyersRRP = !hideBuyersRRP && buyersRRP && buyersRRP !== displayPrice;

  return (
    <div
      className={`aa-ItemWrapper ${isRecommended ? 'recommended-section' : 'product-section'} ${brand?.country}`}
      role="presentation"
    >
      <div className="aa-ItemContent">
        <div
          className={`aa-ItemIcon aa-ItemIcon--alignTop ${globals.country === 'us' ? 'us-results-image' : 'results-image'}`}
        >
          <img src={imageSrc} alt={hit.productName} onError={handleImageError} />
        </div>
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            {components.Highlight({
              hit,
              attribute: 'productName',
            })}{' '}
            {hit.vintage}
          </div>
          {globals?.algoliaNewView && (
            <>
              {countryInfo && (
                <div className="aa-ItemContentCountry" data-testid="country-info">
                  {countryInfo}
                </div>
              )}
              {typeof displayPrice === 'number' && !isNaN(displayPrice) && (
                <div className="aa-ItemContentPrice" data-testid="price-block">
                  {showFromText && <span>from </span>}
                  {globals.currency}
                  {displayPrice.toFixed(2)}{' '}
                  {showBuyersRRP && (
                    <span className="strike">
                      {globals.currency}
                      {buyersRRP?.toFixed(2)}
                    </span>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
