import type { LineItem } from 'tsconfig/types';

/**
 * Checks if a line item has a bulk deal discount applied
 * @param item - The line item to check for bulk deal discount
 * @param discountName - The name of the discount to search for
 * @returns Boolean indicating if the item has specified bulk deal discount
 *
 * @example
 * ```typescript
 * const hasDiscount = hasBulkDealDiscount(lineItem, "bulk10");
 * Returns true if lineItem has a "bulk10" discount applied
 * ```
 */
export const hasBulkDealDiscount = (item: LineItem | undefined, discountName: string): boolean =>
  item?.discountedPricePerQuantity?.some(dpq =>
    dpq?.discountedPrice?.includedDiscounts?.some(discount =>
      discount?.discount?.name?.toLowerCase().includes(discountName.toLocaleLowerCase()),
    ),
  ) ?? false;
