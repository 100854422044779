import React, { memo, useEffect, useState } from 'react';
import type { RichTextEntryType } from 'tsconfig/types.d';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { fontCorrectionInRichText } from '../../../utils/fontCorrectionInRichText';
import RichText from '../../RichText/RichText';
import { contentToSanitize, parseHtmlContent } from '../../../utils/richTextHelper';

export type CmsRichTextProps = {
  contentItem: RichTextEntryType;
};

export const CmsRichText = memo((props: CmsRichTextProps) => {
  const { contentItem } = props;
  const theme = useTheme();
  const widgetTheme = theme.widgets.CmsRichText || {};
  const Wrapper = styled.section`
    ${contentItem?.fields?.backgroundColour && ` background-color: ${contentItem?.fields?.backgroundColour}`}
    ${widgetTheme.wrapper}
  `;

  const [modifiedContent, setModifiedContent] = useState('');

  useEffect(() => {
    const updatedContent = parseHtmlContent(fontCorrectionInRichText(contentToSanitize(contentItem), theme.fonts));
    setModifiedContent(updatedContent);
  }, [contentItem]);

  //TODO: to support asset links in rich text
  //see https://www.contentful.com/developers/docs/concepts/rich-text/
  return (
    <Wrapper
      data-test="contentful-textbox"
      className={`richtext-wrapper ${contentItem?.fields?.backgroundColour ? 'richtext-has-bg' : ''}`}
    >
      {typeof contentItem?.fields?.content === 'string' && (
        <RichText sysId={contentItem.sys.id} content={modifiedContent} />
      )}
    </Wrapper>
  );
});
