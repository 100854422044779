import { useState, useEffect, memo } from 'react';
import { CmsImage, CmsLink, globals, Icon, Rating, saleTag } from 'common-ui';
import Image from 'next/image';
import { CartButton } from '../AddToCart/Button';
import { css, useTheme } from '@emotion/react';
import type { ColourField, ImageEntryType, ProductCardEntryType, RichTextEntryType } from 'tsconfig/types';
import { useScreenLayout } from 'common-ui';

import { formatCurrency } from '../../utils/formatCurrency';
import { productLinkBuilder } from 'common-next/src/components/common-functions';
import { ProductCardSkeleton } from '../Skeleton/ProductCard/ProductCard';
import { CmsRichText } from '../contentful/CmsRichText/CmsRichText';
import { useDisclosure } from '@mantine/hooks';
import Zoom from '../ItemCard/Zoom';

export interface ProductCardPriceTypes {
  productPrice?: number | null;
  productPriceOriginal?: number | null;
  productMemberPrice?: number | null;
  productNumberOfBottles?: number | null;
}

export interface ProductCardProps {
  productName: string;
  productRating?: number;
  productReviewCount?: number;
  productPrice?: number | null;
  productPriceOriginal?: number | null;
  productImage?: string;
  productVintage?: string;
  productCardBackgroundImage?: ImageEntryType;
  productCardBackgroundColor?: ColourField;
  productCardButton?: string;
  productCode: string;
  productCardVariant?: string;
  productType?: string;
  productPricePrefix?: string;
  productNumberOfBottles?: number | null;
  productMemberPrice?: number | null;
  productCardTitle?: string;
  productCardSubTitle?: string;
  productDescription?: string;
  productCardDescription?: RichTextEntryType | null;
  productImageOverride?: ImageEntryType;
  productImageSecondary?: ImageEntryType;
  productLink?: ProductCardEntryType['fields']['productLink'];
  multiViewPrices?: Array<ProductCardPriceTypes>;
  multiViewPriceDiff: boolean;
  isLoading?: boolean;
  hidePriceOnMultiView?: boolean;
}

const ProductCardPrice = (props: ProductCardPriceTypes) => {
  const { productPrice, productPriceOriginal, productNumberOfBottles, productMemberPrice } = props || {};

  return (
    <>
      <div className="product-price body1">
        {productNumberOfBottles && productNumberOfBottles !== 0 ? (
          <span className="product-card__bottles">
            {productNumberOfBottles} bottle{`${productNumberOfBottles > 1 ? 's :' : ' :'}`}
          </span>
        ) : (
          ''
        )}
        {productPrice && productPrice !== null && (
          <>
            {productPriceOriginal && productPriceOriginal !== null && productPriceOriginal !== 0 ? (
              <s className="product-price__original">{formatCurrency(productPriceOriginal)}</s>
            ) : (
              ''
            )}
            <span className="body1 bold">{formatCurrency(productPrice)}</span>
          </>
        )}
      </div>

      {productMemberPrice && productMemberPrice !== 0 ? (
        <span className="product-card__member-price body1 bold">
          {formatCurrency(productMemberPrice)} {globals?.brandId === 'wsj' ? 'Advantage' : 'Unlimited'} Member Price
        </span>
      ) : (
        ''
      )}
    </>
  );
};

export const ProductCard = memo((props: ProductCardProps) => {
  const theme = useTheme();
  const {
    productName,
    productRating,
    productReviewCount,
    productPrice,
    productPriceOriginal,
    productPricePrefix,
    productImage,
    productVintage,
    productCardBackgroundImage,
    productCardBackgroundColor,
    productCardVariant,
    productCardButton,
    productCode,
    productType,
    productNumberOfBottles,
    productMemberPrice,
    productDescription,
    productCardTitle,
    productCardSubTitle,
    productCardDescription,
    productImageOverride,
    productImageSecondary,
    productLink,
    multiViewPrices,
    multiViewPriceDiff,
    isLoading,
    hidePriceOnMultiView,
  } = props || {};

  const [imageURL, setImageURL] = useState('');
  const [opened, { open, close }] = useDisclosure(false);
  const { screen, isMobile } = useScreenLayout();
  const largeProductMultiView = productCardVariant === 'Large Product Multi View';
  const fullBleedBackground = productCardVariant === 'Full bleed background';
  const standardProductCard = productCardVariant === 'Standard product card';

  useEffect(() => {
    if (largeProductMultiView && productImageOverride) {
      const imageSrc =
        screen && isMobile && productImageOverride?.fields?.smallDeviceImage?.fields?.file?.url
          ? productImageOverride?.fields?.smallDeviceImage?.fields?.file?.url
          : productImageOverride?.fields?.largeDeviceImage?.fields?.file?.url;
      setImageURL(imageSrc as string);
    } else {
      setImageURL(productImage as string);
    }
  }, [productImage, productCardVariant, productImageOverride]);

  const handleImageError = () => {
    setImageURL(
      `${globals.publicHost}${globals.defaultImagePath}/${productType === 'Case' ? 'default_case' : 'default_bottle'}.png`,
    );
  };

  const productCard = css`
    ${fullBleedBackground
      ? theme.widgets.Product?.productCardFullBleed
      : `${
          standardProductCard ? theme.widgets.Product?.productCardStandard : theme.widgets.Product?.productCardDetailed
        }`}
  `;

  const productCardImage = css`
    ${largeProductMultiView ? theme.widgets.Product?.productCardImageMultiView : ''}
  `;

  const isCase = productType === 'Case' ? true : false;

  if (isLoading) {
    return <ProductCardSkeleton />;
  }

  const productData = () => ({
    productName: productName ?? '',
    vintage: productVintage ?? '',
    itemCode: productCode ?? '',
    giftFlag: false,
    avgRating: productRating ?? 0,
    skus: [],
    packaging: '',
    productType: productType ?? '',
    productWebType: '',
    largeImage: '',
    price: productPrice ?? 0,
    buyersRRP: productPrice ?? 0,
  });

  const ProductInfoDefault = () => (
    <div className="product-card-info">
      {saleTag(productVintage) && (
        <div className="product-sale-status">
          <Icon kind="sale" size="m" />
          <p className="product-on-sale-text">On Sale</p>
        </div>
      )}
      <div className="product-info-and-button">
        <div>
          <div className="product-name-box">
            <a href={productLinkBuilder(productName, productVintage, productCode)}>
              {fullBleedBackground ? (
                <label className="product-name">
                  {productName} {productVintage}
                </label>
              ) : (
                <>
                  {productName} {productVintage}
                </>
              )}
            </a>
          </div>
          {productRating || productReviewCount ? (
            <div className="product-rating">
              {productRating && <Rating rating={productRating} />}
              {productReviewCount && <span>({productReviewCount})</span>}
            </div>
          ) : (
            <div className="product-rating"></div>
          )}
          <div className="product-price">
            {productPrice && (
              <>
                {productPricePrefix || ''}
                <span>{formatCurrency(productPrice)}</span> each
              </>
            )}
          </div>
        </div>
        {productCardButton && (
          <div className="product-card-button">
            {productCardButton.toLocaleLowerCase() === 'Add to Basket'.toLocaleLowerCase() ? (
              <CartButton itemCode={productCode} qty={'1'} buttonText={productCardButton} />
            ) : (
              <a href={productLinkBuilder(productName, productVintage, productCode)} className="view-product-button">
                {productCardButton}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );

  const ProductInfoDetailed = () => (
    <div className="product-card__info">
      {/* Card info Top */}
      {productName && (
        <div className="product-card__info-top">
          <div className="product-name-box">
            <a href={productLinkBuilder(productName, productVintage, productCode)}>
              <label className="product-name h8">
                {productName} {productVintage}
              </label>
            </a>
          </div>
        </div>
      )}

      {/* Card info Bottom */}
      <div className="product-card__info-bottom">
        <ProductCardPrice
          productPrice={productPrice}
          productPriceOriginal={productPriceOriginal}
          productNumberOfBottles={productNumberOfBottles}
          productMemberPrice={productMemberPrice}
        />

        {productCardButton && (
          <div className="product-card-button">
            {productCardButton.toLocaleLowerCase() === 'Add to Basket'.toLocaleLowerCase() ? (
              <CartButton
                itemCode={productCode}
                qty={'1'}
                buttonText={`${globals.country === 'us' ? 'Add to cart' : productCardButton}`}
              />
            ) : (
              <a href={productLinkBuilder(productName, productVintage, productCode)} className="view-product-button">
                {productCardButton}
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );

  const ProductInfoMultiView = () => (
    <div className="product-card__info product-card__info--multiview">
      {/* Card info Top */}
      <div className="product-card__info-top">
        {productCardTitle && (
          <div className="product-name-box">
            <a href={productLinkBuilder(productCardTitle, productVintage, productCode)} className="product-name h2">
              {productCardTitle}
            </a>
          </div>
        )}

        {productCardSubTitle && <div className="product-card__subtitle h6">{productCardSubTitle}</div>}

        {!productCardDescription && productDescription && (
          <div className="product-card__desc">
            <div dangerouslySetInnerHTML={{ __html: productDescription }} />
          </div>
        )}

        {productCardDescription && (
          <div className="product-card__desc">
            <CmsRichText contentItem={productCardDescription} />
          </div>
        )}

        {/* Product Image Secondary */}
        {productImageSecondary && (
          <div className="product-card__img-secondary">
            <CmsImage contentItem={productImageSecondary} />
          </div>
        )}
      </div>

      {/* Card info Bottom */}
      <div className="product-card__info-bottom">
        {!multiViewPriceDiff && !hidePriceOnMultiView && (
          <ProductCardPrice
            productPrice={productPrice}
            productPriceOriginal={productPriceOriginal}
            productNumberOfBottles={productNumberOfBottles}
            productMemberPrice={productMemberPrice}
          />
        )}

        {productLink && (
          <div className="product-card-button">
            {productLink.map((value: any, index: any) => (
              /* eslint-disable react/no-array-index-key */
              <div key={index}>
                {multiViewPriceDiff && multiViewPrices && multiViewPrices?.length > 0 && !hidePriceOnMultiView && (
                  <ProductCardPrice
                    productPrice={multiViewPrices[index]?.productPrice}
                    productPriceOriginal={multiViewPrices[index]?.productPriceOriginal}
                    productNumberOfBottles={multiViewPrices[index]?.productNumberOfBottles}
                    productMemberPrice={multiViewPrices[index]?.productMemberPrice}
                  />
                )}
                <CmsLink key={index} contentItem={value} />
              </div>
              /* eslint-enable react/no-array-index-key */
            ))}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div css={productCard} className="CmsProductCard">
      <div className="product-card" data-testid="product-card">
        <div css={productCardImage} className="product-image-and-info">
          {fullBleedBackground ? (
            <div className="product-image-wrapper">
              <div
                className="product-background-image"
                style={{
                  backgroundColor: `${productCardBackgroundColor?.value}`,
                }}
              >
                {productCardBackgroundImage ? (
                  <CmsImage contentItem={productCardBackgroundImage} />
                ) : (
                  <div className="product-image">
                    <Image
                      src={imageURL}
                      alt={productImage || 'Product Image'}
                      className={isCase ? 'product-case-default-image' : 'product-bottle-image'}
                      width="10"
                      height="90"
                      onError={handleImageError}
                      layout="responsive"
                      objectFit="cover"
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <a
                className="product-image-wrapper"
                href={productLinkBuilder(productName, productVintage, productCode)}
                style={{ backgroundColor: `${productCardBackgroundColor?.value}` }}
              >
                <div className="product-image">
                  <Image
                    src={imageURL}
                    alt={productName || 'Product Image'}
                    className={`${isCase ? 'product-case-default-image' : 'product-bottle-image'}${imageURL?.includes('default_') ? ' product-image-error' : ''}`}
                    width={`${largeProductMultiView ? '1800' : '600'}`}
                    height={`${largeProductMultiView ? '1200' : '400'}`}
                    onError={handleImageError}
                    objectFit="cover"
                  />
                </div>
              </a>
              {standardProductCard && (
                <>
                  <span className="zoom" onClick={() => open()} onKeyDown={() => open()} role="button" tabIndex={0}>
                    <Icon kind="zoom" size="xs" />
                  </span>
                  <Zoom
                    product={productData()}
                    opened={opened}
                    handleOnClose={() => {
                      close();
                    }}
                  />
                </>
              )}
            </>
          )}

          {productCardVariant === 'Detailed product card' && <ProductInfoDetailed />}
          {largeProductMultiView && <ProductInfoMultiView />}
          {productCardVariant !== 'Detailed product card' && productCardVariant !== 'Large Product Multi View' && (
            <ProductInfoDefault />
          )}
        </div>
      </div>
    </div>
  );
});
