import { globals } from 'common-ui';
import { NotificationType } from '../atoms/notificationsAtom';
import { notificationMessage } from '../content/notificationMessage';

// Function to create notification object
export function cartNotification(
  productSku: string,
  quantity: string,
): {
  type: NotificationType;
  timeout: number | null;
  message: string;
  expires: number;
  miniCart?: { itemCode: string; quantity: string };
} {
  const isNewMiniCartEnabled = globals.enableNewMiniCart;
  const notificationTimeout = isNewMiniCartEnabled ? null : 5000;
  const notificationExpires = isNewMiniCartEnabled ? Infinity : Date.now() + 5000;

  const notificationBase = {
    type: NotificationType.ADD_TO_CART,
    timeout: notificationTimeout,
    message: notificationMessage[NotificationType.ADD_TO_CART],
    expires: notificationExpires,
  };

  // Conditionally add miniCart object if the new mini cart is enabled
  return {
    ...notificationBase,
    ...(isNewMiniCartEnabled &&
      productSku &&
      quantity && {
        miniCart: {
          itemCode: productSku,
          quantity,
        },
      }),
  };
}
