import { globals } from "common-ui";

// This is function is being used to get the hostname from the URL by removing the protocol and path
export const getDomain = (url: string) => {
  try {
    const { hostname } = new URL(url);
    if (globals.countryCode === 'us' && globals.brand === 'law') {
      return 'www.laithwaiteswine.com';
    }
    return hostname;
  } catch {
    return '';
  }
};
