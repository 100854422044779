import React, { useState, useEffect, useRef, memo } from 'react';
import { css, useTheme } from '@emotion/react';
import { useRecoilValue } from 'recoil';
import { selectUserDetails, selectedStateAtom, selectUserIsUnlimited } from 'common-state';
import type { ConditionalContainerEntryType } from 'tsconfig/types.d';
import { useScreenLayout } from '../../../hooks/useScreenLayout';
import USStateData from '../../../utils/USStateData';
import { isBrowser } from 'common-state/src/helpers/utils';

export const BREAKPOINTS = {
  MOBILE: 'Mobile only (small device)',
  DESKTOP: 'Desktop only (large device)',
} as const;

export type CmsConditionalContainerProps = {
  children: React.ReactNode;
  contentItem: ConditionalContainerEntryType;
  enableLoader?: boolean;
};

export const CmsConditionalContainer = memo((props: CmsConditionalContainerProps) => {
  const { children, contentItem, enableLoader } = props;
  const userDetails = useRecoilValue(selectUserDetails);
  const selectedState = useRecoilValue(selectedStateAtom);
  const isUnlimited = useRecoilValue(selectUserIsUnlimited);
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    customerLoggedIn,
    conditionSubscription,
    conditionVineyardPartner,
    conditionWineFund,
    conditionUnlimited,
    conditionBreakpoint,
    conditionWineryDirect,
    conditionState,
  } = contentItem?.fields || {};

  const [isLoading, setIsLoading] = useState(true);

  const wrapper = css`
    opacity: ${isVisible ? 1 : 0};
    transition: opacity 2.3s ease-in-out;
  `;

  const { screen } = useScreenLayout();
  useEffect(() => {
    //conditionUnlimited
    const userUnlimited = () => {
      if (isBrowser()) {
        if (conditionUnlimited === 'Yes' && !isUnlimited) {
          return false;
        } else if (conditionUnlimited === 'No' && isUnlimited) {
          return false;
        }
        return true;
      }
    };

    //conditionSubscription - Wineplan
    const subscribedUser = () => {
      if (isBrowser()) {
        const userWPStatus = userDetails?.subscriptions?.some(
          subscription => subscription?.type === 'WINEPLAN' && subscription?.status === 'ACTIVE',
        );
        return !(
          (conditionSubscription === 'Yes' && !userWPStatus) ||
          (conditionSubscription === 'No' && userWPStatus)
        );
      }
    };

    //conditionVineyardPartner
    const vineyardPartner = () => {
      if (isBrowser()) {
        if (conditionVineyardPartner === 'Yes') {
          return userDetails?.subscriptions?.some(subscribedUserDetails => subscribedUserDetails?.type === 'VP');
        }
        return true;
      }
    };

    //conditionWineFund
    const wineFund = () => {
      if (isBrowser()) {
        if (conditionWineFund === 'Yes') {
          return userDetails?.subscriptions?.some(subscribedUserDetails => subscribedUserDetails?.type === 'WINEFUND');
        }
        return true;
      }
    };

    //User LoggedIn Check
    const userLoggedInCheck = () => {
      if (isBrowser()) {
        if (customerLoggedIn && customerLoggedIn !== 'Choose a value') {
          if (userDetails) {
            const loginType = userDetails.loginType === 'hard_logged' || userDetails.loginType === 'soft_logged';
            if (customerLoggedIn === 'Logged in' && loginType) {
              return true;
            } else if (customerLoggedIn === 'Not logged in' && !loginType) {
              return true;
            }
            return false;
          }
          return false;
        }
        return true;
      }
    };
    //Screen Breakpoint Check
    const screenBreakpointCheck = () => {
      if (typeof window === 'undefined') {
        return false;
      } // Ensure function returns false if window is undefined (SSR)

      if (!conditionBreakpoint || conditionBreakpoint === 'Choose a value') {
        return true;
      }

      const isMobileBreakpoint = window.matchMedia(`(max-width: ${theme.breakpointWidths?.lg}px)`).matches;
      switch (conditionBreakpoint) {
        case BREAKPOINTS.MOBILE:
          return isMobileBreakpoint;
        case BREAKPOINTS.DESKTOP:
          return !isMobileBreakpoint;
        default:
          return false;
      }
    };
    //US State Check
    const stateDataCheck = () => {
      let stateCheckValid = true;
      if (conditionState || conditionWineryDirect) {
        if (selectedState) {
          const selectedStateData = USStateData?.find(stateData => stateData?.stateCode === selectedState);
          if (!selectedStateData) {
            stateCheckValid = false;
          } else {
            if (conditionState && conditionState !== selectedStateData.stateName) {
              stateCheckValid = false;
            }
            if (conditionWineryDirect) {
              stateCheckValid =
                (conditionWineryDirect === 'Yes' && selectedStateData.wineryDirect) ||
                (conditionWineryDirect === 'No' && !selectedStateData.wineryDirect);
            }
          }
        } else {
          stateCheckValid = false;
        }
      }
      return stateCheckValid;
    };

    const checks = [
      userLoggedInCheck(),
      screenBreakpointCheck(),
      stateDataCheck(),
      subscribedUser(),
      vineyardPartner(),
      wineFund(),
      userUnlimited(),
    ];

    const shouldRender = checks.every(Boolean);
    setIsLoading(!shouldRender);
    setIsVisible(shouldRender);
  }, [
    screen,
    conditionBreakpoint,
    selectedState,
    conditionState,
    conditionWineryDirect,
    customerLoggedIn,
    conditionSubscription,
    conditionVineyardPartner,
    conditionWineFund,
    conditionUnlimited,
    userDetails,
  ]);

  const handleTransitionEnd = () => {
    if (!isVisible) {
      setIsLoading(true); // Ensure loading is shown when not visible
    }
  };

  useEffect(() => {
    const node = wrapperRef.current;
    if (node) {
      node.addEventListener('transitionend', handleTransitionEnd);
    }
    return () => {
      if (node) {
        node.removeEventListener('transitionend', handleTransitionEnd);
      }
    };
  }, [isVisible]);

  if (isLoading && enableLoader) {
    return null;
  } else if (isVisible) {
    return (
      <div data-test="widget-CmsConditionalContainer" className="cms-conditional-container" css={wrapper}>
        {children}
      </div>
    );
  }

  return null;
});
