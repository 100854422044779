import { globals } from 'common-ui';
import { typeaheadCheck } from '../utils';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import type { GetSourcesParams, OnSelectParams } from '@algolia/autocomplete-core';
import type { SearchClient } from 'algoliasearch/lite';
import type { BaseItem } from './types';

interface GetItemsParams {
  setContext: (context: any) => void;
  query: string;
}

const PopularSearchPluginHeader = () => <span className="aa-SourceHeaderTitle"> Popular searches </span>;
const PopularSearchPluginItem = ({ item, components }: { item: BaseItem; components: any }) => (
  <div className="aa-ItemWrapper">
    <div className="aa-ItemContent">
      <div className="aa-ItemContentBody">
        <div className="aa-ItemContentTitle"> {components.Highlight({ hit: item, attribute: 'query' })} </div>
      </div>
    </div>
  </div>
);

export function createQuerySuggestionsPlugin(searchClient: SearchClient) {
  return {
    getSources({ query: sourceQuery }: GetSourcesParams<BaseItem>) {
      if (!sourceQuery || !typeaheadCheck(sourceQuery)) {
        return [];
      }
      return [
        {
          sourceId: 'querySuggestionsPlugin',
          getItems({ setContext, query: searchQuery }: GetItemsParams) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: globals.algoliaQuerySuggestions as string,
                  query: searchQuery,
                  params: { clickAnalytics: true, hitsPerPage: globals?.algoliaNewView ? 3 : 5 },
                },
              ],
              transformResponse({ hits, results }) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setContext({ queryID: results[0].queryID });
                return hits;
              },
            });
          },
          onSelect({ item }: OnSelectParams<BaseItem>) {
            if (item?.query) {
              window.location.href = `/search?query=${item.query}`;
            }
          },
          templates: {
            header: PopularSearchPluginHeader,
            item: PopularSearchPluginItem,
          },
        },
      ];
    },
  };
}
