//returns true if the supplied url fragment matches a simple wildcard style 'pattern')
//e.g. 'wine/red' would match 'wine/*' but not 'beer/*'
export function testUrlMatchesPattern(urlFragment = '', urlPattern = '') {
  let updatedUrlFragment = urlFragment;
  if (!urlPattern) {
    return false;
  }

  //ignore a prefixed "/" slash on url
  //however leave a single '/' as home page is a special case
  if (updatedUrlFragment.startsWith('/') && updatedUrlFragment.length > 1) {
    updatedUrlFragment = updatedUrlFragment.substring(1);
  }

  //convert simple wildcard pattern to RegExp that can be used
  //RegExp is prefixed with '^' so pattern matches against start of string
  const regExpPattern = new RegExp(`^${urlPattern.trim().replace(/\*/g, '[^ ]*')}`);
  return regExpPattern.test(updatedUrlFragment);
}

//returns true if the supplied url fragment matches at least one of a coma seperated list of patterns
//e.g. 'wine/red' would match 'about-us,wine/*,beer/*' but not 'about-us,beer/*'
export function testUrlMatchesPatternList(url = '', urlPatternList = '') {
  const urlPatterns: string[] = ((urlPatternList || '') as string).split(',');
  for (const urlPattern of urlPatterns) {
    if (testUrlMatchesPattern(url, urlPattern)) {
      return true;
    }
  }
  return false;
}
