import React, { memo, Suspense, lazy } from 'react';
import { css, useTheme } from '@emotion/react';
import type {
  ColourField,
  DLClickEventHandlerProps,
  IconEntryType,
  ImageEntryType,
  LinkEntryType,
} from 'tsconfig/types.d';
import { Icon } from '../../Icon/Icon';
import { CmsImage } from '../CmsImage/CmsImage';
import { logout } from 'common-state';
import { dwChat } from '../../../dw-chat/DwChat';
import { logger, inspectorMode } from 'common-ui';
import { Link } from '../../Link/Link';
import { usePathname } from 'next/navigation';

const Button = lazy(() => import('../../AddToCart/Button').then(({ CartButton }) => ({ default: CartButton })));

export type CmsLinkProps = {
  contentItem: LinkEntryType;
  category?: string;
  className?: string;
  dlClickEventHandler?: DLClickEventHandlerProps;
};
const UK_COOKIE_TARGET = 'optanon-toggle-display';

export const CmsLink = memo(({ contentItem, category, className, dlClickEventHandler }: CmsLinkProps) => {
  const theme = useTheme();
  const pathname = usePathname();
  const categoryType = category || 'link';
  if (!contentItem?.fields) {
    return null;
  }
  const { title, entryTitle, url, targeting, icon, variant, productCode, fullWidth, openInNewTab } = contentItem.fields;

  if (!url) {
    logger.warn('No url value set for', contentItem?.fields);
  }

  const cssButton = css`
    ${theme.widgets?.CmsLink?.button}
  `;

  const wrapper = css`
    display: block;
  `;
  const getTargeting = (key?: string) => (key === 'Cookie Setting' ? UK_COOKIE_TARGET : '');

  const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (targeting === 'Cookie Setting Osano') {
      window?.Osano?.cm?.showDrawer?.('osano-cm-dom-info-dialog-open');
      event.preventDefault();
    }
    if (targeting === 'Cookie Setting') {
      event.preventDefault();
    }
    if (targeting === 'Soft Logged') {
      logout('hard_logged');
    }
    if (targeting === 'Live Chat') {
      event.preventDefault();
      dwChat.open();
    }
  };

  const getLinkClass = () => {
    const classesToApply = [];
    if (className) {
      classesToApply.push(className);
    }
    const targettingClass = getTargeting(targeting);
    if (targettingClass) {
      classesToApply.push(targettingClass);
    }
    if (pathname === url) {
      classesToApply.push('active');
    }
    return classesToApply.join(' ');
  };

  const renderIconImage = (iconImageField: LinkEntryType['fields']['icon']) => {
    if (!iconImageField) {
      return null;
    }
    let linkIcon;
    if ((iconImageField as ImageEntryType)?.fields?.largeDeviceImage) {
      linkIcon = iconImageField as ImageEntryType;
      return <CmsImage contentItem={linkIcon} />;
    }
    linkIcon = iconImageField as IconEntryType;
    const iconStyle = css`
      color: ${(linkIcon?.fields?.colour as unknown as ColourField)?.value};
    `;
    return (
      <div css={iconStyle}>
        <Icon kind={linkIcon.fields.iconType} size="m" />
      </div>
    );
  };

  let variantType;
  if (variant === 'Secondary button') {
    variantType = css`
      && {
        ${theme.mixins.btnSecondary}
      }
    `;
  } else if (variant === 'Primary button') {
    variantType = css`
      && {
        ${theme.mixins.btnPrimary}
      }
    `;
  } else if (variant === 'Secondary-inverted button') {
    variantType = css`
      && {
        ${theme.mixins.btnTertiary}
      }
    `;
  } else if (variant === 'Transparent button') {
    variantType = css`
      && {
        ${theme.mixins.btnTransparent}
      }
    `;
  } else {
    variantType = '';
  }

  let buttonStyle: 'secondary' | 'tertiary' | 'transparent' | 'primary' | undefined;
  if (variant === 'Secondary button') {
    buttonStyle = 'secondary';
  } else if (variant === 'Secondary-inverted button') {
    buttonStyle = 'tertiary';
  } else if (variant === 'Transparent button') {
    buttonStyle = 'transparent';
  } else {
    buttonStyle = 'primary';
  }

  let content;
  if (targeting === 'Add to Basket' && productCode) {
    content = (
      <div className={`cart-button-wrap ${fullWidth ? 'full-width' : ''}`} css={cssButton}>
        <Suspense fallback={<div>Loading...</div>}>
          <Button
            itemCode={productCode}
            qty={'1'}
            buttonText={title || ''}
            buttonStyle={buttonStyle}
            {...inspectorMode(contentItem?.sys?.id, 'title')}
          />
        </Suspense>
      </div>
    );
  } else if (targeting === 'Plain Text') {
    content = (
      <div className="plainTextWithIcon" css={css([wrapper, variantType])}>
        {icon && renderIconImage(icon)}
        {title || entryTitle}
      </div>
    );
  } else {
    content = (
      <Link
        data-testid="widget-CmsLink"
        css={css([wrapper, variantType])}
        href={(url as string) || ''}
        data-event={`${categoryType}Click`}
        data-section={`${categoryType}:${title || entryTitle}`}
        data-action="navigation"
        data-name={`${categoryType} navigation`}
        data-text={title || entryTitle}
        data-nav-label={title || entryTitle}
        className={getLinkClass()}
        onClick={(e: any) => {
          onClickHandler(e);
          dlClickEventHandler && dlClickEventHandler(title || entryTitle);
        }}
        target={openInNewTab ? '_blank' : '_self'}
        {...inspectorMode(contentItem?.sys?.id, 'title')}
      >
        {icon && renderIconImage(icon)}
        {title || entryTitle}
      </Link>
    );
  }

  return content;
});
