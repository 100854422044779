import { useState } from 'react';
import { Tabs } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { css, useTheme } from '@emotion/react';
import { CaseTabContent } from './CaseTabContent';
import { CaseTab } from './CaseTab';

export const CaseComponents = (props: any): JSX.Element => {
  const { referencedSkus, view } = props;
  const [mobileIndex, setMobileIndex] = useState(1);
  const theme = useTheme();

  const slickSlider = css`
    ${theme.widgets.SlickSlider?.wrapper}
  `;

  // Filter out Skus marke as "Literature"
  const filtereferencedSkus = referencedSkus.filter((item: any) => item.expandedItem.productType !== 'Literature');

  return filtereferencedSkus.length > 0 && view === 'carousel' ? (
    <Tabs
      css={slickSlider}
      className="case-content-container"
      defaultValue="prod-item-1"
      value={view === 'carousel' ? `prod-item-${mobileIndex}` : null}
    >
      <Carousel
        withIndicators={true}
        withControls={true}
        loop={true}
        slidesToScroll={1}
        slideSize={'100%'}
        align="start"
        slideGap="md"
        controlsOffset="md"
        styles={theme.widgets.CmsCarousel}
        onSlideChange={(index: number) => {
          setMobileIndex(index + 1);
        }}
      >
        {filtereferencedSkus.map((item: any, key: number) => (
          <Carousel.Slide key={`case-tab-carousel-${item.expandedItem.itemCode}`}>
            <CaseTab product={item.expandedItem} quantity={item.quantity} count={key} />
          </Carousel.Slide>
        ))}
      </Carousel>

      {filtereferencedSkus.map((item: any, key: number) => (
        <CaseTabContent
          key={`case-tab-content-${item.expandedItem.itemCode}`}
          product={item.expandedItem}
          count={key}
        />
      ))}
    </Tabs>
  ) : (
    <Tabs css={slickSlider} className="case-content-container" defaultValue="prod-item-1">
      <Tabs.List position="center">
        {filtereferencedSkus.map((item: any, key: number) => (
          <CaseTab
            key={`case-tab-list-${item.expandedItem.itemCode}`}
            product={item.expandedItem}
            quantity={item.quantity}
            count={key}
          />
        ))}
      </Tabs.List>

      {filtereferencedSkus.map((item: any, key: number) => (
        <CaseTabContent
          key={`case-tab-content-${item.expandedItem.itemCode}`}
          product={item.expandedItem}
          count={key}
        />
      ))}
    </Tabs>
  );
};
