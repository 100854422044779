import type { ProductProps } from 'tsconfig/types';
import { largeProductImageUrl, smallProductImageUrl } from '../utils/productImage';
interface AccoladeProps {
  accoladeLevelDescription: string;
  accoladeOrgDescription: string;
  accoladeTypeDescription: string;
}

export const adobeProductMapData = (
  data: ProductProps,
  quantity: number,
  productItemCode?: string,
  isFreeGift = false,
) => {
  /**
   * Sets the accolades for the given data.
   * @param data - The data containing accolades.
   * @returns An array of filtered accolades or false if data.accolades is falsy.
   */
  function setAccolades(accoladeData: any) {
    if (accoladeData?.accolades) {
      return accoladeData?.accolades?.map((item: AccoladeProps) => ({
        accoladeDescription: item?.accoladeLevelDescription,
        accoladeNameText: item?.accoladeOrgDescription,
        accoladeCategoryText: item?.accoladeTypeDescription,
      }));
    }
    return false;
  }

  function calculatePrice(productData: ProductProps | undefined): {
    price: number;
    noBottles: number;
    sku: string;
    vppApplier: boolean;
    vppPrice: number;
    vppQualifier: boolean;
    discount: number;
  } {
    if (!productData?.skus?.length) {
      return { price: 0, noBottles: 0, sku: '', vppApplier: false, vppPrice: 0, vppQualifier: false, discount: 0 };
    }

    const { skus } = productData;
    const targetSku = skus.find(sku => sku.itemCode === productItemCode) || skus[0];

    return {
      price: targetSku.enPrimeurPrice || targetSku.buyersRRP || targetSku.salePrice || 0,
      noBottles: targetSku.numberOfBottles ?? 0,
      sku: targetSku.itemCode || '',
      vppApplier: targetSku?.vppApplier ?? false,
      vppPrice: targetSku?.vppPrice ?? 0,
      vppQualifier: !!targetSku?.vppAllowed,
      discount: isFreeGift
        ? targetSku?.buyersRRP ?? 0
        : Math.max(parseFloat(((targetSku?.buyersRRP ?? 0) - (targetSku?.salePrice ?? 0)).toFixed(2)), 0),
    };
  }

  const { noBottles, price, sku, vppApplier, vppPrice, vppQualifier, discount } = calculatePrice(data);

  return {
    accolades: setAccolades(data),
    attributes: {
      organic: data?.organic ?? '',
      vegan: data?.vegan ?? '',
      vegetarian: data?.vegetarian ?? '',
    },
    colour: data?.colourName ?? data?.colour ?? '',
    country: data?.countryName ?? data?.country ?? '',
    discount,
    enPrimeur: data?.enPrimeur || data?.enPrimeurFlag || false,
    favourited: data?.favourited ?? '',
    grape: data?.grapeName ?? data?.grape ?? '',
    isMixed: data?.isMixed || data?.packagingPioneer || false,
    largeImage: data?.largeImage || (data?.skus?.[0]?.itemCode ? largeProductImageUrl(data?.itemCode) : ''),
    name: data?.name ?? data?.productName ?? '',
    noBottles,
    noReviews: data?.totalReviewCount ?? data?.numberOfReviews ?? 0,
    price,
    rating: data?.averageOverallRating ?? data?.avgRating ?? 0,
    salesActivity: data?.salesActivity ?? '',
    sku,
    smallImage: data?.smallImage || (data?.skus?.[0]?.itemCode ? smallProductImageUrl(data?.itemCode) : ''),
    stockAmount: data?.inventoryInfo?.stockQuantity ?? data?.stockQuantity ?? '',
    stockAvailability: data?.inventoryInfo?.availabilityStatus ?? data?.availabilityStatus ?? '',
    subType: data?.productWebType ?? '',
    thumbnailImage: data?.thumbnailImage ?? '',
    type: data?.productType ?? '',
    units: quantity,
    vintage: data?.vintage ?? '',
    vppApplier,
    vppPrice,
    vppQualifier,
    webHeadline: data?.webHeadline ?? '',
  };
};
