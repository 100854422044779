// Wrapper for the legacy ATG API
// This API is in the progress of being migrated to the new AWS API
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { logApiRequest } from '../utils/logging';
import type {
  PostalCodeSearchResponse,
  SavingsAccountBalanceResponse,
  SavingsAccountResponse,
  ShopPostalCodeSearchResponse,
  SubscriptionOrder,
  UpdatingWineplanSubscription,
  UserDefaultAddressResponse,
  WineplanDeliveryDateResponse,
  WineplanDeliveryMethodsResponse,
  WineplanPaymentAvailabilityResponse,
  WineplanSubscriptionListResponse,
  WineplanUpcomingCaseDetailsResponse,
} from 'tsconfig/types';

type AtgLegacyApiConfig = {
  urlPrefix: string;
  extraHeaders?: Record<string, string>;
  enableLogging?: boolean;
};

export const initAtgLegacyApi = (config: AtgLegacyApiConfig) => {
  async function asyncRequest(requestType: string, url: string, data = {}) {
    let requestConfig: AxiosRequestConfig = {},
      response: AxiosResponse | undefined,
      incomingRequestTime: Date | undefined,
      outgoingResponseTime: Date | undefined;

    try {
      incomingRequestTime = new Date();
      requestConfig = {
        method: requestType,
        url: (config.urlPrefix || '') + url,
        headers: {
          ...config.extraHeaders,
        },
      };
      if (data) {
        requestConfig.data = data;
      }
      response = await axios.request(requestConfig);
      return response ? response.data : {};
    } catch (error: any) {
      //rethrow error for caller to handle
      throw error;
    } finally {
      if (config.enableLogging) {
        outgoingResponseTime = new Date();
        //Note: may be better to throw/pass back this info so caller can log (+ add meaningful message)?
        logApiRequest(requestConfig, response, incomingRequestTime, outgoingResponseTime, 'AtgLegacyApi');
      }
    }
  }

  return {
    Cart: {
      batch: async (data: any) => asyncRequest('POST', `/cart`, data),
      multi: async (data: any) => asyncRequest('POST', `/cart/itemcode`, data),
      get: async () => asyncRequest('GET', `/cart/list`),
      add: async (productSku: string, quantity: string) =>
        asyncRequest('PUT', `/cart/itemcode/${productSku}/${quantity}`),
      getItems: async () => asyncRequest('GET', `/cart/list?validate=true`),
      remove: async (data: any) => asyncRequest('DELETE', `/cart/commerceitemidentifier/${data.itemcode}`),
      getCartOffer: async () => asyncRequest('GET', `/offer/cart`),
    },
    Promotions: {
      getProductPromotions: async (itemCode: string) => asyncRequest('GET', `/promotions/bulk-promotions/${itemCode}`),
    },
    Product: {
      getOffers: async (itemCode: string) => asyncRequest('GET', `/offer/product/${itemCode}`),
      getRecentlyViewed: async () => asyncRequest('GET', `/user/recentlybrowsed/list`),
      addItemToRecentlyViewed: async (itemcode: string) => asyncRequest('PUT', `/user/recentlybrowsed/${itemcode}`),
    },
    User: {
      get: async () => asyncRequest('GET', `/user/details`),
      permission: async () => asyncRequest('GET', `/user/permissions/MARKETING`),
      softLogout: async () => asyncRequest('GET', '/jsp/templates/page_elements/common/soft_logout.jsp'),
      hardLogout: async () => asyncRequest('GET', '/jsp/templates/page_elements/common/hard_logout.jsp'),
      // This API is only to set user location in US Brands
      userSetLocation: async (data: { stateCode: string }) =>
        asyncRequest('POST', `/user/profile/locationContext`, data),
      getFavourites: async () => asyncRequest('GET', `/user/favourites/list`),
      getRecentlyBrowsed: async () => asyncRequest('GET', `/user/recentlybrowsed/list`),
    },
    WinePlan: {
      getUpcomingCaseDetails: async (subId: string): Promise<WineplanUpcomingCaseDetailsResponse> =>
        asyncRequest('GET', `/user/subscriptions/wineplan/${subId}/upcomingcases`),
      getProductCaseDetails: async (itemCode: string) => asyncRequest('GET', `/product/case/${itemCode}`),
      getProductItemDetails: async (itemCode: string) => asyncRequest('GET', `/product/item/${itemCode}`),
      getSupportedFrequencies: async (wpSubscriptionid: string): Promise<WineplanSubscriptionListResponse> =>
        asyncRequest('GET', `/user/subscriptions/wineplan/${wpSubscriptionid}/list`),
      putSupportedFrequencies: async (
        wpSubscriptionid: string,
        data: { frequency?: number; caseType?: string; subscriptionOrderId?: string },
      ): Promise<UpdatingWineplanSubscription> =>
        asyncRequest('PUT', `/user/subscriptions/wineplan/${wpSubscriptionid}`, data),
      getDeliveryMethods: async (subId: string): Promise<WineplanDeliveryMethodsResponse> =>
        asyncRequest('GET', `/user/subscriptions/wineplan/delivery/methods/v2/${subId}`),
      getDeliveryDates: async (subId: string, method: string): Promise<WineplanDeliveryDateResponse> =>
        asyncRequest('GET', `/user/subscriptions/wineplan/delivery/dates/${subId}/${method}`),
      updateDeliveryDate: async (
        wpSubscriptionid: string,
        data: SubscriptionOrder,
      ): Promise<WineplanDeliveryDateResponse> =>
        asyncRequest('PUT', `/user/subscriptions/wineplan/delivery/${wpSubscriptionid}`, data),
      getPaymentMethods: async (wpSubscriptionid: string): Promise<WineplanPaymentAvailabilityResponse> =>
        asyncRequest('GET', `/user/subscriptions/wineplan/payment/availablemethods/${wpSubscriptionid}`),
      getSubscriptionCaseDetails: async (wpSubscriptionid: string) =>
        asyncRequest('GET', `/user/subscriptions/wineplan/history/casehistory/${wpSubscriptionid}`),
      getOrderHistoryDetails: async (orderId: string) => asyncRequest('GET', `/user/orderhistory/details/${orderId}`),
      getUserDefaultAddresses: async (wpSubscriptionId: string): Promise<UserDefaultAddressResponse> =>
        asyncRequest('GET', `/user/subscriptions/wineplan/addresses/${wpSubscriptionId}`),
      getAddressByPincode: async (postCode: string): Promise<PostalCodeSearchResponse> =>
        asyncRequest('GET', `/address/postcode/${postCode}`),
      saveDeliveryAddress: async (data: any, wpSubscriptionid: string): Promise<any> =>
        asyncRequest('PUT', `/user/subscriptions/wineplan/delivery/addaddress/v2/${wpSubscriptionid}`, data),
      useDeliveryAddress: async (wpSubscriptionid: string, addressId: string): Promise<any> =>
        asyncRequest('PUT', `/user/subscriptions/wineplan/address/delivery/${wpSubscriptionid}/${addressId}`),
      getStoresByPincode: async (postCode: string): Promise<ShopPostalCodeSearchResponse> =>
        asyncRequest('GET', `/address/shop/${postCode}`),
      selectStoreDeliveryAddress: async (wpSubscriptionid: string, addressId: string): Promise<any> =>
        asyncRequest('PUT', `/user/subscriptions/wineplan/delivery/shopaddress/${wpSubscriptionid}/${addressId}`),
      getSavingsAccount: async (): Promise<SavingsAccountResponse> => asyncRequest('GET', `/user/savingsaccounts`),
      getBalanceForAccount: async (accoundId: string): Promise<SavingsAccountBalanceResponse> =>
        asyncRequest('GET', `/user/savingsaccounts/${accoundId}`),
      payWinePlanwithWineReward: async (optToPay: boolean): Promise<any> =>
        asyncRequest('PUT', `/user/profile/options`, { payForWineplansWithWinereward: optToPay }),
      getCaseOptions: async (bottleCount: string, subId: string, schemeType = 'STANDARD'): Promise<any> =>
        asyncRequest(
          'GET',
          `/user/subscriptions/wineplans/${subId}/options?bottleCountFilter=${bottleCount}&schemeType=${schemeType}`,
        ),
    },
  };
};
