import { useActionAddToCart } from '../../../atoms/cartAtom';

export const useAddToCartCustomEvent = () => {
  const addToCart = useActionAddToCart();

  const dispatchAddToCartStatus = (message: string, originalEvent: CustomEvent, error: boolean) => {
    document.dispatchEvent(
      new CustomEvent('aws-add-to-cart-status', {
        detail: { message, originalEvent, error },
      }),
    );
  };

  const addToCartCallback = async (event: CustomEvent<[{ itemCode: string; quantity: string }]>) => {
    try {
      if (!event?.detail) {
        throw new Error('Something went wrong. Please try again.');
      }
      await addToCart(event?.detail);
      // Dispatch a new event with the status of the add to cart action
      dispatchAddToCartStatus('Success', event, false);
    } catch (error: any) {
      // Dispatch a new event with the status of the add to cart action
      dispatchAddToCartStatus(error, event, true);
    }
  };

  return (event: Event): void => {
    addToCartCallback(event as CustomEvent<[{ itemCode: string; quantity: string }]>);
  };
};
