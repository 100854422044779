import { userStatus } from 'common-state';
import { isBrowser } from 'common-state/src/helpers/utils';
import type {
  AnalyticPageInfo,
  AnalyticUserInfo,
  UserDetails,
  DLClickEventProps,
  UserPermissions,
  DLBasketUpdateProps,
  DLBasketDetailsProps,
} from 'tsconfig/types';
import { globals } from '../utils/globals';
import { DL_EVENTS_CONFIGURATION } from './AdobeDataLayerEvents';
import AdobeDataLayerMappings from '../utils/AdobeDataLayerMappings';

// import { mockUser } from 'common-state/src/atoms/userMockData';

// This is the list of events that are allowed to be pushed to the data layer in embedded
const WHITE_LIST_EVENTS = [
  'mainNavClick',
  'topNavClick',
  'cartIcon',
  'search',
  'loginButton',
  'logout',
  'notYou',
  'phoneClick',
  'liveChatInteraction',
  'emailClick',
  'accordionMenu',
  'socialShare',
  'launchMiniCart',
  'miniCartClick',
  'basketUpdate',
  'basketDetails',
];

const datalayerCleanup = [
  ...WHITE_LIST_EVENTS,
  'favourite',
  'unfavourite',
  'addToCart',
  'InitiateCart',
  'unlimited auto-renewal opt-in',
  'unlimited auto-renewal opt-out',
  'removeFromCart',
  'pageView',
];
interface ExtendedUserDetails extends UserDetails {
  userPermissions: UserPermissions;
}

export class AdobeDataLayer {
  private enableLogger: boolean;

  // Constructor for the AdobeDataLayer class. Initializes the data layer and sets the logger status.
  constructor(enableLog = false) {
    // this.initilizeDL();
    this.enableLogger = enableLog;
  }

  // Getter for the Adobe Data Layer from the window object. Returns an empty array if not available.
  get getWindowDL(): any {
    return isBrowser() && window?.adobeDataLayer ? window?.adobeDataLayer : [];
  }

  pageView(userData: any, pageViewData: any, permissionData: []) {
    const page = this.getPageInfo();
    const user = this.getUserInfo(userData, permissionData);
    this.removeEvent();
    this.createEvent(
      {
        ...pageViewData,
        page,
        user,
      },
      'pageView',
    );
  }

  // This method triggers a click event in the Adobe Data Layer.
  // It takes an event name and click object with properties for the click event.
  // It then pass the data to updateOrCreateEvent method.
  clickEvent(event: string, click: DLClickEventProps, additionalData = {}) {
    this.createEvent(
      {
        click,
        ...additionalData,
      },
      event,
    );
  }

  isPathMatched = (checkPath: string | string[] | undefined, path: string) => {
    if (Array.isArray(checkPath)) {
      return checkPath.some(pathValue => path.includes(pathValue));
    }
    return checkPath && path.includes(checkPath);
  };

  isSearchMatched = (checkSearch: string | undefined, searchContent: string) =>
    checkSearch && searchContent.includes(checkSearch);

  isUrlMatched = (checkURL: string | undefined, pageUrl: string) => checkURL && pageUrl.includes(checkURL);

  // This method retrieves information about the current page from the window location.
  // It returns an object with properties for the page name, type, hier, country, and brand.
  // If the current path is the root ("/"), it sets the page name, type, and hier to "home".
  // Otherwise, it sets these properties to a cleaned-up version of the path.
  getPageInfo(): AnalyticPageInfo {
    let path = '',
      searchContent = '',
      pageUrl = '';
    if (isBrowser()) {
      ({ pathname: path, search: searchContent, href: pageUrl } = window.location);
    }
    const name = path?.replace(/[\W_]+/g, ' ')?.trim();

    // Default page info
    const page: AnalyticPageInfo = {
      pageName: '',
      pageType: '',
      pageHier: '',
      country: globals.country as string,
      brand: globals.brandId as string,
    };

    if (path === '/') {
      return { ...page, pageHier: 'home', pageName: 'homepage', pageType: 'home' };
    }

    const pageInfo = AdobeDataLayerMappings?.find(
      ({ checkPath, checkSearch, checkURL }) =>
        this.isPathMatched(checkPath, path) ||
        this.isSearchMatched(checkSearch, searchContent) ||
        this.isUrlMatched(checkURL, pageUrl),
    );

    if (pageInfo) {
      const { pageHier, pageType, pageName } = pageInfo,
        accountPages = pageHier.includes('account:');

      return {
        ...page,
        pageHier,
        pageType: pageType || (accountPages ? 'My Account' : name),
        pageName: pageName === 'path' ? path : pageName || (accountPages ? pageHier.replace(':', ' ') : name),
      };
    }

    // Default
    return { ...page, pageHier: name, pageName: name, pageType: name };
  }

  getPageType() {
    const pageInfo = this.getPageInfo();
    return pageInfo.pageType;
  }

  getUserInfo(loginUser?: ExtendedUserDetails, permissionData?: any) {
    if (!isBrowser()) {
      return;
    }
    // const loginUser = mockUser

    const { loginStatus, loginType } = userStatus(loginUser?.securityStatus);

    const setCorrectLoginTypeForAdobe = (login_Type: string) => {
      if (login_Type === 'hard_logged') {
        return 'Hard Logged-In';
      }
      if (login_Type === 'soft_logged') {
        return 'Soft Logged-In';
      }
      return 'unidentified';
    };

    // Defaults
    const user: AnalyticUserInfo = {
      state: loginUser?.shoppingLocationContext?.stateCode || '',
      loginStatus,
      loginType: setCorrectLoginTypeForAdobe(loginType),
      type: loginType !== 'unidentified' ? 'Existing Customer' : 'New Customer',
    };

    /* Push limited user detail if not hard logged in
      and overwrite the default user object */
    if ((loginType === 'soft_logged' || loginType === 'hard_logged') && loginUser?.summaryDetails) {
      user.customerId = loginUser.summaryDetails.brandAccountNumber;
      user.eProfileId = loginUser.summaryDetails.profileId;
      user.salutation = loginUser.summaryDetails.salutation || '';
      user.name = `${loginUser.summaryDetails.firstName} ${loginUser.summaryDetails.lastName}`;
      user.type = 'Existing Customer';
      user.memberships = [];
      user.subscriptions = [];

      if (loginUser?.trustedDetails?.subscriptions && loginUser?.trustedDetails?.subscriptions.length > 0) {
        loginUser.trustedDetails.subscriptions.forEach((sub: any) => {
          // Users Memberships - Unlimited
          // Push wineplan into the subscriptions array
          if (sub?.type === 'WINEPLAN') {
            user.subscriptions.push({
              schemeCode: sub.schemeId,
              type: sub.type,
              startDate: sub.enrollmentFromDateAsString,
              endDate: sub.enrollmentToDateAsString,
              frequency: sub.frequency,
              status: sub.status,
            });
          }

          // Return all memberships if not wineplan
          if (sub?.type !== 'WINEPLAN') {
            user.memberships.push({
              schemeCode: sub.schemeId,
              type: sub.type,
              startDate: sub.enrollmentFromDateAsString,
              endDate: sub.enrollmentToDateAsString,
              status: sub.status,
              frequency: sub.frequency,
            });
          }
        });
      }

      /* Permission */
      if (permissionData) {
        // Create a string of permissions
        const result = permissionData
          .map((item: { code: string; state: string }) => `${item.code}=${item.state}`)
          .join(':');
        user.marketingPermissions = result;
      }
    }

    return user;
  }

  preferenceSavedDLEvent() {
    this.createEvent(
      DL_EVENTS_CONFIGURATION.ACCOUNT_PREFERENCE_SAVED,
      DL_EVENTS_CONFIGURATION.ACCOUNT_PREFERENCE_SAVED.action,
    );
  }

  // Basket update DL for cart page
  basketUpdate(productInformation: DLBasketUpdateProps[]) {
    const basketArray = productInformation.map(({ itemCode, name, webHeadline, skus, cartQuantity }) => ({
      itemCode,
      productName: name,
      webHeadline,
      unitPrice: skus?.[0]?.salePrice,
      qty: cartQuantity,
    }));

    this.createEvent(basketArray, 'basketUpdate', 'basket');
  }

  basketDetails(cartDetails: DLBasketDetailsProps) {
    this.createEvent(cartDetails, 'basketDetails');
  }

  private removeEvent() {
    if (Array.isArray(window.adobeDataLayer)) {
      // Loop through the array in reverse order (to avoid skipping elements when removing)
      for (let i = window.adobeDataLayer.length - 1; i >= 0; i--) {
        if (datalayerCleanup.includes(window.adobeDataLayer[i].event)) {
          window.adobeDataLayer.splice(i, 1); // Remove the element at index i
        }
      }
    }
  }

  // This method creates a new event in the data layer.
  private createEvent(eventData: any, event: string, propName = 'eventData') {
    if ((globals?.isEmbedded && !WHITE_LIST_EVENTS.includes(event)) || !isBrowser()) {
      return;
    }

    // If the window data layer is available, it push the data to window data layer otherwise it push the data to temporary data layer.
    if (this.getWindowDL) {
      // if (this.enableLogger && isBrowser()) {
      //   console.log('Pushing to Adobe Data Layer:', { event, [propName]: eventData });
      // }
      window.adobeDataLayer?.push({ event, [propName]: eventData });
    }
  }
}

export const adobeDataLayer = new AdobeDataLayer();
