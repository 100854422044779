type pageInfoMappings = {
  checkPath?: string | string[];
  checkSearch?: string;
  checkURL?: string;
  pageHier: string;
  pageName?: string;
  pageType?: string;
};

export const AdobeDataLayerMappings: pageInfoMappings[] = [
  {
    checkPath: ['/search', '/wine'],
    pageHier: 'product:search results',
    pageName: 'path',
    pageType: 'search results',
  },
  { checkPath: '/product/', pageHier: 'product:product', pageName: 'path', pageType: 'product' },
  { checkPath: '/cart', pageHier: 'checkout:shopping cart', pageName: '/shopping cart', pageType: 'shopping cart' },
  {
    checkPath: 'unlimited_shipping_page.jsp',
    checkSearch: 'pg=unlimited',
    pageHier: 'offer:unlimited',
    pageName: 'unlimited_shipping_page',
    pageType: 'Unlimited',
  },
  {
    checkPath: 'jsp/vineyardpartners/',
    pageHier: 'offer:vineyardpartners',
    pageName: 'vineyardpartners',
    pageType: 'Vineyard Partners',
  },
  { checkSearch: 'pg=winefund', pageHier: 'winefund', pageName: 'winefund', pageType: 'Wine Fund' },
  {
    checkPath: '/myaccount/winesubscriptions/manage/address',
    pageHier: 'account:winesubscriptiondashboard:addressespayments',
    pageName: 'account wine subscription addresses payments',
  },
  {
    checkPath: '/myaccount/winesubscriptions/swap-subscription',
    pageHier: 'account:winesubscriptiondashboard:swap',
    pageName: 'account wine subscription swap',
  },
  {
    checkPath: '/myaccount/unlimited',
    pageHier: 'account:unlimited',
  },
  {
    checkPath: '/myaccount/advantage',
    pageHier: 'account:advantage',
  },
  {
    checkPath: ['wines_purchased.jsp', 'account/purchased'],
    checkURL: 'account_lists.jsp#/purchased',
    pageHier: 'account:purchased',
  },
  {
    checkPath: ['wines_favourited.jsp', 'account/favourites'],
    checkURL: 'account_lists.jsp#/favourites',
    pageHier: 'account:wines_favourited',
  },
  {
    checkPath: ['top_rated_wines.jsp', 'account/top-rated'],
    checkURL: 'account_lists.jsp#/top-rated',
    pageHier: 'account:top_rated_wines',
  },
  {
    checkPath: ['not_for_me.jsp', 'account/not-for-me'],
    checkURL: 'account_lists.jsp#/dont-send',
    pageHier: 'account:not_for_me',
  },
  {
    checkPath: ['recommendations.jsp', 'account/recommendations'],
    checkURL: 'account_lists.jsp#/recommendations',
    pageHier: 'account:recommendations',
  },
  {
    checkPath: 'account_summary.jsp',
    pageHier: 'account:account_summary',
  },
  {
    checkPath: ['account_order_history.jsp', 'account/order-history'],
    pageHier: 'account:order_history',
  },
  {
    checkPath: 'wine_cellar.jsp',
    pageHier: 'account:wine_cellar',
  },
  {
    checkPath: ['account_details.jsp', 'account/personal-details'],
    pageHier: 'account:account_details',
  },
];

export default AdobeDataLayerMappings;
