import React from 'react';

const ErrorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
    <g clipPath="url(#clip0_1594_54090)">
      <path fill="#D7363B" d="M7.989.664a7.373 7.373 0 100 14.746 7.373 7.373 0 000-14.746z"></path>
      <path
        fill="#D7363B"
        d="M8 0a8 8 0 11-8 8 8.01 8.01 0 018-8zm0 14.772A6.771 6.771 0 101.23 8 6.779 6.779 0 008 14.772z"
      ></path>
      <path
        fill="#fff"
        d="M7.959 3.94a.614.614 0 01.614.615v4.739a.614.614 0 01-1.229 0v-4.74a.614.614 0 01.615-.614zM7.325 11.436a.624.624 0 111.248 0 .624.624 0 01-1.248 0z"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_1594_54090">
        <path fill="#fff" d="M0 0H16.001V16H0z" transform="rotate(-180 8 8)"></path>
      </clipPath>
    </defs>
  </svg>
);

export default React.memo(ErrorIcon);
