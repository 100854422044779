
import { useEffect } from 'react';
import aa from 'search-insights';
import { globals } from '../utils/globals';

export const useAlgoliaInit = (): void => {
  useEffect(() => {
    // Check if the window object is available
    aa('init', {
      appId: globals.algoliaAppId,
      apiKey: globals.algoliaSearchKey,
      useCookie: true,
    });
  }, []);
};