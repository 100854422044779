import React from 'react';

const Play = () => (
  <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.92906 0.87974C1.40936 0.9347 0.902619 1.24382 0.626359 1.6744C0.459979 1.9337 0.377619 2.16114 0.339239 2.46712C0.311399 2.68924 0.311399 16.3091 0.339239 16.5312C0.390419 16.9393 0.545439 17.2735 0.819399 17.5663C1.15814 17.9285 1.6174 18.1233 2.12906 18.1221C2.43018 18.1213 2.6705 18.064 2.94024 17.9286C3.37148 17.7121 15.76 11.066 15.8822 10.9856C16.3079 10.7055 16.6053 10.208 16.6595 9.68526C16.7275 9.02948 16.4191 8.36604 15.8822 8.0127C15.759 7.93164 3.35126 1.27544 2.94906 1.07466C2.85006 1.02524 2.72406 0.9706 2.66906 0.95328C2.4488 0.88382 2.1609 0.85522 1.92906 0.87974ZM1.94236 2.15732C1.80226 2.2057 1.67136 2.33394 1.61886 2.47426C1.5794 2.57974 1.57906 2.64072 1.57906 9.49916C1.57906 16.4047 1.57914 16.4179 1.6197 16.5263C1.70824 16.7629 1.96094 16.9082 2.20506 16.8628C2.30188 16.8448 3.43412 16.2453 8.73036 13.4081C12.2548 11.52 15.1724 9.95066 15.2138 9.92064C15.2552 9.89064 15.3161 9.81726 15.3491 9.75762C15.4007 9.66418 15.4091 9.62838 15.4091 9.49916C15.4091 9.36856 15.4011 9.33494 15.3472 9.23916C15.3043 9.16282 15.2523 9.10672 15.1772 9.05586C14.9811 8.92292 2.35248 2.17202 2.2497 2.14514C2.1195 2.1111 2.07058 2.11304 1.94236 2.15732Z"
      fill="white"
    />
  </svg>
);

export default React.memo(Play);
