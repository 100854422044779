import type { StyledTextInputProps } from './types';
import styled from '@emotion/styled';

export const StyledTextInput = styled.div<StyledTextInputProps>`
  ${({ theme, status, label, loading, message }) => {
    let statusLabelColor = theme.colors.grey40;
    let statusBorderColor = theme.colors.grey20;
    if (!loading) {
      if (status === 'error') {
        statusLabelColor = theme.colors.error;
        statusBorderColor = theme.colors.error;
      } else if (status === 'warning') {
        statusLabelColor = theme.colors.warning;
        statusBorderColor = theme.colors.warning;
      }
    }

    const messageCss = message
      ? `.feedback {
      font-size: ${theme.fonts.sizeSmall};
      display: flex;
      padding-top: 8px;
      align-items: center;
      gap: 4px;
      margin-block: 0;
      color: ${statusLabelColor};
    }`
      : '';
    const loadingCss = loading
      ? `.loader {
      ::after {
        ${theme.mixins.loading}
      }
    }`
      : '';
    const labelCss = label
      ? `label {
        color: ${theme.colors.black};
        font-weight: normal;
        font-size: ${theme.fonts.sizeDefault};
        position: absolute;
        pointer-events: none;
        padding: 0 2px;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.2s ease all;
        span{
          color: red;
        }
      }
      input:focus ~ label,
      input:-webkit-autofill ~ label,
      input:not(:placeholder-shown) ~ label {
        font-size: ${theme.fonts.sizeSmall};
        background-image: linear-gradient(rgba(255,0,0,0) 70%, ${theme.colors.white} 0%);
        color: ${statusLabelColor};
        top: -4px;
        left: 9px;
        padding: 0 3px;
      }
      input:disabled ~ label {
        color:${theme.colors.grey30};
      }
      `
      : '';

    return `
    margin: 8px 0;
  .field-container {
    position: relative;
    input {
      background-color: ${theme.colors.white};
      border: 1px solid ${statusBorderColor};
      font-size: ${theme.fonts.sizeDefault};
      width: 100%;
      padding: 12px 18px;
      text-overflow: ellipsis;
      white-space: nowrap;
      border-radius: 5px;
      overflow: hidden;
      &::placeholder {
        opacity: 1;
        transition: all 0.2s;
      }
      &:placeholder-shown:not(:focus)::placeholder {
        opacity: 0;
      }
      &:focus {
        outline: none;
        border: 1px solid ${theme.colors.black};
      }
      &:disabled {
        cursor: default;
      }
    }
    .suffix-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }
    ${loadingCss}
    ${labelCss}
  }
  .help-info{
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-bottom: 8px;
  }
  ${messageCss}
  `;
  }}
`;
