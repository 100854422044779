import React from 'react';

const ErrorIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" fill="none" viewBox="0 0 16 15">
    <path
      fill="#EB8308"
      d="M5.907 1.208c.93-1.61 3.256-1.61 4.185 0l5.58 9.665c.93 1.611-.232 3.625-2.092 3.625H2.42c-1.86 0-3.023-2.014-2.093-3.625l5.58-9.665z"
    ></path>
    <path
      fill="#fff"
      d="M7.964 4.468a.533.533 0 01.533.533v4.11a.533.533 0 01-1.066 0v-4.11a.533.533 0 01.533-.533zM7.414 10.97a.541.541 0 111.083 0 .541.541 0 01-1.083 0"
    ></path>
  </svg>
);

export default React.memo(ErrorIcon);
