import { globals } from '../../utils/globals';

const medalImageMapping: { [key: string]: string } = {
  Gold: '/next/images/medals/gold_medal.svg',
  Silver: '/next/images/medals/silver_medal.svg',
  Bronze: '/next/images/medals/bronze_medal.svg',
  'Double Gold': '/next/images/medals/2_gold.svg',
  'Triple Gold': '/next/images/medals/3_gold.svg',
  'Four Gold': '/next/images/medals/4_gold.svg',
  Platinum: '/next/images/medals/platinum.svg',
};

export const getImgUrl = ({
  accoladeLevelDescription,
  accoladeTypeDescription,
  accoladeOrgDescription,
}: {
  accoladeLevelDescription: string;
  accoladeTypeDescription: string;
  accoladeOrgDescription: string;
}): string => {
  const isAUMarket = globals.country?.toLowerCase() === 'au';
  const accoladeLevelSlug = accoladeLevelDescription.replace(/ /g, '_').toLowerCase();

  if (isAUMarket) {
    if (accoladeTypeDescription === 'Points') {
      const basePath = `${process.env.NEXT_PUBLIC_DW_URL}/images/au/common/accolades/${accoladeLevelDescription}pts`;
      return accoladeOrgDescription === 'James Halliday Wine Companion'
        ? `${basePath}_halliday.svg`
        : `${basePath}.svg`;
    }
    if (accoladeTypeDescription === 'Stars') {
      return `${process.env.NEXT_PUBLIC_DW_URL}/images/au/common/accolades/${accoladeLevelSlug}_stars.svg`;
    }
    if (accoladeTypeDescription === 'Trophy') {
      return `${process.env.NEXT_PUBLIC_DW_URL}/images/au/common/accolades/trophy.svg`;
    }
    return `${process.env.NEXT_PUBLIC_DW_URL}/images/au/common/accolades/${accoladeLevelSlug}.svg`;
  }

  if (accoladeTypeDescription === 'Medal') {
    return medalImageMapping[accoladeLevelDescription];
  }
  if (accoladeTypeDescription === 'Points') {
    return '/next/images/medals/points.svg';
  }
  return '/next/images/medals/trophy.svg';
};
