/**
 * Rich text font family correction as per next/font family
 *
 * @param   {any}  contentToSanitize  [contentToSanitize description]
 * @param   {any}  font               [font description]
 *
 * @return  {[type]}                  [return description]
 */
export function fontCorrectionInRichText(contentToSanitize: string, font: Record<string, string>): string {
  let combinedContent = contentToSanitize;
  if (!combinedContent) {
    return combinedContent;
  }
  const fontFamilyKeys = [
    { name: '&quot;Noto Serif&quot;, serif', value: font.noto },
    { name: 'Roboto, sans-serif', value: font.roboto },
    { name: 'Oswald, sans-serif', value: font.oswald },
    { name: 'Arsenal, sans-serif', value: font.arsenal },
    { name: '&quot;Libre Caslon Text&quot;, serif', value: font.libreCaslonText },
    { name: '&quot;Crimson Text&quot;, serif', value: font.crimsonText },
    { name: '&quot;Playfair Display&quot;, serif', value: font.playfairDisplay },
    { name: '&quot;PT Sans&quot;, sans-serif', value: font.PTSans },
    { name: 'Lato', value: font.lato },
    { name: 'Montserrat', value: font.montserrat },
  ].filter(fontObj => !!fontObj.value);

  const escapeRegExp = (string: string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  fontFamilyKeys.forEach(fontObj => {
    const regex = new RegExp(`${escapeRegExp(fontObj.name)}(?=[^;]*;)`, 'gi');
    combinedContent = combinedContent.replace(regex, fontObj.value);
  });
  return combinedContent;
}
