import { css, useTheme } from '@emotion/react';
import { globals } from 'common-ui';
import { FlavourHeading } from './FlavourHeading';

type FlavourProfileProps = {
  shortTastingNotesTaste: string;
  shortTastingNotesAroma: string;
  allergens: string[];
};

const FoodPairing: React.FC<{ product: FlavourProfileProps; displayHeading: boolean }> = ({
  product,
  displayHeading,
}) => {
  const { shortTastingNotesTaste, shortTastingNotesAroma } = product;

  return (
    <div>
      <FlavourHeading displayHeading={displayHeading} />
      {shortTastingNotesTaste && (
        <div className="item">
          <div className="item-title">Wine Flavor Profile</div>
          <div className="item-sub-title">{shortTastingNotesTaste}</div>
        </div>
      )}
      {shortTastingNotesAroma && (
        <div className="item">
          <div className="item-title">Wine Food Pairings</div>
          <div className="item-sub-title">{shortTastingNotesAroma}</div>
        </div>
      )}
    </div>
  );
};

const TastingNote: React.FC<{ product: FlavourProfileProps; displayHeading: boolean }> = ({
  product,
  displayHeading,
}) => {
  const { shortTastingNotesTaste, shortTastingNotesAroma, allergens } = product;
  const formatAllergens = () =>
    allergens.map(item => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()).join(', ');

  return (
    <>
      <FlavourHeading displayHeading={displayHeading} />
      <div className="flavour">
        {shortTastingNotesTaste && (
          <div className="item">
            <div className="item-title">Tasting notes</div>
            <div className="item-sub-title">{shortTastingNotesTaste}</div>
          </div>
        )}

        {shortTastingNotesAroma && (
          <div className="item">
            <div className="item-title">Aroma</div>
            <div className="item-sub-title">{shortTastingNotesAroma}</div>
          </div>
        )}

        {globals?.country === 'uk' && allergens.length > 0 && (
          <div className="item">
            <div className="item-title">Allergy information</div>
            <div className="item-sub-title">Contains {formatAllergens()}</div>
          </div>
        )}
      </div>
    </>
  );
};

export const FlavourProfile: React.FC<{ product: FlavourProfileProps; displayHeading?: boolean }> = ({
  product,
  displayHeading = true,
}) => {
  const { shortTastingNotesTaste } = product;
  // Early return if there's no shortTastingNotesTaste
  if (!shortTastingNotesTaste) {
    return null;
  }

  // Use the theme hook and memoize the wrapper style
  const theme = useTheme();

  const wrapper = css`
    ${theme.widgets.Product?.flavourProfile || ''}
  `;

  // Determine which component to render based on the country
  const RenderFlavourComponent = globals.country === 'us' ? FoodPairing : TastingNote;

  return (
    <div className="flavour box" css={wrapper}>
      <RenderFlavourComponent product={product} displayHeading={displayHeading} />
    </div>
  );
};
