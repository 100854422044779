import type { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeMixinsUSWSJ, buildThemeWidgetStylesUSWSJ, buildThemeVarsUSWSJ } from 'dw-us-wsj/theme';
const createTheme: ThemeCreateFunction = () =>
  buildTheme(
    (vars: Pick<Theme, CommonThemeVars>) => merge({}, buildThemeVarsUSWSJ(vars)),
    (vars: Omit<Theme, 'mixins' | 'widgets'>) => buildThemeMixinsUSWSJ(vars),
    (vars: Omit<Theme, 'widgets'>) => {
      const buildThemeWidget = buildThemeWidgetStylesUSWSJ(vars);
      return merge({}, buildThemeWidget, {
        Footer: {
          default: `
          ${buildThemeWidget.Footer.default}
          .footer-nav-links .tab-panel ul{
            text-align: left;
          }
          `,
          navItems: `
            ${buildThemeWidget.Footer.navItems}
          `,
        },
      });
    },
  );
export default createTheme;
