/**
 * Theme for Laithwaites UK site
 */
import type { Theme } from '@emotion/react';
import { buildTheme, globals } from 'common-ui';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { icons } from 'common-font-icons';
import { notoSerifFont, oswaldFont, robotoFont } from '../common/nextFont';

export const buildThemeVarsLW = (vars: Pick<Theme, CommonThemeVars>) => {
  //primary
  const lwPink = '#CF004F'; //color1
  const lwDarkBlue = '#00193C'; //color2
  const lwCream = '#F8F5EF'; //color3
  const lwRed = '#B1070D'; //color4
  const lwlightRed = '#F5DEE6'; //color5
  const lwlightError = '#FFCFD5'; //color5
  const lwUnlimitedMemberText = '#009F8E';
  const lwUnlimitedMemberHoverText = '#00797F';
  const lwPink500 = '#E2004D';

  //secondary
  const lwPurple = '#7A0040'; //color4/11
  const lwLightGreen = '#ABC7AB'; //color6
  const lwBlue = '#203C5D'; //color7
  const lwPeach = '#FFA8A1'; //color8
  const lwDarkGreen = '#0B400A'; //color10
  const lwSand = '#B1927B'; //color5
  //Category Banners
  const whiteWine = '#E6EFF7';
  const redWine = lwPurple;
  const roseWine = '#FBE6F1';
  const champagneAndSparkling = '#674F3E';
  const dessert = '#55002D';
  const fortifiedWine = '#D5BFB1';
  const wineBox = '#C896B1';
  //CTA colors
  const lwCTAGreen = '#117B53'; //color9
  const lwSvgCheckboxBg = '%23117B53';
  const lwSvgCheckboxStroke = '%23FFFFFF';
  const lwCTARolloverGreen = '#094B32';
  const lwCTAPink = lwPink;
  const lwCTARolloverPink = '#8B0035';
  const lwHUELWBlue = '#D7E0EE';
  const lwDrGray = '#00000099';
  const lwGrey400 = '#BCBCBC';
  const lwCarbon = '#333333';
  const lwSquant = '#666666';
  const lwBorderBlue = '#51B1FF';
  const lwStockPink = lwPink;
  const contentBodyPrimaryBlack = '#222222';
  const congressBlue = '#004D86';
  const lwUnlimitedButtonColor = '#112b4b';
  const Linen = '#FCFBF9';
  const lwShadowGray = '#DBDBDB';

  const facetLabel = '#303030';
  const facetCheckbox = '#767676';

  const bodySecondary = '#616161';

  const colors = {
    //commmon colour definitions that will used in widgets
    primary0: lwBorderBlue,
    primary1: lwPink,
    primary2: lwDarkBlue,
    primary3: lwCream,
    primary4: lwRed,
    primary5: lwlightRed,
    primary6: lwStockPink,
    primary7: lwlightError,
    primary8: contentBodyPrimaryBlack,
    primary9: lwPink500,
    secondary1: lwPurple,
    secondary2: lwSand,
    secondary3: lwLightGreen,
    secondary4: lwBlue,
    secondary5: lwPeach,
    secondary6: lwCTAGreen,
    secondary7: lwDarkGreen,
    secondary8: lwPurple,
    secondary9: lwGrey400,
    secondary10: lwCarbon,
    secondary11: lwSquant,
    secondary12: congressBlue,
    secondary13: Linen,
    secondary14: lwShadowGray,
    secondary15: bodySecondary,

    //specific colour definitions
    btnsPrimaryBg: lwCTAGreen,
    btnsPrimaryColor: vars.colors.white,
    btnsPrimaryHoverBg: lwCTARolloverGreen,
    btnsPrimaryHoverColor: vars.colors.white,
    btnsSecondaryBg: vars.colors.white,
    btnsSecondaryColor: vars.colors.black,
    btnsSecondaryHoverBg: lwCTARolloverGreen,
    btnsSecondaryHoverColor: vars.colors.white,
    btnsTertiaryBg: lwCTAPink,
    btnsTertiaryColor: vars.colors.white,
    btnsTertiaryBorder: lwCTAPink,
    btnsTertiaryHoverBg: lwCTARolloverPink,
    btnsTertiaryHoverColor: vars.colors.white,
    unlimitedButtonColor: lwUnlimitedButtonColor,
    facetCheckboxColor: facetCheckbox,
    facetLabelColor: facetLabel,
    svgCheckboxBg: lwSvgCheckboxBg,
    svgCheckboxStroke: lwSvgCheckboxStroke,
    savingsTextColor: lwPink,

    textPrimaryBg: lwPink500,
    textUnlimitedBg: lwUnlimitedMemberText,
    textUnlimitedHoverBg: lwUnlimitedMemberHoverText,

    linkPrimaryColor: vars.colors.black,
    linkSecondaryColor: lwCTAPink,
    linkTertiaryColor: lwCTAGreen,
    lWHUELWBlue: lwHUELWBlue,
    lwDrGray,

    headerCartBtn: lwCTAGreen,
    headerCartBtnSmall: lwCTAGreen,
    headerSignInBtnSmall: lwDarkBlue,
    footerPrimaryBg: lwPink,
    footerGuidelinesFillColor: lwPink,
    footerIconFillColor: vars.colors.black,
    footerdrinkaware: vars.colors.black,

    bgColors: {
      LWPink: lwPink,
      LWDarkBlue: lwDarkBlue,
      LWCream: lwCream,
      LWPurple: lwPurple,
      LWSand: lwSand,
      LWLightGreen: lwLightGreen,
      LWBlue: lwBlue,
      LWPeach: lwPeach,
      LWCTAGreen: lwCTAGreen,
      LWDarkGreen: lwDarkGreen,
      whiteWine,
      redWine,
      roseWine,
      champagneAndSparkling,
      dessert,
      fortifiedWine,
      wineBox,
      lwlightError,
    },
  };

  const oswald = oswaldFont.style.fontFamily;
  const roboto = robotoFont.style.fontFamily;
  const noto = notoSerifFont.style.fontFamily;

  const fontSizeDefault = '14px';
  const fonts = {
    familyPrimary: oswald,
    familySecondary: roboto,
    familyTertiary: noto,
    sizeRoot: fontSizeDefault, //used in page root
    sizeSmall: '12px',
    sizeDefault: fontSizeDefault,
    sizeLarge: '16px',
    sizeLarger: '18px',
    sizeXL: '64px',
    sizeH1: '40px',
    sizeH2: '28px',
    sizeH3: '20px',
    sizeH4: '16px',
    sizeH5: '14px',
    sizeH6: '24px',
    sizeH7: '20px',
    sizeH8: '22px',
    sizeH9: '26px',
    sizeH10: '30px',
    weightLight: '300',
    weightNormal: '400',
    weightMedium: '500',
    weightMediumPlus: '600',
    weightBold: '700',
    lineHeightDefault: '24px',
    headerMainNavTitle: noto,
    headerMainNavSubTitle: noto,
    styleItalic: 'italic',
    roboto,
    noto,
    oswald,
  };

  const space = {
    //common spacing based on design guide grid/typography/buttons etc
    xs: '4px',
    sm: '8px',
    md: '10px',
    lg: '16px',
    xl: '24px',
    xl2: '32px',
    xl3: '48px',
    xl4: '64px',
  };

  return {
    meta: {
      name: 'Laithwaites UK',
    },
    icons,
    assets: {
      headerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      headerLogoMobileImgUrl: `${globals.assetsPath}/next/images/brand-logo-mobile.svg`,
      stickyHeaderLogoImgUrl: `${globals.assetsPath}/next/images/sticky-brand-logo.svg`,
      footerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
    },
    colors,
    fonts,
    space,
  };
};
export const buildThemeMixinsLW = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  const buttonBase = `
    display: inline-block;
    font-size: ${vars.fonts.sizeDefault};
    border-radius: 5px;
    //custom line-height and vertical pad for correct icon fit
    line-height: 26px;
    padding: 12px 24px;
    border-width: 0;
    border-style: solid;

    :hover{
      text-decoration: none;
    }

    :focus:hover {
      color: ${vars.colors.btnsSecondaryColor} !important;
    }

    i.icon-left {
      font-size: ${vars.fonts.lineHeightDefault};
      transform: translate(-6px);
    }
  `;
  const linkBase = `
    display: inline;
    font-size: inherit;
    background:none;
    border-width: 0;
    padding: 0;
    text-decoration: none;
    text-underline-offset: ${vars.space.xs};
    font-weight: inherit;
    align-self: auto;

    :hover, :focus{
      text-decoration: underline;
      background-color: transparent;
      cursor: pointer;
    }

    &[aria-disabled="true"] {
      pointer-events: none;
      color: ${vars.colors.grey40};
    }

  `;

  return {
    h8: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightBold};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing6};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    body1: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    body2: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize14};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    btnPrimary: `
    ${buttonBase}
    background-color: ${vars.colors.btnsPrimaryBg};
    color: ${vars.colors.btnsPrimaryColor};
    border-width: 2px;
    border-color: transparent;

    &.desktop-sticky-content:hover, :hover{
      background-color: ${vars.colors.btnsPrimaryHoverBg};
      color: ${vars.colors.white};
    }
    &.desktop-sticky-content:focus, :focus{
      background-color: ${vars.colors.white};
      color: ${vars.colors.black};
      border-color: ${vars.colors.primary0};
    }
    &.desktop-sticky-content:disabled, :disabled{
      background-color: ${vars.colors.grey00};
      color: ${vars.colors.grey70};
    }
  `,
    btnPrimaryUnlimited: `
    ${buttonBase}
    background-color: ${vars.colors.textUnlimitedBg};
    color: ${vars.colors.btnsPrimaryColor};
    border-width: 2px;
    border-color: transparent;

    &.desktop-sticky-content:hover, :hover{
      background-color: ${vars.colors.textUnlimitedHoverBg};
      color: ${vars.colors.white};
    }
    &.desktop-sticky-content:focus, :focus{
      background-color: ${vars.colors.white};
      color: ${vars.colors.black};
      border-color: ${vars.colors.primary0};
    }
    &.desktop-sticky-content:disabled, :disabled{
      background-color: ${vars.colors.grey00};
      color: ${vars.colors.grey70};
    }
  `,
    btnSecondary: `
    ${buttonBase}
    background-color: ${vars.colors.btnsSecondaryBg};
    color: ${vars.colors.btnsSecondaryColor};
    border-width: 1px;
    border-color: ${vars.colors.btnsSecondaryColor};
    &.desktop-sticky-content:hover, :hover{
      background-color: ${vars.colors.btnsSecondaryHoverBg};
      color: ${vars.colors.btnsSecondaryHoverColor};
    }
    &.desktop-sticky-content:focus, :focus{
      background-color: ${vars.colors.white};
      color: ${vars.colors.black};
      border-color: ${vars.colors.primary0};
      border-width: 2px;
      padding: 6.5px 21px;
    }
    &.desktop-sticky-content:disabled, :disabled{
      background-color: ${vars.colors.grey00};
      border: none;
      color: ${vars.colors.grey70};
    }
  `,
    btnTransparent: `
      ${buttonBase}
      background-color: transparent;
      color: ${vars.colors.btnsSecondaryColor};
      border-width: 1px;
      border-color: ${vars.colors.btnsSecondaryColor};
      &.desktop-sticky-content:hover, :hover{
        background-color: ${vars.colors.btnsSecondaryHoverBg};
        color: ${vars.colors.btnsSecondaryHoverColor};
      }
      &.desktop-sticky-content:focus, :focus{
        background-color: ${vars.colors.white};
        color: ${vars.colors.black};
        border-color: ${vars.colors.primary0};
        border-width: 2px;
        padding: 6.5px 21px;
      }
      &.desktop-sticky-content:disabled, :disabled{
        background-color: ${vars.colors.grey00};
        border: none;
        color: ${vars.colors.grey70};
      }
  `,
    btnTertiary: `
    ${buttonBase}
    background-color: ${vars.colors.btnsTertiaryBg};
    color: ${vars.colors.btnsTertiaryColor};
    border-width: 1px;
    border-color: ${vars.colors.btnsTertiaryBorder};

    :hover{
      background-color: ${vars.colors.btnsTertiaryHoverBg};
      color: ${vars.colors.btnsTertiaryHoverColor};
    }
    :focus{
      background-color: ${vars.colors.white};
      color: ${vars.colors.black};
      border-color: ${vars.colors.primary0};
      border-width: 2px;
      padding: 6.5px 21px;
    }
    :disabled{
      background-color: ${vars.colors.grey00};
      color: ${vars.colors.grey30};
    }
    `,
    btnPill: `
    ${buttonBase}
    box-sizing: border-box;
    color: #112B4B;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    background: none;
    width: 65px;
    height: 32px;
    border: 1px solid ${vars.colors.grey60};
    border-radius: 16px;

    &:hover {
      cursor: pointer;
    }

    &.active {
      background-color: #112B4B;
      color: ${vars.colors.white};
    }

    `,
    linkPrimary: `
    ${linkBase}
    color: ${vars.colors.linkPrimaryColor};
  `,
    linkSecondary: `
    ${linkBase}
    color: ${vars.colors.linkSecondaryColor};
  `,
    linkTertiary: `
    ${linkBase}
    color: ${vars.colors.linkTertiaryColor};
  `,
    clearRefinements: `
    background: none;
    color: ${vars.colors.black};
    font-size: ${vars.fonts.sizeLarge};
    line-height: ${vars.fonts.lineHeightDefault};
    text-decoration-line: underline;
    padding: 0;
    margin: 0 0 16px 0;
    cursor: pointer;
    border: none;
    box-shadow: none;
    &:hover {
      background: none;
      color: ${vars.colors.black};
      text-decoration-line: underline;
    }
  `,
  };
};
export const buildThemeWidgetStylesLW = (vars: Omit<Theme, 'widgets'>) => ({
  CategoryPage: {
    mobileFilter: `
        .mantine-Drawer-close {
          outline: 0px;
          color: rgb(34, 34, 34);
          width: 40px;
          position: absolute;
          right: 10px;
          top: 15px;
        }
        .mantine-Drawer-body {
          position: relative;
          .button-container {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            margin-top: 50px;
            button  {
              line-height: initial;
              border-radius: 3px;
              width: 100%;
            }
          }
          .drawer-title {
            margin-top: 20px;
            display: flex;
            gap: 10px;
            justify-content: space-between;
            align-items: center;
            h2 {
              margin-bottom: 0px;
            }
          }
          button {
            &.clear-refinements {
              ${vars.mixins.linkPrimary}
            }
          }
          ul {
            &.current-refinements {
              display: flex;
              padding: 0;
              gap: 10px;
              button {
              line-height: initial;
                &.refinement-pill {
                  ${vars.mixins.btnPill}
                }
              }
            }
          }
        }
      `,
    wrapper: `
        position: relative;
        .product-listing-wrapper {
          margin: 0px auto;
        }
        .sticky-banner {
          position: sticky;
          background-color: ${vars.colors.primary3};
          padding: 25px;
          bottom: 0;
          z-index: 2;
          border-top: 1px solid ${vars.colors.primary2};
          border-bottom: 1px solid ${vars.colors.primary2};
        }
        .product-image-container {
          .product-image {
            padding: 20px;
            position: relative;
          }
        }
        .utility-bar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;
          margin-bottom: 20px;
          ${vars.breakpoints.lg} {
            margin-bottom: initial;
          }
          .filtered-pills {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          .sorting {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: space-between;
          }
          .ais-SearchBox {
            flex-grow: 1;
             ${vars.breakpoints.sm} {
              flex-grow: initial;
             }
          }
          .clear-refinements {
            ${vars.mixins.linkPrimary}
          }
          ul {
            &.current-refinements {
              display: flex;
              padding: 0;
              gap: 8px;
            }
          }
          .results .options .label {
            color: ${vars.colors.secondary15};
            font-weight: ${vars.fonts.weightNormal};
          }

          button {
            line-height: initial;
            &.refinement-pill {
              ${vars.mixins.btnPill}
            }
          }
        }
        .ais-SortBy-select {
          color: ${vars.colors.grey60};
        }
        .ais-SearchBox-input {
          border-color: #222222;
          box-shadow: none;
          border-radius: 5px;
          &::placeholder {
            color: ${vars.colors.grey60};
            font-size: 1rem;
          }
        }
        .ais-SearchBox-form {
          height: 40px;
          min-width: 200px;
          &::before {
            background-color: inherit;
          }
        }
        .ais-Hits-list {
          margin: 15px 0px;
          display: grid;
          align-items: flex-start;

          grid-template-columns: 1fr 1fr;

          ${vars.breakpoints.sm} {
            grid-template-columns: 1fr 1fr 1fr;
          }
          ${vars.breakpoints.xl} {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
          gap: 24px;
          .ais-Hits-item {
            padding: 0px;
            box-shadow: none;
            margin-bottom: 30px;
          }
        }
        .pagination-mainContainer {
          justify-content: center;
          margin: 50px 0;
          .ais-Pagination {
            .ais-Pagination-list {
              .ais-Pagination-item--disabled {
                background-color: #EDEDED;
                .ais-Pagination-link, .ais-Pagination-list {
                  color: #767676;
                }
              }
              .ais-Pagination-item--selected {
                border: none;
                a {
                  background-color: #F8F8F8;
                  color: #222222;
                  border: 1px solid #222222;
                }
              }
              li {
                border: 1px solid #BCBCBC;
                border-radius: 1px;
                  a {
                    color: #222222;
                    border-radius: 1px !important;
                    &:hover, &:focus {
                      border-color: #222222 !important;
                    }
                  }
              }
              .ais-Pagination-item--previousPage, .ais-Pagination-item--nextPage {
                border: 1px solid #222222;
              }
            }
          }
        }
      `,
  },
  Loading: {},
  Form: {
    wrapper: `
        .field {
          margin: 10px 0;
          &.inline {
            display: flex;
            align-items: center;
            .label {
              min-width: 120px;
            }
          }
        }
        &.rate-review-modal-container {
          .field {
            margin: 30px 0;
          }
        }
      `,
  },
  CollapseBox: {
    wrapper: `
        margin-bottom: 20px;
      `,
    header: `
        background-color: ${vars.colors.primary1};
        color: white;
        padding: 20px;
      `,
    body: `
        padding: 20px;
        background-color: ${vars.colors.white};
      `,
  },

  WinePreferences: {
    wrapper: `
        h3 {
          text-transform: uppercase;
          font-size: 28px;
        }
        em{
          display: block;
          font-weight: bold;
          margin-bottom: 20px;
        }
      `,
    loadingMsg: `
      `,
    errorMsg: `
      `,
    singleChoiceList: `
        margin-bottom: 30px;

        label{
          margin: 10px 0;
          input {
            margin-right: 10px;
          }
        }
      `,
    singleChoiceTable: `
        th,
        td {
          width: 25%;
        }

        td {
          text-align: center;
        }

        thead {
          th,
          td {
            padding: 10px 5px;
          }
        }

        tbody {
          th,
          td {
            font-weight: normal;
            padding: 15px 5px;
          }

          tr:last-child {
            th,
            td {
              border-bottom-width: 0;
            }
          }
        }
      `,
    multiSelectList: `
        label{
          margin: 10px 0;

          input {
            margin-right: 10px;
          }
        }
      `,
  },

  GlobalLayout: {
    previewMode: `
        position: fixed;
        bottom: 30px;
        width: 85%;
        text-align: center;
        left: 7.5%;
        border-radius: 8px;
        font-weight: ${vars.fonts.weightNormal};
        padding: 15px;
        background-color: ${vars.colors.white};
        box-shadow: 2px 2px 2px 2px ${vars.colors.primary1};
        z-index: 9999;
        p {
          margin: 0;
          color: ${vars.colors.black};
          a {
            margin-left: 5px;
            color: ${vars.colors.primary1};
          }
        }
      `,

    wrapper: `
      .no-results {
        .container {
          margin: 25px 0px;
        }
      }
      `,
  },

  GlobalHeader: {
    wrapper: `
        font-size: ${vars.fonts.sizeH5};
        &.mainHeader {
          z-index: 10 !important ;
          .sticky-header{
            position: fixed;
            top: 0;
            width: 100%;
            left: 0;
            z-index: 3;
            background-color: ${vars.colors.white};
            box-shadow: 0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 0.625rem 0.9375rem -0.3125rem,
            rgba(0, 0, 0, 0.04) 0 0.4375rem 0.4375rem -0.3125rem;
            .search-navbar-container{
              padding: 0;
              transition:all 0.9s ease;
              -webkit-transition:all 0.9s ease;
              -moz-transition:all 0.9s ease;
            }
            .mantine-HoverCard-dropdown {
              padding: 12px 0;
            }
          }
        ${vars.breakpoints.xs} {
            position: sticky;
            top: 0;
            z-index: 500;
          }
          ${vars.breakpoints.lg} {
              position: relative;
              top: unset;
              z-index: 10;
          }
          .nav-overlay  {
            display: none;
            position: fixed;
            left: 0;
            right: 0;
            height: 100%;
            background: ${vars.colors.black};
            opacity: 0.6;
            z-index: 1;
          }
          .minicart-overlay {
            position: fixed;
            left: 0;
            right: 0;
            height: 100%;
            background: ${vars.colors.black};
            opacity: 0.6;
            z-index: 1;
            top: 0;
          }
          .active-nav-overlay  {
            display: block
          }
        }
        .state-selector-mobile {
          display: flex;
          justify-content: center;
          padding: 10px;
          background: ${vars.colors.primary3};
          ${vars.breakpoints.lg} {
            display: none;
          }
        }
      `,
    stripBanner: `
        div{
          > * {
            margin: 0;
            padding: 15px;
          }
        }
      `,
    tertiaryNav: `
      display: none;
      ${vars.breakpoints.lg} {
        display: block;
        padding: 1em 0;
      }
      .sticky-header &{
        display: none;
      }
      ul {
        display: flex;
        padding: 0;
        margin: 0;
        justify-content: end;
        li {
          list-style: none;
          margin-left: 48.3px;
          a {
            font-family: ${vars.fonts.familySecondary};
            color: ${vars.colors.black};
            font-weight: ${vars.fonts.weightNormal};
            text-transform: capitalize;
          }
        }
      }
    `,
    mainNavBarWrapper: `
        display: none;
        .logo-wrapper{
          display: flex;
          width: 49px;
          height: 64px;
          padding: 8px 0px;
          justify-content: center;
          align-items: center;
          gap: 10px;
        }

        ${vars.breakpoints.lg} {
          display: flex;
          align-items: center;
          justify-content: space-around;

          .input-on-focus & {
            display: none;
          }
        }

        .tabPanes-wrapper{
          width: 100%;
        }

        .is-sticky{
          display: flex;
          gap: 33px;
          &.is-sticky-searchbar {
            gap: 0px;
          }
        }
        .searchbar-input-container {
          position: relative;
        }
        .isSearchBar {
            position: absolute;
            top: 11px;
            left: 15px;
        }

        .isStickyCancelContainer {
          .isStickyCancelButton {
            display: block;
            text-decoration: none;
            padding: 0px 20px 0 0px;
            background: transparent;
            color: ${vars.colors.black};
            border: 0;
            cursor: pointer;
            &:hover, &:focus {
              outline:0;
            }
          }
        }

        ${vars.breakpoints.lg} {
            border-left: 0;
            margin: auto;
            .isStickyCancelButton {
              display: none;
              padding: 0;
            }
          }



        .search-user-cart{
          display: flex;
          gap: 32px;
          width: 10%;
        }

        .topLevelLinks{
          display: flex;
          justify-content: center;
          width: 100%;
          .cms-conditional-container {
            display: flex;
          }
          & > div {
             display: flex;
             justify-content: center;
             width: 100%;
             & + div {
              display: none;
            }
          }
          .navigation-link .cms-conditional-container {
            flex-direction: column;
            a {
              text-wrap: initial;
              line-height: 18px;
              margin-bottom: 14px;
              width: fit-content;
            }
          }
        }

        .topLevelLink{
          padding-top: 0;
          padding-bottom: 0;
          &:hover .megaNavTab{
            color: ${vars.colors.grey50};
            text-decoration: underline;
            text-decoration-color: ${vars.colors.primary1};
            text-decoration-thickness: 4px;
          }
        }
        .megaNavTab:focus{
            text-decoration: none;
        }

        .isStickySearchBar{
          width: 100%;
        }

        .isSticky {
          color: ${vars.colors.black};
          font-family: ${vars.fonts.familySecondary};
          font-size: ${vars.fonts.sizeH5};
          font-style: normal;
          font-weight: ${vars.fonts.weightNormal};
          line-height: ${vars.fonts.lineHeightDefault};
          text-transform: uppercase;
          display: inline-block;
          text-underline-offset: ${vars.space.lg};
          padding: ${vars.space.lg} 0;

          &:hover{
            text-decoation: underline;
            text-decoration-color: ${vars.colors.primary1};
            text-decoration-thickness: 3px;
          }

          &.emphasize{
          color: ${vars.colors.primary1};
          font-family: ${vars.fonts.familySecondary};
          font-size: ${vars.fonts.sizeH5};
          font-style: normal;
          font-weight: ${vars.fonts.weightBold};
          line-height: ${vars.fonts.lineHeightDefault}; /* 171.429% */
          text-transform: uppercase;
          }
        }

        .megaNavTab {
          font-size: 18px;
          text-underline-offset: ${vars.space.lg};
          padding: ${vars.space.lg} 14px;
          display: inline-block;
          font-family: ${vars.fonts.headerMainNavTitle};
          color: ${vars.colors.grey50};
          letter-spacing: ${vars.fonts.fontLetterSpacing5};
          font-weight: ${vars.fonts.weightMediumPlus};
          ${vars.breakpoints.xl} {
            padding: ${vars.space.lg} 20px;
          }

          span {
            display: inline-block;
          }
          &:hover {
            text-decoration: none;
            span {
              position: relative;
              ::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: -13px;
                height: 5px;
                background: ${vars.colors.primary1};
                z-index: 0;
              }
            }
          }

          &.emphasize, &.emphasize span {
            color: ${vars.colors.primary1};
            font-weight: ${vars.fonts.weightBold};
            white-space: nowrap;
          }
          &:focus{
            text-decoration: none;
          }
        }

        .mantine-HoverCard-dropdown{
          padding: 0;
          border: none;
          background: transparent;
          box-shadow: 0px 0px 0px;
          .header-main-nav-layout {
            background: ${vars.colors.white};
            border-radius: 0px 0px 12px 12px;
            box-shadow: 0px 0px 3px 0px #DEDEDE inset;
            .dynamicComponents {
              padding: 22px 40px;
              .richtext-wrapper {
                &.richtext-has-bg {
                  padding: 1rem;
                }
                p {
                  margin-top: 1em;
                  margin-bottom: 1em;
                  &:first-of-type {
                    margin-top: 0;
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
            .navigation-link {
              a {
                letter-spacing: ${vars.fonts.fontLetterSpacing5};
              }
              > a {
                width: 100%;
                overflow-x: hidden;
                text-overflow: ellipsis;
              }
            }
          }
          .mantine-ScrollArea-root  {
            .widget-cms-column > div {
              margin: 0;
              width: 100%;
              max-width: 100%;
            }
          }
        }
      `,

    mainNavBarMenuTabPane: `
        text-align: left;
        margin-top: -8px;
        left: 0 !important;
        ${vars.breakpoints.xxl} {
          right: 0;
        }
        .header-main-nav-layout {
          h4{
            font-family: ${vars.fonts.headerMainNavSubTitle};
            font-size: 18px;
            font-weight: ${vars.fonts.weightBold};
            margin: 0 0 ${vars.space.lg} 0;
            color: ${vars.colors.primary1};
            letter-spacing: 0.01em;
            text-transform: unset;
          }
          a{
            line-height: 22px !important;
            font-size: ${vars.fonts.sizeH4};
            &:hover {
              color: ${vars.colors.primary1};
            }
          }
        }
      `,
    searchNavBar: `
      .state-selector {
        display: none;
        ${vars.breakpoints.lg} {
          display: block;
        }
      }
      .search-navbar-container {
        padding: 0;
        .grid-search-navbar-container{
          flex-wrap: nowrap;
        }
        .navbar-left-container {
          order: 2;
          display: flex;
          justify-content: center;
        }
        .navbar-center-container {
          position: relative;
          .sticky-header & {
            ${vars.breakpoints.lg} {
              position: initial;
              padding-right: 24px;
            }
            &.input-on-focus {
              position: relative;
            }
            max-width: none;
          }
          min-width: 83px;
          text-align: left;
          min-height: unset;
          display:flex;
          align-items: center;
          gap:10px;
          order: 1;
          ${vars.breakpoints.lg} {
            max-width: 480px;
          }
          ${vars.breakpoints.xl} {
            max-width: 540px;
          }
          ${vars.breakpoints.xxl} {
            max-width: 689px;
          }
          .sticky-nav-bar{
            display:none;
          }
          .sticky-header &{
            align-items: center;
            ${vars.breakpoints.lg} {
              .sticky-nav-bar{
                display:flex;
                width: 100%;
              }
            }
            .search-icon {
              display: initial;
            }
            .searchbar-grid-block-container {
              display: none;
              margin: 0 auto;
              right: 0;
              transition: all 1s;
              ${vars.breakpoints.lg} {
                max-width: 410px;
                position: fixed;
              }
              ${vars.breakpoints.xl} {
                max-width: 480px;
                position: fixed;
              }
              ${vars.breakpoints.xxl} {
                max-width: 612px;
                position: fixed;
              }
            }
            .header-search-bar{
              margin-left: auto;
            }
          }
          .search-overlay  {
            display: none
          }
          .isSearchBar {
            position: absolute;
            top: 5px;
            right: 5px;
            background: ${vars.colors.primary1};
            padding: 8px;
            border-radius: 50px;
            color: ${vars.colors.white};
            width: 36px;
            height: 36px;
          }
          .sticky-header & .header-mainnav-mobile{
            ${vars.breakpoints.lg} {
              display:none;
            }
          }
          &.input-on-focus {
            + .navbar-right-container {
              .sticky-header & {
                display: block;
              }
            }
            .header-mainnav-mobile, .search-icon {
              display: none;
            }
            .searchbar-grid-block-container {
              display: block;
              .searchbar-grid  {
                background: ${vars.colors.white};
                position: relative;
              }
            }
            .suffix-icon, .searchbar-cancel-link {
              display: block;
            }
            .search-overlay  {
              display: block
            }
            .aa-Form {
              border: 0;
              ${vars.breakpoints.lg} {
                border: 1px solid ${vars.colors.black};
              }
            }
          }
        }
        .navbar-right-container {
          order: 3;
          padding-right: 21px;
          & > div {
            gap: 0em;
            .favourites-col-container {
              display: none;
              ${vars.breakpoints.md} {
                display: block;
              }
            }
          }
          .sticky-header &{
            .sign-in-link {
              span {
                display: none;
                vertical-align: middle;
                margin-left: 0.5em;
                color: ${vars.colors.black};
              }
            }
            .header-cart-price-container {
              display: none;
            }
            .header-cart-count-container {
              background: transparent;
              padding: 0em;
              .header-cart-grid-container {
                min-width: 90%;
                .header-cart-price-container {
                  display: none;
                }
                .cart-quantity{
                  display: initial;
                }
              }
            }
          }
        }
      }
      ${vars.breakpoints.lg} {
        border-bottom: 0.5px solid ${vars.colors.grey20};
        .search-navbar-container {
          padding: 0.8em 0;
          .navbar-left-container {
            order: 1;
            justify-content: flex-start;
            .sticky-header & {
              display:none;
            }
          }
          .navbar-center-container {
            min-width: unset;
            text-align: unset;
            min-height: 69px;
            flex: auto;
            order: 2
          }
          .navbar-right-container {
            & > div {
              .favourites-col-container {
                display: flex;
              }
              .sticky-header & .favourites-col-container {

              }
            }
          }
        }
      }
      ${vars.breakpoints.md} {
        .navbar-center-container {
          min-width: 106px;
        }
        .search-navbar-container {
          .navbar-right-container {
            & > div {
              gap: 1em;
            }
          }
        }
      }

      //TODO: unhide when DSP-259 implemented
      .hamburger-menu-icon {
        display: none;
      }
    `,
    headerLogo: `
      img {
        display: block;
        max-width: 10em;
        width: 100%;

        ${vars.breakpoints.lg} {
        max-width: 13.5em;
        }
      }
    `,
    stickymainNavWrapper: `
      #header-logo img {
        width: auto;
        min-width: auto;
      }
      .topLevelLink {
        .megaNavTab, .navigation-link h4 {
          font-size: ${vars.fonts.sizeDefault};
        }
        .navigation-link a {
          font-size: ${vars.fonts.sizeDefault};
          line-height: 18px !important;
          letter-spacing: ${vars.fonts.fontLetterSpacing5};
        }
      }
    `,
    headerFavourites: `
      a {
        &.icon-hover {
          color: ${vars.colors.primary1};
        }
        &.favourites-link {
          text-decoration: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 5px;
          span {
            display: none;

            .sticky-header & {
              display: none;
            }
            ${vars.breakpoints.lg} {
              display: block;
            }
          }
        }
      }
    `,
    headerSignIn: `
      color: ${vars.colors.black};
      .user-logged-in-label {
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 5px;
      }
      .sign-in-label {
        width: 2em;
        height: 2em;
        background: ${vars.colors.headerSignInBtnSmall};
        color: ${vars.colors.white};
        display: inline-flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        vertical-align: middle;
      }
      .sign-in-name {
        margin-left: 0.3em;
        display: none;
        text-transform: capitalize;
        max-width: 90px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        ${vars.breakpoints.lg} {
          display: inline-block;
          .sticky-header & {
            display: none;
          }
        }
      }
      .sign-in-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        &:hover {
          text-decoration: none;
        }
        span {
          display: none;
          vertical-align: middle;
          color: ${vars.colors.black};
          ${vars.breakpoints.lg} {
            display: inline-block;
          }
        }
      }
    `,
    headerCart: `
      display: inline-flex;
      border-radius: 0.35em;
      color: ${vars.colors.black};
      background: transparent;
      position: relative;
      padding: 0em;
      .header-cart-link:hover {
          text-decoration: none;
      }
      .header-cart-grid-container {
        margin-left: 0;
        margin-right: 0;
        .header-cart-price-container {
          display: none;
          padding-left: 0.75em !important;
          font-family: ${vars.fonts.familySecondary};
          font-weight: ${vars.fonts.weightNormal};
        }
        .header-cart-icon-container {
          position: relative;
          padding-right: 0.75em;
          .cart-quantity {
            position: absolute;
            border-radius: 0.8em;
            min-width: 2em;
            min-height: 1.65em;
            background: ${vars.colors.headerCartBtnSmall};
            top: 2px;
            left: 1.45em;
            font-family: ${vars.fonts.familySecondary};
            font-weight: ${vars.fonts.weightBold};
            font-size: 0.78em;
            padding: 1.5px 3px 0px;
            line-height: ${vars.fonts.sizeLarge};
            text-align: center;
            display: inline-block;
            color: ${vars.colors.white};
          }
        }
      }
      &.has-quantity {
        :not(.sticky-header) &{
          background: transparent;
          color: ${vars.colors.black};
          .header-cart-link {
            color: ${vars.colors.black};
          }
          .header-cart-grid-container {
            .header-cart-icon-container {
              padding-right: 0.75em;
              .cart-quantity {
                background: ${vars.colors.headerCartBtnSmall};
                color: ${vars.colors.white};
              }
            }
          }
        }
      }
      .cart-confirmation-popup {
        background: ${vars.colors.white};
        border: 1px solid ${vars.colors.grey20};
        border-radius: 3px;
        padding: 10px;
        position: absolute;
        right: 0px;
        top: 68px;
        min-width: 180px;
        white-space: nowrap;
        color: ${vars.colors.black};
        display: none;
        i {
          padding-right: 0.15em;
          color: ${vars.colors.secondary6};
        }
        &.fadedown {
        ${vars.mixins.animateFadeInDown};
          display: block;
          z-index: 201;
        }
        &.fadeout {
          ${vars.mixins.animateFadeOutUp};
        }
        &::before {
          background: ${vars.colors.white};
          border: solid ${vars.colors.grey20};
          border-width: 0 1px 1px 0;
          content: '';
          display: block;
          left: 150px;
          padding: 7px;
          position: absolute;
          top: -8px;
          transform: rotate(225deg);
          width: 1px;
          z-index: 1;
        }
      }
      .header-cart-price-container {
        display:none;
      }
      .header-cart-link {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
      }
      .header-cart-icon-container {
        position: relative;
      }
      .cart-quantity {
        position: absolute;
        background: ${vars.colors.primary1};
        border-radius: 50px;
        border: 1px solid ${vars.colors.white};
        display: flex;
        align-items: center;
        justify-content: center;
        top: -7px;
        right: -8px;
        padding: 9px 5px;
        color: ${vars.colors.white};
        font-size: 14px;
        font-weight: 500;
        min-width: 20px;
        height: 20px;
      }

      ${vars.breakpoints.lg} {
        .header-cart-price-container {
          display: block;
        }

        .header-cart-grid-container {
          .header-cart-price-container {
            display: block;
          }
          .header-cart-icon-container {
            padding-right: 0;
            .cart-quantity {
              display: none;
            }
          }
        }
        &.has-quantity {
          ${vars.breakpoints.lg} {
            .header-cart-link, div.header-cart-link {
              .header-cart-price-container span,.header-cart-icon-container i {

              }
              .sticky-header & {
                .header-cart-price-container span,.header-cart-icon-container i {
                  color: ${vars.colors.black};
                }
              }
            }
            .header-cart-grid-container {
              .header-cart-icon-container {
                padding-right: 0.75em;
                .cart-quantity {
                  display: inline-block;
                  background: ${vars.colors.white};
                  color: ${vars.colors.headerCartBtn};
                  .sticky-header & {
                    background: ${vars.colors.headerCartBtn};
                    color: ${vars.colors.white};
                  }
                }
              }
            }
          }
          :not(.sticky-header) &{
            background: ${vars.colors.headerCartBtn};
            color: ${vars.colors.white};
            .header-cart-link {
              color: ${vars.colors.white};
            }
            .header-cart-grid-container {
              .header-cart-icon-container {
                .cart-quantity {
                  display: inline-block;
                  background: ${vars.colors.white};
                  color: ${vars.colors.headerCartBtn};
                }
              }
            }
          }
        }
      }
    `,
    stickyHeader: `
        position: sticky;
        top: 0;
        background: ${vars.colors.white};
        z-index: 1;
        border-radius: 0;
        .stickyMainNavBar-container{
          background-color: white;
        }
    `,
    headerSearchInput: `
      .search-icon {
        display: block;
        ${vars.breakpoints.lg} {
          display: none;
        }
      }
      .searchbar-grid-block-container {
        position: fixed;
        top: 0px;
        left:0;
        width: 100%;
        display: none;
        box-shadow: 0px 4px 25px rgb(0 0 0 / 6%);
        border-radius: 0;
        background: ${vars.colors.white};
        z-index: 1;
        min-width: 30%;
        transition: min-width 1s ease-out;
        ${vars.breakpoints.lg} {
          position: absolute;
          left: 0px;
          width: 100%;
          display: block;
          box-shadow: 0 0 0;
        }
        .searchbar-input-container {
          min-width: unset;
          position: relative;
          input {
            background-color: ${vars.colors.white};
            font-size: ${vars.fonts.sizeDefault};
            font-family: ${vars.fonts.familySecondary};
            width: 100%;
            padding: 10px 16px;
            line-height: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            border: 0;
            border-radius: 0;
            &:focus {
              outline: none;
              border: 0;
              &::placeholder {
                opacity: 0;
              }
            }
            &:disabled {
              cursor: default;
            }
            &::placeholder {
              color: ${vars.colors.grey40};
            }
            &:not(:placeholder-shown) ~ .suffix-icon {
              display: block;
            }
            // This is a temporary fix will be remove in sprint 10
            &.temp {
              border: 1px solid ${vars.colors.grey20};
              border-radius: 50px !important;
              box-sizing: border-box;
              padding: 10px 16px;
              height: 46px
            }
          }
          .prefix-icon {
            position: absolute;
            top: 5px;
            right: 5px;
            background: ${vars.colors.primary1};
            padding: 8px;
            border-radius: 50px;
            color: ${vars.colors.white};
            width: 36px;
            height: 36px;
            display: none;
            ${vars.breakpoints.lg} {
              display: block;
            }
          }

          .suffix-icon {
            display: none;
            position: absolute;
            top: 10px;
            right: 6px;
            cursor: pointer;
            ${vars.breakpoints.lg} {
              right: 15px;
            }
          }
        }
        .searchbar-cancel-container {
          border-left: 1px solid ${vars.colors.grey20};
          outline: none;
          ${vars.breakpoints.lg} {
            position: absolute;
            right: -15%;
          }
          ${vars.breakpoints.xxl} {
            position: absolute;
            right: -12%;
          }
          .searchbar-cancel-link {
            display: block;
            text-decoration: none;
            padding: 0px 20px 0 16px;
            background: transparent;
            color: ${vars.colors.black};
            border: 0;
            cursor: pointer;
            &:hover, &:focus {
              outline:0;
            }
          }

          ${vars.breakpoints.lg} {
            border-left: 0;
            .searchbar-cancel-link {
              display: none;
              padding: 0;
            }
          }
        }
      }
    `,
    navStickyButton: `
      text-align: center;
      padding: 24px 0;
      border-top: 1px solid #DEDEDE;
      a + a {
        margin-left: 10px;
      }
    `,
  },
  Footer: {
    default: `
      font-size: ${vars.fonts.sizeDefault};
      ${vars.mixins.footerBase}
      .footer-nav-links {
        background: ${vars.colors.primary3};
        box-shadow: 0 0 0 100vmax ${vars.colors.primary3};
        .wrapper {
          .navItem-navigation {
            p{
              font-weight: ${vars.fonts.weightBold};
            }
            .tab-panel {
              ul{
                margin: 12px 0px 0px 20px;
                ${vars.breakpoints.lg} {
                  margin-left: 0;
                }
                li {
                  line-height : 35px;
                  padding: 0;
                  a:hover {
                    color: unset;
                  }
                }
              }
            }
          }
        }
      }
      .social-connections,
      .footer-bottom-section {
        color: ${vars.colors.black};
      }
    `,
    navItems: `
      ${vars.mixins.footerNavItems}
    `,
  },
  DrawerMenu: {
    drawerContainer: `
        .mantine-Drawer-overlay {
          z-index: 501;
        }
        .mantine-Drawer-inner {
          z-index: 501;
          left: 0;
        }
        .mantine-Drawer-content {
          flex: 0 0 328px;
          ${vars.breakpoints.sm} {
            flex: 0 0 380px;
          }
        }
        .mantine-Drawer-body {
          padding: 0;
          height: 100%;
          border-top: ${vars.colors.grey20};
          display: flex;
          flex-direction: column;
          .drawer-header-logo-section {
            width: 100%;
            padding: 16px 24px;
            top: 0;
            z-index: 10;
            background-color: ${vars.colors.white};
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-shadow: 0px -1px 0px 0px rgba(204, 204, 204, 1) inset;
            #header-logo a {
              border: 0;
              padding: 0;
              &:focus-visible {
                outline: none;
              }
            }
          }
          .drawer-menu-heading {
            font-weight: ${vars.fonts.weightBold};
            font-size: ${vars.fonts.sizeH4};
            letter-spacing: 0.03em;
            margin: 0;
            background: #EEEAE2;
            padding: 8px 16px;
            text-align: center;
            min-height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            color: ${vars.colors.grey50};
            font-family: ${vars.fonts.familySecondary};
            margin-left: -24px;
            margin-right: -24px;
          }
          .drawer-main {
            background: ${vars.colors.primary3};
            height: 100%;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-left: 24px;
            padding-right: 24px;
            .highlighted-link {
              box-shadow: 0px 1px 0px 0px #DBDBDB inset;
              * {
                color: ${vars.colors.primary1};
                text-decoration: underline;
              }
            }
            a,span {
              font-family: ${vars.fonts.familySecondary};
              font-weight: ${vars.fonts.weightNormal};
              font-size: ${vars.fonts.sizeRoot};
              color: #303030;
              line-height: 18px;
              padding: 12px 0;
              display: block;
              &:hover,&:focus,&:focus-visible {
                text-decoration: none;
                outline: 0;
                background-color: transparent;
              }
              span {
                border: 0;
                padding: 0;
              }
            }
          }
          .drawer-footer-sticky-button-section {
            width: 100%;
            padding: 16px;
            background-color: ${vars.colors.white};
            display: flex;
            boxShadow: 0px -5px 40px 0px rgba(0, 20, 30, 0.1);
            gap: 16px;
            left: 0;
            justify-content: center;
            align-items: center;
            a {
              letter-spacing: 0.03em;
              text-transform: uppercase;
              font-size: ${vars.fonts.sizeSmall};
              padding: 15px 12px;
              line-height: 14px;
              ${vars.breakpoints.sm} {
                padding: 15px 24px;
              },
            },
          },
        },

      `,
    mobileNavMenuContainer: `
        position: relative;
        margin: 0 -24px;

        a{
          padding: 18px 0px; //reapply mantine pad
          border-bottom-width: 1px;
          &:hover{
            text-decoration: none;
          }
        }

        .mantine-Drawer-body & .mantine-NavLink-body,
        .mantine-Drawer-body & .mantine-NavLink-rightSection {
          border: none;
          margin: 0;
          padding: 0;
        }

        .mantine-NavLink-root {
          border-top: 1px solid ${vars.colors.grey20};
          border-radius: 0;
          padding: 15px 24px;
          position: relative;
          display: flex;
          background: transparent;
          color: black;
          margin: 0px;

          &:focus, &:hover{
            background: transparent;
            border-color: transparent;
            border-top: 1px solid ${vars.colors.grey20};
            color: black;
          }
        }

        .mantine-NavLink-children {
          padding-bottom: 20px;

          .mantine-NavLink-root {
            border-top-width: 0;
            padding: 7px 0;
          }
        }

        .mantine-NavLink-root:first-of-type {
          border-top-width: 0;
        }

        .navMenuItemIcon{
          ${vars.icons['chevron-right']}
          :before {
            ${vars.mixins.iconBase}
            font-size: ${vars.fonts.lineHeightDefault};
          }
        }
        .mainnav-mobile-drawer-social-panel {
          display: flex;
          justify-content: center;
          padding: 16px 24px;
          background-color: ${vars.colors.white};
          gap: 55px;
          border-top: 1px solid ${vars.colors.grey20};
          > div {
            a {
              padding: 0;
            }
          }
        }
        .mainnav-mobile-drawer-badges-panel {
          display: flex;
          flex-wrap: wrap;
          padding: 16px 24px;
          align-items: center;
          justify-content: center;
          gap: 10px;
          > div {
            &:nth-of-type(1) {
              flex-basis: 100%;
              text-align: center;
              order: 3;
            }
            a {
              padding: 0;
              border: 0;
            }
          }
        }
        .mainnav-mobile-drawer-footer-links-panel {
          padding: 0 24px;
          > div {
            box-shadow: 0px -1px 0px 0px #DBDBDB inset;
          }
          .navItem-navigation {
            margin: 0;
            p {
              color: ${vars.colors.grey60};
              text-transform: unset;
              font-size: 16px;
              font-weight: ${vars.fonts.weightNormal};
              padding: 16px 0;
            }
            &.navItem-navigation-active div p{
              color: ${vars.colors.primary1};
            }
            .tab-indicator {
              i {
                font-size: ${vars.fonts.lineHeightDefault};
              }
            }
            .tab-indicator.tab-collapse {
              i {
                color: ${vars.colors.grey60};
              }
            }
            .tab-indicator.tab-expand {
              i {
                color: ${vars.colors.primary1};
              }
            }
            .tab-panel {
              border-bottom: 1px solid #DBDBDB;
              ul {
                margin: 0;
                li {
                  padding: 0;
                  a {
                    padding: 12px 0;
                    display: block;
                    color: ${vars.colors.grey50};
                    font-weight: ${vars.fonts.weightNormal};
                    line-height: 18px;
                    text-align: left;
                  }
                }
              }
            }
            &:last-child {
              .tab-panel  {
                border-bottom: 0;
              }
            }
          }
        }

        .level1MenuPane{
          transition: left 0.3s ease;
          position: absolute;
          width: 100%;
          height: auto;
          top: 0;
          & > div.menu-container > div + div {
            display: none;
          }
          .cms-conditional-container:not(:first-of-type) {
            .level1Link:first-of-type {
              border-top: 1px solid ${vars.colors.grey20};
            }
          }

          .level1Link{
            span {
              font-family: ${vars.fonts.headerMainNavTitle};
              font-size: ${vars.fonts.sizeH4};
              font-weight: ${vars.fonts.weightBold};
              letter-spacing: 0.01em;
              text-transform: unset;
              padding: 0;
            }
            &[data-expanded=true] span, &:active span{
              color: ${vars.colors.primary1};
            }

            &.emphasize, &.emphasize span {
              color: ${vars.colors.primary1};
            }
          }
          a.level1Link {
              padding: 15px 24px;
              display: flex;
              font-family: ${vars.fonts.headerMainNavTitle};
              font-size: ${vars.fonts.sizeH4};
              font-weight:  ${vars.fonts.weightBold};
              letter-spacing: 0.01em;
              text-transform: unset;
              text-decoration: none;
              border-bottom: 1px solid ${vars.colors.grey20};

            &.emphasize, &.emphasize span {
              color: ${vars.colors.primary1};
            }

            &:not(:first-of-type) {
              border-top: 1px solid ${vars.colors.grey20};
            }
          }
          a.level1Link:hover {
              color: ${vars.colors.primary1};
          }
          a.level1Link > div {
              margin-right: auto;
          }
        }
        .level2And3MenuPane {
          transition: left 0.3s ease;
          padding: 0 24px;
          position: absolute;
          width: 100%;
          height: 100%;
          .mantine-NavLink-root {
            padding-right: 0;
            padding-left: 0;
            span {
              font-family: ${vars.fonts.familySecondary};
              font-weight: ${vars.fonts.weightMedium};
              font-size: ${vars.fonts.sizeH4};
              color: #303030;
              padding: 0;
            }
            &[data-expanded=true] span{
              color: ${vars.colors.primary1};
              font-weight: ${vars.fonts.weightBold};
            }
            .mantine-NavLink-rightSection[data-rotate] {
              transform: rotate(-90deg);
            }
            &.level2Link .mantine-NavLink-rightSection[data-rotate] {
              transform: rotate(180deg);
            }
            &:last-of-type {
              border-bottom: 1px solid ${vars.colors.grey20};
              &[data-expanded=true] {
                border-bottom: 0;
              }
              + div {
                border-bottom: 1px solid ${vars.colors.grey20};
              }
            }
          }
          .mantine-NavLink-children {
            padding: 0;
            a {
              display: block;
              padding: 12px 0;
              font-weight: ${vars.fonts.weightNormal};
              color: ${vars.colors.grey50};
              text-align: left;
              letter-spacing: 0em;
              &.navMenuSectionLink {
                text-decoration: underline;
                color: ${vars.colors.primary1};
              }
            }
          }
          div.navMenuBackLink{
            border-bottom: 0;
            text-transform: none;
            cursor: pointer;
            background: #EEEAE2;
            padding: 8px 16px;
            text-align: center;
            min-height: 60px;
            display: flex;
            align-items: center;
            margin-left: -24px;
            margin-right: -24px;
            span {
              flex: 1 1 0px;
              font-family: ${vars.fonts.familySecondary};
              font-weight: ${vars.fonts.weightMediumPlus};
              font-size: ${vars.fonts.sizeH4};
              letter-spacing: 0.03em;
              text-transform: uppercase;
              color: ${vars.colors.grey50};
            }
          }
          .navMenuContent{
            margin-top: 20px;
            //TODO: this tweaks the styling of the link in the MenuBanner but we should
            //find a more elegant/targetted way to fix this
            a{
              border: 0;
              padding: 0;
            }
            .richtext-wrapper {
              p {
                margin-top: ${vars.space.sm};
                margin-bottom: ${vars.space.sm};
                line-height: initial;
                &:first-of-type {
                  margin-top: 0;
                }
                &:last-child {
                  margin-bottom: 0;
                }
                a {
                  line-height: initial;
                }
              }
              &.richtext-has-bg {
                padding: 1rem;
              }
            }
          }
          .navMenuContent:has(.richtext-wrapper){
            margin-bottom: 10px;
            margin-top: 0px;
          }
        }
      `,
  },
  MenuDropdown: {
    dropdown: {
      boxShadow: `0px 2px 20px 0px #00141E33`,
      borderRadius: '12px',
      margin: 0,
      width: '190px !important',
      '&.mantine-Menu-dropdown': {
        padding: '8px 0px 15px',
        maxHeight: 'calc(100vh - 260px)',
        overflowY: 'auto',
        '.sticky-header &': {
          maxHeight: 'calc(100vh - 240px)',
        },
      },
      '&.account-navbar .dynamicComponents': {
        textAlign: 'left',
        a: {
          padding: '8px 21px',
          display: 'block',
        },
      },
    },
    item: {
      textAlign: 'left',
      padding: '8px 21px !important',
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
      },
    },
    itemLabel: {
      fontFamily: vars.fonts.familySecondary,
      fontWeight: vars.fonts.weightNormal,
      fontSize: vars.fonts.sizeRoot,
      color: vars.colors.black,
      lineHeight: 'normal',
      '.signout-button &': {
        color: `${vars.colors.primary1}`,
      },
    },
    arrow: {
      border: `1px solid transparent`,
      borderBottomColor: 'transparent',
      borderRightColor: 'transparent',
    },
    divider: {
      borderColor: vars.colors.grey20,
      marginTop: '12px',
      marginBottom: '11px',
    },
  },
  Product: {
    ItemCard: `
        position: relative;
        .zoom {
          position: absolute;
          top: 16px;
          left: 16px;
          z-index: 2;
          border-radius: 4px;
          background-color: ${vars.colors.white};
          width: 44px;
          height: 44px;
          padding: 12px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          opacity: 0;
          visibility: hidden;
          i {
            transition: color 0.3s ease-in-out;
            font-size: 20px;
          }
          &:hover {
            background-color: ${vars.colors.btnsPrimaryBg};
            i {
              color: ${vars.colors.white};
            }
          }
        }
        &:hover .zoom {
          opacity: 1;
          visibility: visible;
        }
        .info-container {
          gap: 12px;
          display: grid;
          margin-bottom: 20px;
          .product-name {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 44px;
            > a {
              font-size: 16px;
              line-height: 22.5px;
            }
          }
        }
        .rating-container {
          height: 17px;
          overflow: hidden;
        }
        .buyers-price {
          text-decoration: line-through;
        }
        .sale-price {
          font-weight: ${vars.fonts.weightBold};
        }
        .cart-button {
          button {
            border-radius: 2px;
            line-height: 16px;
            width: 167px;
            padding: 12px 24px;
            font-weight: ${vars.fonts.weightMediumPlus};
            letter-spacing: 0.28px;
          }
        }

        font-size: 1rem;
        width: 100%;

        .product-image-container {
          position: relative;
          padding: 25px;
          margin-bottom: 12px;
          justify-content: center;
          text-align: center;

          &.no-background {
            background: none;
          }

          background-color: ${vars.colors.primary3};

          .product-image {
            padding: 20px;
            position: relative;
            aspect-ratio: 1 / 1
          }
        }
      .mantine-Modal-body {
        .zoom-container {
          position: relative;
          height: 70vh;
        }
      }
      `,
    gallery: `
      display: flex;
      height: 343px;
      ${vars.breakpoints.sm} {  
        height: 600px; 
      }
      .gallery-image {
        border: 1px solid transparent;
      }
      `,
    flavourProfile: `
      .item {
        font-size: 18px;
        line-height: ${vars.fonts.lineHeightDefault};
        font-weight: ${vars.fonts.weightNormal};
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        .item-title {
          font-weight: ${vars.fonts.weightBold};
        }
        &.flavour-icon-item{
              grid-template-columns: 0.1fr 1fr;
                  display: grid;
                  .flavour-icon{
                    grid-row:1/3;
                    margin-right: 12px;
                  }

        }
      }
      `,
    bazaarvoice: `
      .reviews-container {
        padding: 15px;
        background: ${vars.colors.primary3};
        margin-bottom: 15px;
        h2 {
          &.title {
            text-align: center;
          }
        }
      }
      `,
    averageCaseRating: `
        display: flex;
        grid-gap: 10px;
        align-items: center;
        button {
          background: none;
          padding: 0;
          i {
            color: ${vars.colors.black};
          }
          &:hover {
            background: none;
          }
        }
      `,
    enPrimeur: `
      display: flex;
      justify-content: space-between;
      a {
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
      .reserve-text {
        font-weight: ${vars.fonts.weightBold}
      }
      border: 1px solid ${vars.colors.grey20};
      padding: 15px;
      background: #F9F5EF;
      margin-bottom: 15px;
      `,
    compactOrderForm: `
        .add-to-cart-section {
          display: flex;
          gap: 10px;
          .quantityFields {
            border: 1px solid ${vars.colors.grey30};
            display: flex;
            align-items: center;
            padding: 0 10px;
            .quantity {
              width: 40px;
              text-align: center;
              border: 0;
            }
            .input-group-prepend, .input-group-append {
              button {
                border: 0;
                background: transparent;
              }
            }
          }

        }
      `,
    presell: `
      display: flex;
      justify-content: space-between;
      .reserve-text {
        font-weight: ${vars.fonts.weightBold}
      }
      border: 1px solid ${vars.colors.grey20};
      padding: 15px;
      background: #F9F5EF;
      margin-bottom: 15px;
      `,
    orderform: `
      flex:1;
      .border-bottom-ccc {
        border-bottom: 1px solid ${vars.colors.grey20};
        padding-bottom:6px;
        &.with-padding{
          padding-bottom:18px;
          margin-bottom: 8px;
        }
        &.with-margin-bottom{
          padding-bottom:24px;
          margin-bottom:8px;
        }
      }
      &.bulk-deal-button-container {
        margin: 15px 0px;
        .deal {
          margin: 15px 0px;
          border-bottom: 1px solid ${vars.colors.grey20};
          padding-bottom: 10px;
        }

        .price-title {
          font-weight: ${vars.fonts.weightBold};
          margin-bottom: 10px;
          a {
            text-decoration: underline;
          }
        }
        .top-price-section {
          padding-top: 5px;
          .add-to-cart-section {
            padding-top: 0;
          }
        }
      }
      .happy-to-wait-container {
        background-color: #D7E0EE;
        padding: 15px;
        text-align: center;
        margin-bottom: 15px;
        width: 100%;
        @media (min-width: 1025px) {
          padding: 5px;
          margin-bottom: 5px;
        }
        ${vars.breakpoints.lg} {
          padding: 4px 8px;
          margin-bottom: 10px;
        }
        ${vars.breakpoints.xl} {
          padding: 8px;
          margin-top: 20px;
          margin-bottom: 15px;
        }
        @media (min-width: 1400px) {
          margin-top: 0px;
          padding: 15px;
        }
        .date-expected {
          font-weight: ${vars.fonts.weightBold};
          margin-bottom: 5px;
        }
      }
      .add-on-container {
        padding-top: 10px;
        color: ${vars.colors.primary1};
        margin: 10px 0px;
        font-weight: ${vars.fonts.weightBold};
      }
      &.out-of-stock {
        .sold-out-text{
          padding: 15px;
          border: 1px solid ${vars.colors.error};
          border-radius: 5px;
          margin-bottom: 20px;
          display: flex;
          align-items: flex-start;
        }
        .sold-out-text i{
          color: ${vars.colors.error};
          background: ${vars.colors.primary7};
          padding: 10px;
          border-radius: 50%;
          margin-right: 15px;
        }
        .sold-out-text span{
          font-weight: ${vars.fonts.weightBold};
        }
        .sold-out-text p{
          margin-bottom: 0;
        }
        .clickToViewSimilarWines {
          cursor: pointer;
          padding: 15px;
          border: 1px solid ${vars.colors.grey50};
          border-radius: 5px;
          background-color: transparent;
          width: 100%;
          text-align: left;
        }
      }
      &.cart-state-compliance {
        .sold-out-text{
          padding: 15px;
          margin-bottom: 20px;
          display: flex;
          align-items: flex-start;
          background: ${vars.colors.primary7};
        }
        .sold-out-text span {
          font-weight: ${vars.fonts.weightBold};
        }
        .sold-out-text p {
          margin-bottom: 0;
        }
        .clickToViewSimilarWines {
          cursor: pointer;
          padding: 15px;
          border: 1px solid ${vars.colors.grey50};
          text-transform: uppercase;
          text-align: center;
          background-color: transparent;
          width: 100%;
          text-align: left;
        }
      }
      .vpp-btn {
        margin-bottom: 15px;
      }
      .cart-button {
          button {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            width: 100%;
            &:hover {
              cursor: pointer;
            }
            &:disabled {
              cursor: default;
            }
          }
        }
      .grid-addto-cart-btn, .loading-add-to-cart-btn {
        height: 44px;
      }
      .vpp-price {
        color: ${vars.colors.primary1};
        padding-bottom: 16px;
        // border-bottom: 1px solid ${vars.colors.grey20};
        &.first-price-head{
          padding-bottom:0;
          .price{
          font-size: 28px;
          }
        }
        .price {
          font-size: ${vars.fonts.sizeH3};
          font-family: ${vars.fonts.familyTertiary};
          font-weight: ${vars.fonts.weightBold};
        }
      }
            .tiered-price-container {
        color: ${vars.colors.primary1};
        font-size: ${vars.fonts.sizeLarge};
        .price {
          font-size: ${vars.fonts.sizeH3};
          font-family: ${vars.fonts.familyTertiary};
          font-weight: ${vars.fonts.weightBold};
        }
        div {
          margin-bottom: 15px;
          &:first-of-type {
            .price {
              font-size: 28px;
            }
          }
          &:last-child {
            // padding-bottom: 10px;
            // border-bottom: 1px solid ${vars.colors.grey20};
          }
        }
      }
      .show-qty-error{
        margin-top:16px;
        padding:8px;
        background-color:${vars.colors.errorLight};
        color:${vars.colors.error};
      }
      .add-to-cart-section {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        padding-top:24px;
        &:not(:last-child) {
          padding-bottom:24px;
      }
      &.last-addto-cart-section{
        padding-bottom: 0;
      }
        .customProdQantityField {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .quantity {
            width: 40px;
            min-height: 34px;
            text-align: center;
            padding: 6px;
            font-size: ${vars.fonts.sizeDefault};
            color: ${vars.colors.black};
            border-radius: 5px;
            border: 1px solid ${vars.colors.grey20};
            margin: 0 auto;
            font-size: 16px;
            &:focus {
              outline: none;
            }
            &.form-control{
              border: none;
            }
          }
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type=number] {
            -moz-appearance: textfield;
          }
          .btn {
            width: 34px;
            height: 34px;
            background: ${vars.colors.white};
            border-radius: 5px;
            border: 1px solid ${vars.colors.black};
            color: ${vars.colors.black};
            padding: 0;
            cursor: pointer;
            &:disabled{
              color: ${vars.colors.grey20};
              border: 1px solid ${vars.colors.grey20};
              cursor: auto;
            }
          }
          & div:first-of-type{
            max-width:43px;
            margin-right: 18px;
            font-size: ${vars.fonts.sizeSmall};
          }
        }
        .add-to-cart-btn-container {
          .add-to-cart-btn {
            font-size: ${vars.fonts.sizeLarge};
            font-weight: 400;
            line-height: ${vars.fonts.lineHeightDefault};
          }
        }
      }
      .top-price-section {
        display: flex;
        justify-content: space-between;
        font-size: ${vars.fonts.sizeDefault};
        line-height: ${vars.fonts.lineHeightDefault};
        &.mixed-case {
          padding-top: 24px;
        }

        .price-section {
          &.saving {
            color: ${vars.colors.primary1};
            .price-with-strike{
              font-size:28px;
            }
            & .case-details {
              font-size:16px;
            }
          }
          &.presell {
            .total-price {
              font-weight: ${vars.fonts.weightBold};
            }
          }
        .price {
          font-size: 28px;
          line-height: ${vars.fonts.lineHeightDefault};
        }
        .strike {
          text-decoration: line-through;
          padding-left: 5px;
          line-height: 22.4px;
        }
        }
        .sale-price {
          .price {
            font-size: 18px;
            font-family: ${vars.fonts.familyTertiary};
            line-height: 25.2px;
            font-weight: ${vars.fonts.weightBold};
          }
        }

        .case-count {
          color: ${vars.colors.primary1};
        }
        .bottle-price {
          font-weight: ${vars.fonts.weightBold};
          font-size: 16px;
        }

        .saving-section {
          text-align: right;
          .saving-section-content {
            text-align:center;
            border-radius: 30px;
            background: ${vars.colors.primary5};
            font-size: 12px;
            font-weight: ${vars.fonts.weightBold};
            line-height: 18px;
            padding: 5px 8px;
            .strikePrice {
              text-decoration: line-through;
            }
            .saving {
              color: ${vars.colors.primary1};
              .sale-price, .case-details{
                color: ${vars.colors.primary1}!important;
              }

            }
          }
        }
        .strong {
          font-weight: bold;
        }
      }

      .view-wine-link {
        z-index: 9;
        text-decoration: underline;
        margin: 6px 0 10px;
        font-size: 16px;
        line-height: 24px;
        color: ${vars.colors.grey50}
      }

      .bulk-add-to-cart {
        display: grid;
        gap: 10px;
        .bulk-deal-link {
          line-height: ${vars.fonts.lineHeightDefault};
          color: ${vars.colors.primary1};
          padding: 5px 0px 8px 0px;
          //border-bottom: 1px solid ${vars.colors.grey20};
        }
      }

      .separation-line {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid ${vars.colors.grey20};
        line-height: 1px;
        margin-bottom: 22px;
        font-size: ${vars.fonts.sizeLarge};
        text-transform: uppercase;
        color: ${vars.colors.grey30};
        span {
          background:${vars.colors.white};
          padding: 0 33px;
        }
        &.tired-promo-button{
          margin-top: 24px;
          margin-bottom: 22px;
        }
      }

      .sale-price{
        color: ${vars.colors.grey50}
      }
      .saving .sale-price{
        color: ${vars.colors.primary1}
      }
      .vpp-price,.sale-price{
        font-size: ${vars.fonts.sizeLarge};
      }
      `,
    pagination: `
      display: flex;
      align-items: center;
      justify-content: end;
      border-width: 1px 0 1px 0;
      padding: 0px;
      margin: 0px;
      .pagination-bottom{
        margin: 20px 0;
      }
      .pagination-top{
        margin-bottom: 16px;
        ${vars.breakpoints.sm}{
          margin-bottom: 0;
        }
      }
      @media (max-width: 767px) {
        display: block;
      }
      li {
        list-style: none;
        &.page-count {
          margin-right: 25px;
          @media (min-width: 768px) and (max-width: 991px) {
            font-size: 12px;
            margin-right: 15px;
          }
          @media (max-width: 767px) {
            display:none;
            margin-right: 0;
            margin-bottom: 20px;
            text-align: center;
          }
        }
        .ais-Pagination-list {
          @media (max-width: 767px) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          }
          li {
            width: 40px;
            height: 40px;
            display: inline-flex;
            align-items: center;
            margin: 0;
            border-radius: 5px;
            @media (max-width: 767px) {
              margin: 0;
            }
            a, button {
              cursor: pointer;
              margin-left: 0 !important;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              box-shadow: none;
              border-radius: 5px !important;
              border: none;
              margin: 0;
              &:active,
              &:hover,
              &:focus {
                background-image: none !important;
                border: none;
                border-color: transparent !important;
                box-shadow: none !important;
                text-decoration: none;
              }
            }
            &.ais-Pagination-item--previousPage {
              border: 1px solid ${vars.colors.black};
              margin-right: 10px;
              @media(max-width:767px){
                justify-content:left;
              }
              a, button {
                border-radius: 3px 0 0 3px;
                background: transparent;
                border: none;
                outline: none;
                color: #333;
                box-shadow: none;
              }
              a, button,
              span {
                font-size: 26px;
                line-height: 0;
              }
            }
            &.ais-Pagination-item--disabled {
              border: 1px solid #969696 !important;
              a, button {
                pointer-events: none;
                color: #969696;
                border-radius: 0 !important;
                padding: 0;
                width: 40px;
                height: 40px;
                box-shadow: none;
                background: transparent;
                border: none;
              }
            }
            &.ais-Pagination-item--selected {
              a {
                pointer-events: none;
                background: ${vars.colors.primary1};
                color: ${vars.colors.white};
                p {
                  margin: 0px;
                  font-family: ${vars.fonts.familySecondary};
                }
              }
              @media(max-width:767px){
                display:none;
              }
            }
            &.ais-Pagination-item-notSelected {
              a {
                p {
                  margin: 0px;
                  font-family: ${vars.fonts.familySecondary};
                }
              }
              @media(max-width:767px){
                display:none;
              }
            }
            &.ais-Pagination-item--nextPage {
              border: 1px solid ${vars.colors.black};
              margin-left: 10px;
              a, button
              span {
                font-size: 26px;
                line-height: 0;
                margin-left: 0 !important;
              }
              a, button {
                margin-left: 0;
                background: transparent;
                border: none;
                outline: none;
                box-shadow: none;
              }
            }
            @media (min-width: 768px) and (max-width: 991px) {
              width: 35px;
              height: 35px;
              a, button {
                width: 35px;
                height: 35px;
              }
            }
          }
          .mobileView-Pagination{
            @media(min-width : 768px){
              display:none;
            }
          }
        }
      }
      `,
    countryNameGrapeVariety: `
      display: flex;
      font-size: 14px;
      line-height: 18.4px;
      .country-grapeVariety {
        display: flex;
        align-items: center;
        &.grapeVariety-container {
          margin-left: 10px;
          .grapeVariety-title{
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            &::after{
              margin: 0px;
            }
          }
          i {
            margin-right: 3px;
            font-size: 21px;
          }
        }
      }
      img {
        margin-right: 8px;
      }
      `,
    caseComponentsMobile: `
      position: relative;
      .layout {
        display: flex;
        align-items: center;
        justify-content; center;
        flex-direction: column;
        margin: 40px 0px 0px;
        background-color: ${vars.colors.primary3};
        padding: 15px;
        .case-compo-heart-icon {
          position: absolute;
          right: 10px;
          padding: 12px;
          background: white;
          border-radius: 25px;
          width: 44px;
          height: 44px;
        }
        .product {
          &-image {
            position: relative;
            height: 115px;
            width: 60px;
          }
        }
        .title {
          font-weight: ${vars.fonts.weightBold};
          font-size: 22px;
          line-height: ${vars.fonts.lineHeightDefault};
          color: #CF004F;
          margin-bottom: 15px;
          margin-top: 12px;
          text-align: center;
        }
        .quantity {
          margin-top: 12px;
          font-weight: ${vars.fonts.weightBold};
          font-size: ${vars.fonts.sizeLarge};
          text-align: center;
        }
        .description{
          text-overflow: ellipsis;
          white-space: break-spaces;
          max-width: 38rem;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .redirect-link-container{
        margin: 10px 0;
        .redirect-link {
          margin-top: 15px;
          text-decoration: underline;
          font-weight: 500
        }
      }

      `,
    case: `
        .slick-slider {
          background: ${vars.colors.primary3};
          border-bottom: 1px solid ${vars.colors.grey20};
          ${vars.breakpoints.sm} {
            background: none;
          }
        }
        .sub-title {
          background: ${vars.colors.primary3};
          margin: 0;
          text-transform: capitalize;
          ${vars.breakpoints.sm} {
            background: none;
            margin: 18px 0px;
          }
        }
        @media (min-width: 400px) and (max-width: 900px) {
          background: #F8F5EF;
        }
        .header-container {
          display: flex;
          gap: 15px;
          justify-content: space-between;
          align-items: center;
          .main-title {
            a {
              color: ${vars.colors.primary1};
            }
            margin-bottom: 15px;
            font-size: 30px;
          }
          .price {
            text-transform: uppercase;
            font-weight: ${vars.fonts.weightBold};
          }
        }

        .rating-container {
          display: flex;
          gap: 15px;
          align-items: center;
          margin-bottom: 15px;
        }
        
        .case-content {
          &.title {
            text-align: center;
            margin-top: 40px;
            margin-bottom: 80px;
          }
        }

        .case-content-container {
          .sub-title-with-border {
            ${vars.mixins.h5}
            padding-bottom: 10px;
            border-bottom: 1px solid #ccc;
            margin-bottom: 15px;
          }
          
          .mantine-Tabs-tabsList {
            border: none;
            margin-bottom: 20px;
            margin-top: 20px;
            gap: 40px 0;  
            .mantine-Tabs-tab {
              text-align: center;
              background: none;
              border: none;
              width: 108px;
              height: 190px;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              aspect-ratio: 1 / 1;
              position: relative;
              &[data-active]:after {
                content: "";
                position: absolute;
                bottom: -20px;
                left: -5px;
                right: 0;
                margin: 0 auto;
                border-width: 0 22px ${vars.fonts.lineHeightDefault};
                border-style: solid;
                border-color: ${vars.colors.primary3} transparent;
                display: block;
                width: 0;
              }
            &Icon {
              margin: 0; 
              img {
                height: 150px !important;
              }
              svg {
                width: 53px;
                height: 190px;
              }
            }
            &Label {
              font-size: 18px;
              line-height: ${vars.fonts.lineHeightDefault};
              font-weight: ${vars.fonts.weightBold};
              color: ${vars.colors.black};
              display: inline-block;
              margin-top: 7.5px;
            } 
            }
          }
          .mantine-Tabs-panel {
            background: ${vars.colors.primary3};
            padding: 25px;
            margin-bottom: 40px;
            .web-description{
              text-align:left;
            }
            .case-content-details-section {              
              margin-top: 20px;             
              text-align: left;
              ${vars.breakpoints.sm} {
                display: grid;
                grid-template-columns: 2fr 1fr 2fr;
              }
              .case-image {
                text-align: center;
                &.case-content-img {
                  img {
                    width: auto;
                  }
                }
              }
              .case-details {
                width: 100%;
                .title-print-container {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  border-bottom: 1px solid ${vars.colors.grey20};
                  .title {
                    margin-top: 0;
                  }
                }                
                .price-section {
                  padding-bottom: 20px;
                  border-bottom: 1px solid ${vars.colors.grey20};
                  .price {
                    font-size: ${vars.fonts.sizeLarge};
                    line-height: ${vars.fonts.lineHeightDefault};
                    font-weight: ${vars.fonts.weightBold};
                  }
                  .shortDesc {
                    margin-top: 20px;
                    a {
                      text-decoration: underline;
                    }
                  }
                }
              }
            }
          }
        }
      `,
    wineDetails: `
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          display: grid;
          gap: 10px;

          ${vars.breakpoints.lg} {
            grid-template-columns: repeat(2, 1fr);
            align-items: center;
          }

          @media print {
            grid-template-columns: repeat(2, 1fr);
          }

          li {
            a {
              display: flex;
              align-items: center;
              cursor: pointer;
              &:hover {
                text-decoration: none;
              }
              span {
                margin-left: 4px;
              }
            }
            i {
              margin-right: 10px;
              width: 32px;
              @media print {
                width: 22px;
              }
            }
            img {
              margin-right: 18px;
              width: 24px;
              @media print {
                margin-right: 10px;
              }
            }
            svg {
              margin-right: 10px;
              width: 32px;
              height: 32px;
              @media print {
                width: 22px;
              }
            }
          }
      }
      `,
    subscribeSave: `
      .accordion-item {
        border-width: 1px;
        border-color: ${vars.colors.secondary9};
        background-color: #ffffff;
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        ${vars.breakpoints.sm} {
          padding: 24px 16px 24px 48px;
        }

        .accordion-item-control {
          .accordion-item-control-header {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            gap: 10px;

            ${vars.breakpoints.sm} {
              flex-direction: row;
              justify-content: space-between;
            }

            .accordion-item-control-header-label {
              position: relative;
              flex: 1;

              .accordion-item-control-icon {
                position: absolute;
                display: flex;
                align-items: baseline;
                justify-content: center;
                transform: translateY(-50%);
                top: 50%;
                right: 0;
                color: #767676;
                width: fit-content;

                ${vars.breakpoints.sm} {
                  left: -32px;
                }
              }

              .utility {
                margin: 0;
              }
            }

            .ss-suffix-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .how-it-works {
                line-height: 140%;

                ${vars.breakpoints.sm} {
                  display: none;
                }
              }
            }
          }

          // These style should not be needed...
          // Add to cart component should be able to style itself accordingly to fit the S&S component
          .straight-sku-wrapper,
          .case-sku-wrapper {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .first-price-head {
              margin: 0;
              font-family: ${vars.fonts.familySecondary};
              font-size: ${vars.fonts.sizeDefault};

              ${vars.breakpoints.sm} {
                font-size: ${vars.fonts.sizeLarge};
              }
            }

            .top-price-section {
              position: relative;
              padding: 0;

              .price-section {
                flex-direction: column;
                gap: 8px;

                .sale-price {
                  display: flex;
                  align-items: center;
                  margin: 0px;
                  font-family: ${vars.fonts.familySecondary};
                  font-size: ${vars.fonts.sizeDefault};
                  gap: 5px;

                  ${vars.breakpoints.sm} {
                    font-size: ${vars.fonts.sizeLarge};
                  }

                  .strike {
                    line-height: 140%;
                    color: ${vars.colors.secondary15};
                  }
                }

                .case-details {
                  padding: 0;
                  font-family: ${vars.fonts.familySecondary};
                  color: ${vars.colors.primary9};
                }


              }

              .saving-section {
                position: absolute;
                top: 0;
                right: 0;

                .saving-section-content {
                  padding: 4px 8px;
                  font-family: ${vars.fonts.familySecondary};
                }
              }
            }

            .price {
              font-size: ${vars.fonts.sizeH7};

              ${vars.breakpoints.sm} {
                font-size: ${vars.fonts.sizeH8};
              }
            }

            .add-to-cart-section {
              padding: 0;
            }
          }

          .ss-item-content {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 8px;

            .saving-content {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding-bottom: 2px;

              ${vars.breakpoints.sm} {
                margin-top: -8px;
              }

              .saving {
                font-family: ${vars.fonts.familySecondary};
                font-size: ${vars.fonts.sizeDefault};
                line-height: 130%;
              }

              .how-it-works {
                line-height: 120%;
                display: none;

                ${vars.breakpoints.sm} {
                  display: block;
                }
              }

              &.absolute {
                position: absolute;
                top: 0;
                right: 0;
                transform: translateY(50%);
              }
            }


            .case-price-content {
              display: flex;
              flex-direction: column;
              color: ${vars.colors.primary9};
              gap: 4px;

              ${vars.breakpoints.sm} {
                flex-direction: row;
                align-items: baseline;
              }

              .case-price-savings {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .case-price {
                  font-family: ${vars.fonts.familyTertiary};
                  font-size: ${vars.fonts.sizeH7};
                  font-weight: ${vars.fonts.weightMediumPlus};
                }

                .saving-badge {
                  ${vars.breakpoints.sm} {
                    display: none;
                  }
                }
              }

              .case-content {
                font-family: ${vars.fonts.familySecondary};
                font-size: ${vars.fonts.sizeDefault};

                ${vars.breakpoints.sm} {
                  font-size: ${vars.fonts.sizeLarge};
                }
              }
            }

            .per-bottle-content {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .per-bottle-price {
                font-family: ${vars.fonts.familySecondary};
                font-size: ${vars.fonts.sizeDefault};
                line-height: 130%;

                &.red {
                  color: ${vars.colors.primary9};
                }

                ${vars.breakpoints.sm} {
                  font-size: ${vars.fonts.sizeLarge};
                }
              }

              .saving-badge {
                display: none;

                ${vars.breakpoints.sm} {
                  display: block;
                }
              }
            }

            .saving-badge {
              padding: 4px 8px;
              font-family: ${vars.fonts.familySecondary};
              color: ${vars.colors.primary9};
              background-color: ${(vars.colors.bgColors as unknown as { [key: string]: string }).roseWine};
              border-radius: 30px;
              font-size: ${vars.fonts.sizeSmall};
              font-weight: ${vars.fonts.weightBold};
              line-height: 150%;
              white-space: nowrap;
            }
          }

          .how-it-works {
            font-family: ${vars.fonts.familySecondary};
            font-size: ${vars.fonts.sizeSmall};
            text-decoration: underline;
            cursor: pointer;
            white-space: nowrap;
          }
        }

        .accordion-item-panel {
          // These style should not be needed...
          // Add to cart component should be able to style itself accordingly to fit the S&S component
          .straight-sku-wrapper,
          .case-sku-wrapper {
            .add-to-cart-section {
              padding: 0;

              .customProdQantityField {
                &>div:first-of-type {
                  display: none
                }

                .input-group-prepend, .input-group-append {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  button {
                    i {
                      font-size: ${vars.fonts.sizeLarge};
                    }
                  }
                }

                .quantity {
                  font-family: ${vars.fonts.familySecondary};
                  background-color: transparent;
                }
              }

              .add-to-cart-btn-container {
                flex: 1;
              }
            }

            .vpp-btn {
              margin-top: 16px;

              .separation-line {
                margin-bottom: 16px;
                span {
                  background:${vars.colors.primary3};
                }
              }

              .cart-button {
                margin: 0;
              }
            }
          }

          .cart-button {
            button {
              font-size: ${vars.fonts.sizeDefault};
              font-weight: ${vars.fonts.weightMediumPlus};
              line-height: 1;
              width: 100%;
              text-transform: uppercase;
            }
          }
        }

        &[data-active] {
          border-color: ${vars.colors.success};
          background-color: ${vars.colors.primary3};

          .accordion-item-control {
            .accordion-item-control-header-label {
              .accordion-item-control-icon {
                color: ${vars.colors.secondary6};
              }
            }
          }
        }
      }
      `,
    subscribeSaveWorks: `
        .how-it-works-content {
          display: flex;
          flex-direction: column;
          gap: 24px;
          height: 100%;

          .how-it-works-headline {
            margin: 0;

            span {
              display: block;
              font-family: ${vars.fonts.familySecondary};
              font-size: ${vars.fonts.sizeLarge};
              line-height: 140%;
              color: ${vars.colors.secondary15};
              text-transform: none;
              padding-top: 16px;
              padding-bottom: 8px;
            }
          }

          .how-it-works-items {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin: 0;
            padding: 0;
            list-style: none;
            flex: 1;

            .how-it-works-item {
              display: flex;
              gap: 16px;

              i {
                color: #112B4B;
              }

              span {
                font-family: ${vars.fonts.familySecondary};
                font-size: ${vars.fonts.sizeLarge};
                line-height: 140%;
                color: ${vars.colors.primary8};
              }
            }
          }

          .how-it-works-footer {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 16px 0;

            ${vars.breakpoints.sm} {
              flex-direction: row-reverse;
              justify-content: space-between;
              align-items: center;
              padding: 12px 0 0;
            }

            .cart-button {
              button {
                width: 100%;
              }
            }

            .how-it-works-link {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 8px;
              color: ${vars.colors.primary8};

              .terms-link {
                font-family: ${vars.fonts.familySecondary};
                text-decoration: underline;
                line-height: 130%;
              }

              .how-it-works-info-button {
                background: transparent;
                border: none;
                padding: 0;

                span {
                  display: none;
                }
              }
            }
          }
        }

        &.subscribeAndSaveModal {
          display: none;

          ${vars.breakpoints.sm} {
            display: block;
          }
        }

        &.subscribeAndSaveDrawer {
          display: block;

          ${vars.breakpoints.sm} {
            display: none;
          }
        }
      `,
    wrapper: `
      max-width: 1429px;
      margin-left: auto;
      margin-right: auto;
      padding: 0px 16px;
      &.giftPage {
        max-width: 1680px;
      }

      .see-more-description{
        background: none;
        color: ${vars.colors.grey50};
        line-height: 24px;
        font-size: ${vars.fonts.sizeH4};
        padding: 0;
        text-decoration-line: underline;
        cursor: pointer;
        margin: 0 0 16px 0;
        box-shadow: none;
        border: none;
      }

      .image-container {
        &.about-producer {
          position: relative;
          min-height: 300px;
        }
      }

      .accordion-mobile-wine-details {
        button {
          background-color: ${vars.colors.primary3};
          padding: 0px 14px;
        }
        .border-bottom{
          background: red;
        }
        .mantine-Accordion-label {
          font-weight: ${vars.fonts.weightBold};
          font-size: ${vars.fonts.sizeLarge};
          font-family: ${vars.fonts.familyTertiary};
        }
        .mantine-Accordion-panel {
          background-color: ${vars.colors.primary3};
          @media print {
            display: block!important;
            height: auto!important;
            & div:first-of-type {
              opacity: 1!important;
            }
          }
        }
        .mantine-Accordion-content {
          padding-top: 1rem;
        }
        .mantine-Accordion-item{
          .mantine-Accordion-chevron{
            @media (min-width: 400px) and (max-width: 900px) {
              width: 2.5rem;
            }
          }
        }
      }

      .print-share-container {
        background-color: ${vars.colors.primary3};
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .label {
          display: none;
        }
      }

      .gift-main-container {
        .plainTextWithIcon {
          display: flex;
          align-items: center;
          text-align: left;
          gap: 10px;
          margin-bottom: 20px;
          font-size: 16px;
        }
        .gift-details-container {
          background-color: #F8F5EF;
          width: 100vw;
          margin-left: calc(-50vw + 50%);
          .product-layout {
            padding: 60px 20px;
            gap: 15px;
              .layout-name {
                order: 1;
                .description-container {
                  margin-bottom: 0;
                }
              }
              .layout-image {
                order: 0;
                  .image-container {
                    padding: 20px 0;
                    .image-size {
                      height: 300px;
                    }
                  }
              }
              .layout-details {
                order: 2;
                .top-price-section {
                  padding: 0;
                  .price-section {
                    .sale-price {
                      display: flex;
                      align-items: center;
                        .price {
                          font-size: 22px;
                          font-weight: ${vars.fonts.weightMediumPlus};
                          line-height: 26.4px;
                          color: #222222;
                        }
                        .strike {
                          color: ${vars.colors.secondary15};
                        }
                    }
                    .case-details {
                      display: none;
                    }
                  }
                  .saving-section {
                    display: none;
                  }
                }
                .add-to-cart-section {
                  justify-content: unset;
                  .customProdQantityField {
                    border: 1px solid #303030;
                    border-radius: 5px;
                    .btn-minus, .btn-plus {
                      border: none;
                      background: transparent;
                    }
                    div:first-of-type {
                      display: none;
                    }
                    .quantity {
                      background-color: #F8F5EF;
                    }
                  }
                }
              }
          }
        }
        .gift-content-container {
          display: flex;
          justify-content: center;
          width: auto;
          margin: 0 auto;
          padding: 50px 0;
          .richtext-wrapper {
            margin-bottom: 35px;
          }
          a {
            display: inline-block;
            margin-top: 10px;
            text-decoration: underline;
            font-size: 16px;
            display: flex;
            justify-content: center;
          }
        }
        .greeting-banner-container {
          width: 100vw;
          margin-left: calc(-50vw + 50%);
          background-color: #E6EFF7;
          ${vars.breakpoints.md} {
            width: auto;
            margin-left: 0;
          }
          .plainTextWithIcon {
            justify-content: center;
            margin: 0;
            padding: 15px 15px;
          }
        }
        .gift-banner-container {
          background-color: #F8F5EF;
          width: 100vw;
          margin-left: calc(-50vw + 50%);
          padding: 0;
          & > div {
            overflow: unset;
            margin-bottom: 0;
          }
          .banner {
            flex-direction: column-reverse;
            margin: 0;
            .banner-content-wrapper {
              .banner-content {
                padding: 0;
                margin-bottom: 40px;
              }
              .banner-button {
                padding: 0;
                a {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      .product-layout {
        display: flex;
        gap: 24px;
        flex-direction: column; /* Default layout for mobile */
        margin-top: 20px;

        &.sundry {
          margin-bottom: 14px;

          .layout-details {
            .top-price-section {
              padding: 0;
            }

            .case-details {
              display: none;
            }

            .addToCart-QuantitySelector {
              .add-to-cart-section {
                padding-top: 12px;

                ${vars.breakpoints.md} {
                  padding-top: 16px;
                }

              }
            }
          }
        }

        .richtext-wrapper {
          a {
            text-decoration: underline;
          }
        }

        @media print {
          display: grid ;
          gap: 0px;
          grid-template-columns: 1fr !important;
          .layout-name {
            .main-title {
              font-size: 22px;
            }
            .sub-title {
              font-size: 18px;
              padding: 0;
              margin-top: 5px;
              margin-bottom: 0;
            }
            .rating-country-container {
              margin-top: 5px;
            }
            .bv_main_container {
              .bv_main_container_row_flex {
                margin: 3px 0 0px !important
              }
              .bv_main_container_row_normal, .bv_main_container_line_break, .bv_main_container_CTA_row {
                display: none !important;
              }
            }
          }
        }

        .description-container {
          font-size: ${vars.fonts.sizeLarge};
          line-height: ${vars.fonts.lineHeightDefault};
          margin: 20px 0px;
          ${vars.breakpoints.md} {
            margin: 0px 0px 20px 0px;
          }
          @media print {
            font-size: 12px;
            margin: 0px 0px 10px;
          }
        }

        .rating-country-container {
          margin-top: 15px;
          .rating {
            display: flex;
            a {
              display: inline-flex;
              .star-icons{
                padding-top: 2px;
              }
              .rating-cout {
                margin: 0 15px 0;
                font-size: ${vars.fonts.sizeDefault};
                font-weight: ${vars.fonts.weightBold};
              }
              .review-count {
                margin-left: 10px;
                text-decoration: underline;
              }
            }
          }
          .country {
            display: flex;
            align-items: center;
            font-size: ${vars.fonts.sizeLarge};
            line-height: ${vars.fonts.lineHeightDefault};
            img, svg {
              margin-right: 10px;
            }
          }
        }

        .bv_main_container {
          background-color: transparent !important;
        }

        .unlimited-banner {
          margin-top: 24px;
          background: ${(vars.colors.bgColors as unknown as { [key: string]: string }).whiteWine};
          display: flex;
          padding: 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 12px;
          align-self: stretch;
          border-radius: 5px;

          .banner-decription {
            font-family: ${vars.fonts.familySecondary};
            font-size: ${vars.fonts.sizeLarge};
            line-height: 140%;
          }

          button {
            min-width: 181px;
            height: 32px;
            border-radius: 4px;
            padding: 7px 16px;
            background: ${vars.colors.unlimitedButtonColor};
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            font-family: ${vars.fonts.familySecondary};
            font-size: ${vars.fonts.sizeSmall};
            font-weight: ${vars.fonts.weightMediumPlus};
            text-transform: uppercase;
            letter-spacing: 0.24px;
            line-height: ${vars.fonts.sizeDefault};
            border: none;
          }
        }

        .money-back-guarantee {
          background-color: ${vars.colors.lWHUELWBlue};
          padding: 15px;
          margin: 30px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .content {
            max-width: 429px;
            .descp {
              font-weight: ${vars.fonts.weightBold};
              padding-bottom: 10px;
            }
          }
          .icon i:before {
            font-size: 44px;
          }
        }

        .product-info-container {
          // position: absolute;
          // top: 15px;
          // left: 15px;
          // display: flex;
          // flex-direction: column;
          .stock {
            .case-count {
              font-size: ${vars.fonts.sizeLarge};
              font-weight: ${vars.fonts.weightBold};
            }
          }

          .sold-last-hour {
            .image-count {
              position: relative;
              display: inline-block;
              .sold-count {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                right: 0;
                margin: 0 auto;
                text-align: center;
              }
            }
            .sold-last-hour-text {
              max-width: 56px;
              font-size: 12px;
              line-height: 18px;
              font-weight: 500;
            }
          }
        }

        &.mixed {
          ${vars.breakpoints.sm} {
            max-width: 1310px;
            grid-template-columns: 1fr 1.14333fr;
            gap: 24px;
          }

          .layout-name {
            ${vars.breakpoints.sm} {
              padding: 0 36px;
            }
          }

          .product-image-container {
            height: 284px;

            ${vars.breakpoints.sm} {
              height: initial;
            }

            .image-container {
              height: inherit;

              ${vars.breakpoints.sm} {
                height: 492px;
              }

              .image-size {
                ${vars.breakpoints.sm} {
                  max-height: 100%;
                }

                position: relative;
                  height: 260px;

                  ${vars.breakpoints.sm} {
                    height: inherit;
                  }
              }
            }
          }

          .layout-details {
            ${vars.breakpoints.sm} {
              padding: 0 36px;
            }
          }
        }

        .available-vintage {
          border-top: 1px solid var(--neutral-colours-cccccc, ${vars.colors.grey20});
          margin-bottom: 60px;
          .title {
            font-size: 20px;
            line-height: 28px;
            padding: 20px 0;
          }
          .d-flex {
            display: flex;
            .available-vintage-data {
              a {
                color: ${vars.colors.white};
                background-color: ${vars.colors.secondary4};
                width: 64px;
                padding: 3px;
                border-radius: 30px;
                margin-right: 10px;
                text-align: center;
                display: inline-block;
              }
            }
          }
        }

        .product-image-container{
          height: 100%;

          .image-container {
            background-color: ${vars.colors.primary3};
            padding: 12px 16px;
            text-align: center;
            position: relative;
            
            &.gallery {
              margin-bottom: 54px;
              aspect-ratio: 1 / 1;
              ${vars.breakpoints.sm} {
                margin-bottom: 0;
              }
              
              .image-size {
                height: 343px;

                ${vars.breakpoints.sm} {
                  height: 600px;
                }
              }
            }
            
            ${vars.breakpoints.sm} {
              padding: 32px;
            }

            .image-size {
              position: relative;
              height: 220px;
              
              ${vars.breakpoints.sm} {
                height: 600px;
              }
            }

            .awards {
              position: absolute;
              top: 5px;
              left: 10px;
            }

            .pill {
              position: absolute;
              left: 15px;
              ${vars.breakpoints.lg} {
                right: 15px;
                left: auto;
              }
              bottom: 15px;
              font-size: 16px;
              .bold{
                font-weight: 700;
              }
            }


            .image-bg-circle {
              width: 500px;
              height: 500px;
              background-color: ${vars.colors.primary3};
              border-radius: 100%;
              position: absolute;
              top: 45%;
              transform: translateY(-50%);
              z-index: -1;
              margin: 0 auto;
              left: 0;
              right: 0;
            }
            .d-flex {
              display: flex;
              align-items: center;
              justify-content: center;

              &.sustainable-awards {
                margin-top: 40px;
              }
            }
            .zoom-fav-container {
              display: flex;
              gap: 15px;
              position: absolute;
              right: 15px;
              top: 15px;
              
              .zoom, .favourite {
                padding: 12px;
                background: white;
                border-radius: 25px;
                width: 44px;
                height: 44px;
                div {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .award-main-container {
        margin-bottom: 20px;
        .medal {
          margin-right: 7px;
          position: relative;
          display: inline-block;
          margin-bottom: 4px;
          &:last-child {
            margin-right: 0px;
          }
          .point-main-container {
            position: relative;
            .point-text-container {
              position: absolute;
              display: flex;
              flex-direction: column;
              align-items: center;
              top: 5px;
              left: 0;
              right: 0;
              .point {
                font-size: 28px;
                font-weight: ${vars.fonts.weightMediumPlus};
                color: ${vars.colors.white};
                letter-spacing: -1px;
                line-height: 110%;
              }
              .point-text {
                font-size: 12px;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: 0;
                color: ${vars.colors.white};
              }
            }
          }
        }
      }

      ${vars.breakpoints.xs} {
        .gift-main-container {
          .gift-content-container {
            margin: 0 -15px;
          }
        }
      }

      ${vars.breakpoints.sm} {
        .gift-main-container {
          .gift-details-container {
            .product-layout {
              padding: 100px 20px;
              margin: 0 auto;
              gap: 25px;
              .layout-name {
                max-width: 720px;
                .description-container {
                  margin: 20px 0px;
                }
              }
              .layout-image {
                .product-image-container {
                  .image-container {
                    padding: 0;
                    .image-size {
                      height: inherit;
                    }
                  }
                }
              }
            }
          }
          .gift-banner-container {
            .banner {
              flex-direction: row;
              .banner-content-wrapper {
                .banner-content {
                  margin-bottom: 60px;
                  text-align: left !important;
                  p, h1, h2, h3, h4, h5, h6, span {
                    text-align: inherit !important;
                  }
                }
                .banner-button {
                  justify-content: flex-start;
                  a {
                    width: auto;
                  }
                }
              }
            }
          }
        }
        .product-layout {
          display: grid;
          grid-template-columns: 1fr 1fr; /* Space divided between the two columns */
          grid-template-rows: auto 1fr; /* First row with auto sizing second row occupies remaining space */
          margin: 60px auto 20px auto;
          gap: 25px;

          &.mixed {
            .description-container {
              margin: 20px 0px;
            }
            .product-image-container {
              .image-container {
                .image-bg-circle {
                  top: 47%;
                }
                .d-flex.sustainable-awards {
                  margin-top: 80px;
                }
              }
            }
          }
        }

        .layout-image {
          grid-column: 1; /* Image starts from first column */
          grid-row: 1 / span 2; /* Image spans two rows */
        }

        .layout-name {
          grid-column: 2; /* Name starts from second column */
          grid-row: 1; /* Name is in the first row */
          padding: 0 48px;
        }

        .layout-details {
          grid-column: 2; /* Details starts from second column */
          grid-row: 2; /* Details is in the second row */
          padding: 0 48px;
        }

        .print-share-container {
          background: none;
          max-width: ${vars.breakpointWidths?.xl}px;
          margin: 0px auto 20px auto;
          display: flex;
          align-items: center;
          justify-content: right;
          .label {
            display: inline;
          }
          a, button, span:not(.label) {
            padding: 0 15px;
            margin: 0;
            &:last-child {
              padding-right: 0;
            }
          }
        }
        // End of desktop CSS
      }

      ${vars.breakpoints.md} {
        .gift-main-container {
          .gift-banner-container {
            .banner {
              .banner-img {
                width: 560px;
              }
            }
          }
        }
      }

      ${vars.breakpoints.lg} {
        .gift-main-container {
          .gift-banner-container {
            .banner {
              padding: 33px 0;
              .banner-img {
                width: auto;
              }
            }
          }
        }
      }

      h1.main-title {
        font-family: ${vars.fonts.familyPrimary};
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 105%;
        letter-spacing: -1.2px; // 30 * -0.04
        text-transform: uppercase;
        margin: 0px;
        margin-bottom: 12px;

        &.npr, &.osw {
          text-transform: capitalize;
        }

        ${vars.breakpoints.sm} {
          font-size: 34px;
          letter-spacing: -1.36px; // 34 * -0.04
          margin-bottom: 16px;
        }

        ${vars.breakpoints.lg} {
          font-size: 48px;
          letter-spacing: -1.44px; // 48 * -0.03
        }

        i {
          color: #E2004D;
          top: -3px;
          position: relative;
          ${vars.breakpoints.lg} {
            font-size: 32px;
            top: -8px;
          }
        }
      }

      .headline {
        font-family: ${vars.fonts.familyTertiary};
        font-size: ${vars.fonts.sizeH3};
        line-height: ${vars.fonts.lineHeightDefault};
        color: ${vars.colors.primary8};
        letter-spacing: -0.2px; // 20 * -0.01

        ${vars.breakpoints.sm} {
          font-family: ${vars.fonts.familySecondary};
          font-size: 16px;
          line-height: 140%;
          letter-spacing: 0;
        }
      }

      .sub-title {
        font-family: ${vars.fonts.familyTertiary};
        font-size: 22px;
        ${vars.breakpoints.lg} {
          font-size: 22px;
        }
        font-style: normal;
        font-weight: ${vars.fonts.weightMediumPlus};
        margin: 18px 0;
        padding-top: 10px;
        line-height: 120%; /* 26.4px */
        letter-spacing: -0.22px;
        &.center {
          text-align: center;
        }
      }

      .sub-title-with-border {
        font-family: ${vars.fonts.familyTertiary};
        font-size: 22px;
        font-style: normal;
        text-transform: capitalize;
        font-weight: ${vars.fonts.weightBold};
        line-height: 120%; /* 36px */
        letter-spacing: -0.3px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;
        margin-bottom: 15px;
      }

      .tooltip-container {
        margin: 10px 0 10px;
        .mantine-Tooltip-tooltip {
          width: 200px;
          font-Size: ${vars.fonts.sizeLarge};
          line-height: ${vars.fonts.lineHeightDefault};
        }
      }

      .mantine-Tooltip-tooltip {
        margin-left: 7px;
        background-color: #2C2C2C;
        color: ${vars.colors.white};
        text-align: left;
        border-radius: 6px;
        padding: 0 15px;
        .award-item {
          margin: 20px 0;
        }
      }
      .tooltip {
        margin-left: 7px;
      }

      .product-container {
        max-width: ${vars.breakpointWidths?.xl}px;
        margin: 60px auto 20px auto;
        .product-main-container {
            display: grid;
            gap: 25px;
            grid-template-columns: 1fr;
            ${vars.breakpoints.lg} {
              grid-template-columns: 1fr 1fr;
            }

          .w-100 {
            width: 100%;
          }
          .product-image-container {
            max-width: 827px;
            position: relative;
            .image-container {
              background-color: ${vars.colors.primary3};
              padding: 15px;
              text-align: center;
              position: relative;
              .image-bg-circle {
                width: 500px;
                height: 500px;
                background-color: ${vars.colors.primary3};
                border-radius: 100%;
                position: absolute;
                top: 45%;
                transform: translateY(-50%);
                z-index: -1;
                margin: 0 auto;
                left: 0;
                right: 0;
              }
              .d-flex {
                display: flex;
                align-items: center;
                justify-content: center;
                &.awards {
                  margin-left: 15px;
                }
                &.sustainable-awards {
                  margin-top: 40px;
                }
              }
            }
          }
        }
      }

      .menu-banner-image, .cms-image-container, .richtext-wrapper {
        @media print {
          display: none
        }
      }

      .about-the-wine-container {
        .wine-details-container {
          max-width: ${vars.breakpointWidths?.xl}px;
          margin: 0px auto;

          .first-row {
            .column-align {
              display: flex;
              justify-content: center; 
              flex-wrap: wrap;
              gap: 30px;
              .box {
                flex: 0 1 48%;
                max-width: 50%;
                margin-bottom: 25px; 
                &:only-child {
                  margin: 25px auto; /* Center a single child */
                  max-width: 100%; /* Ensure it doesn’t shrink */
                }
              }
              // Handle single child centering for modern browsers with :has()
              &:has(.box:nth-child(1):nth-last-child(1)) {
                justify-content: center; // Center if there's only one box
                .sub-title-with-border {
                  text-align: center;
                }
              }

              @media print {
                gap: 25px;
                margin-bottom: 0px;
                  .sub-title-with-border {
                    margin-bottom: 10px;
                    text-transform: capitalize;
                    padding-bottom: 5px;
                    font-size: 16px;
                  }
                  li {
                    font-size: 12px;
                  }
                  .item {
                    margin-bottom: 0px;
                    .item-title {
                      font-size: 14px;
                    }
                    .item-sub-title {
                      font-size: 12px;
                    }
                  }
              }
            }

            @media print {
              display: grid;
              gap: 10px;
              grid-template-columns: 1fr 2fr;
            }
          }

          .print-bottle-image-container {
            position: relative;
            height: 750px;
            text-align: center;
            @media print {
              height: 350px !important;
            }
          }

          .about-wine {
            background-color: ${vars.colors.primary3};
            text-align: center;
            padding: 15px;
            margin: 25px 0px;
            .show-in-print-view-only {
              display : none;
            }
            @media print {
              padding: 0;
              margin: 20px 0px;
              font-size: 12px !important;
              text-align: left;
              margin-top: 10px;
              .title {
                text-align: center;
                font-size: 20px;
                margin-bottom: 10px;
              }
              .show-in-print-view-only {
                display : block;
                font-size: 12px !important;
              }
              .longDescription {
                display : none;
              }
            }
          }

          .second-row {
            display: grid;
            gap: 25px;
            grid-template-columns: 1fr;
            ${vars.breakpoints.sm} {
              grid-template-columns: 1fr 1fr 1fr;
            }
            @media print {
              gap: 10px;
              grid-template-columns: 1fr 1fr 1fr;
              .about-producer {
                min-height: 200px;
              }
              .map-image {
                min-height: 200px !important;
                img {
                  height: 90% !important;
                  width: 90% !important;
                  object-fit: contain !important;
                  margin: 0 auto;
                }
              }
              .quote {
                padding: 0 !important;
                font-size: 22px !important;
              }
            }

            .quote {
              display: grid;
              place-items: center;
              height:100%;
              font-family: ${vars.fonts.familyTertiary};
              font-size: 28px;
              font-style: italic;
              font-weight: 400;
              line-height: 120%; /* 33.6px */
              letter-spacing: -1.12px;
              text-align: center;
              color: ${vars.colors.white};
              background-color: ${vars.colors.secondary4};
              padding: 40px;
              margin: 0px;
              .person-name {
                font-style: normal;
              }
            }

            .about-the-producer {
              background-color: ${vars.colors.primary3};
              .content-container {
                padding: 15px;
              }
            }
            .map-image {
              position: relative;
              min-height: 300px;

              img {
                border-radius: 10px;
              }
            }
          }
          .flavour, .wine-detail, .quote, .about-wine, .about-the-producer {
            border-radius: 10px;
            font-size: ${vars.fonts.sizeLarge};
            line-height: ${vars.fonts.lineHeightDefault};
          }
          .about-wine{
            padding-left:80px;
            padding-right:80px;
          }
          .title {
            font-family: ${vars.fonts.familyTertiary};
            font-size: 24px;
            font-style: normal;
            font-weight: ${vars.fonts.weightMediumPlus};
            line-height: 120%; /* 36px */
            letter-spacing: -0.3px;
            margin-bottom: 15px;
          }
        }
       
          .print-view-only {
            display : none;
          }
          @media print {
            .first-row {
              display: grid;
              grid-template-columns: 1fr 2fr;
              .column-align {
                .box {
                  flex: 100% !important;
                  max-width: 100% !important;
                  margin-bottom: 10px !important;
                }
              }
            }
            .print-bottle-image-container {
              position: relative;
              height: 350px !important;
            }
            .fixed-bottom {
              display: none;
            }
            .accordion-mobile-wine-details {
              .mantine-Accordion-item {
                border-bottom: none;
                .mantine-Accordion-control {
                  padding: 0;
                  .mantine-Accordion-chevron {
                    display: none;
                  }
                  .mantine-Accordion-label {
                    border-bottom: 1px solid #ccc;
                    padding-bottom: 10px;
                    font-size: 16px;
                  }
                }
                .mantine-Accordion-panel {
                  .mantine-Accordion-content {
                    padding: 10px 0;
                    li {
                      font-size: 12px;
                    }
                    .item {
                      margin-bottom: 15px;
                      &:last-child{
                        margin-bottom: 0px;
                      }
                      .item-title {
                        font-size: 14px;
                      }
                      .item-sub-title {
                        font-size: 12px;
                      }
                    }
                  }
                }
                &:first-of-type {
                  .mantine-Accordion-control {
                    .mantine-Accordion-label {
                      padding-top: 0;
                    }
                  }
                }
              }
            }
            .print-view-only {
              display : block;
              .title {
                text-align: center;
                font-size: 20px;
                font-weight: ${vars.fonts.weightMediumPlus};
                margin-bottom: 10px;
                margin-top: 20px;
                font-family: ${vars.fonts.familyTertiary};
              }
              .show-in-print-view-only {
                font-size: 12px;
                line-height: 24px;
              }
              .producer {
                position: relative;
                min-height: 200px;
                margin-top: 20px;
              }
            }
            .longDescription {
              display : none;
            }
          }
        }
      

      .details-container {
        margin: 50px 0 80px;
        background-color: ${vars.colors.primary3};
        padding: 25px 25px 50px;
        h1 {
          margin: 0;
        }
        p {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .pill {
        display: inline-block;
        border-radius: 10px;
        padding: 6px 10px;
        &.stock {
          background-color: ${vars.colors.primary1};
          color: ${vars.colors.white};
        }
        &.savings {
          background-color: #FFDCD9;
          .discount-price {
            text-decoration: line-through;
            margin-right: 10px;
          }
          .saving-price {
            color: ${vars.colors.primary1};
            font-weight: ${vars.fonts.weightBold}
          }
        }
      }
    }
    `,
    AwardDetails: `
        margin-bottom: 20px;
        .medal {
          margin-right: 7px;
          position: relative;
          display: inline-block;
          &:last-child {
            margin-right: 0px; 
          }
          .point-main-container {
            position: relative;
            .point-text-container {
              position: absolute;
              display: flex;
              flex-direction: column;
              align-items: center;
              top: 5px;
              left: 0;
              right: 0;
              .point {
                font-size: 28px;
                font-weight: ${vars.fonts.weightMediumPlus};
                color: ${vars.colors.white};
                letter-spacing: -1px;
                line-height: 110%;
              }
              .point-text {
                font-size: 12px;
                font-weight: 700;
                line-height: 120%;
                letter-spacing: 0;
                color: ${vars.colors.white};
              }
            }
          }
          .award-img {
            width: 56px;
          }
        }
      `,
    zoom: `
      .mantine-Modal-inner {
        z-index: 999;

        .mantine-Modal-content {
          .mantine-Modal-header {
            justify-content: flex-end;
            background-color: transparent;

            .mantine-CloseButton-root {
              background: transparent;
              color: ${vars.colors.black};
              padding: 0;
              border: none;
              width: 44px;
              height: 44px;
              line-height: 0;
              outline: none;

              svg {
                width: 32px !important;
                height: 32px !important;
              }
            }
          }

          .mantine-Modal-body {
            text-align: center;

            .image-gallery {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              .mantine-Carousel-indicators {
                bottom: 18px;
                padding: 24px;
                z-index: 2;
              }

              .mantine-Carousel-controls {
                bottom: 0;
                padding: 24px;
                background-color: rgba(255, 255, 255, 0.9);
              }
            }


            @media (max-width: 1199px) {
              .bottle {
                width: 180px;
                height: 660px;
                @media (min-height: 768px) and (max-height: 1024px) {
                  top: 50%;
                  transform: translateY(-50%);
                  position: absolute;
                  left: 0;
                  right: 0;
                  margin: 0 auto;
                }
              }
              .case {
                color: transparent;
                width: 315px;
                height: 280px;
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
              }
            }
          }
        }
      }
      `,
    reviews: {
      wrapper: `
          background-color: ${vars.colors.primary3};
          margin: 25px auto;
          padding: 15px 25px;
          max-width: ${vars.breakpointWidths?.xl}px;

          a.login-to-review {
            ${vars.mixins.btnPrimary}
          }

          .title {
            text-align: center;
          }

          .review-heading-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px; /* Adjust as needed */
            text-align: center;

            .item {
              &.review-button {
                grid-column: span 2;
              }
            }
            ${vars.breakpoints.md} {
              display: flex;
              align-items: center;
              justify-content: center;

              .item {
                padding: 0px 15px;
                ${vars.breakpoints.md} {
                  padding: 0px 30px;
                }
                &:not(:last-child) {
                  border-right: 1px solid #ccc; /* Remove border from all items except the last one */
                }
              }

            }

            .review-heading {
              text-align: center;

              .review-title {
                font-weight: ${vars.fonts.weightBold};
              }
              .review-total {
                font-family: ${vars.fonts.familyPrimary};
                font-size: 24px;
                ${vars.breakpoints.md} {
                  font-size: 36px;
                }
                &.flex-center {
                  display: flex;
                  gap: 10px;
                  justify-content: center;
                }
              }
            }
          }

        .rating-sort-container {
          select {
            background-color: rgb(255, 255, 255);
            border: 1px solid ${vars.colors.grey40};
            padding: 8px 12px;
            text-overflow: ellipsis;
            white-space: nowrap;
            border-radius: 5px;
            min-width: 250px;
          }
        }

        .rating-filter-container {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;

          border: 1px solid ${vars.colors.grey20};

          ${vars.breakpoints.md} {
            gap: 5px;
            border: none;
          }

          .stars {
            text-align: center;
            background: white;
            padding: 10px;

            &.disabled {
              background: none;
              color: ${vars.colors.grey30};
              a {
                svg {
                  opacity: 0.3;
                }
              }

            }

            a {
              display: flex;
              align-items: center;
              gap: 5px;
            }

            &:not(:first-of-type) {
              border-right: 1px solid ${vars.colors.grey20};
            }

            ${vars.breakpoints.md} {
              padding: 8px;
              border: 1px solid ${vars.colors.grey20};
            }
          }
        }

        .rating-sort-container {
          display: flex;
          align-items: center;
        }

      .review-toolbar {
        display: grid;
        gap: 25px;
        grid-template-columns: 1fr;
        margin: 25px 0px;
        justify-items: center;

        .rating-pagination-container {
          display: flex;
          align-items: baseline;
          gap: 25px;
        }

        ${vars.breakpoints.md} {
          display: flex;
          gap: 25px;
          justify-content: space-evenly;
          padding: 15px;
          align-items: center;
        }
      }

      .review {
        display: grid;
        gap: 25px;
        grid-template-columns: 1fr;

        border-bottom: 1px solid ${vars.colors.grey20};
        padding: 25px 0px;

        ${vars.breakpoints.lg} {
          grid-template-columns: 120px auto;
        }

        .user-info {
          order: 2;
          display: flex;

          ${vars.breakpoints.md} {
            order: inherit;
          }

          .user-profile-container {
            display: flex;
            align-items: baseline;

            .nickname-badge {
              color: ${vars.colors.white};
              background-color: ${vars.colors.secondary4};
              padding: 8px;
              border-radius: 15px;
              margin-right: 10px;
              text-align: center;
              display: inline-block;
              text-transform: uppercase;
              height: 32px;
              text-transform: uppercase;
            }
          }
        }

        .review-info {
          padding-right: 25px;
          .submission-time {
            padding-left: 10px;
            color: ${vars.colors.grey40};
          }
          .title {
            font-weight: ${vars.fonts.weightBold};
            margin: 20px 0px 5px 0px;
            text-align: left;
          }
          .review-text {
            margin-bottom: 20px;
          }
          .review-helpfulness {
            display: flex;
            gap: 30px;
            align-items: center;
          }
          .client-response-container {
            .agent {
              font-weight: ${vars.fonts.weightBold};
              margin-bottom: 15px;
            }
            background: white;
            padding: 15px;
            border-radius: 10px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
            margin-top: 20px;
          }
        }
      }
    `,
    },
    related: `
      margin: 40px 0;      
      scroll-margin-top: 75px;
        .auc-Recommend-title {
          font-family: ${vars.fonts.familyTertiary};
          font-weight: ${vars.fonts.weightBold};
          font-size: 24px;
          line-height: 40px;
          margin-bottom: 20px;
          text-transform: none;
        }
        .container {
          margin-bottom:10px;
        }
      `,
    recentlyViewed: `
      .container {
        margin: 20px 0;
      }
      .recently-viewed-carousel {
        .product-name {
          height: 42px !important;
          > a {
            font-weight: 500;
            line-height: 20px;
            text-decoration: underline;
          }
        }
      }
      `,
    productItem: `
      margin: 0 10px;

      .product {
        &-image {
          position: relative;
          height: 180px;
        }
        &-title {
          font-size: 24px;
          font-weight: ${vars.fonts.weightBold};
          display: block;
          word-wrap: break-word;
          max-width: 200px;
          margin: 20px auto;
        }
        &-rating {
          margin-bottom: 15px;
          height: 16.5px
        }
        &-price {
          font-size: 16px;
          line-height: 32px;
          font-weight: ${vars.fonts.weightMediumPlus};
          color: ${vars.colors.primary1};
          .price {
            font-size: 18px;
            line-height: 26px;
            font-weight: ${vars.fonts.weightBold};
          }
        }
        &-detail {
          margin-top: 20px;
          display: block;
          text-decoration: none;
          padding-bottom: 5px
        }
        &-detail:hover {
          text-decoration: underline;
        }
      }
      `,
    productCardFullBleed: `
        .product-card {
          height: 100%;
          width: 100%;
          .product-image-and-info{
            position: relative;
            .product-image-wrapper {
              .product-background-image {
                z-index: 1;
                height: 540px;
                img {
                  width: 100%;
                  object-fit: cover;
                  width: 100%;
                  height: 540px;
                }
              }
              .product-image {
                position: absolute;
                display: flex;
                justify-content:center;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height: 540px;
                object-fit:cover;
                align-items: flex-end;
                .product-bottle-image{
                  max-width: 105px;
                  margin-bottom: 50px;
                  width: 100%;
                  height: max-content;
                }
                .product-case-default-image{
                  position:absolute;
                  width: 100%;
                  top: 110px;
                  height: 60% !important;
                  max-width: max-content;
                }
              }
            }
          }
        }

        .product-card-info {
          z-index: 3;
          position: absolute;
          top: 2%;
          left: 8%;
          display: flex;
          height: 94%;
          justify-content: space-between;
          flex-direction: column;
          .product-sale-status {
            display: flex;
            align-items: center;
            gap: ${vars.space.sm};
            background-color: ${vars.colors.primary1};
            padding: ${vars.space.sm};
            color: ${vars.colors.white};
            width: fit-content;
            .product-on-sale-text{
              margin: 0px;
            }
          }
          .product-info-and-button{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 90%;
          }
          .product-name-box{
            a:hover{
              text-decoration: none;
            }
            overflow: hidden;
          }
          .product-name  {
            font-size: ${vars.fonts.sizeH8};
            color: ${vars.colors.white};
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            webkit-box-orient: vertical;
            line-height: 32.4px;
            width: fit-content;
            font-weight: ${vars.fonts.weightMediumPlus};
            font-family: ${vars.fonts.familyTertiary};
            margin: 12px 0px;
          }

          .product-rating {
            display: flex;
            align-items: center;
            height:17.33px;
            gap: ${vars.space.sm};
              span {
                color: ${vars.colors.white};
              }
          }

          .product-price {
            margin: 12px 0px;
            color:${vars.colors.white};
            font-size: ${vars.fonts.sizeH4};
            font-size: ${vars.fonts.sizeLarge};
          }

          .product-card-button{
            .cart-button button{
              padding: ${vars.space.md};
              background: ${vars.colors.white};
              border: none;
              font-family: ${vars.fonts.familySecondary};
              font-weight: ${vars.fonts.weightMediumPlus};
              background: ${vars.colors.btnsPrimaryColor};
              color: ${vars.colors.black};
              display: flex;
              gap: 10px;
            }
            .cart-button button:hover {
                background-color: ${vars.colors.btnsPrimaryHoverBg};
                color: ${vars.colors.btnsPrimaryHoverColor};
                text-decoration: none;
            }

            .view-product-button{
              padding: ${vars.space.md};
              border: 1px solid ${vars.colors.black};
              padding: 12px 24px 12px 24px;
              font-family: ${vars.fonts.familyTertiary};
              font-weight: ${vars.fonts.weightMediumPlus};
              color: ${vars.colors.black};
              display: inline-block;
              font-size: ${vars.fonts.sizeH5};
              background: ${vars.colors.btnsPrimaryColor};
              border-radius: 5px;
              line-height: 26px;
            }
            .view-product-button:hover {
              background-color: ${vars.colors.btnsPrimaryHoverBg};
              color: ${vars.colors.btnsPrimaryHoverColor};
              text-decoration: none;
            }
          }
        }
      }
      `,

    productCardStandard: `
        .product-card {
          height: 100%;
          width: 100%;
          .zoom {
            position: absolute;
            top: 16px;
            left: 16px;
            z-index: 3;
            border-radius: 4px;
            background-color: ${vars.colors.white};
            width: 44px;
            height: 44px;
            padding: 12px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            opacity: 0;
            visibility: hidden;
            i {
              transition: color 0.3s ease-in-out;
              font-size: 20px;
            }
            &:hover {
              background-color: ${vars.colors.btnsPrimaryBg};
              i {
                color: ${vars.colors.white};
              }
            }
          }
          &:hover .zoom {
            opacity: 1;
            visibility: visible;
          }
          .mantine-Modal-body {
            .zoom-container {
              position: relative;
              height: 70vh;
            }
          }
          .product-image-and-info {
            position: relative;
            .product-image-wrapper {
              display: flex;
              position: relative;
              justify-content: center;
              height: 320px;
              align-items: center;
              margin-bottom: 12px;
              .product-background-image {
                position: absolute;
                display: none;
                top: 0;
                left: 0;

                img {
                  object-fit: cover;
                  height: 540px;
                }

              }

              .product-image {
                position: absolute;
                display: flex;
                justify-content:center;
                top: 0;
                left: 0;
                z-index: 2;
                width: 100%;
                height:100%;
                object-fit:cover;
                align-items: center;
                > img {
                  padding: 25px;
                }
                .product-bottle-image{
                  width: 100%;
                  height: 90%;
                  object-fit: contain;
                }
                .product-case-default-image{
                  min-height: -webkit-fill-available;
                  width: 100%;
                  height: auto;
                  max-height: 100%;
                  object-fit: contain;
                }
              }
            }
          }

        }
        .product-card-info {
          z-index: 3;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .product-sale-status {
            background-color: ${vars.colors.primary1};
            display: flex;
            align-items: center;
            gap: ${vars.space.sm};
            padding: ${vars.space.sm};
            color: ${vars.colors.white};
            position: absolute;
            top:0;
            left:0;
            margin: 0px;
            .product-on-sale-text{
              margin: 0px;
            }
          }

          .product-info-and-button{
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
          }
          .product-name-box{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow: hidden;
            height: 44px;
            margin-bottom: 12px;
            > a {
              font-size: 16px;
              line-height: 22.5px;
            }
          }
          .product-name{
            font-size: ${vars.fonts.sizeH8};
            color: ${vars.colors.black};
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            line-height: 28.4px;
            font-weight: ${vars.fonts.weightMediumPlus};
            font-family: ${vars.fonts.familyTertiary};
            margin-bottom: 12px;
            word-break: break-word;
          }

          .product-rating {
            display: flex;
            align-items: center;
            height: 17.33px;
            gap: ${vars.space.sm};
            span {
              color: ${vars.colors.black};
            }
          }

          .product-price {
            height: 16px;
            margin: 12px 0px;
            font-family: ${vars.fonts.familySecondary};

            span {
              font-weight: ${vars.fonts.weightBold};
            }
          }

          .product-card-button{
            .cart-button button,
            .view-product-button{
              padding: 12px 24px;
              font-weight: ${vars.fonts.weightMediumPlus};
              border-radius: 2px;
              line-height: 18px;
              letter-spacing: 0.28px;
              width: 167px;
              height: 44px;
            }
            .cart-button button{
              border: 1px solid ${vars.colors.black};
              font-family: ${vars.fonts.familySecondary};
              background: ${vars.colors.btnsPrimaryColor};
              color: ${vars.colors.black};
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
            }
            .cart-button button:hover {
              background-color: ${vars.colors.btnsPrimaryHoverBg};
              color: ${vars.colors.btnsPrimaryHoverColor};
              text-decoration: none;
            }
            .view-product-button{
              border: 1px solid ${vars.colors.black};
              font-family: ${vars.fonts.familySecondary};
              background: ${vars.colors.btnsPrimaryColor};
              color: ${vars.colors.black};
              display: inline-block;
              text-align: center;
              font-size: 14px;
            }
            .view-product-button:hover {
              background-color: ${vars.colors.btnsPrimaryHoverBg};
              color: ${vars.colors.btnsPrimaryHoverColor};
              text-decoration: none
            }
          }
        }
      }
      `,

    productCardDetailed: `
        height: 100%;

        .product-card {
          border-radius: 8px;
          border: 1px solid ${vars.colors.secondary9};
          background: #ffffff;
          height: 100%;
          overflow: hidden;
        }

        .product-image-and-info {
          display: flex;
          flex-flow: column;
          height: 100%;
        }

        // Image Wrap
        .product-image-wrapper {
          position: relative;
          padding-top: 67%;
          height: 0;
          width: 100%;
          display: block;

          img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100% !important;
            height: 100% !important;
            object-fit: contain;
            padding: 20px 10px;
          }
        }

        // Product Info
        .product-card__info {
          display: flex;
          flex-flow: column;
          height: 100%;

          div:empty {
            display: none;
          }
        }

        .product-card__info--multiview {
          .product-name {
            color: ${vars.colors.primary9};
          }
        }

        // Product Info Top
        .product-card__info-top {
          display: flex;
          flex-flow: column;
          grid-gap: 16px;
          flex: 1;
          padding: 16px;
          border-top: 1px solid ${vars.colors.secondary9};

          .product-card__badge + .product-name-box {
            margin: 8px 0 4px;
            text-transform: none;
          }

          .product-name {
            margin: 0;
          }
        }

        .product-card__subtitle {
          margin: 0 !important;
        }

        .product-card__desc {
          font-size: ${vars.fonts.sizeDefault};
          font-weight: 400;
          line-height: ${vars.fonts.lineHeightDefault};
          max-width: 764px;

          p {
            font-size: ${vars.fonts.sizeDefault};
            font-weight: 400;
            line-height: ${vars.fonts.lineHeightDefault};
          }
        }

        // Product Info Bottom
        .product-card__info-bottom {
          display: grid;
          gap: 8px;
          padding: 16px 16px 24px;
          border-top: 1px solid ${vars.colors.secondary9};

          .product-card-button >div:not(.cart-button) {
            display: grid;
            gap: 8px;
          }
        }

        // Badge
        .product-card__badge {
          display: inline-block;
          padding: 5px 11px;
          border-radius: 32px;
          border: 1px solid #000;
          font-size: ${vars.fonts.sizeSmall};
        }

        p, .body1 {
          margin: 0 !important;
        }

        .product-card__img-secondary img {
          width: 100%;
          height: auto;
          object-fit: cover;
        }

        .product-card__member-price {
          margin-top: 4px;
          color: #0055A0;
        }

        .product-card-button {
          display: grid;
          gap: 12px;

          > div:not(.cart-button) {
            margin-top: 4px;

            ${vars.breakpoints.md} {
              margin-top: 0;
            }
          }

          button {
            cursor: pointer;
            padding: 12px 32px;
            font-weight: ${vars.fonts.weightMediumPlus};
            letter-spacing: 0.42px;
            text-transform: uppercase;
            min-width: 220px;
            width: 100%;

            &:focus {
              padding: 12px 32px;
            }
          }

          ${vars.breakpoints.md} {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 12px 24px;
          }

          .product-price + .cart-button {
            margin-top: 8px;
          }
        }

        .view-product-button {
          padding: ${vars.space.md};
          border: 1px solid ${vars.colors.black};
          font-weight: ${vars.fonts.weightMediumPlus};
          letter-spacing: 0.42px;
          text-transform: uppercase;
          color: ${vars.colors.black};
          display: inline-block;
          font-size: ${vars.fonts.sizeH5};
          background: ${vars.colors.btnsPrimaryColor};
          border-radius: 5px;
          line-height: 26px;
        }
        .view-product-button:hover {
          background-color: ${vars.colors.btnsPrimaryHoverBg};
          color: ${vars.colors.btnsPrimaryHoverColor};
          text-decoration: none;
        }

        .product-price {
          display: flex;
          gap: 4px;
        }

        .product-price__original {
          color: ${vars.colors.secondary15};
        }

        .product-rating {
          svg {
            width: 14px;
            height: 14px;
          }
        }
      }
      `,
    productCardSkeleton: `
        .product-rating {
          padding-top: 20px;
        }
        .product-price {
          padding-top: 10px;
        }
      `,
    productCardImageMultiView: `
        .product-image-wrapper {
          position: static;
          height: auto;
          padding: 0;
          font-size: 0;

          img {
            position: static;
            transform: none;
            padding: 0;
            object-fit: cover;
          }
        }
      `,
  },
  TrustPilotWidget: {
    skeletonWrapperStyle: `
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 24px;
        padding: 60px 0px;
        justify-content: center;
        ${vars.breakpoints.md} {
          justify-content: space-between;
        }
      `,

    headingSkeletonStyle: `
        display: flex;
        flex-direction: column;
        gap: 8px;
        max-width: 200px;
        align-items: center;
      `,

    reviewCardStyle: `
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 12px;
        &:nth-last-child(-n + 4) {
          display: none;
        }
        ${vars.breakpoints.lg} {
          &:nth-child(-n + 4) {
            display: flex;
          }
        }
        ${vars.breakpoints.md} {
          &:nth-child(-n + 2) {
            display: flex;
          }
        }
      `,

    starsAndTitleStyle: `
        display: flex;
        flex-direction: column;
        gap: 6px;
      `,
  },

  Search: {
    noResults: `
      .no-results {
        .container {
          text-align: center;
          margin: 30px 0px;
          .title {
            font-size: ${vars.fonts.sizeH2};
            font-weight: ${vars.fonts.weightBold};
            margin-bottom: 5px;
          }
        }

        .query {
          font-size: ${vars.fonts.sizeH2};
        }
      }
      `,
    wrapper: `
      max-width: ${vars.breakpointWidths?.xxl}px;
      margin-left: auto;
      margin-right: auto;
      // padding: 0px 15px;
      ${vars.breakpoints.xxl} {
        padding: 0px;
      }
      h1 {
        margin-top: 0px;
      }
      .facets-navigation {
        padding:0 16px 16px 0;
        @media (max-width: 767px) {
          display: none;
        }
      }
      .search-results-listing.loading {
        display: none;
      }
      .search-toolbar-container + .loaderContainer {
        ${vars.breakpoints.sm} {
          margin-top: 250px;
        }
        @media (max-width: 767px) {
          margin: 100px 0;
        }
      }
      .filter-header{
        display:flex;
        align-items: baseline;
        justify-content: space-between;
        p {
          font-family: ${vars.fonts.familyTertiary};
          font-size: 26px;
          font-weight: ${vars.fonts.weightMediumPlus};
        }

      .clear-refinements {
        background: none;
        color: ${vars.colors.black};
        font-size: ${vars.fonts.sizeLarge};
        line-height: ${vars.fonts.lineHeightDefault};
        text-decoration-line: underline;
        padding: 0;
        margin: 0 2px 0 0;
        cursor: pointer;
        border: none;
        box-shadow: none;
        &:hover {
          background: none;
          color: ${vars.colors.black};
          text-decoration-line: underline;
        }
      }
    }

      margin-top: 15px;
      .search-toolbar-container {
        ${vars.breakpoints.sm} {
          display: flex;
          gap: 15px;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
        }
        .toolbar-start{
          flex:1 0 0;
          ${vars.breakpoints.md} {
            padding-bottom: 16px;
          }
          ${vars.breakpoints.lg} {
            adding-bottom: initial;
          }
      }
        .toolbar-end {
          ${vars.breakpoints.sm} {
            display: flex;
            flex:0.9 0 0;
            justify-content:end;
            align-items: end;
            gap: 8px;
            ${vars.breakpoints.lg} {
              gap: 12px;
            }
          }
        }

      .quick-toggle-container {
        display: none;
        ${vars.breakpoints.md} {
          display: flex; // hiding the Grid view. The list view is default, So hiding the toggle icons;
          align-items: center;

          button {
            border: 1px solid ${vars.colors.grey80};
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            &.list-icon {
              background-color: ${vars.colors.white};
              border-radius: 5px 0px 0px 5px;
              svg {
                path {
                  fill: ${vars.colors.grey80};
                }
              }
              &.active {
                svg {
                  path {
                    fill: ${vars.colors.black};
                  }
                }
                border: 1px solid ${vars.colors.black};
                background-color: ${vars.colors.grey10};
              }
            }
            &.grid-icon {
              background-color: ${vars.colors.white};
              border-radius: 0px 5px 5px 0px;
              svg {
                path {
                  fill: ${vars.colors.grey80};
                }
              }
              &.active {
                border: 1px solid ${vars.colors.black};
                background-color: ${vars.colors.grey10};
                svg {
                  path {
                    fill: ${vars.colors.black};
                  }
                }
              }
            }
          }
        }
      }
    }

    .category-intro-copy {
      text-align: center;
      border: 1px solid ${vars.colors.grey20};
      border-radius: 10px;
      margin-bottom: 15px;
      padding: 24px 16px 32px;
      margin-top: 25px;
      h1 {
        &.Red, &.Rose, &.dessert {
          color: ${vars.colors.primary1};
        }
        &.White, &.Beer {
          color: #D89B00;
        }
        &.Fortified, &.Sparkling, &.Cider {
          color: ${vars.colors.primary2};
        }
        &.Mixed {
          color: ${vars.colors.secondary1};
        }
        margin-bottom: 20px;
      }
      @media (max-width: 767px) {
        p, span {
          font-size: 16px !important;
        }
      }
    }

    .category-faq {
      margin: 30px 0px;
      .heading {
        font-family: ${vars.fonts.familyTertiary};
        font-weight: ${vars.fonts.weightNormal};
        font-size: ${vars.fonts.sizeH2};
        margin-bottom: 15px;
        text-transform: capitalize;
      }

      u {
        text-decoration-line: blink;
        text-decoration: none;

        a {
          text-decoration: underline;
        }
      }
    }

    .category-banner {
      margin-bottom: 15px;
      margin-top: 25px;

      p u {
        text-decoration-line: blink;
        text-decoration: none;

        a {
          text-decoration: underline;
        }
      }
    }
    display: grid;
    grid-gap: 24px;
      ${vars.breakpoints.xs} {
        grid-template-columns: auto;
      }
      ${vars.breakpoints.sm} {
        grid-template-columns: 270px 1fr;
      }

      ${vars.breakpoints.md} {
        grid-template-columns: 228px 1fr;
      }
      ${vars.breakpoints.lg} {
        grid-template-columns: 272px 1fr;
      }
      ${vars.breakpoints.xl} {
        grid-template-columns: 292px 1fr;
      }
      ${vars.breakpoints.xxl} {
        grid-template-columns: 402px 1fr;
      }
    .facets-navigation.hide-refinementlist{
      display : none;
    }

    .sort-filter-block{
      display:flex;
      gap: 10px;
      @media (min-width: 768px) {
        .mobile-filter-box {
          display: none;
        }
      }
    }

    @media (max-width: 767px) {
        .sort-filter-block{
          gap: 0px;
          padding-bottom:16px;
          .filter-box {
            display: flex;
            height: 40px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            flex: 1 0 0;
            border-radius: 5px 0px 0px 5px;
            border: 1px solid #303030;
            .applied-filter-red-dot-icon {
              display: inline-block;
              width: 9px;
              height: 9px;
              background-color: #D7363B;
              border-radius: 50%;
              position: relative;
              left: 42px;
              bottom: 8.5px;
              border: 1.5px solid #FFFFFF;
            }
          }
          .sort-box {
            border-radius: 0px 5px 5px 0px;
            border-width: 1px 1px 1px 0;
            border-color: #303030;
            border-style: solid;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1 0 0;
            & .label {
              display:none;
            }
            & .options {
              border:none;
              max-height:38px;
            }
          }
        }
      }
      .refinement-drawer{
        position: fixed;
        background-color: ${vars.colors.lwDrGray};
        z-index:500;
        .drawer-container{
          position: fixed;
          width: 100%;
          top: 0px;
          left: 0px;
          bottom: 0px;
          max-height: 100%;
          background-color: ${vars.colors.grey80};
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          .drawer-section{
            outline: 0px;
            -webkit-tap-highlight-color: transparent;
            display: block;
            text-decoration: none;
            background-color: ${vars.colors.white};
            box-sizing: border-box;
            flex: 0 0 27.5rem;
            max-width: 100%;
            max-height: 100%;
            height: 100%;
            border-radius: 0px;
            overflow-y: auto;

            .drawer-body{
              position:relative;
              min-height: 100vh;
              padding: 1rem;
              & >:nth-of-type(2) .heading{
                border-top:none;
              }
              .filter-header{
                position:sticky;
                top:0;
                background:${vars.colors.white};
                z-index:1;
                display: flex;
                flex-direction: column;

              & :first-of-type{
                align-self: end;
                }
            .header-row{
              display:flex;
              justify-content:space-between;
              align-items:center;
              width:100%;
            }
          }
          .bottom-button{
            width:100%;
            position: sticky;
            margin: auto;
            background:${vars.colors.white};
            padding:10px;
            display:flex;
            justify-content:space-between;
            gap:10px;
            margin-bottom: 0;
            bottom:0;


            & button{
                flex: 1;
                width:100%;
              display: flex;
              padding: 10px 16px;
              justify-content: center;
              align-items: center;
              gap: 4px;
              flex: 1 0 0;
              border-radius: 8px;
              border: 1px solid ${vars.colors.secondary6};
            }

            & :first-of-type{
                background:${vars.colors.white};
                color:black;
                padding: 10px 16px;
                border-radius: 8px;
                border: 1px solid ${vars.colors.secondary6};
            }
          }

            }
          }
        }
      }
      body:has(.drawer-body:not(.refinement-drawer-show))
    {
      overflow: visible;
    }

      body:has(.drawer-body.refinement-drawer-show){
        overflow: hidden;
      }
    .refinement-drawer.refinement-drawer-hide{
      display:none;
      }
      .facets-navigation >:nth-of-type(2) .heading {
      border-top:none;
      }
      `,
    refinements: `
      .show {
        display: block;
      }
      .hide {
        display: none;
      }
      .text-capitalize {
        text-transform: capitalize;
      }
      .line-seperator {
        border-top: 1px solid #dadada;
      }
      .heading {
        display: flex;
        align-items: center;
        background-color: ${vars.colors.white};
        justify-content: space-between;
        border-top: 1px solid #dadada;
        .text-wrap {
          text-wrap: wrap;
          font-family: ${vars.fonts.familySecondary};
        }
        .title {
          font-weight: ${vars.fonts.weightMediumPlus};
        }
        button {
          text-transform: uppercase;
          background: transparent;
          padding: 16px 40px 16px 0px;
          line-height: ${vars.fonts.lineHeightDefault};
          color: black;
          width: 100%;
          text-align: left;
          border-radius: 0px;
          font-size: ${vars.fonts.sizeLarge};
          font-weight: ${vars.fonts.weightBold};
          border-width: 0px;
          position: relative;
          font-family: ${vars.fonts.familySecondary};
          &:hover {
            cursor: pointer;
          }
          i {
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
          }
        }
      }

      .ais-RefinementList-searchBox {
        margin-bottom: 12px;
        .ais-SearchBox-form {
          height: 44px;
          .ais-SearchBox-input {
            padding: 0px ${vars.fonts.sizeLarge};
            border-radius: 5px;
            border: 1px solid var(--neutral-colours-cccccc, ${vars.colors.grey20});
            box-shadow: none;
            &::placeholder {
              color: ${vars.colors.grey80};
            }
          }
          button {
            border: none;
            background: white;
            padding: 0;
            position: absolute;
            right: ${vars.fonts.sizeLarge};
            width: 20px;
            height: 20px;
            top: 50%;
            transform: translateY(-50%);
            svg {
              width: 20px;
              height: 20px;
              fill: ${vars.colors.black};
            }
          }
          &:before {
            content: none;
          }
        }
      }
      .ais-RefinementList-noResults{
        color:red;
        padding-bottom: 16px;
      }
      ul.ais-RefinementList-list {
        margin: 0;
        li {
          &.ais-RefinementList-item {
            padding-bottom: 18px;
            .ais-RefinementList-label {
              .ais-RefinementList-checkbox {
                width: 20px;
                height: 20px;
                box-shadow: none;
                border-radius: 2px;
                color: ${vars.colors.facetCheckboxColor};
              }
              .ais-RefinementList-labelText {
                font-size: ${vars.fonts.sizeLarge};
                font-weight: 400;
                line-height: ${vars.fonts.lineHeightDefault};
                padding-left: 8px;
                color: ${vars.colors.facetLabelColor};
                .ais-Highlight-highlighted {
                  background-color: transparent;
                  color: ${vars.colors.black};
                }
              }
              .ais-RefinementList-count {
                font-size: ${vars.fonts.sizeDefault};
                line-height: ${vars.fonts.lineHeightDefault};
                background: none;
                border: none;
                padding: 0;
                margin-left: 5px;
                &:before {
                  content: '(';
                }
                &:after {
                  content: ')';
                }
              }
              .rating-star {
                margin-left: 5px;
              }
            }
          }
          &.ais-RefinementList-item--selected .ais-RefinementList-checkbox:checked,
          .ais-GeoSearch-input:checked {
           background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Checkbox'%3E%3Crect width='18' height='18' fill='var(--checkbox-bg-color, ${vars.colors.svgCheckboxBg})'/%3E%3Cpath id='Vector' d='M14 5L7.125 12L4 8.81818' stroke='var(--checkbox-stroke-color, ${vars.colors.svgCheckboxStroke})' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
           background-size: 18px;
            color: transparent;
          }
        }
      }
      .ais-RefinementList-showMore:not([disabled]),
      .clear-refinements {
        background: none;
        color: ${vars.colors.grey50};
        font-size: ${vars.fonts.sizeLarge};
        line-height: ${vars.fonts.lineHeightDefault};
        text-decoration-line: underline;
        padding: 0;
        margin: 0 0 16px 0;
        cursor: pointer;
        border: none;
        box-shadow: none;
        &:hover {
          background: none;
          color: ${vars.colors.black};
          text-decoration-line: underline;
        }
      }
      .ais-RefinementList-showMore:not([disabled]) {
        position: absolute;
      }
      .clear-refinements-container {
        display: flex;
        justify-content: end;
        padding-right: 6px;
      }
      // .ais-RefinementList:has(.ais-RefinementList-showMore:not([disabled]))
      //   ~ .clear-refinements-container:empty {
      //   height: 40px;
      // }

      .clear-refinements-container.have-showmore:empty{
        height:40px
      }
      .ais-RefinementList:has(.ais-RefinementList-showMore[disabled])
        ~ .clear-refinements-container:empty {
        height: 0;
      }

      `,
    currentRefinement: `
      .row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 10px;
      }

    .refinements {
      display: flex;
      gap: 4px;
      flex-wrap: wrap;
    }
    button {
      &.refinement-item {
        // width: 100%;
        color: ${vars.colors.white};
        display: flex;
        border-width: 1px;
        align-items: center;
        justify-content: space-between;
        border-radius: 0px;
        border-color: transparent;
        background-color: #01193c;
        border-radius: 15px;
        padding: 4px 15px;
        gap: 10px;
        margin-bottom: 4px;
        line-height: 14px;
        svg {
          path {
            stroke: ${vars.colors.white};
          }
        }
        :hover {
          cursor: pointer;
          border-color: #eee;
          filter: brightness(55%);
        }
        &.collapse-refinement{
          background-color:  ${vars.colors.lWHUELWBlue};
          color:black;
          filter: unset !important;
        }
      }
      &.clear-refinements {
        width: 100%;
        color: #333;
        background: transparent;
        text-decoration-line: underline;
        line-height: 24px;
        font-size: ${vars.fonts.sizeLarge};
        padding: 0px;
        margin-left:8px;
        :hover {
          text-transform: underline;
          cursor: pointer;
        }
      }
    }
    @media (max-width: 767px) {
      margin-bottom:14px;
      gap: 0;
      .refinements{
        gap:4px;
        flex-wrap:wrap;
        margin-bottom:5px;
        .refinement-item{
        margin-bottom: 0px;
        }
      }
      .refinement-item{

        &.collapse-refinement{
          background-color:  ${vars.colors.lWHUELWBlue};
          color:black;
          filter: unset !important;
        }
      }
      .clear-refinements-container{

          display: none;

      }

    }
    .text-capitalize {
      text-transform: capitalize;
    }
      `,
    sorting: `
      .results {
        .options {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border: 1px solid #222222;
          height: 40px;
          padding: 5px 5px 5px 10px;
          border-radius: 5px;
          font-family: ${vars.fonts.familySecondary};
          ${vars.breakpoints.sm} {
          min-width: 200px;
          }
          .label {
            font-weight: 700;
            line-height: 24px;
            margin-right: 7px;
          }
        }
      }
      .ais-SortBy-select {
        border: none;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E')
          96% / 10% no-repeat;
        width: 120px;
        padding: 0;
      }`,
    pagination: `
      .results {
        .options {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          border: 1px solid #222222;
          height: 40px;
          padding: 5px 5px 5px 10px;
          border-radius: 5px;
          min-width: 190px;
          font-family: ${vars.fonts.familySecondary};
          .label {
            font-weight: 700;
            line-height: 24px;
            margin-right: 7px;
          }
        }
        @media (max-width: 767px) {
          display: none;
        }
      }
      .ais-HitsPerPage-select {
        border: none;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E')
          85% / 25% no-repeat;
        width: 50px;
        padding: 0;
      }
      `,
    accordionWrapper: `
      .accordion-item {
        border-bottom: 1px solid ${vars.colors.grey20};
        padding: 10px 0px;
        // display: flex;
        align-items: center; /* Center the text and icon vertically */
        cursor: pointer;
      }

      .accordion-title {
        display: flex;
        justify-content: space-between;
        padding-right: 10px;
        align-items: center;
        .title {
          font-family: ${vars.fonts.familySecondary};
          font-size: 1.2em;
          font-weight: ${vars.fonts.weightBold};
          text-transform: capitalize;
          margin:0;
          padding:0;
        }
      }

      .accordion-icon {
        margin-left: 8px; /* Add some space between the text and icon */
        svg {
          max-height: 10px;
        }
      }
      .accordion-content {
        display: none;
        &.show-accordion {
          display: block;
        }
      }
      `,
    quickViewWrapper: `
      .description-title .mantine-gm39d{
        font-weight: ${vars.fonts.weightMediumPlus};
        font-size: 16px;
      }
        &.quick-view-modal-container {
          .mantine-Modal-content .mantine-Modal-header {
            justify-content: flex-end;
          }
          &.rate-review {
            .mantine-Modal-body, .mantine-Modal-header {
              background-color: ${vars.colors.primary3};
            }
          }
        }s
        .mantine-Modal-overlay {
          z-index: 500;
        }
        .mantine-Modal-inner {
          z-index: 500;
          @media (max-width: 767px) {
            padding: 0;
            align-items: unset;
          }
        }
        .mantine-Modal-content {
          padding: 20px;
          border-radius: 10px;
          background: #F8F5EF;
          .mantine-Modal-header {
            background: #F8F5EF;
            .mantine-Modal-title {
              color: #000;
              font-size: 30px;
              font-weight: 500;
            }
            .mantine-CloseButton-root {
              padding: 0;
              line-height: 0;
              background: transparent;
              color: ${vars.colors.black};
              width: auto;
              height: auto;
              border: none;
              svg {
                width: 32px !important;
                height: 32px !important;
              }
            }
          }
          .mantine-Modal-body {
            padding: 0px;
            padding-top: 0;
            .PDP-title {
              margin-top: 0;
              font-size: 38px;
              line-height: 52px;
              @media (max-width: 767px) {
                font-size: 25px;
                line-height: 28px;
              }
            }
            .submission-success {
              text-align: center;
            }
            .table-container {
              header, .row {
                display: flex;
              }
              header {
                .column {
                  font-weight: ${vars.fonts.weightBold};
                }
              }
              .row {
                border-bottom: 1px solid ${vars.colors.grey20};

              }
              .column {
                flex: 1;
                padding: 10px 0px;
              }
            }
            .product-main-container {
              display: flex;
              .w-100 {
                width: 100%;
              }
              .product-image-container {
                max-width: 217px;
                position: relative;
                .image-container {
                  text-align: center;
                  position: relative;
                  img {
                    min-height: 400px;
                    min-width: 112px;
                    &.case {
                      min-height: 275px;
                      min-width: 306px;
                    }
                  }
                }
                .wine-details {
                  ul {
                    list-style: none;
                    margin-top: 30px;
                    padding-left: 30px;
                    li {
                      padding-bottom: 10px;
                      display: flex;
                      align-items: center;
                      a {
                        display: flex;
                        align-items: center;
                      }
                      i, img {
                        width: 32px;
                        margin-right: 10px;
                      }
                      &:last-child {
                        padding-bottom: 0px;
                      }
                    }
                  }
                }
                &.case {
                  max-width: 320px;
                }
              }
              .product-content-container {
                margin-left: 24px;
                @media (max-width: 767px) {
                  margin-left: 0px;
                  margin-top: 20px;
                }
                // .rating-country-container {
                //   display: flex;
                //   align-items: center;
                //   justify-content: space-between;
                //   @media (max-width: 767px) {
                //     justify-content: unset;
                //   }
                //   .rating {
                //     ${vars.breakpoints.xl} {
                //       display: flex;
                //       gap: 5px;
                //     }
                //     .star-icons {
                        margin-right:4px;
                //       ${vars.breakpoints.xl} {
                //         gap: 5px;
                //         display: inline-flex;
                //       }
                //     }
                //     .rating-cout {
                //       margin: 0 15px 0;
                //       font-size: ${vars.fonts.sizeDefault};
                //       font-weight: ${vars.fonts.weightBold};
                //     }
                //     .review-count {
                //       text-decoration: underline;
                //     }
                //   }
                //   .country {
                //     display: flex;
                //     align-items: center;
                //     font-size: ${vars.fonts.sizeLarge};
                //     line-height: ${vars.fonts.lineHeightDefault};
                //     img, svg {
                //       margin-right: 10px;
                //       @media (max-width: 767px) {
                //         width: 24px;
                //       }
                //     }
                //     @media (max-width: 767px) {
                //       font-size: 12px;
                //       margin-left: 25px;
                //     }
                //   }
                // }
                .image-price-container {
                  border-bottom: 1px solid ${vars.colors.grey20};
                }
                .image-container {
                  text-align: center;
                  margin: 30px 0;
                }
                .wine-details {
                  margin-top: 30px;
                  ul {
                    list-style-type: none;
                    padding: 0;
                    height: 100px;
                    li {
                      display: flex;
                      align-items: center;
                      margin-bottom: 25px;
                      float: left;
                      width: 160px;
                      a {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        &:hover {
                          text-decoration: none;
                        }
                      }
                      i {
                        margin-right: 10px;
                        width: 24px;
                        font-size: 24px;
                      }
                      svg {
                        margin-right: 10px;
                        width: 24px;
                        height: 24px;
                      }
                    }
                  }
                }
                .money-back-guarantee {
                  background-color: ${vars.colors.lWHUELWBlue};
                  padding: 15px;
                  margin: 30px 0;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .content {
                    max-width: 429px;
                    .descp {
                      font-weight: ${vars.fonts.weightBold};
                      padding-bottom: 10px;
                    }
                  }
                  .icon i:before {
                    font-size: 44px;
                  }
                }
                &.case {
                  .title-rating-container, .description-container {
                    display: none;
                  }
                }
              }
              @media (min-width: 768px) {
                .desktopOnly {
                  display: block;
                }
                .mobileOnly {
                  display: none;
                }
              }
              @media (max-width: 767px) {
                .desktopOnly {
                  display: none;
                }
                .mobileOnly {
                  display: block;
                }
              }
            }
          }
        }
      `,
    sliderWrapper: `
        .price-heading {
          line-height: ${vars.fonts.lineHeightDefault};
          text-transform: uppercase;
          // margin: 25px 0 15px;
          padding: 16px 0px;
          font-size: ${vars.fonts.sizeLarge};
          font-weight: ${vars.fonts.weightBold};
          border-top: 1px solid #dadada;

        }
        .slider-root {
          position: relative;
          display: flex;
          align-items: center;
          margin: 25px 10px 40px 10px;
          .slider-track {
            position: relative;
            flex-grow: 1;
            background-color: #969696;
            height: 4px;
            border-radius: 9999px;
            .slider-range {
              position: absolute;
              background-color: ${vars.colors.primary1};
              height: 100%;
            }
          }
          .slider-thumb {
            position: relative;
            display: block;
            width: 12px;
            height: 12px;
            background-color: ${vars.colors.primary1};
            border-radius: 9999px;
            cursor: pointer;
            &[aria-label='Minimum']:after {
              left: 0;
            }
            &[aria-label='Maximum']:after {
              right: 0;
            }
            &:after {
              position: absolute;
              bottom: 15px;
              content: '£'attr(aria-valuenow);
              font-size: 12px;
              line-height: 18px;
            }
            &:focus-visible {
              outline: none;
            }
          }
        }
        .range-input {
          display: flex;
          justify-content: center;
          margin: 20px 10px;
          .ais-RangeInput {
            width: 100%;
            label {
              width: 25%;
              &:first-of-type:before {
                content: 'From';
              }
              &:before {
                content: 'To';
              }
            }
            input {
              border: 1px solid ${vars.colors.grey20};
              border-radius: 5px;
              box-shadow: none;
              text-align: center;
              margin-top: 5px;
              width: 100%;
              padding: 0;
            }
            button {
              background: ${vars.colors.btnsPrimaryBg};
              text-align: center;
              margin: 21px 0 0 10px;
              line-height: 10px;
              border-radius: 3px;
              &:hover {
                background: ${vars.colors.btnsPrimaryHoverBg};
                border: none;
              }
              &:focus {
                color: ${vars.colors.white};
              }
            }
          }
        }
      `,
    hitWrapper: `
      button {
        width: 100%;
      }
      &.grid-view {
        .class-grid {
          display: block !important;
        }
        .ais-Hits-list {
          @media (min-width: 769px) {
            grid-gap: 18px;
          }
          ${vars.breakpoints.xxl} {
            grid-gap: 26px;
          }
          .border-bottom-ccc{
            border-bottom:none;
          }
          .web-description {
            .web-description-content {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            @media (max-width: 1365px) {
              display: none !important;
            }
            @media (max-width: 1600px){
              &.case {
                position: absolute;
                top: 66% !important;
              }
            }
            @media (min-width: 1900){
              &.case {
                position: absolute;
                top: 59% !important;
              }
            }
            @media (min-width: 1300px) and (max-width: 1400px) {
              &.case {
                position: absolute;
                top: 57%;
              }
            }
            margin: 20px 0;
            &.case {
              position: absolute;
              top: 70%;
              transform: translateY(-50%);
              margin: 0 auto !important;
              width: 90%;
            }
          }

          @media (min-width: 768px) and (max-width: 1229px) {
            margin-top: 16px;
          }

          @media (min-width: 769px) {
            grid-template-columns: 1fr 1fr 1fr;
          }

          .ais-Hits-item {
            display: flex;
            flex-direction: column;
            padding: 0px;
            border: 1px solid #BCBCBC;
            border-radius: 12px;
            transition: box-shadow 0.3s ease-in-out;
            ${vars.breakpoints.md} {
              border-radius: 4px;
              min-height: 550px;
            }
            ${vars.breakpoints.xl} {
              min-height: 614px;
            }
            &:hover {
              box-shadow: 0px 30px 60px 0px rgba(52, 31, 0, 0.10), 0px 10px 30px 0px rgba(30, 20, 0, 0.10);
            }
            .image-bg {
              width: 100%;
              border-radius: 4px;
              .image-container {
                height: 209px;
                .offer {
                  .stock {
                    position: absolute;
                    left: 10px;
                    bottom: 4px;
                    margin-bottom: 0;
                    z-index: 0;
                  }
                  svg {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                  }
                }
                .image {
                  .bottle {
                    max-width: 52px;
                    height: 177px;
                  }
                }
                .quick-links-container {
                  flex-direction: column-reverse;
                  gap: 15px;
                  bottom: 10px;
                }
                .quick-view-container {
                  display: none;
                }
              }
            }
            .hit-content {
              text-align: center;
              padding: 16px;
              width: 100%;
              margin: 0;
              @media(min-width: 330px) and (max-width: 460px){
                min-height: 328px;
              }
              ${vars.breakpoints.xl} {
                min-height: 160px;
              }
              ${vars.breakpoints.xxl} {
                min-height: 164px;
              }
              min-height: 185px;
              display:flex;
              flex-direction:column;
              justify-content: space-between;
              position: relative;
              .title {
                ${vars.breakpoints.sm} {
                  font-size: 18px;
                  font-weight: 700;
                  line-height: 140%;
                }
                ${vars.breakpoints.xl} {
                  font-size: 22px;
                  font-weight: ${vars.fonts.weightMediumPlus};
                  line-height: 120%;
                }
                ${vars.breakpoints.xxl} {
                  font-size: ${vars.fonts.sizeH8}
                }
              }
              .happy-to-wait-container {
                min-height: 46px;
                background-color: #B3CFE5;
                padding: 8px;
                text-align: center;
                width: 100%;
                border-radius: 4px;
                ${vars.breakpoints.sm} {
                  margin: 10px 0 15px 0;
                }
                ${vars.breakpoints.md} {
                  margin: 4px 0;
                }
                ${vars.breakpoints.xl} {
                  margin: 10px 0 15px 0;
                }
                .date-expected {
                  font-weight: ${vars.fonts.weightBold};
                }
              }
              .inline-container {
                .country-rating{
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                }
                .happy-to-wait-container{
                  min-height: 46px;
                  margin-top: 10px;
                  margin-bottom: 20px;
                }

                justify-content: center;
                flex-direction: column;
                gap: 0;

                .rating {
                  a{
                    span{
                      svg{
                        margin-right:4px;
                      }
                    }
                  }
                  margin-top: 5px;
                  order: 1;
                  justify-content: center;
                }
                .country {
                  color: ${vars.colors.primary8};
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 5px;
                  order: 0;
                  ${vars.breakpoints.sm} {
                    flex-direction: row;
                  }
                  ${vars.breakpoints.md} {
                    flex-direction: row;
                  }
                  ${vars.breakpoints.xl} {
                    flex-direction: row;
                  }
                  .country-grapeVariety.grapeVariety-container {
                    margin-left: 0;
                    ${vars.breakpoints.xl} {
                      margin-left: 8px;
                    }
                    ${vars.breakpoints.xxl} {
                      margin-left: 10px;
                    }
                  }
                }
              }
              .hide-inline-container {
                ${vars.breakpoints.sm} {
                  display: block !important;
                }
                ${vars.breakpoints.md} {
                  display: none !important;
                }
                ${vars.breakpoints.xl} {
                  display: block !important;
                }
              }
              .title {
                margin-bottom: 0px;
                ${vars.breakpoints.md} {
                  min-height: 56px;
                }
                @media (min-width: 1025px) {
                  min-height: 56px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  height: 0;
                }
                @media (min-width: 1366px) {
                  min-height: 56px;
                }
                @media (min-width: 1920px) {
                  min-height: 67px;
                }
                .titleDescSale {
                  height: 100%;
                  line-height: 28px;
                  ${vars.breakpoints.xxl} {
                    height: 90%;
                  }
                  a{
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    height: auto;
                    ${vars.breakpoints.xxl} {
                      height: 100%;
                    }
                  }

                }
              }
              .reserveTodayContainer {
                margin: 0 auto!important;
                width: 90%;
                > div {
                    @media (min-width: 1025px) {
                      padding: 10px;
                      margin-bottom: 10px;
                      margin-right:0;
                      margin-left:0;
                    }
                    ${vars.breakpoints.xl} {
                      padding: 15px;
                      margin: 0 20px 25px 20px;
                    }
                }
                div {
                  border: none;
                }
              }
            }
            .addToCart-container {
              position: absolute;
              bottom: 16px;
              padding: 0 16px;
              width: 100%;
              left: 0;
              right: 0;
              margin: 0 auto;
              .lowestPricepossible, .lowestPricePossibleOnOffer{
                font-family: ${vars.fonts.familySecondary};
                letter-spacing: 0.18px;
                font-weight: 700;
                color: ${vars.colors.primary1};
                text-align: center;
                margin-bottom: 3px;
                &.no-pill-lowestPricepossible{
                  color: ${vars.colors.grey50};
                }
                &.no-pill-lowestPricePossibleOnOffer{
                  color: ${vars.colors.grey50};
                }
                @media(min-width: 330px) and (max-width: 460px){
                  margin-bottom:10px;
                }
                @media (min-width: 1025px) {
                  font-size: 14px;
                  line-height: 130%;
                }
                ${vars.breakpoints.lg} {
                  font-size: 16px;
                  line-height: 140%;
                }
                ${vars.breakpoints.xl} {
                  font-size: 16px;
                  line-height: 140%;
                }
                ${vars.breakpoints.xxl} {
                  font-size: 18px;
                  line-height: 140%;
                }
                .strike {
                  font-family: ${vars.fonts.familyTertiary};
                  font-weight: 400;
                  text-decoration: line-through;
                }
                &.presell-primeur {
                  color: #303030;
                  font-weight: 400;
                  font-family: ${vars.fonts.familySecondary};
                  font-size: 16px;
                  .currency {
                    color: ${vars.colors.black};
                    font-weight: 700;
                  }
                }
                &.lowestPricePossibleOnOffer{
                  font-weight:400 !important;
                }
              }
              .maxSavingsContainer {
                text-align: center;
                .saving-section-content {
                  border-radius: 30px;
                  background: ${vars.colors.primary5};
                  font-size: 12px;
                  font-weight: 700;
                  line-height: 18px;
                  padding: 5px 8px;
                  display: inline-block;
                  .saving {
                    color: ${vars.colors.primary1};
                  }
                }
                @media(min-width: 360px) and (max-width: 460px){
                  margin-bottom:10px;
                }
                @media(min-width: 300px) and (max-width: 360px){
                  margin-bottom:30px;
                }
              }
              .top-price-section {
                padding-top: 0px;
                // justify-content: space-around;
                // text-align: center;
                padding-bottom: 6px;
                display: block;
                // .saving-section {
                //   display: none;
                // }
                .top-price-section {
                  display: flex !important;
                }
              }
              .single-sku-container {
                text-align: center;
                .top-price-section {
                  justify-content: center;
                  gap: 15px;
                  align-items: center;
                }
              }
              .country {
                justify-content: center;
                gap: 10px;
                height: 50px;
              }
              .vpp-price, .tiered-price-container {
                // text-align: center;
              }

              .grid-addto-cart-container {
                margin-top: 12px;
                .mantine-Popover-dropdown {
                  @media (min-width: 1025px) {
                    width: 350px !important;
                  }
                  @media (min-width: 1480px) and (max-width: 1680px) {
                    width: 380px !important;
                  }
                  @media (min-width: 1551px) {
                    width: 420px !important;
                  }
                  @media (min-width: 1680px) {
                    width: 480px !important;
                  }
                }
                .grid-addto-cart-btn {
                  background-color: ${vars.colors.btnsPrimaryBg};
                  &:hover {
                    background-color: ${vars.colors.btnsPrimaryHoverBg};
                    color: ${vars.colors.white};
                  }
                }
              }
            }
          }
        }
      }
      &.list-view {
        .class-grid {
          display: none !important;
        }
        .ais-Hits-list {
          grid-template-columns: 1fr;

          .ais-Hits-item {
            border-top: 1px solid #eee;
            display: block;
            box-shadow: none;
            padding: 32px 0px;
            align-items: flex-start;

            &:first-of-type {
              border-top: none;
            }
            &:last-child {
              padding-bottom: 48px;
            }

              display: grid;
              grid-template-columns: 1fr;
              gap: 24px;

              ${vars.breakpoints.lg} {
                grid-template-columns: 240px auto 320px;
              }

              ${vars.breakpoints.xl} {
                grid-template-columns: 340px auto 400px;
              }

              .image-bg {
                border-radius: 8px;
              }

              .hit-content{
                .web-description{
                  height: fit-content;
                  min-height: 0px;
                }
              }

              .offer {
                .stock {
                  position: absolute;
                  left: 10px;
                  bottom: 4px;
                  margin-bottom: 0;
                }
                svg {
                  position: absolute;
                  left: 10px;
                  top: 10px;
                }
              }

              .addToCart-container {
                .country {
                  display: none;
                }
              }


              .quick-links-container {
                flex-direction: column-reverse;
                gap: 15px;
                bottom: 10px;
              }

              .quick-view-container {
                display: none;
              }

          }


        }
      }
      .ais-Hits div + ol {
        @media (min-width: 768px) {
          margin-top: 16px;
        }
      }
      .ais-Hits-list {
        display: grid;
        grid-template-columns: 1fr;
        .ais-Hits-item {
          box-shadow: none;
          position : relative;
          font-size: 1rem;
          .ais-Highlight {
            mark {
              color: ${vars.colors.primary1};
            }
          }

          .title {
            font-size: ${vars.fonts.lineHeightDefault};
            font-family: ${vars.fonts.familyTertiary};
            font-weight: ${vars.fonts.weightBold};
            line-height: 32px;
            margin-bottom: 10px;
          }

          .prod-link {
            a {
              text-decoration: underline;
              &:hover {
                text-decoration: none;
              }
            }
          }

          .pill {
            display: inline-flex;
            padding: 2px 5px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 30px;
            margin: 10px 0px;
            background: rgba(255, 168, 161, 0.4);
            .saving {
              color: ${vars.colors.primary1};
              font-size: 10px;
              font-family: ${vars.fonts.familySecondary};
              font-weight: ${vars.fonts.weightBold};
            }
          }

          .stock {
            background: ${vars.colors.primary6};
            color: ${vars.colors.white};
            font-size: 12px;
            line-height: 18.75px;
            gap: 5px;
            padding: 3px 8px;
            margin-top: 0;
            .case-count {
              font-size: 16px;
              font-weight: 700;
            }
          }

          .hit-content {
            margin-bottom: 5px;
            .inline-container {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              gap: 15px;
              .country-rating{
                margin:10px 0px;
              }
              .rating {
                display: flex;
                align-items: center;
                margin-top: 20px;
                justify-content: center;
                ${vars.breakpoints.sm} {
                  justify-content: start;
                }
                .star-icons {
                  display: flex;
                }
                .review-count {
                  color: ${vars.colors.primary8};
                  font-size: 14px;
                  line-height: 24px;
                  margin-left: 5px;
                  text-decoration: underline;
                }
              }
            }
            .web-description {
              margin-top: 10px;
              margin-bottom: 20px;
              color: ${vars.colors.primary8};
              font-size: ${vars.fonts.sizeLarge};
              line-height: 20px;
              ${vars.breakpoints.xl} {
                font-size: 16px;
                font-weight: 400;
                line-height: 1.4rem;

                text-overflow: ellipsis;
                overflow: hidden;
                height: 0;
                min-height: 46px;
              }
              ${vars.breakpoints.xxl} {
                font-size: ${vars.fonts.sizeLarge};
                line-height: 140%;
                min-height: 44px;
              }
            }
          }

          .image-bg {
            background-color: ${vars.colors.primary3};
            padding: 10px 0px;
            &.gift-image {
              background: none;
              padding: 0px;
              .image-container{
                height: 229px;
                padding: 0px;
              }
              .web-description {
                min-height: 95px!important;
              }
              .image {
                margin: 0px;
                height: 100%;
              }
              .image img {
                border-radius: 10px;
                object-fit: cover;
              }
            }
          }

          .image-container {
            position: relative;
            padding: 20px;
            height: 245px;
            max-height: 245px;
            .image {
              margin: 10px 0px;
              position: relative;
              height: 100%;
              img {
                object-fit: contain;
                object-position: 50% 50%;
                border-radius: 10px;
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                color: transparent;
              }
            }
            .image-center {
              position: relative;
              height: 100%;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0;
            }
            .offer {
              text-align: center;
            }

            .quick-links-container {
              display: flex;
              gap: 10px;
              position: absolute;
              top: 15px;
              right: 15px;
              .zoom, .favourite {
                background: white;
                padding: 5px 5px 0px 5px;
                border-radius: 20px;
                &:hover {
                  cursor: pointer;
                }
              }
            }
            .quick-view-btn {
              position: absolute;
              bottom: 15px;
              right: 15px;
              max-width: 95px;
              padding: 0;
              max-height: 24px;
              border-radius: 4px;
              background-color: ${vars.colors.secondary4};
              .mantine-Button-label {
                font-size: 12px;
                line-height: 14.06px;
                font-weight: 400;
              }
              &:focus {
                color: ${vars.colors.white};
                border-color: transparent;
              }
            }
            // display: grid;
            // grid-template-columns: 1fr 3fr 1fr;
          }
        }



      }
      li.ais-Hits-item:empty {
        display: none !important;
    }
      @media (max-width: 767px) {
        .ais-Hits-list {
          grid-gap: 30px;
        }
        .ais-Hits-item{
          border-radius: 12px;
          border-top: none;
          border: 1px solid #CCC;
          background: ${vars.colors.white};
          padding: 0px!important;
          .hit-content{
            padding:16px;
            padding-bottom: 0;
            text-align: center;
            .inline-container {
              justify-content: center;
              .rating {
                order: 1;
              }
            }
          }
          .addToCart-container {
            margin:0px 16px 16px 16px;
            .form-control.quantity {
              border:none;
            }
            .customProdQantityField {
              & div:first-of-type {
                min-width:38px;
                margin-right: 8px;
              }
            }
            .add-to-cart-btn-container {
              .cart-button {
                button {
                  padding: 8px 24px;
                  @media (max-width: 374px) {
                    padding: 8px 16px;
                  }
                }
              }
            }
          }
        }
      }
    `,
    searchBoxWrapper: `
      .aa-Autocomplete {
        display: flex;
        flex-direction: column;

        .aa-Form {
          box-shadow: none;
          border: 0;
          border-radius: 50px;
          display: flex;
          width: 100%;
          align-items: center;
          ${vars.breakpoints.lg} {
            border: 1px solid ${vars.colors.grey20};
          }
        }
        .aa-InputWrapperPrefix {
          display: none;
        }
        .aa-InputWrapper {
          padding-left: 0;
          width: 100%;
          .aa-Input {
            border-radius: 50px !important;
            box-sizing: border-box;
            box-shadow: none;
          }
        }
        .aa-InputWrapperSuffix {
          padding-right: 10px;
          z-index: 1;
          ${vars.breakpoints.lg} {
            padding-right: 4px;
          }
          button {
            &.aa-ClearButton {
              vertical-align: middle;
              background: ${vars.colors.primary1};
              padding: 5px;
              border-radius: 50px !important;
              color: ${vars.colors.white};
              width: 26px;
              height: 26px;

              ${vars.breakpoints.lg} {
                padding: 8px;
                width: 36px;
                height: 36px;
              }

              svg {
                width: 26px;
                height: 26px;

                &:focus {
                  outline: none;
                }
              }
            }
            &:hover {
            }
          }
        }
      }
      `,
    autocompletePanel: `
        display: flex;
        align-content: stretch;
        column-gap: 30px;

        .ui-container {
          display: flex;
          gap: 25px;
          flex-direction: column;
          ${vars.breakpoints.md} {
            flex-direction: unset;
          }
          .aa-Item {
            &[aria-selected="true"] {
              background: ${vars.colors.lWHUELWBlue};
            }
          }

          ${
            globals?.algoliaNewView &&
            `
              gap: 0px;
              margin-bottom: 50px;
              ${vars.breakpoints.md} {
                gap: 25px;
                margin-bottom: 0px;
              }
              `
          }

          #query-suggestions {
            .aa-ItemIcon {
              display: none;
            }
            .aa-ItemActions {
              display: none;

            }
          }

          #recent-searches {
            .aa-ItemActions button:nth-of-type(1) {
              background-color: transparent;
              padding: 0px;
              display: inline-grid;

              &:hover {
                background-color: transparent;
              }
                svg {
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 4L4 12' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4 4L12 12' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                    path {
                        display: none;
                    }
                }
            }
            .aa-ItemActions button:nth-of-type(2),
            .aa-ItemIcon {
                display: none;
            }
            .aa-ItemContentTitle {
                mark {
                    font-weight: ${vars.fonts.weightNormal};
                }
            }
        }

          .us-results-image{
            box-shadow: none;
            height: 60px;
            min-width: 70px;
            width: 60px;

            img {
              height: 40px;
              max-height: none;
              max-width: 65px;
          }
          }

          .results-image {
            box-shadow: none;
            height: 60px;
            min-width: 70px;
            width: 60px;

            img {
              height: 60px;
              max-height: none;
              max-width: none;
          }
        }
          
        .uk.product-section {
          .results-image {
          ${
            globals?.algoliaNewView &&
            `height: 83px;
            min-width: 80px;
            width: 70px;
              img {
                height: 83px;
              }`
          }
          }
        }  
            
          .aa-SourceHeader {
            border-bottom: 1px solid #eee;
            font-size: ${vars.fonts.sizeDefault};
            padding-bottom: 10px;
            text-transform: uppercase;
            white-space: nowrap;
            .aa-SourceHeaderTitle {
              font-size: 1em;
              font-weight: ${vars.fonts.weightNormal};
              color: ${vars.colors.black};
            }
            ${
              globals?.algoliaNewView &&
              `border-bottom: none; 
              text-transform: capitalize; 
              font-size: 16px; 
              .aa-SourceHeaderTitle { font-weight: bold; }`
            }
          }

          .ui-plugins {
            ${
              globals?.algoliaNewView &&
              `
              order: 3;
                .collection-search {
                  .aa-Source {
                    .aa-SourceHeader {
                      margin-bottom: 5px;
                    }
                    .aa-List {
                      .aa-Item {
                        padding-left: 0;
                      }
                    }
                  }
                }
                #recent-searches {
                  .aa-Source {
                    .aa-SourceHeader {
                      margin-bottom: 5px;
                    }
                    .aa-List {
                      .aa-Item {
                        padding-left: 0;
                      }
                    }
                  }
                }
              ${vars.breakpoints.md} {
                order: 1;
              }
              `
            }
            .aa-Source {
              margin-bottom: 25px;
            }
          }
          .ui-products {
            ${
              globals?.algoliaNewView &&
              `
              order: 2;
              &:has(> div:empty) {
                height: auto;
              }
              .view-all-items-container a {
                text-decoration: underline;
              }
              ${vars.breakpoints.md} {
                order: 2;
                padding-bottom: 50px;
                height: calc(98vh - 150px);
                overflow: scroll;
                scrollbar-width: none;
                  .aa-SourceHeader {
                    position: sticky;
                    top: 0;
                    z-index: 1;
                    background: white;
                    padding: 10px 0;
                    margin: 0px;
                  }
              }
              ${vars.breakpoints.lg} {
                height: calc(94vh - 150px);
              }
              `
            }
          }
          .ui-recommended {
            ${
              globals?.algoliaNewView &&
              `
              order: 1;
                .aa-Source {
                  margin-bottom: 20px;
                  .aa-SourceHeader {
                    letter-spacing: 0.4px;
                    margin-bottom: 5px;
                    font-size: 18px;
                  }
                  .aa-Item {
                    padding: 0;
                      .recommended-section {
                        .aa-ItemContent {
                          grid-auto-flow: column;
                          align-items: start;
                            .results-image {
                              width: 162px;
                              height: 162px;
                              background-color: ${vars.colors.primary3};
                                img {
                                  height: 145px;
                                }
                            }
                            .aa-ItemContentBody {
                              gap: 10px;
                              padding-top: 10px;
                              padding-left: 5px;
                            }
                        }
                      }
                  }
                }
              ${vars.breakpoints.md} {
                order: 3;
                padding-bottom: 50px;
                  .aa-Source {
                    margin-bottom: 0px;
                    .aa-SourceHeader {
                      letter-spacing: normal;
                      margin-bottom: 10px;
                      font-size: 20px;
                    }
                    .aa-Item {
                      .recommended-section {
                        .aa-ItemContent {
                          grid-auto-flow: row;
                            .results-image {
                              width: 220px;
                              height: 220px;
                              background-color: ${vars.colors.primary3};
                                img {
                                  height: 180px;
                                }
                            }
                            .aa-ItemContentBody {
                              padding-top: 0px;
                              padding-left: 0px;
                            }
                        }
                      }
                    }
                  }
              }
              `
            }
          }
        }
      `,
    searchOverlay: `
        position: fixed;
        left: 0;
        right: 0;
        height: 100%;
        background: ${vars.colors.black};
        opacity: 0.6;
      `,
  },
  CmsBanner: {
    default: `
      overflow: hidden;
      .banner{
        ${vars.breakpoints.md}{
          padding: 5% 5% 5% 5%;
          position:relative;
        }
        ${vars.breakpoints.lg}{
          padding: 5% 15% 5% 10%;
        }
      }
      .banner-firstgrid{
        position: relative;
        padding:0px;
        margin:0px;
        order:1
      }
      .banner-secondgrid{
        position: relative;
        padding:0px;
        margin:0px;
        order: 2
      }
      .banner-img{
      display: block;
      object-fit:contain;
      }
      .banner-imgcomp{
        ${vars.breakpoints.md}{
        position: absolute;
        top:50%;
        left: 50%;
        transform: translate(-50%, -50%);
        }
      }
      .banner-sources{
        padding:0px;
      }
      .banner-title {
          color: ${vars.colors.white};
          font-weight:${vars.fonts.weightMedium};
          padding-bottom: 12px;
          text-align:center;
          margin:0%;
          font-size:22px;
          font-family:${vars.fonts.familyTertiary};
          padding-top:36px;

        ${vars.breakpoints.md}{
          text-align:left;
          font-size:60px;
          font-family:${vars.fonts.familyPrimary};
        }
        }
        .banner-content {
          a {
            color: ${vars.colors.white};
          }
            padding-left:${vars.fonts.sizeLarge};
            padding-right:${vars.fonts.sizeLarge};

          ${vars.breakpoints.md} {
            padding:0px 0px 6px 0px;
          }
        }

      .banner-button{
        display: flex;
        gap: ${vars.space.lg};
        flex-wrap: wrap;
        align-items: center;
        padding:0 ${vars.space.lg};
        ${vars.breakpoints.md}{
          padding:0;
        }
        > .cart-button-wrap {
          width: 100%;
          ${vars.breakpoints.sm} {
            width: auto;  
          }
          .grid-addto-cart-btn {
            width: 100%;
          }
        }
        >a{
          width: 100%;
          text-align: center;
          ${vars.breakpoints.sm}{
            width: auto;
            min-width: 180px;
          }
          button {
            font-size: 12px;
            font-weight: ${vars.fonts.weightMediumPlus};
            text-transform: uppercase;
            letter-spacing: 0.24px;
            min-width: 140px;
            padding: 2px 16px;
            background: transparent;

            cursor: pointer;
            &.darkColor {
              color: ${vars.colors.primary8};
              border: 1px solid ${vars.colors.primary8};
              &:hover {
                background-color: ${vars.colors.btnsPrimaryHoverBg};
                color: ${vars.colors.white};
                border-color: ${vars.colors.btnsPrimaryHoverBg};
              }
            }
            &.lightColor {
              color: ${vars.colors.white};
              border: 1px solid #f8f8f8;
              &:hover {
                background-color: ${vars.colors.white};
                color: ${vars.colors.primary8};
              }
            }
          }
        }
      }
        .banner-info{
          padding: 16px;
          margin-bottom: 24px;
          ${vars.breakpoints.xs}{
            padding: 16px
          }
          ${vars.breakpoints.sm}{
            padding: 30px;
            margin-bottom: 0;
          }
          ${vars.breakpoints.md}{
            padding: 32px
          }
          ${vars.breakpoints.lg}{
            padding: 40px 85px;
          }
          ${vars.breakpoints.xxl}{
            padding: 40px 120px;
          }
        }
      `,
    wrapper: `
        margin-bottom: 12px;
        border-radius: 8px;
        padding: 24px 16px 0;
        ${vars.breakpoints.sm} {
          margin-bottom: 24px;
          padding: 24px;
        }
    .banner-info {

      max-width: 764px;
      margin: 0 auto;
      padding: 0;
      .banner-content {
        padding: 0;
        a:hover {
          text-decoration: none;
        }
      }
      .banner-button {
        margin-top: 16px;
        display: flex;
        align-items: center;
        gap: 16px;

        flex-wrap: wrap;
        padding: 0;
        ${vars.breakpoints.xs}{
          gap: 12px;
        }
        a {
          button {
            font-size: 12px;
            font-weight: ${vars.fonts.weightMediumPlus};
            text-transform: uppercase;
            letter-spacing: 0.24px;
            min-width: 140px;
            padding: 2px 16px;
            background: transparent;

            cursor: pointer;
            &.darkColor {
              color: ${vars.colors.primary8};
              border: 1px solid ${vars.colors.primary8};
              &:hover {
                background-color: ${vars.colors.btnsPrimaryHoverBg};
                color: ${vars.colors.white};
                border-color: ${vars.colors.btnsPrimaryHoverBg};
              }
            }
            &.lightColor {
              color: ${vars.colors.white};
              border: 1px solid #f8f8f8;
              &:hover {
                background-color: ${vars.colors.white};
                color: ${vars.colors.primary8};
              }
            }
          }
        }
      }
      h2,
      p {
        margin: 0;
      }
    }
  `,
    bannerSkeleton: `
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        min-height: 300px;
          .text-section{
            flex: 1;
            max-width: 60%;
            margin-left: 40px;
          }
          .image-section{
            margin-left: 40px;
            min-height: 500px;
          }
          .cta-section{
            display: flex;
            gap:10px;
          }
        `,
    BannerWithButton: `
      position:relative;
        .banner-content {
          display: flex;
          flex-direction: column;
          padding:15px !important;
          ${vars.breakpoints.xl} {
            padding:18px !important;
          }
          color: ${vars.colors.btnsPrimaryColor};
          justify-content: space-between;
          width: 100%;
          height: 100%;
          position:absolute;
          background: linear-gradient(90deg, #000 0.05%, rgba(0, 0, 0, 0.00) 107.69%);
          z-index:2;
          .banner-button { 
            margin-top: 10px;
              a {
                margin: 5px;
                button {
                  font-size: 12px;
                  font-weight: ${vars.fonts.weightMediumPlus};
                  text-transform: uppercase;
                  letter-spacing: 0.24px;
                  min-width: 140px;
                  padding: 2px 16px;
                  background: transparent;
                  cursor: pointer;
                  &.darkColor {
                    color: ${vars.colors.primary8};
                    border: 1px solid ${vars.colors.primary8};
                    &:hover {
                      background-color: ${vars.colors.btnsPrimaryHoverBg};
                      color: ${vars.colors.white};
                      border-color: ${vars.colors.btnsPrimaryHoverBg};
                    }
                  }
                  &.lightColor {
                    color: ${vars.colors.white};
                    border: 1px solid #f8f8f8;
                    &:hover {
                      background-color: ${vars.colors.white};
                      color: ${vars.colors.primary8};
                    }
                  }
                }
              }
           }  
        }
        & img{
          min-height:230px;
        }
      `,
    ExternalVideoBackgroundContenVariant: `
      position: relative;
      overflow: hidden;
      > div {
        position: absolute;
        left: 0;
        top: 0;
        ${vars.breakpoints.md} {
          transform: scale(1.55);
        }
        ${vars.breakpoints.lg} {
          transform: scale(1.32);
        }
        ${vars.breakpoints.xl} {
          transform: scale(1.1);
        }
        ${vars.breakpoints.xxl} {
          transform: none;
        }
      }
    `,
    ExternalVideoBackgroundTintedVariant: `
      > .banner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        margin: 0;
      }
      .external-video-player {
        position: absolute;
        left: 0;
        top: 0;
      }
    `,
  },
  bannerSkeleton: {
    wrapper: `display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      min-height: 300px;
        .text-section{
          flex: 1;
          max-width: 60%;
          margin-left: 40px;
        }
        .image-section{
          margin-left: 40px;
          min-height: 500px;
        }`,
  },

  CmsMenuBanner: {
    wrapper: `
        display: flex;
        flex-direction: column;
        align-items: end;

        .nav-banner-img {
          display: block;
          width: 100%;
          height: auto;
        }
        .main-content-container & {
            display: unset;
            a {
              display: inline-block;
              img {
                width: auto;
              }
            }
        }
      `,
  },
  CmsNavigation: {
    wrapper: `
      `,
    body: `
        &.navigation-link {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          a{
            text-wrap:nowrap;
          }
          > a {
            text-wrap:initial;
            line-height: 18px;
            margin-bottom: ${vars.fonts.sizeDefault};
            width: fit-content;
          }
        }
      `,
    sectionLinkFooter: `
        margin-top:20px;
        text-wrap:nowrap;
        a{
          display: inline-block;
          text-decoration: underline;
        }
        i{
          ${vars.mixins.iconBase}
          ${vars.icons['chevron-right']}
          display: inline-block;
          font-size: ${vars.fonts.lineHeightDefault};
          margin-left: 0px;
          ${vars.breakpoints.xl}{
            margin-left: 8px;
          }
        }
      `,
  },
  CmsArticle: {
    wrapper: `
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        > * {
          flex-basis: 50%;
        }
        ${vars.breakpoints.lg} {
          margin-bottom: 0;
        }
        img {
          max-width: 100%;
          height: 100%;
        }
        .article-container {
          margin-top: 24px;
          > p {
            font-weight: ${vars.fonts.weightNormal};
            font-size: ${vars.fonts.fontSize16};
            line-height: 22.4px;
            color: ${vars.colors.grey60};
          }
          h2 {
            margin-top: 8px;
            margin-bottom: 16px;
            color: ${vars.colors.black};
          }
          .secondary {
            color: ${vars.colors.black};
          }
          .date {
            color: ${vars.colors.grey60};
          }
          .article-content {
            color: ${vars.colors.grey60};
            p {
              ${vars.mixins.body1}
            }
          }
        }
      `,
  },
  CmsCarousel: {
    viewport: `
        padding-bottom: 24px;
      `,
    indicators: `
        bottom: 17px;
      `,
    indicator: `
        background: ${vars.colors.secondary9};
        width: 8px;
        height: 8px;
        border-radius: 100%;
        opacity: 1;

        &[data-active] {
          background: ${vars.colors.grey50};
        }
      `,
    controls: `
        position: static;
        margin: 0 auto;
        padding: 0;
        ${vars.breakpoints.sm}{
          max-width: 275px;
        }
      `,
    control: `
        width: 44px;
        height: 44px;
        border-color: ${vars.colors.secondary9};
        svg {
          width: 1.5rem !important;
          height: 1.5rem !important;
        }

        &[data-inactive] {
          background: transparent !important;
        }
      `,
  },
  CmsVideo: {
    videoCompCss: `
        display: flex;
        margin: 0 auto;
        position: relative;
        justify-content: center;
        align-items: center;
        max-width: 1100px;
        margin: 0 auto;

        .main-content-container & .video-container {
          position: relative;
          height: 0;
          width: 100%;
          & > div,
          .cms-image-container img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100% !important;
            height:100% !important;
            object-fit: cover;
          }
          .react-player__shadow {
            display: none !important;
          }
        }
        &.full-width {
          display: grid;
          grid-template-columns: 1fr;
          min-height: 600px;
          height: 100%;
          max-width: none;
          .full-width-overlay {
            grid-row-start: 1;
            grid-column-start: 1;
            width: 100%;
            height: 100%;
            z-index: 1;

            .video-grid {
              height: 100%;
              margin: 0;
              .overlay-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                color: ${vars.colors.white};
                padding: 16px;
                ${vars.breakpoints.sm}{
                  padding: 40px 32px;
                }
                ${vars.breakpoints.xl}{
                  padding: 40px 72px;
                }
                ${vars.breakpoints.xxl}{
                  padding: 40px 120px;
                }
                .play-button {
                  border: 1px solid ${vars.colors.white};
                  padding: 12px 24px;
                  border-radius: 1px;
                  cursor: pointer;

                  display: flex;
                  gap: 8px;
                  align-items: center;
                  justify-content: space-between;
                }
              }
            }
          }
          .video-container {
            grid-row-start: 1;
            grid-column-start: 1;
            width: 100%;
            height: 100%;
            padding: 0 !important;
            .react-player__shadow {
              display: none !important;
            }
            .cms-image-container {
              width: 100%;
              height:100%;
              img {
                width: 100%;
                height: 100%;
                ${vars.breakpoints.xs} {
                  min-height: 600px;
                  object-fit: cover;
                }
              }
            }
            div {
              width: 100% !important;
              height:100% !important;
            }
            ${vars.breakpoints.sm} {
              height:  600px;
            }
            ${vars.breakpoints.md} {
              height:  625px;
            }
          }
        }
        &.playing {
          background-color: ${vars.colors.black};
          .video-container {
            width:100%;
            margin: 0 auto;
            ${vars.breakpoints.xs} {
              width:  100%;
              height: 211px;
            }
            ${vars.breakpoints.sm} {
              width:  100%;
              height: 432px;
            }
            ${vars.breakpoints.md} {
              width:  100%;
              height: 576px;
            }
            ${vars.breakpoints.lg} {
              width:  1068px;
              height: 600px;
            }
          }
        }
      `,
    overlayCss: `
        position: absolute;
        z-index: 2;
        margin: 0 auto;
        display: inline-block;
        cursor: pointer;

        svg {
          width: 47px;
          height: 47px;
          ${vars.breakpoints.sm} {
            width: 90px;
            height: 90px;
          }
        }
      `,
  },
  SlickSlider: {
    wrapper: `
      .slick-slider {
        position: relative;
        display: block;
        box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
        overflow: hidden;
        cursor: pointer;
        .slick-list {
          position: relative;
          display: block;
          overflow: hidden;
          padding: 0;
          // width: 1400px;
          margin: 0px 60px;
          .slick-track {
            position: relative;
            top: 0;
            left: 0;
            display: block;
            margin-left: auto;
            margin-right: auto;
            .slick-slide {
              display: block;
              float: left;
              height: 100%;
              min-height: 1px;
              .product-item-container {
                .product-title {
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-family: ${vars.fonts.familyTertiary};
                  ${vars.breakpoints.xs}{
                    font-size: ${vars.fonts.sizeLarge};
                    min-height: auto;
                  }
                  ${vars.breakpoints.sm}{
                    min-height: 43px;
                  }
                  ${vars.breakpoints.lg}{
                    font-size: ${vars.fonts.sizeLarger};
                    min-height: 49px;
                  }
                }
              }
            }
          }
        }
        .slick-list , .slick-track {
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
          -o-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }
        .slick-arrow {
          cursor: pointer;
          top: 25%;
          transform: translateY(-50%);
          position: absolute;
          background: transparent;
          color: ${vars.colors.black};
          padding: 0;
          i {
            font-size: 60px;
          }
          &.slick-prev {
            left: 0
          }
          &.slick-next {
            right: 0;
          }
          &:focus {
            border-color: transparent;
          }
        }
        .slick-disabled {
          display: none !important;
        }
        .slick-dots {
          display: flex !important;
          justify-content: center;
          margin: 0;
          padding: 1rem 0;
          list-style-type: none;
          li {
            margin: 0 0.25rem;
            button {
              display: block;
              width: 12px;
              height: 12px;
              padding: 0;
              border: none;
              border-radius: 100%;
              background-color: ${vars.colors.grey20};
              text-indent: -9999px;
            }
            &.slick-active {
              button {
                background-color: ${vars.colors.primary1};
              }
            }
          }
        }
        .case-slick-dots {
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          display: flex !important;
          justify-content: center;
          margin: 0;
          padding: 1rem 0;
          list-style-type: none;
          li {
            margin: 0 0.25rem;
            button {
              display: block;
              width: 12px;
              height: 12px;
              padding: 0;
              border: none;
              border-radius: 100%;
              background-color: ${vars.colors.grey20};
              text-indent: -9999px;
            }
            &.slick-active {
              button {
                background-color: ${vars.colors.primary1};
              }
            }
          }
        }
      }
      `,
  },
  StateSelector: {
    default: `
      .mantine-Modal-body {
        background: ${vars.colors.primary3};
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        width: 100vw !important;
        overflow-y: auto;
        max-height: 90vh;
        ${vars.breakpoints.lg}{
          border-radius: 12px;
          width: 785px !important;
        }
      }
      .mantine-Modal-inner{
        background: transparent;
        z-index: 999 !important;
        top: auto;
        bottom: 0px;
        padding: 0;
        ${vars.breakpoints.lg}{
          top: 0px;
          bottom: 0px;
        }
      }
      .mantine-Select-input{
        width: 80vw;
        ${vars.breakpoints.lg}{
          width: auto;
        }
      }
      .modal-body-inner {
        padding: 24px;
      }
      .selector-title {
        font-family: ${vars.fonts.familyTertiary};
        font-size: 26px;
        font-weight: ${vars.fonts.weightMediumPlus};
        line-height: 31px;
        letter-spacing: -0.01em;
        text-align: center;
        margin: 0 0 25px;
      }
      .state-selection {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        margin: 24px 0;
        flex-direction: column;
        ${vars.breakpoints.sm} {
          flex-direction: row;
        }
      }
      .state-selector-terms {
        font-family: ${vars.fonts.familySecondary};
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        max-width: 219px;
        color: ${vars.colors.grey50};
        margin: auto;
      }
      .state-selector-compliance {
        margin-top: 25px;
        color: ${vars.colors.grey70};

        a {
          display: inline-block;
          color: ${vars.colors.grey50};
        }
      }
      button.verify-state-btn {
        width: 142px;
        text-transform: uppercase !important;
        font-weight: ${vars.fonts.weightMediumPlus};
        border-radius: 4px !important;
        padding: 7px 24px;
        font-family: ${vars.fonts.familySecondary} !important;
        background-color: ${vars.colors.primary1};
        &:disabled {
          cursor: not-allowed;
        }
        &:disabled:hover {
          background-color: ${vars.colors.btnDisabled};
        }
        &:hover {
          background-color: ${vars.colors.primary1BtnHover};
        }
      }
      .mantine-Input-input {
        box-sizing: border-box;
        height: 44px;
        border: 1px solid ${vars.colors.grey40};
        border-radius: 4px;
        ${vars.breakpoints.xs} {
          min-width: 343px;
        }
      }
      .mantine-Modal-content {
        border-radius: 12px;
        overflow: unset;
        transition: flex .3s ease-in-out;
      }
      .selector-subtitle {
        font-family: ${vars.fonts.familySecondary};
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        .error-text {
          color: #c84646;
        }
      }
      .dropdown-item {
        font-family: ${vars.fonts.familySecondary} !important;
        font-size: 14px !important;
        line-height: 18px !important;
        letter-spacing: 0em !important;
        text-align: left !important;
        padding: 12px 10px !important;
        &[data-selected] {
          background-color: ${vars.colors.primary1} !important;
          color: ${vars.colors.white} !important;
          &:hover, &:active{
            background-color: ${vars.colors.primary1} !important;
          }
        }
      }
    `,
    locationWrapper: `
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
    `,
  },
  Cart: {
    cartLayout: {
      wrapper: `
          padding-right: 16px;
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
          border-bottom: 1px dashed #bcbcbc;
          padding-bottom: 8px;
        `,
      layout: `
          display: flex;
          width: 100%;
          flex-wrap: no-wrap;
          align-items: flex-start;
          column-gap: 10px;
          flex-direction: column;
          justify-content: space-between;
          ${vars.breakpoints.sm} {
            flex-direction: row;
          }
        `,
      cartCounterAndPrice: `
          display: flex;
          justify-content: space-between;
          margin-top: 16px;
          width: 100%;
          ${vars.breakpoints.sm} {
            margin-top: 0;
            width: 45%;
          }
          ${vars.breakpoints.md} {
            width: 50%;
          }
          .price-savings {
            display: flex;
            align-items: flex-end;
            flex-direction: column;

            h6 {
              margin: 8px 0;
            }
          }
        `,
      reservationItem: `
          color: ${vars.colors.primary8};
          background-color: ${(vars.colors.bgColors as unknown as { [key: string]: string }).whiteWine};
          padding: 8px;
          border-radius: 4px;
          .reservation-item-text {
            font-size: 14px;
            display: flex;
            align-items: center;
            gap: 8px;
            font-weight: bold;
          }
          ul {
            margin: 0;
            padding-inline-start: 45px;
          }
        `,
      freeGift: `
          margin-bottom: 16px;
          border-bottom: 1px dashed #bcbcbc;
          padding-bottom: 16px;
          .wrapper {
            display: flex;
            border: 1px solid #BCBCBC;
            padding: 16px 0;
            border-radius: 4px;
            align-items: center;
            ${vars.breakpoints.sm} {
              align-items: flex-start;
            }
          }
          .free-gift-image {
            width: 70px;
            height: 70px;
            margin-left: 8px;
            ${vars.breakpoints.sm} {
              width: 200px;
              height: 170px;
              margin-left: 0;
            }
          }
          .freeline-item-imagewrapper {
            margin:16px 32px;
            ${vars.breakpoints.sm} {
              margin:4px 0 12px 12px;
            }
            ${vars.breakpoints.lg} {
              margin:8px 0 16px 16px;
            }
          }
          .text-container {
            display: flex;
            flex-grow: 1;
            justify-content: center;
            align-items: flex-start;
            margin: 0 24px;
            flex-direction: column-reverse;
            gap: 24px;
            padding: 18px 0px;
            ${vars.breakpoints.sm} {
              justify-content: space-between;
              flex-direction: unset;
              padding:0;
            }
            .freeline-item-product-link, h3, h4 {
              margin: 0;
              text-align: middle;
            }
            .freeline-item-product-link:hover, h3:hover {
              text-decoration: underline;
            }
            .freeline-item-product-link,h3 {
              font-family: ${vars.fonts.familyTertiary};
              font-size:16px;
              font-weight:500;
              line-height: 21px;
              height: max-content;
              ${vars.breakpoints.sm} {
                font-size:26px;
                line-height: 31px;
              }
            }
          }
          .gift {
            display: flex;
            align-items: center;
            height: max-content;
            gap: 8px;

            h4 {
              font-family: ${vars.fonts.familyTertiary};
              font-size: 20px;
              font-weight: 500;
              line-height: 24px;
              ${vars.breakpoints.sm} {
                h3 {
                  font-size:22px;
                  ine-height: 26.4px;
                }
              }
            }
          }
        `,
    },
    enPrimeur: `
        display: flex;
        flex-direction: row;
        gap: 8px;
        border-radius: 4px;
        background-color: ${(vars.colors.bgColors as unknown as { [key: string]: string }).whiteWine};
        ul {
          color: ${vars.colors.secondary12};
          margin: 4px 0;
          > li {
            margin: 2px 0;
          }
        }
        .info-text {
          color: ${vars.colors.primary8};
          font-weight: bold;
          padding-inline-start: 25px;
          a {
            text-decoration: underline;
          }
        }
        .info-text p {
          margin:0;
          margin-bottom: 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 18.2px;
          letter-spacing: 0.02em;
          ${vars.breakpoints.lg} {
            line-height: 16px;
          }
        }
        p.clickable-link {
          cursor: pointer;
          font-weight: 400;
          font-size: 12px;
          line-height: 16.8px;
          text-decoration: underline;
          ${vars.breakpoints.lg}{
            font-weight: 500;
            font-size: 16px;
            line-height: 19.2px;
          }
        }
        &.normal-pad {
          padding: 8px;
          .info-text {
           padding-inline-start: 0;
          }
        }
      `,
    quickViewWrapper: `
        @media (max-width: 768px) {
          .button-wrapper {
            margin-top: 28px !important;
            button {
              width:100%;
            }
          }
        }
        @keyframes slideFromBottom {
          from {
            opacity: 0;
            transform: translateY(100%);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .mantine-Modal-overlay {
          z-index: 400;
        }

        .mantine-Modal-inner {
          padding:0;
          z-index: 400;
          margin-top:107px;
          ${vars.breakpoints.md}{
            margin-top:0;
          }
          .mantine-Modal-content {
            animation: slideFromBottom 0.5s ease forwards;
            height: -webkit-fill-available;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;

            @media screen and (min-width: 768px) {
              max-height: calc(100vh - (5vh * 2)) !important;
              border-radius: 12px;
              height: auto !important;
            }
          }

          .mantine-Modal-header .mantine-Modal-body {
            padding:24px;
          }
        }

        .mantine-Modal-content {
          background: #F8F5EF;
          .mantine-Modal-header {
            background: #F8F5EF;
            .mantine-Modal-title {
              ${vars.mixins.h3}
            }
            justify-content: space-between;
            padding: 24px 24px 15px 24px;
            ${vars.breakpoints.md}{
              padding: 32px 32px 16px 32px;
            }
            .mantine-Modal-close {
              outline: 0;
              color: #222222;
              width: auto;
              svg {
                width: 32px !important;
                height: 32px !important;
              }
            }
          }
          .mantine-Modal-body {
            [data-test="navigation"] {
              border-bottom:1px solid #DBDBDB;
              color: ${vars.colors.secondary15};
              padding:12px 0;
              margin:0;
            }
            padding:0 24px 24px 24px;
            ${vars.breakpoints.md}{
              padding:0 32px 32px 32px;
            }
            .regular {
              font-weight: 400;
              font-family: ${vars.fonts.familySecondary};
              font-size: 16px;
              line-height: 22.4px;
              color: ${vars.colors.secondary15};
              text-transform:none;
            }
            .bold {
              font-weight: 700;
              font-family: ${vars.fonts.familySecondary};
              font-size: 16px;
              line-height: 22.4px;
            }
          }
        }
      `,
    responsiveTable: `
        .mt-32 {
          margin-top: 32px;
        }

        table.dltrc {
          width: 100%;
          border-collapse: separate;
          border-spacing: 0px;
          border: solid #616161 1px;
          border-radius: 8px;
        }

        tr.dlheader {
          text-align: left;
          font-weight: bold;
          border-left: solid #616161 1px;
          padding: 16px 12px;
          width:50%;
        }

        td.dlheader {
          background: #d9d9d9;
          text-align: left;
          font-weight: bold;
          border-left: solid #616161 1px;
          border-radius: 0px;
          padding: 16px 12px;
          background-color: #EDEDED;
          color: #222222;
          font-family: ${vars.fonts.familySecondary};
          font-size: 16px;
          font-weight: 700;
          line-height: 22.4px;
        }

        tr.dlinfo,
        td.dlinfo {
          text-align: left;
          border-left: solid #616161 1px;
          border-top: solid #616161 1px;
          padding: 16px 12px;
          font-family: ${vars.fonts.familySecondary};
          font-size: 16px;
          font-weight: 400;
          line-height: 22.4px;
          color:#222222;
          background-color:#fff;
          width:50%;
        }

        td.dlinfo:first-of-type,
        td.dlheader:first-of-type {
          border-left: none;
        }

        td.dlheader:first-of-type {
          border-radius: 8px 0 0 0;
        }

        td.dlheader:last-child {
          border-radius: 0 8px 0 0;
        }
        .dlinfo:first-of-type {
          border-bottom-left-radius: 8px;
        }
        .dlinfo:last-child {
          border-bottom-right-radius: 8px;
        }
        .dlheader:first-of-type {
          border-left: none;
        }
      `,
    //EDWrapper - estimated Delivery Wrapper
    EDWrapper: `
      .mt-16 {
        margin-top: 16px;
      }

      .mt_mb-16 {
        margin: 16px 0;
      }

      .left-aligned {
        display: flex;
        gap: 16px;
      }

      .wrapper-container {
        border-radius: 8px;
        background: ${vars.colors.primary3};
        padding:16px;
        font-family: ${vars.fonts.familySecondary};
        color:${vars.colors.primary8};
        margin-bottom:16px;
        width: 100%;

        ${vars.breakpoints.sm}{
          width: 100%;
        }
        ${vars.breakpoints.md}{
          max-width: 284px;
        }
        ${vars.breakpoints.lg}{
          max-width: 378px;
        }
        ${vars.breakpoints.xl}{
          max-width: 397px;
        }
        ${vars.breakpoints.xxl}{
          max-width: 402px;
          padding:24px;
        }

        p,h4 {
          margin:0;
          padding:0
        }

        .delivery-text {
          margin-left:12px;
          margin-bottom: 8px;
          font-family: ${vars.fonts.familySecondary};
          h4 {
            font-family: ${vars.fonts.familySecondary};
          }
          h4, p, span {
            font-size: 12px;
            font-weight: ${vars.fonts.weightMediumPlus};
            line-height: 15.6px;
          }
          p {
            font-weight: 400;
          }

            ${vars.breakpoints.sm}{
              h4 {
                font-size: 16px;
                font-weight: 700;
                line-height: 22.4px;
              }

              p {
                font-size: 14px;
                line-height: 18.2px;
              }
            }
            ${vars.breakpoints.md}{
              h4, p {
                font-size: 12px;
                font-weight: 700;
                line-height: 15.6px;
              }
            }
            ${vars.breakpoints.lg}{
              h4 {
                font-size: 14px;
                font-weight: 700;
                line-height: 18.2px;
              }
              p {
                font-weight: 400;
                font-size: 14px;
                line-height: 18.2px;
              }
            }

            ${vars.breakpoints.xl}{
              h4 {
                font-size: 16px;
                font-weight: 700;
                line-height: 22.4px;
              }

              p {
                font-size: 14px;
                line-height: 18.2px;
              }
            }

          p {
            font-weight: 400;
            margin-top: 8px;
          }

          .presell-item {
            text-align: left;
            line-height:18.2px;
          }
        }

        .delivery-info {
          display:flex;
          margin-bottom:12px;
        }

        .delivery-truck {
          margin-right: 12px;
          ${vars.icons['truck']}
          :before {
            ${vars.mixins.iconBase}
            font-size: ${vars.fonts.lineHeightDefault};
          }
        }

        .info-cicle {
          margin-right: 8px;
          ${vars.icons['information-circle']}
          :before {
            ${vars.mixins.iconBase}
            font-size: ${vars.fonts.lineHeightDefault};
          }
        }

        .other-info {
          display:flex;
          border-radius: 4px;
          padding: 12px 16px;
          background: #FFFFFF;
        }

        .align-end {
          justify-self: end;
          white-space: nowrap;
        }

        .order-total {
          margin:16px 0;
          display: grid;
          grid-template-columns: 2fr 1fr;
        }

        .order-detail, .save-on-order, .unlimited-save, .subscribe-save {
          font-weight: 400;
          margin:16px 0;
          display: grid;
          grid-template-columns: 2fr 1fr;
          font-size: 16px !important;
          line-height: 22px;
        }

          .deliveryWrapper {
            display: contents;
          }

          .deliveryWrapperEmptyBasket {
            display: block;
          }
          .payment-checkout {
            ${vars.breakpoints.sm}{
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-template-areas: "payment-options button";
              align-items: center;
            }
            ${vars.breakpoints.md}{
              display: block ;
            }

            span {
              display:inline;
              margin-right : 8px;
              ${vars.breakpoints.sm}{
                display:none;
              }
              ${vars.breakpoints.xl}{
                display:inline;
              }
              ${vars.breakpoints.xxl}{
                display:none;
              }
            }
        }

        button {
          grid-area: button;
          width:100%;
          font-weight: ${vars.fonts.weightMediumPlus};
          text-transform: uppercase;
          letter-spacing: 0.02em;


          ${vars.breakpoints.sm}{
            width:auto;
            font-size: 12px;
            line-height: 14px;
          }

          ${vars.breakpoints.md}{
            width:100%;
          }
        }
        .gst-text {
          display: inline-block;
          color: ${vars.colors.grey60};
          font-family: ${vars.fonts.familySecondary};
          font-size: 12px;
          font-weight: ${vars.fonts.weightNormal};
          line-height: 15.6px;
          margin-top: 16px;
          text-transform: none;
        }

        .empty-basket .payment-options {
          display:flex;
          justify-content: space-around;
        }

        .payment-options {
          padding: 0 24px;
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          text-align: center;
          gap: 8px;
          justify-items: center;

          ${vars.breakpoints.sm}{
            grid-area: payment-options;
            padding:0;
          }

          ${vars.breakpoints.md}{
            display:flex;
            justify-content: center;
            padding:0 24px;
          }

          ${vars.breakpoints.xl}{
            display:flex;
            justify-content: center;
            padding:0 14px;
          }

          ${vars.breakpoints.xxl}{
            gap: 22.8px;
          }
        }

        .information-text {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.37rem;
          margin-left:8px;
        }

        .save-on-order, .subscribe-save {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.37rem;
          color: ${vars.colors.textPrimaryBg};
        }
        
        .unlimited-save {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.37rem;
          color: ${vars.colors.textUnlimitedPrice};
        }
        .coupon-text {
          font-size: 14px;
          line-height: 18.2px;
          font-weight: 700;
          margin-left:8px;
          ${vars.breakpoints.sm}{
            font-size: 16px;
            line-height: 22.4px;
          }

          ${vars.breakpoints.md}{
            font-size: 14px;
            line-height: 18.2px;
          }

          ${vars.breakpoints.xl}{
            font-size: 16px;
            line-height: 22.4px;
          }
        }

        .sub {
          font-weight: 400;
          margin-top:4px;
        }
      }

      .reffered {
        font-family: ${vars.fonts.familySecondary};
        font-size: 14px;
        font-weight: ${vars.fonts.weightMediumPlus};
        line-height: 16px;
        text-align: center;
        margin: 24px 44.5px;
        display: block;
        text-underline-offset: 4px;
        a {
          text-decoration: underline;
        }
      }
      `,
    deliveryCardWrapper: `
        position: fixed;
        bottom: -16px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 300;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease-in-out;
        background-color: ${vars.colors.primary};
        color: ${vars.colors.white};
        border: none;
        cursor: pointer;
        width: 100%;
        &.show-button {
          opacity: 1;
          visibility: visible;
        }
        .secondary-checkout-button {
          padding: 16px 32px;
          border-radius: 0;
          border-top: 1px solid #BCBCBC;
        }
        .mt-0 {
          margin-top: 0px !important;
        }
        ${vars.breakpoints.sm}{
          display: none;
        }
      `,
    cartDetailSection: `
        width: 100%;
        margin-top: 16px;
        ${vars.breakpoints.sm}{
          margin-top: 0;
          width: 50%;
        }
        .info-message {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #004d86;
          margin-bottom: 8px;
          font-weight: ${vars.fonts.weightMediumPlus};
          font-size: 14px;
          line-height: 16px;
        }
        .out-of-stock-error{
          color: ${vars.colors.primary8};
          padding: 8px;
          border-radius: 4px;
          background-color: ${(vars.colors.bgColors as unknown as { [key: string]: string }).lwlightError};
        }
          .count-disqualified-error{
            flex-direction: column;
            align-items: flex-start;
            & > div {
              margin: 0px;
            }
          }
        .cart-item-error{
          background-color: ${(vars.colors.bgColors as unknown as { [key: string]: string }).whiteWine};
          color: ${vars.colors.primary8};
          padding: 8px;
          border-radius: 4px;
        }
        .mix-match {
          color: ${vars.colors.savingsTextColor};
          text-transform: uppercase;
          svg path {
            fill: ${vars.colors.savingsTextColor};
          }
        }
        .member-price-savings {
          color: ${vars.colors.textUnlimitedPrice}
        }
        .product-link {
          text-align: left;
          margin-bottom: 8px;
          display: inline-block;
          margin-bottom: 8px !important;
        }
        .num-bottles {
          font-size: 16px;
          line-height: 22px;
          color: ${vars.colors.grey60};
          margin-bottom: 8px;
        }
      `,
    orderSummaryContainer: `
        max-width: 1396px;
        margin: auto;

        .order-summary {
          &.is-sticky {
            position: sticky;
            top: 114px;
            height: fit-content;
          }
        }

        .order-summary-quickOrder {
          display: flex;
          flex-direction: column-reverse;
          ${vars.breakpoints.sm} {
            flex-direction: column;
          }
        }

        .continue-shopping {
          margin: 14px 0;
          a {
            text-decoration: underline;
            margin-left:8px;
            text-transform: uppercase;
            font-weight: ${vars.fonts.weightMediumPlus};
          }
        }

        .cart-head {
          padding:0 16px;
          margin-bottom:24px;
          ${vars.breakpoints.sm}{
            margin-bottom:32px;
            text-align: center;
          }
          .heading {
            margin: 0 0 8px 0;
          }
        }

        .sub-heading {
          margin-bottom: 24px;
          font-weight:400;
          font-size:16px;
          line-height:22.4px;
        }



        .cart-area {
          display: grid;
          width: 100%;
          max-width: 1396px;
          margin: auto;
          gap: 24px;
          grid-template-rows: auto auto;

          ${vars.breakpoints.sm}{
            grid-template-rows: 1fr auto;
          }
          ${vars.breakpoints.md}{
            grid-template-columns: 1fr 284px;
          }
          ${vars.breakpoints.lg}{
            grid-template-columns: 1fr 378px;
          }
          ${vars.breakpoints.xl}{
            grid-template-columns: 1fr 397px;
          }
          ${vars.breakpoints.xxl}{
            grid-template-columns: 1fr 402px;
            gap: 24px;
          }
        }
        .cart-area.hide-order-summary{
          ${vars.breakpoints.md}{
            grid-template-columns: 1fr auto;
          }
          ${vars.breakpoints.lg}{
            grid-template-columns: 1fr auto;
          }
          ${vars.breakpoints.xl}{
            grid-template-columns: 1fr auto;
          }
          ${vars.breakpoints.xxl}{
            grid-template-columns: 1fr auto;
          }
        }

        .container {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          align-content: space-around;

          ${vars.breakpoints.xs}{
            margin-left: 16px;
            margin-right: 16px;
          }

          ${vars.breakpoints.sm}{
            margin-left: 32px;
            margin-right: 32px;
          }

          ${vars.breakpoints.md}{
            // display: flex;
            // flex-direction: row;
            // justify-content: center;
            margin-left: 32px;
            margin-right: 32px;
          }

          ${vars.breakpoints.lg}{
            margin-left: 72px;
            margin-right: 72px;
          }

          ${vars.breakpoints.xl}{
            margin-left: 72px;
            margin-right: 72px;
          }

          .product-area {
            width: 100% !important;
            padding:0 16px;
            ${vars.breakpoints.sm}{
              width: 100%;
            }
            ${vars.breakpoints.md}{
              width: 581px;
            }
            ${vars.breakpoints.lg}{
              width: 848px;
            }
            ${vars.breakpoints.xl}{
              width: 913px;
            }
            ${vars.breakpoints.xxl}{
              width: 970px;
            }
          }
        }

        .empty-basket {
          text-align:center;
          padding: 16px 16px 24px 16px;
          a {
            text-transform: uppercase;
            text-decoration:underline;
            letter-spacing: 0.02em;
            display: inline-block;
            width: 100%;
            ${vars.breakpoints.md}{
              width: auto;
            }
          }

          i {
              margin-bottom:16px;
            }
        }
      `,
    trustContainer: `
      &.reassurance-carousel {
        border: 1px #bcbcbc solid;
        border-radius: 8px;
      }

      .trust-container {
        .date-rating .star-icons {
          display: flex;
          gap: 4px;
        }
        .date-rating .star-icons svg {
          width: 16px;
          height: 16px;
        }
        
        padding:16px;
        text-align:center;
        font-family:  ${vars.fonts.familySecondary};
        color:${vars.colors.primary8};

        .money-back-gaurantee {
          padding: 12.6px 4.57px;
        }

        .title {
          font-family:  ${vars.fonts.familySecondary};
          font-size: 16px;
          line-height: 22.4px;
          font-weight: 700;
          text-align: center;
          margin: 12px 0 0;
          ${vars.breakpoints.sm}{
            font-size: 18px;
            line-height: 23px;
          }
        }

        .innerWrapper {
          padding:16px;
          margin-top:16px;
        }

        .reviewer {
          font-size: 12px;
          font-weight: ${vars.fonts.weightMediumPlus};
          line-height: 17px;
          text-align: left;
          color:${vars.colors.grey60};
          text-transform: uppercase;
        }

        .description {
          margin:16px 0 ;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-align: center;
        }

        .reviewDate {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
          margin-left:16px
        }

        .date-rating {
          display: inline-flex;
        }
      }

      .slick-list .slick-track {
        display:flex !important;
      }
      .slick-list .slick-track .slick-slide {
        height: inherit !important
      }

      .slick-slider .slick-list {
        max-width: calc(100vw - 32px);
        margin: 0;

          ${vars.breakpoints.sm}{
            max-width:704px;
          }
          ${vars.breakpoints.md}{
            max-width:284px;
          }
          ${vars.breakpoints.lg}{
            max-width:378px;
          }
          ${vars.breakpoints.xl}{
            max-width:397px;
          }
          ${vars.breakpoints.xxl}{
            max-width:402px;
          }
      }
      `,
    productItemsTrending: `
        .auc-Recommend .auc-Recommend-title {
          margin: 16px 0;
        }
      }
      `,
    Pill: `
        padding: 2px 12px;
        background: ${vars.colors.primary1};
        border-radius: 16px;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        display: inline-flex;
        align-items: center;
        margin-top: 12px;
        span {
          font-size: 16px;
          font-weight: 700;
          line-height: 20.8px;
        }
        ${vars.breakpoints.sm}{
          &.absolute{
            position: absolute;
            bottom: 0px;
            right: -4px;
          }
        }
      `,
    MiniCart: `
          z-index: 16777216;
          position:relative;

        .mantine-Modal-inner {
          padding:0;
          justify-content: end;
          bottom:0;
        }

        .mantine-Modal-content {
          border-radius: 0;
          min-height: 100vh;
          min-width:100vw;
          ${vars.breakpoints.md}{
            max-width:482px;
            min-width:auto;
          }
          .mantine-Modal-header {
            padding: 21.5px 32px ;
              ${vars.breakpoints.sm}{
                padding: 21px 32px ;
              }
            border-bottom:1px solid #BCBCBC;
          }
          .mantine-Modal-close {
            outline: 0;
            color: ${vars.colors.primary8};
            width: auto;
            svg {
              width: 32px !important;
              height: 32px !important;
            }
          }

          .mini-cart-title {
            font-size:20px;
            line-height:24px;
            font-weight:500;
            color: ${vars.colors.primary8};
            font-family: ${vars.fonts.familyTertiary};
            margin:18px 0;
            ${vars.breakpoints.md} {
              font-size:18px;
              line-height:23.5px;
            }
          }
        }

        .mantine-Modal-title {
          margin-bottom:0 !important;
        }
        .mantine-Modal-body {
          padding:0;
          padding: 0 16px !important;
          ${vars.breakpoints.sm}{
            padding: 0 32px 32px 32px !important;
          }
        }

        .mini-cart-header {
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          gap: 8px;
          i {
            color:#006E1A;
          }
          h3{
            margin: auto;
          }
        }

        .wrapper {
          .product-image , .product-image> div{
            min-width: 164px;
            height: 164px;
            margin: auto 0;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            ${vars.breakpoints.sm} {
              min-width: 254px;
              height: 254px;
            }

            ${vars.breakpoints.md} {
              min-width: 216px;
              height: 216px;
            }
          }
        }

        .wrapper {
          display:flex;
          align-items: center;
          margin:24px 0;
          gap:16px;
          h2 {
            margin:0;
          }

          .product-image {
            position:relative;
          }
        }

        .product-great-pick{
          color: ${vars.colors.grey60};
          margin: 8px 0 !important;
          text-align:left;
        }

        .product-detail-text {
          text-align: left;
          color: ${vars.colors.primary8};
          .checkout-item-qty {
            color:#006E1A;
          }
          h5 {
            margin-bottom: 8px !important;
          }
        }

        .price-section {
          display:flex;
          gap:4px;
          align-items: center;
          padding: 0 0 8px 0;
          margin-bottom: 0px;
        }

        .price-section h6 {
          margin: 0;
          font-family: ${vars.fonts.familyTertiary};
        }

        .price-section .strike {
          ${vars.mixins.h7}
          color:${vars.colors.grey60};
          text-decoration-line: line-through;
        }

        .price-section .strike+.sale-price {
          color: ${vars.colors.primary1}
        }

        .price-section .strike+.member-price {
          color:${vars.colors.secondary4};
        }

        .member-savings {
          font-size: 14px;
          font-weight: ${vars.fonts.weightMediumPlus};
          line-height: 16px;
          color: ${vars.colors.secondary4};
          letter-spacing: 0.02em;
          margin: 0px;
        }

        .case-details {
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          color: #20513B;
        }

        .num-bottles {
          font-size: 16px;
          font-weight: 400;
          line-height: 22.4px;
          text-align: left;
          margin-bottom: 8px;
          color:${vars.colors.grey60};
        }

        .light-grey {
          color: ${vars.colors.grey60};
        }

        .gift-tablet {
        display: none;
          ${vars.breakpoints.sm}{
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 12px;
          }
          ${vars.breakpoints.md}{
            display: none;
          }
        }

        .gift-other-device {
          display: flex;
          gap: 8px;
          align-items: center;
          padding: 12px;
          ${vars.breakpoints.sm}{
            display:none;
          }
          ${vars.breakpoints.md}{
            display: flex;
            gap: 8px;
            align-items: center;
            padding: 12px;
          }
        }

        .gift-other-device h4, .gift-tablet h4 {
          margin-bottom:0px;
          text-transform: none;
        }

        h4 {
          font-size: 16px;
          font-weight: 400;
          margin:0;
          line-height: 22.4px;
          font-family: ${vars.fonts.familySecondary};
          color:${vars.colors.primary8};
          .cart-link {
            font-weight: 500;
            line-height: 19.2px;
            text-decoration:underline;
            margin-left: 8px;
            float:none;
            font-size: 16px;
          }

          .cart-link:hover, a:hover {
            text-decoration: none;
          }
        }

        .button-container {
          display:flex;
          gap:16px;
          flex-direction: column;
          padding-top: 24px;
          ${vars.breakpoints.sm}{
            flex-direction: row;
            margin-bottom:32px;
          }
          ${vars.breakpoints.md}{
            flex-direction: column;
          }
          a, button {
            width:100%;
            text-decoration: none;
          }

          button {
            font-weight: ${vars.fonts.weightMediumPlus};
            cursor:pointer;
            letter-spacing: 0.02em;
            height:44px;
            display:flex;
            align-items: center;
            justify-content: space-around;
          }
        }

        .continue-shopping {
          display: grid;
          margin-bottom: 24px;
          justify-content: center;
        }

        .continue-shopping-text {
          text-decoration: underline;
          letter-spacing: 0.02em;
          line-height:16px;
          margin-left: 8px;
          text-transform: uppercase;
          font-weight: ${vars.fonts.weightMediumPlus};
          margin: 12px;
          transition: text-decoration 0.3s ease; /* Smooth transition for text-decoration */
          cursor: pointer;
        }
        .continue-shopping .continue-shopping-text:hover {
          text-decoration: none; /* Remove underline on hover */
        }

        .tax{
          text-align: center;
          & .tax-info{
            display: inline-block;
            color: ${vars.colors.grey60};
            font-family: ${vars.fonts.familySecondary};
            font-size: 12px;
            font-weight: ${vars.fonts.weightNormal};
            line-height: 15.6px;
            text-align: center;
            text-transform: none;
          }
        }

        .desktop {
          display:none;
          ${vars.breakpoints.sm}{
            display:grid;
          }
        }

        .mobile {
          display:grid;
          margin: 16px 0;
          ${vars.breakpoints.sm}{
            display:none;
          }
        }

        @keyframes slideInFromRight {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(0);
          }
        }
        @keyframes slideOutToRight {
          from {
            transform: translateX(0);
          }
          to {
            transform: translateX(100%);
          }
        }
      `,
    CartItemWrapper: `
        display: flex;
        margin-bottom: 16px;
        border-bottom: 1px dashed #bcbcbc;
        padding-bottom: 16px;
        min-height: 252px;
        gap: 24px;
        position: relative;

        .product-image , .product-image > div:first-child{
            width: 90px;
            height: 90px;
            margin: auto 0;
            position: relative;
            align-self: center;
            text-align: center;
            display: flex;
            flex-direction: column;
            ${vars.breakpoints.sm}{
              display: inline-block;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            ${vars.breakpoints.sm} {
              width: 164px;
              height: 141px;
            }

            ${vars.breakpoints.md} {
              width: 228px;
              height: 195px;
            }

            ${vars.breakpoints.lg} {
              width: 181px;
              height: 154px;
            }

            ${vars.breakpoints.xl} {
              width: 190px;
              height: 161px;
            }

            ${vars.breakpoints.xxl} {
              width: 260px;
              height: 220px;
            }
          }
          
        .vpp-price {
          font-size: 14px;
          font-weight: 400;
          line-height: 18.2px;
          text-align: center;
          color: ${vars.colors.grey60};
        }
        
        .member-price {
          color : ${vars.colors.textUnlimitedPrice};
        }
        .savings-price {
          color: ${vars.colors.savingsTextColor};
        }
        
        .full-price {
          font-size: 16px;
          line-height: 22.4px;
          text-align: center;
          color: ${vars.colors.grey60};
          text-decoration: line-through;
        }

        .product-details {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          margin: 0 0 0 8px;
          cursor: inherit;
          flex: 1;
        }

        .product-delete {
          display: flex;
          flex-direction: row;
          justify-content: left;
          align-items: center;
          width: 100%;
          margin: 24px 0;
          cursor: pointer;
          max-width: max-content;
          &-text {
            text-align: center;
            text-decoration: underline;
            text-transform: uppercase;
            margin-left: 4px;
          }
        }
        
        .button-link {
          background: none;
          border: none;
          padding: 0;
        }
      `,
    cartError: `
        display: flex;
        align-items: center;
        gap: 8px;
        color: #222222;
        margin-bottom: 24px;
        padding: 12px;
        border-radius: 4px;
        background: ${(vars.colors.bgColors as unknown as { [key: string]: string }).lwlightError};
      `,
    CartInfo: `
        display: flex;
        align-items: center;
        gap: 8px;
        color: ${vars.colors.warning};
        margin-bottom: 24px;
        padding: 12px;
        border-radius: 4px;
        background: ${vars.colors.warningLight};
      `,
    CartCounter: `
        .qty-counter {
          display: flex;
          justify-content: center;
          align-items: center;
          height: max-content;
          padding: 8px;
          border-radius: 4px;
        }
        .btn {
          background: none;
          min-width: 32px;
          min-height: 32px;
          border: 1px solid ${vars.colors.grey50};
          border-radius: 50%;
          padding: 0;
          color: ${vars.colors.grey50};
        }
        .btn.disabled {
          border: 1px solid ${vars.colors.grey20};
          color: ${vars.colors.grey20};
        }

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }

        input {
          font-size: 16px;
          max-width: 40px;
          min-width: 40px;
          text-align: center;
          border: 0;
          margin: 0;
          padding: 0;
          outline: 0;
        }
      `,
    abandonModalOverlayWrapper: `
      .mantine-Modal-overlay {
        z-index: 500 !important;
      }

      .mantine-Modal-inner {
        z-index: 500 !important;
      }
    `,
    abandonModalOverlay: `
        .mantine-Modal {
          &-content {
            display: flex;
            flex-direction: column;
            gap: 32px;
            color: ${vars.colors.primary8};
            padding-bottom: 0 !important;
            font-family: ${vars.fonts.familySecondary};
            font-size: 16px;
            padding: 22px 0;
          }
        }
        .modal-action {
          min-width: 324px;
          font-weight: ${vars.fonts.weightMediumPlus};
          align-self: end;
          cursor: pointer;
          width: 100%;
          ${vars.breakpoints.sm} {
            width: auto;
          }
        }
      `,
    subscribeSaveContainer: `
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
        border-bottom: 1px solid ${vars.colors.secondary9};
        padding-bottom: 16px;

        ${vars.breakpoints.md} {
          flex-direction: row;
          gap: 48px;
        }

        .subscribe-save-image {
          max-width: 148px;
          max-height: 24px;
          ${vars.breakpoints.sm} {
            max-width: 220px;
            max-height: 35px;
          }
        }

        .subscribe-save-content {
          font-family: ${vars.fonts.familySecondary};
          color: ${vars.colors.primary8};
          font-size: ${vars.fonts.sizeSmall};
          line-height: 14px;
          font-weight: ${vars.fonts.weightMedium};
          display: flex;
          gap: 8px;
          flex-direction: row;
          text-align: center;
          ${vars.breakpoints.md} {
            font-size: ${vars.fonts.sizeH4};
            line-height: 19.2px;
            flex-direction: column;
          }

          .subscribe-save-link {
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
      `,
    subscribeSavePopup: `
        .mantine-Paper-root {
          padding: 0 32px 32px;
          background: ${vars.colors.primary3};
          border-radius: 12px;
        }

        .mantine-Modal-header {
          padding: 32px 0 0;
          background-color: inherit;
          margin-bottom: 23px;

          .mantine-CloseButton-root {
            width: auto;
            height: auto;
            outline: none;
            svg {
              width: 32px !important;
              height: 32px !important;
            }
          }
        }

        .mantine-Modal-title {
          margin-bottom: 0 !important;
        }

        .body1.body-paragraph {
          margin-bottom: 26px !important;
        }

        .mantine-Accordion-control {
          &:hover {
            background-color: inherit;
          }
        }

        .mantine-Modal-body h5 {
          margin-top: 16px;
        }

        .mantine-Modal-body,
        .mantine-Accordion-control {
          padding: 0;
        }

        .mantine-Accordion-content {
          padding: 0;
          padding-bottom: 16px;
          color: ${vars.colors.grey60};
        }

        .mantine-Accordion-panel {
          margin: 0 !important;
        }

        .accordion-body {
          margin: 0 !important;
          padding: 8px 0;
        }

        .mantine-Accordion-chevron i {
          font-size: ${vars.fonts.sizeH3};
          color: ${vars.colors.grey50};
        }

        .accordion-wrapper {
          margin-bottom: 32px;
        }

        .button-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column-reverse;
          gap: 16px;
          ${vars.breakpoints.sm} {
            flex-direction: row;
            gap: 0;
          }

          button {
            cursor: pointer;
            span {
              font-weight: ${vars.fonts.weightMediumPlus};
            }
          }
        }
      `,
    quickOrder: `
        padding: 24px;
        background: ${vars.colors.primary3};
        border-radius: 8px;
        margin: 0 0 16px 0;

        .field-container label {
          color: ${vars.colors.secondary15} !important;
        }

        .field-container input:focus + label {
            visibility: hidden;
            opacity: 0;
            transition: opacity 0.3s ease;
        }

        .field-container input:not(:placeholder-shown)~label {
          visibility: hidden;
        }

        .itemCode {
          width: 100%;
          height: 44px;
          padding: 11px 16px 11px 16px;
          border-radius: 5px;
          border: 1px solid ${vars.colors.secondary9};
          background:  #FFFFFF;
          :focus-visible {
             border: 1px solid ${vars.colors.primary8};
             outline: 0;
          }
        }

        .info-message {
          display: inline-flex;
          gap: 8px;
          align-items: center;
          ${vars.mixins.body2};
          margin:16px 0 0;
        }

        .quickAddButton {
          .cart-button {
            margin-top: 16px;
            button {
              display: flex;
              width: 100%;
              height: 44px;
              padding: 12px 24px 12px 24px;
              border-radius: 4px;
              border: 1px solid ${vars.colors.primary8};
              align-items: center;
              justify-content: space-around;
            }
          }
        }
      `,
  },
  WinePlanCarousel: {
    controls: `
        width: 100%;
        margin-bottom: 10px;
        top: 90px;
           ${vars.breakpoints.sm} {
              top: calc(50% - 3rem);
           }
      `,
    control: `
        height: 36px;
        width: 36px;
      `,
    viewport: `
        padding-bottom: 24px;
      `,
    indicators: `
        bottom: 5px;
      `,
    indicator: `
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background: #BCBCBC;
        opacity: 1;
        &[data-active] {
          background: ${vars.colors.grey50};
        }
      `,
  },
  Membership: {
    wrapper: `
        & .accordion-navigation{
          width:200px;
          span {
            padding: 0;
          }
        & .accordion-panel {
          background-color: #FCFBF9;
          margin-top: 10px;
          padding: 10px 2px 2px 20px;
        }
        & .mantine-Accordion-item{
            width: 100%;
            border:none;
            margin-bottom: 10px;
            font-size:14px;
            color: #333333;
            margin-left: 4px;
            margin-top: 4px;
            margin-bottom: 10px;
            text-wrap: nowrap;
            gap: 10px;
          & button{
            padding: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
        }
      }
      margin-left: 0;
      margin-right: 0;
      background: ${vars.colors.white};
      display:flex;
        .membership-layout {
          width:100%;
          padding: 10px;
          margin-left:0px;
          ${vars.breakpoints.xs} {
            padding:20px;
          }

        .error-message{
          padding: 0 25px 15px 0;
        }
        .init-loader{
          display:flex;
          margin-left: 45%;
          margin-top: 20%;
        }
        .loader{
          position: fixed;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 9999;
          &.full-page-loader{
            background-color: rgb(0 0 0 / 36%);
          }
        }
        .card-container {
          display:flex;
          gap: 24px;
          flex-wrap: wrap;
          flex-direction: row;


          .cancel-membership {
            width: 100%;
            display:flex;
            font-size:14px;
            font-weight: ${vars.fonts.weightNormal};
            margin-bottom:0;
            align-items:center;
            .cancel-membership-checkbox{
              appearance: none;

              width: 19px;
              height: 19px;
              box-shadow: none;
              border-radius: 4px;
              border: 1px solid;
              color: ${vars.colors.black};

              margin-right:10px;
              cursor:pointer;
              &:checked{
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Checkbox'%3E%3Crect width='18' height='18' fill='var(--checkbox-bg-color, ${vars.colors.svgCheckboxBg})'/%3E%3Cpath id='Vector' d='M14 5L7.125 12L4 8.81818' stroke='var(--checkbox-stroke-color, ${vars.colors.svgCheckboxStroke})' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
                color: transparent;
              }
              &.disable{
                pointer-events: none;
                cursor: not-allowed;
              }
            }
            .cancel-membership-text{
              width:100%;
            }
          }

          .update-visible {
            display: block;
          }

          .update-hidden {
            display: none;
          }
          

          .membership-card {
            width:420px;
            height:260px;
            background:white;
            padding:24px;
            display:flex;
            flex-direction:column;
            border: 1px solid ${vars.colors.secondary9};
            border-radius: 4px;
            h2{
                  margin-top: 0;
                }
            h3{
              font-family: ${vars.fonts.familySecondary};
              text-transform: none;
            }
            .card-heading{
              font-size: 22px;
              ${vars.breakpoints.lg} {
                font-size:28px;
              }
              font-family: ${vars.fonts.familyTertiary};
              font-weight: ${vars.fonts.weightNormal};

            }

            .cancel-save-offer-cta {
              text-decoration:underline;
              margin: auto;
            }

            .card-details{
              display:flex;
              align-items:center;
              .card-details-number{
                padding-left:10px;
              }

            }
            .membership-status{
            font-size:40px;
            font-family: ${vars.fonts.familyPrimary};
            color:${vars.colors.primary1};
            text-transform:uppercase;
            line-height: 52px;
            margin:auto;

            }
            .change-payment-card{
              display:flex;
              font-size:16px;
              font-weight: ${vars.fonts.weightNormal};
              margin:auto;
              margin-bottom:0;
              align-items:center;

            }
            .cancel-membership.disable{
              cursor: not-allowed;
            }

            .enroll-data{
              display:grid;
              grid-template-columns: 0.7fr 1fr;

              h3{
                font-family: ${vars.fonts.familySecondary};
                font-weight:${vars.fonts.weightNormal};
                font-size:16px;
                line-height: 24px;
              }
              .date{
                font-weight: ${vars.fonts.weightBold};
                padding-left: 10px;
              }
            }

            .last-payment-row{
              display:grid;
              grid-template-columns: 0.7fr 1fr;

              h3{
                font-family: ${vars.fonts.familySecondary};
                font-weight:${vars.fonts.weightNormal};
                font-size:16px;
                line-height: 24px;
              }
              .payment-date{
              font-size:16px;
              font-weight:${vars.fonts.weightBold};
              padding-left: 10px;
              .loaderContainer{
                justify-content: start;
                div{
                  width: 21px;
                  height: 21px;
                }
              }
              }
            }

          }
          .saving{
            font-family: ${vars.fonts.familyTertiary};
            align-items:center;
            text-align: center;
            .saving-price{
              padding:20px;
              display: grid;


            .card-heading{
              margin:0;
              font-family: ${vars.fonts.familyPrimary};
              font-weight: ${vars.fonts.weightNormal};
              font-size: 50px;
              ${vars.breakpoints.md} {
                font-size: 44px;
                }
              ${vars.breakpoints.lg} {
              font-size: 60px;
              }
              color:${vars.colors.primary1};
              }
              .year-note{
                font-size: 18px;
                font-family: ${vars.fonts.familyTertiary};
              }
              color: ${vars.colors.grey60};
            }
            .static-data{
              font-family: ${vars.fonts.familyTertiary};

              font-weight: ${vars.fonts.weightMedium};
              font-size: 18px;
              color: ${vars.colors.grey60};
              padding: 20px 0px;
            }
            .static-note{
              display: grid;
              font-family: ${vars.fonts.familySecondary};
              font-weight: ${vars.fonts.weightNormal};
              font-size: ${vars.fonts.sizeLarge};
              color: ${vars.colors.black};
              margin: auto;
              margin-bottom: 20px;
              font-weight: ${vars.fonts.weightNormal};
              font-size: ${vars.fonts.sizeLarge};
              line-height: 22.4px;
            }
          }
          .faq-card{
            width:100%;
            ${vars.breakpoints.xl} {
              width:854px;
            }
            height:auto;
            display:flex;
            flex-direction: row;
            .left-faq{
              width: 40%;
              .view-all-faq{
                font-size:16px;
                margin-top: 10%;
                ${vars.breakpoints.xs} {
                  padding:2px 5px;
                  font-size:14px;
                }
                ${vars.breakpoints.sm} {
                  padding:10px;
                }
                ${vars.breakpoints.md} {
                  padding: 7.5px 22px;
                }

              }
            }
          .right-faq{
            width: 60%;
          }
            .faq-question{
              margin-bottom:24px;
              text-decoration:underline;
              display:block;
              font-weight: ${vars.fonts.weightNormal};
              line-height:22px;
              font-size: ${vars.fonts.sizeLarge};
            &.active{
              font-weight: ${vars.fonts.weightBold};
            }
            }
            .faq-question-right.heading{
              margin-top:12%;
              margin-bottom:24px;
              font-weight: ${vars.fonts.weightBold};
            }
            .vertical-line {
              width: 1px;
              background-color: ${vars.colors.grey20}; /* Adjust the color as needed */
              margin: 5% 16px 10% 16px;
            }

          }
          // footer banner
        .footer-banner{
          width: 100%;
          & div{
            margin:0;
          }
          & .description{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          & .banner-button{
            bottom: 0;
            margin-bottom: 18px;
            position:absolute;
          }
        }
        }
      }
      .bread-crumb-list{
        display:flex;
        & a{
          font-size:11px;
          ${vars.breakpoints.sm} {
            font-size:16px;
          }
        }
        .slash{
          padding:12px;
        }
      }
      .title-icon{
        display:flex;
        justify-content:center;
      }
      .hub-page-no-plan{
        width:80%;
        h1{
          margin-left:10px;
        }
        h1{
          font-size: 40px;
        }
        .no-plan-message{
          background:${vars.colors.specialLight};;
          padding: 10px;
          margin:10px;
        }
    }
      `,
    Sidebar: `
      background:white;
      padding-left: 5%;
      display:none;
      ${vars.breakpoints.md} {
       display:flex;
       }
        .membership-sidebar{
          background: white;
          padding-left:40px;
          height:100%;

          & div{
            margin:0;
            padding:0;
          }

          & .navigation-link{
            ${vars.breakpoints.xs} {
                  width:260px;
            }
            > h4{
              font-size:28px;
              font-family: ${vars.fonts.familyTertiary};
                // margin-bottom: 24px;
                color:${vars.colors.primary1};
                margin-bottom: ${vars.space.lg};
                margin-top: ${vars.space.lg};
            }
            & a{
              font-size:18px;
              margin-bottom:18px;
              line-height:24px;
            }
          }
      }`,
    pageWrapper: `
        & .intro-text{
          ${vars.mixins.h5}
          font-size: ${vars.fonts.sizeLarger};
          text-align: center;
          margin-top: 16px;
          margin-bottom: 16px;
          line-height: 200%;
          ${vars.breakpoints.sm} {
            font-size: ${vars.fonts.sizeLarger};
          }
        }
        .top-section .widget-container > div {
          margin: 0;
        }
        .bottom-section{
          .widget-cms-column > div{
            margin: 0;
          }  
          .widget-cms-column{
            .banner-wrapper {
             height: 100%;
            }
            .banner {
              margin: 0;
              ${vars.breakpoints.md} {
                overflow: hidden;
              }     
              p span {
                font-size: 16px;
              }
              h3 span {            
                line-height: 29.4px;
                ${vars.breakpoints.md} {
                  line-height: 33px;
                } 
              }
              .banner-image-wrapper {
                order: 1;
                ${vars.breakpoints.md} {          
                  align-self: center;
                  order: 2;
                }
                ${vars.breakpoints.lg} {
                  max-height: 250px;
                }
              }
              .banner-content-wrapper {
                order: 2;
                ${vars.breakpoints.md} {
                  order: 1;
                }
              }
              .banner-bg-img {
                height: 115px;
                ${vars.breakpoints.sm} {
                  height: 250px;
                }
                ${vars.breakpoints.lg} {
                  height: 100%;
                }
              }
              .banner-button {
                padding: 0;
              }
              .banner-button a{
                padding: 7px 16px;
                min-width: 180px;
                font-weight: ${vars.fonts.weightMediumPlus};
                font-size: 12px;
                line-height: 14px;
                &:active, &:focus {
                  text-decoration: none;
                }
              }
              .cms-image-container {
                width: 100%;
                height: 100%;
                img {      
                 height: 100%;
                 object-fit: cover;
                }
              }
            }
            & .banner-info {
              ${vars.breakpoints.md} {
                 padding: 24px;
              }
              width: 100%;
              padding: 16px;
            }
            .banner-content {
              text-align: center;
              padding: 0;
              ${vars.breakpoints.sm} {
                text-align: initial;
                padding: initial;
              }
            }
          }     
          
        }
        .payment-method {
          .mantine-Accordion-item::after {
              content: "";
              left: -16px;
              right: -16px;
              height: 1px;
              background-color: ${vars.colors.secondary9};
              position: absolute;
              ${vars.breakpoints.sm} {
                left: 0px;
                right: 0px;
              }
          }     
        }
      `,
  },
  MembershipRebranding: {
    container: `
        & .no-plan-message{
          margin:40px 0;
          & p {
            font-family: ${vars.fonts.roboto};
            font-weight: ${vars.fonts.weightBold};
          }
        }

        .loader{
          position: fixed;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 9999;
          &.full-page-loader{
            background-color: rgb(0 0 0 / 36%);
          }
        }
      `,
    layout: `
        margin-top: 24px;
        ${vars.breakpoints.sm} {            
          margin-top: 40px;
        }
        & .membership-details{
          display: grid;
          grid-template-columns: 1fr;
          justify-content: center;
          gap: 16px;
          & > div{
            flex: 1;
          }
          ${vars.breakpoints.sm} {
            grid-template-columns: 1fr 1fr;
            gap: 24px;
          }
        }

        
        & .renew-layout {
            margin-bottom: 24px;
            ${vars.breakpoints.sm} {            
              margin-bottom: 40px;
            }
            .cancel-membership {
            width: 100%;
            display:flex;
            font-size:14px;
            font-weight: ${vars.fonts.weightNormal};
            margin-bottom:0;
            gap: 16px;
            align-items: flex-start;
            ${vars.breakpoints.sm} {                       
              align-items:center;
            }
            .cancel-membership-checkbox{
              appearance: none;
              width: 19px;
              height: 19px;
              box-shadow: none;
              border-radius: 4px;
              border: 1px solid;
              color: ${vars.colors.black};
              margin-right:10px;
              cursor:pointer;
              &:checked{
                background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Checkbox'%3E%3Crect width='18' height='18' fill='var(--checkbox-bg-color, ${vars.colors.svgCheckboxBg})'/%3E%3Cpath id='Vector' d='M14 5L7.125 12L4 8.81818' stroke='var(--checkbox-stroke-color, ${vars.colors.svgCheckboxStroke})' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
                color: transparent;
              }
              &.disable{
                pointer-events: none;
                cursor: not-allowed;
              }
            }
            .cancel-membership-text{
              width:100%;
              font-family: ${vars.fonts.roboto};
              font-weight: ${vars.fonts.weightNormal};
              font-size: ${vars.fonts.sizeH4};
              color: ${vars.colors.primary8};
            }
          }
          .update-visible {
            display: block;

            button.unlimited-modal-primary-cta {
              cursor: pointer;
              width: 100%;
              background-color: #117B53;
              color: ${vars.colors.btnsPrimaryColor};
              ${vars.breakpoints.sm} {
                width: auto;
              }

              :hover {
                background-color: #094B32;
                color: ${vars.colors.btnsPrimaryColor};
              }
              }
            }

          .update-hidden {
            display: none;
          }
        }
      `,
    membershipOffers: `
        border: 1px solid ${vars.colors.secondary15};
        padding-left: 24px;
        display: grid;
        grid-template-columns: 0.75fr 1fr;
        gap: 8px;
        .membership-offers {
          padding: 24px 0;
        }
        .membership-offers-subtitle {
          color: ${vars.colors.textUnlimitedBg};
          margin: 14px 0;
        }
        .offers-message {
          ${vars.mixins.body1};
          font-weight: ${vars.fonts.weightBold};
          margin-bottom: 14px;
          max-width: 75%;
        }
        button.shop-now-button {        
          cursor: pointer;
          padding: 7px 16px;
          min-width: 150px;
          font-weight: ${vars.fonts.weightMediumPlus};
          text-transform: uppercase;
          font-size: 12px;
          line-height: 14px;
        }
        .membership-image {
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          ${vars.breakpoints.lg} {            
            clip-path: polygon(0% 20%, 30% 0%, 100% 0%, 100% 60%, 60% 90%, 0% 75%);
          }
        }
      `,
    membershipStatus: `
        border: 1px solid ${vars.colors.secondary15};
        padding: 24px;
        h1.membership-status {
          font-family: ${vars.fonts.oswald};
          font-weight: ${vars.fonts.weightMedium};
          color: ${vars.colors.textUnlimitedBg};
        }
        .year-note {
          font-family: ${vars.fonts.oswald};
          font-weight: ${vars.fonts.weightMedium};
          font-size: ${vars.fonts.sizeLarge};
          padding-left: 8px;
          color: ${vars.colors.textUnlimitedBg};
          position: relative;
          top: -3px;
        }
        .saving-instruction {
          font-family: ${vars.fonts.roboto};
          padding-top: 8px;
          font-weight: ${vars.fonts.weightNormal};
          font-size: ${vars.fonts.sizeLarge};
        }
        .saving-note {
          font-family: ${vars.fonts.roboto};
          font-weight: ${vars.fonts.weightNormal};
          font-size: ${vars.fonts.sizeH5};
          color: ${vars.colors.secondary15};
          margin-top: 12px;
        }
        .price-section {
          display: flex;
          align-items: baseline;
        }
        .membership-status-title {
          font-family: ${vars.fonts.noto};
          font-weight: ${vars.fonts.weightMediumPlus};
          .savings-title {
            text-transform: capitalize;
          }
        }
        .membership-subscription-title {
          font-family: ${vars.fonts.roboto};
          font-weight: 700;
          font-size: ${vars.fonts.sizeLarge};
          padding-top: 10px;
        }
        .enroll-info {
          margin-top: 12px;
          font-family: ${vars.fonts.roboto};
          font-weight: ${vars.fonts.weightBold};
          font-size: ${vars.fonts.sizeLarge};
          & .date {
            font-weight: ${vars.fonts.weightNormal};
            font-size: ${vars.fonts.sizeLarge};
            padding-right: 8px;
          }
        }
      `,
    payment: `
        margin-top: 24px;
        ${vars.breakpoints.sm} {            
          margin-top: 40px;
        }
        .payment-title{
          font-family: ${vars.fonts.noto};
          font-weight: ${vars.fonts.weightMediumPlus};
        }
        .last-payment-row{
          display: flex;
          font-family: ${vars.fonts.roboto};
          font-size: ${vars.fonts.sizeLarge};
          color:${vars.colors.primary8};
          gap: 4px;
        }
        .last-payment-title{
          font-weight: ${vars.fonts.weightNormal};
          padding-right: 4px;
        }
        .payment-date{
          font-weight: ${vars.fonts.weightBold};
          .loaderContainer div {
            width: 20px;
            height: 20px;
            border-width: 2px;
          }
        }
      `,
    paymentMethod: `
        margin: 24px 0;
        .single-frequency-text {
          font-family: ${vars.fonts.roboto};
          font-size: ${vars.fonts.sizeH4};
        }
        .accordion-content {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .card-details {
          display: flex;
          align-items: center;
          gap: 16px;
        }
        .card-number {
          ${vars.mixins.body1}
          color:${vars.colors.primary8};
        }
        .change-payment-card a {
          font-weight: ${vars.fonts.weightMediumPlus};
          font-size: ${vars.fonts.sizeH5};
          color:${vars.colors.primary8};
          word-break: normal;
        }
        .subscription-option-accordion {
          .mantine-Accordion-item {
            border-bottom: none;
          }
        }
      `,
  },
  MyAccount: {
    deliveryStepperWrapper: `
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        width: 100%;

        .stepper-item {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          flex: 1;

          @media (max-width: 768px) {
            font-size: 12px;
          }

          &::before {
            position: absolute;
            content: "";
            border-bottom: 1px solid #BCBCBC;
            width: 100%;
            top: 8px;
            left: -50%;
            z-index: 2;
          }

          &::after {
            position: absolute;
            content: "";
            border-bottom: 1px solid #BCBCBC;
            width: 100%;
            top: 8px;
            left: 50%;
            z-index: 2;
          }

          .step-counter {
            position: relative;
            z-index: 5;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #ffffff;
            border: 1px solid ${vars.colors.secondary15};
            margin-bottom: 6px;
          }

          &.active {
            color: #006E1A;

            .step-counter {
              background: #ffffff;
              border: 1px solid #006E1A;

              .step-counter-inner {
                background: #006E1A;
                width: 12px;
                height: 12px;
                border-radius: 50%;
              }
            }


          }

          &:first-of-type::before {
            content: none;
          }

          &:last-child::after {
            content: none;
          }
        }
      `,
    yourFutureDelivery: `
        margin: 50px auto 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        .delivery-heading {
          width: fit-content;
          font-size: 24px;
          margin: auto;
          ${vars.breakpoints.sm} {
            font-size:34px;
          }
          
          font-family: ${vars.fonts.familyPrimary};
        }

        .delivery-text {
          font-size: 12px;
          font-family: ${vars.fonts.familySecondary};
          margin:auto;

          ${vars.breakpoints.sm} {
            font-size:16px;
          }
        }

        .change-delivery-notification {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          background-color: #f8f5ec;
          padding: 12px;
          border-radius: 6px;
          width: 100%;
          margin-top: 24px;

          .left {
            display: flex;
            align-items: center;

            .notification-text {
              font-size: 12px;
              margin-left: 8px;

              ${vars.breakpoints.sm} {
                font-size:16px;
              }
            }
          }

          .notification-action {
            background: none;
            border: none;
            text-decoration: underline;
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            ${vars.breakpoints.sm} {
              font-size:16px;
            }
          }
        }

        .packing {
          background-color: #B3CFE5;
        }
        .inDelivery {
          background-color: #B3CFE5;
        }
      `,
    wrapper: `
        display: grid;
        ${vars.breakpoints.md} {
          grid-template-columns:380px 1fr;
        }
      `,
    sideBar: `
      display:none;
      ${vars.breakpoints.md} {
        display:grid;
      }
      padding: 50px 10px 30px 45px;
        & div{
          margin:0;
          padding:0;
        }
        & .navigation-link{
          ${vars.breakpoints.xs} {
              width:260px;
          }
          a{
            font-size: ${vars.fonts.sizeLarge};
            margin-bottom: 18px;
            font-family: ${vars.fonts.familySecondary};
            line-height: 22.4px;
            color: ${vars.colors.grey50};

          }
          h4{
            font-size:30px;
            color:${vars.colors.primary1};
            font-family: ${vars.fonts.familyTertiary};
            margin: 20px 0px;
          }
        }
        & .myaccount-sidebar{
          padding:10px;

        }
      `,
    winePlan: `
        display: grid;

          .spinner {
            min-height: 50px;
            position: relative;
            margin-top: 50px;
          }
          .payment-method {
              margin: 0;
              &:last-of-type{
                margin-bottom: 40px;
                .mantine-Accordion-item::after {
                  content: "";
                  left: -16px;
                  right: -16px;
                  height: 1px;
                  background-color: ${vars.colors.secondary9};
                  position: absolute;
                  ${vars.breakpoints.sm} {
                    left: 0px;
                    right: 0px;
                  }
                }
              }
          }
          .subscription-option{         
            h6{
              font-size:26px;
            }
            .loadIcon {
              margin: 10px 0;
            }
          }
          .case-details{
            display: grid;
            margin-top:${vars.space.xl2};
            gap:${vars.space.xl};

            ${vars.breakpoints.xs} {
              grid-template-columns:0.4fr 1fr;
            }
            ${vars.breakpoints.sm} {
            grid-template-columns:0.8fr 1fr;
            }

              .case-image{
                display: grid;
                justify-content:end;
                & div{
                position:relative;
                width:90px;
                height:82px;

                ${vars.breakpoints.sm} {
                  width:164px;
                  height:146px;
                }
                ${vars.breakpoints.md} {
                  width:146px;
                  height:130px;
                }
                ${vars.breakpoints.lg} {
                  width:174px;
                  height:156px;
                }
                ${vars.breakpoints.xl} {
                  width:190px;
                  height:170px;
                }
                ${vars.breakpoints.xxl} {
                  width:260px;
                  height:233px;
                }
                }
              }
              .case-info{
              // margin-top:${vars.space.xl2};
              display: flex;
              flex-direction: column;
              align-items: start;
              justify-content: center;
                .scheme-name{
                  font-size:24px;
                  font-weight: ${vars.fonts.weightMediumPlus};
                  ${vars.breakpoints.sm} {
                    font-size:30px;

                    }
                  ${vars.breakpoints.xxl} {
                    font-size:34px;
                    text-transform: uppercase;
                  }


                  font-family: ${vars.fonts.familyPrimary};
                  line-height: 35.7px;


                }
                .case-price-info{
                  font-size: 18px;
                  font-weight: ${vars.fonts.weightBold};
                  ${vars.breakpoints.sm} {
                    font-size: 22px;
                    font-weight: ${vars.fonts.weightMediumPlus};
                  }

                  display:flex;
                  flex-direction: column;
                  ${vars.breakpoints.sm} {
                  align-items:center;
                  flex-direction: row;
                  margin-top:${vars.space.xs};
                  }
                  padding:10px 0px;
                  .case-price{
                  line-height: 26.4px;
                  }
                  .case-saving-price{
                    margin-top:${vars.space.xs};
                    ${vars.breakpoints.sm} {
                      margin-top:0;
                      margin-left:${vars.space.xs};
                      }

                    color: ${vars.colors.primary1};
                    background: ${vars.colors.primary5};
                    border-radius:16px;
                    padding:8px;
                    width: fit-content;
                    font-size: 12px;

                  }
                }
                .case-standard-price{
                  color: ${vars.colors.grey60};
                  padding-bottom:13px;
                  font-size: 16px;

                }
                .case-bottle-count{
                  font-size: 16px;
                  color: ${vars.colors.grey50};
                  font-weight: ${vars.fonts.weightNormal};

                }
                .case-swap-links {
                  margin-top: 8px;
                }
                .case-not-select-container{
                  display: flex;
              flex-direction: column;
              align-items: start;
              .coming-soon-heading {
                color: ${vars.colors.grey50};
                margin: 0;
                padding:13px 0px;
              }
              .case-not-select-notes{
                font-size: ${vars.fonts.sizeH4};

              }
              .take-case{
                width:190px;
                margin:13px 0px;
                font-size: 14px;
                font-weight: ${vars.fonts.weightMediumPlus};
                border-radius: 4px;
              }
                }
              }

          }
          .bottles-details-wrapper{
          display:grid;
          margin-top: ${vars.space.xl};
            .header{
              font-size: 18px;
              font-weight: ${vars.fonts.weightMediumPlus};
              font-family: ${vars.fonts.familyTertiary};

            }
          .product-row{
            display:flex;
            align-items: center;
            .arrow-slide {
              background:white;
              border-radius:15px;
              cursor: pointer;
              ${vars.breakpoints.xs} {
              position: absolute;
              z-index: 1;
              &.right{
                right:25px;
                }
              }
              ${vars.breakpoints.sm}{
                position: relative;
                &.right{
                margin:auto;
                margin-right: 0px;
                }
              }
            }

            .bottles-details {

              display: grid;

              .bottle-container {
                display: grid;
                grid-auto-flow: column; /* Arrange items in a single row */
                grid-auto-columns: minmax(293.33px, 1fr); /* Define minimum and maximum column sizes */
                overflow-x: auto; /* Enable horizontal scrolling */
                white-space: nowrap; /* Prevent line breaks */
                gap: 10px; /*space between grid items */
                padding: 20px;
                scrollbar-width: none;


                  .product-card {
                    display: flex;
                    position: relative;
                    flex-direction: column;
                    align-items: center;
                    padding: 15px;
                    white-space: normal;
                    height:100%;
                      &.gift-card{
                        border: 1px solid ${vars.colors.secondary9};
                        border-radius: 4px;
                        background: none;
                          & .gift-tag{
                            justify-content: start;
                            position: absolute;
                            display:flex;
                            gap:7px;
                            background-color: ${vars.colors.grey00};
                            padding:10px;
                            border-radius: 4px;
                            font-weight:700;
                            font-size:16px;
                            z-index:1;
                          }
                        }
                    & .product-details-parent{
                       height:auto;
                       ${vars.breakpoints.sm}{
                         height:100%;
                       }
                    }
                    .product-details{
                      margin: auto;
                      margin-bottom: 0;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      height:100%;
                      ${vars.breakpoints.sm} {
                         align-items: start;
                      }
                      .product-name{
                      font-size: 18px;
                      font-weight: ${vars.fonts.weightMediumPlus};
                      margin: 18px 0 15px 0;
                      font-family: ${vars.fonts.familyTertiary};
                      margin-top: 10px;
                      &.single-line{
                        max-height: 4em;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        //margin-top: -8.1px;
                      }
                      }
                      .product-count{
                        color: ${vars.colors.grey60};
                        font-size: 16px;
                        font-weight: ${vars.fonts.weightNormal};
                        padding-bottom: 15px;
                        align-items: center;
                        display: flex;
                        margin-top:auto;
                      }
                    
                      .product-label,.mobile-product-label{
                        display:none;
                        ${vars.breakpoints.sm} {
                          display:block;
                          cursor:pointer;
                          }
                        background:none;
                        border:none;
                        color: ${vars.colors.grey50};
                        font-size: 16px;
                        font-weight: ${vars.fonts.weightNormal};
                        padding-top: 10px;
                        padding-bottom: 2px;
                        width: fit-content;
                        box-shadow: inset 0 -1px 0 0 black;
                        cursor:pointer;

                      }
                       .mobile-product-label{
                         display:block;
                        ${vars.breakpoints.sm} {
                          display:none;
                          cursor:pointer;
                          }
                      }
                      &.non-wine-gift{
                        .product-label,.product-count,.wine-rating{
                          visibility: hidden;
                        }

                      }
                    }
                    .product-name{
                        font-size: 18px;
                        font-weight: ${vars.fonts.weightMediumPlus};
                        font-family: ${vars.fonts.familyTertiary};
                        padding: 16px;
                    }
                    .image-container {
                      display: flex;
                      justify-content: center;
                    
                      .image-size {
                        position: relative;
                        width: 48px;
                        height: 180px;
                      
                        ${vars.breakpoints.lg} {
                          width: 120px;
                          height: 220px;
                        }
                    }
                }
              }
            }
          }
          }
          .error-message{
            margin-top:16px;
            padding:18px;
            background-color:${vars.colors.errorLight};
            color:${vars.colors.error};
          }
      `,
    winePlanProductImage: `
              .product-image-container {
          margin-bottom: 13px;
              
          .label-card {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: white;
            color: black;
            padding: 10px;
            box-sizing: border-box;
            z-index: 1;
            height: 200px;
            flex-direction: column;
            justify-content: center;
            align-items: start;
          
            ${vars.breakpoints.lg} {
              height: 240px;
            }
          
            .label-row {
              margin-left: 20%;
            
              i {
                padding: 5px;
              }
            
              display: grid;
              grid-template-columns: auto 1fr;
              align-items: center;
              padding:8px;
            }
          }
        
          &:hover .label-card {
            display: flex;
          }
        
          .image-container {
            display: flex;
            justify-content: center;
          
            .image-size {
              position: relative;
              width: 48px;
              height: 180px;
            
              ${vars.breakpoints.lg} {
                width: 120px;
                height: 220px;
              }
            }
          
            &.gift-image {
              .image-size {
                width: 220px;
              }
            }
          
          }
        }
      `,
    accordion: `
      .mantine-UnstyledButton-root {
        padding: 0;
        ${vars.breakpoints.sm} {
          padding: 0 16px;
        }
      }
      .mantine-Accordion-content {
        padding: 1rem 0;
        padding-top: calc(0.625rem / 2);
        ${vars.breakpoints.sm} {
          padding: 1rem;
          padding-top: calc(0.625rem / 2);
        }
      }
      .mantine-Accordion-label {
        padding: 24px 0;
      }
      .subscription-option-accordion{
        position: relative;
        .mantine-Accordion-item {
          border: none;
        }
        .mantine-Accordion-item::before {
          content: "";
          left: -16px;
          right: -16px;
          height: 1px;
          background-color: ${vars.colors.secondary9};
          position: absolute;
          ${vars.breakpoints.sm} {
              left: 0px;
              right: 0px;
          }
        }
        transform: rotate(0deg);
        &.single-frequency{
          & .mantine-Accordion-chevron {
            visibility: hidden;
            }
          }
            & .accordion-heading{
              display:flex;

              align-items: center;
              gap: 16px;
            .accordion-heading-text{
              font-size:16px;
              font-weight: ${vars.fonts.weightBold};
              font-family: ${vars.fonts.familySecondary};
            }
            .single-frequency-text{
              color:${vars.colors.lwDrGray};
              display: none;
              ${vars.breakpoints.sm} {
                 display: initial;
              }
            }
          }
          & .option-heading{
            margin-top:10px;
            margin-bottom:20px;
            font-size:16px;
            font-weight: ${vars.fonts.weightNormal};

          }
             & .choosing-weeks{
               // display:flex;
               display: grid;
               grid-auto-flow: row;
               grid-auto-columns: minmax(150px, 1fr); /* Define minimum and maximum column sizes */
               gap:20px;
               align-items: unset;
              ${vars.breakpoints.md} {
                grid-template-columns: repeat(3, 1fr);
                &.choosing-frequency{
                  width:80%;
                }
                // grid-auto-flow: column;
               }
              .weeks-chip {
                  //padding: 20px !important;
                  border-radius: 0;
                  width: auto;
                  height: auto;
                  padding: 10px;
                  border: 1px solid ${vars.colors.secondary9};
                  border-radius: 2px;
                  display:flex;
                  align-items: center;
                  // justify-content: center;
                  cursor:pointer;
                  font-size:16px;
                  font-weight: ${vars.fonts.weightNormal};
                  &.active{
                    border: 2px solid ${vars.colors.primary8};

                    font-weight: ${vars.fonts.weightBold};
                    font-family: ${vars.fonts.familySecondary};

                  }
                  .icon{
                    position: absolute;
                    // margin-left:10px;
                    i:before{
                      color:${vars.colors.btnsPrimaryBg};;
                    }
                  }
                  .frequency-varient{
                     margin: auto;
                   padding:0 25px;
                    width: 100%;
                    display: flex;
                    justify-content: center;

                  }

              }

            }
            & .notes{
              display:flex;
              align-items:center;
              gap:10px;
              color:${vars.colors.secondary12};
              margin-top: 20px;
              .notes-text{

              }
            }
            & .update-section{
              display:flex;
              margin-top: 20px;
              justify-content:end;
              .clear {
                background: none;
                font-size: ${vars.fonts.sizeLarge};
                line-height: ${vars.fonts.lineHeightDefault};
                text-decoration-line: underline;

                cursor: pointer;
                border: none;
                box-shadow: none;
                &:hover {
                  background: none;
                  color: #000;
                  text-decoration-line: underline;
                }
              }
              .update{
                width: 10rem;
                ${vars.breakpoints.sm}{
                  width:250px;
                  }
              }
            }
          }
    `,
    winePlanModalWrapper: `
    & .mantine-Modal-inner{
      bottom: unset;
      top: 10rem;
      ${vars.breakpoints.lg} {
      bottom: 0;
      top: 0;
      }
    }
    & .mantine-Paper-root{
      padding:20px;
    & h2.mantine-Modal-title{
    font-size: 30px;
    font-weight: 500;
    }
    .frequency-weeks{
      font-size: 16px;
      margin:20px 0px;
      .bold{
        font-weight: ${vars.fonts.weightMediumPlus};
      }
    }
    .notes{
      margin-bottom:20px
    }
    .close{
      display:flex;
      justify-content:end;
      button{
      width:190px;
      }
    }
  }
    `,
    unlimitedModalWrapper: `
        .mantine-Modal-title {
          font-size: 20px;
          font-weight: ${vars.fonts.weightMediumPlus};
          margin-bottom: 0 !important;

          ${vars.breakpoints.sm} {
            font-size: 30px;
          }
        }

        .mantine-Modal-header {
          align-items: center;
          padding-top: 20px;
        }

        .mantine-Modal-body {
          padding-bottom: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        }

        .mantine-Modal-content {
          position: absolute;
          padding: 12px;
          padding-top: 0;
          bottom: 0;
          width: 100%;
          min-height: fit-content;
          border-radius: 8px 8px 0 0;
          box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          gap: 12px;

          ${vars.breakpoints.sm} {
            position: relative;
            max-width: 728px;
            width: 100%;
            height: fit-content;
            padding: 24px;
            border-radius: 8px;
            box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
            top: 20%;
          }
        }

        .unlimited-modal-content-wrapper {
          justify-content: space-between;
          gap: 32px;
          ${vars.breakpoints.sm} {
            justify-content: space-evenly;
          }

          .unlimited-icons{
            display:flex;
            align-items: center;
           .notes {
            margin-left: 10px;
          }
           i {
            color: #20513B;
          }
        }

          .unlimited-offers-content {
            padding: 10px 20px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            height: 70px;
            background-image: url("/next/images/unlimited-offer-bg.png");
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: white;
            text-align: center;
            font-size: 24px;
            font-style: bold;
          }
        }

        .unlimited-modal-cta-wrapper {
          justify-content: center;
          flex-direction: column-reverse;
          margin-top: 20px;
          ${vars.breakpoints.sm} {
            flex-direction: row;
            justify-content: flex-end;
            margin-top: auto;
          }
        }

        button.unlimited-modal-primary-cta {
          cursor: pointer;
          width: 100%;
          background-color: #117B53;
          color: ${vars.colors.btnsPrimaryColor};
          ${vars.breakpoints.sm} {
            width: auto;
          }

          :hover {
            background-color: #094B32;
            color: ${vars.colors.btnsPrimaryColor};
          }
        }

        button.unlimited-modal-tertiary-cta {
          cursor: pointer;
          text-decoration: underline;
          background-color: transparent;
          border: none;
          color: ${vars.colors.black};
          &:hover {
            text-decoration: none;
            color: ${vars.colors.black};
            background-color: transparent;
            border: none;
          }
          width: 100%;
          ${vars.breakpoints.sm} {
            width: auto;
          }
        }       
    `,
    delayCaseModalModalWrapper: {
      wrapper: `
          .mantine-Modal-inner {
            z-index: 9999;
          }
          .mantine-Modal-content {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 100vh;
            border-radius: 0;
            & .mantine-Modal-body {
              padding: 0rem 24px;
            }
          }
          .modal-content {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100vh;        
          }
          .delivery-content {
            position: relative;
            flex: 1;
            .title {
              ${vars.mixins.body1}
              font-weight: ${vars.fonts.weightBold};
              padding-bottom: 1rem;
              text-align: center;
            }
          }
          .title-content {
            display: flex;
            background: #FFF;
            padding-top: 32px;
            z-index: 100;
            align-items: center;
            position: sticky;
            top: 0;
            width: 100%;
            & i {
              margin-bottom: 16px;
              cursor: pointer;
            }
            .title {
              flex: 1;
              text-align: center;
              margin-right: 24px;
            }
          }
          .subtitle {
            ${vars.mixins.body1}
            padding: 1rem 0;
            span {
              font-weight: ${vars.fonts.weightBold};
            }
          }
          .delay-caseoption-container {                 
              .delay-caseoption-details {
                display: flex;
                align-items: center;
                border: 1px solid ${vars.colors.secondary9};
                cursor: pointer;
                padding: 8px 16px;
                margin: 8px 0;
                &.selected {
                  border: 1px solid ${vars.colors.primary8};
                }      
                .delay-caseoption-week {
                  font-weight: ${vars.fonts.weightBold};
                }
              }          
              .delay-caseoption-checkmark {
                color: green;
                margin-right: 8px;
              }
           }
          .button-container {
            text-align: right;
            padding: 16px 0;
            background: #FFF;
            z-index: 100;
            align-items: center;
            position: sticky;
            bottom: 0;
            width: 100%;
            & button {
             cursor: pointer;
            }
            .submit-button {
              width: 100%;  
              padding: 16px; 
              text-transform: uppercase;
              background: ${vars.colors.btnsPrimaryBg};
              .mantine-Button-icon {
                width: 16px;
              }
              &:disabled{
                 background-color: ${vars.colors.grey00};
                 color: ${vars.colors.grey70};
              }
            }
          }
        `,
    },
    changeDeliveryDateModalWrapper: {
      wrapper: `
          .mantine-Modal-inner {
            z-index: 9999;
            .error{
              margin-top:16px;
              padding:18px;
              background-color:${vars.colors.errorLight};
              color:${vars.colors.error};
            }
          }
          .spinner {
            min-height: 150px;
            position: relative;
            margin-top: 70px;
          }
          .no-options {
            ${vars.mixins.body1}
            padding: 32px;
            text-align: center;
          }
          .delay-case-option-content {
            margin-top: 12px;
            .delay-case-option-header {
              display: flex;
              align-items: center;
              margin-top: 12px;
              gap: 12px;
              ${vars.mixins.body1}
                .delay-caseoption-week {
                  font-weight: ${vars.fonts.weightBold};
              }
            }
            .delay-case-selected-option {
              flex: 1;
              .mantine-Menu-dropdown {        
                max-height: 200px;
                overflow-y: scroll;         
                .mantine-Menu-itemLabel {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
            .delay-caseoption-container {
              display: flex;
              align-items: center;
              border: 1px solid ${vars.colors.secondary9};
              cursor: pointer;
              padding: 8px;
              justify-content: space-between;
              .delay-caseoption-details {
                flex: 1;
              }            
              .delay-caseoption-checkmark {
                margin-right: 8px;
                color: green;
              }
            }
            .delay-caseoption-menu {
              display: flex;
              align-items: center;
              gap: 8px;
              cursor: pointer;
              justify-content: space-between;
              .delay-caseoption-menu-details {
                flex: 1;
              }            
              .delay-caseoption-menu-checkmark {
                margin-right: 8px;
                color: green;
              }
            }
          }
          .choose-date-option-content {
            margin-top: 12px;
            .choose-date-option-title {
              display: flex;
              align-items: center;
              margin-top: 12px;
              gap: 12px;
              ${vars.mixins.body1}
              .choose-date-date {
                font-weight: ${vars.fonts.weightBold};
              }
            }
            .choose-date-option-subtitle {
              margin-top: 12px;
              color: ${vars.colors.secondary12};
              ${vars.mixins.body1}
              .choose-date-date {
                padding-left: 8px;
              }
            }
            .choose-date-calendar {
              .mantine-MonthLevel-monthLevel {
                margin: auto;
              }
              .mantine-Day-day.weekend {
                color: ${vars.colors.primary5};
              }
              margin-top: 12px;
            }
          }
          .send-now-option-content {
            display: flex;
            align-items: center;
            margin-top: 12px;
            gap: 12px;
            ${vars.mixins.body1}
            .send-now-date {
              font-weight: ${vars.fonts.weightBold};
            }
          }
          .mantine-Modal-content {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 100vh;
            border-radius: 0;
            ${vars.breakpoints.sm} {
              border-radius: 12px; 
              min-width: 70%;
              position: static;
              min-height: initial;
            }
            & .mantine-Modal-body {
              padding: 0rem 24px;
            }
          }
          .modal-content {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100vh;
            ${vars.breakpoints.sm} {
              padding: 0 16px;
              display: block;
              height: initial;
            }          
          }
          .delivery-content {
            position: relative;
            flex: 1;
          }
          .title-content {
            display: flex;
            justify-content: space-between;
            background: #FFF;
            padding-top: 32px;
            z-index: 100;
            align-items: center;
            position: sticky;
            top: 0;
            width: 100%;
            & i {
              margin-bottom: 16px;
              cursor: pointer;
            }
          }
          .subtitle {
            ${vars.mixins.body1}
            span {
              font-weight: ${vars.fonts.weightBold};
            }
          }
          .option {
            padding: 16px;
            border: 1px solid ${vars.colors.secondary9};
            margin: 16px 0;
            display: flex;
            align-items: flex-start;
            label {
              ${vars.mixins.body1}
              cursor: pointer;
              display: inline-block;
              width: 100%;
            }
            input {
              appearance:none;
              background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><circle cx="8" cy="8" r="7.5" fill="white" stroke="%23BCBCBC"/></svg>');
              background-size: 16px;
              width: 16px;
              height: 16px;
              position: relative;
              top: 3px;
              &:checked {
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><circle cx="8" cy="8" r="7.5" fill="white" stroke="${encodeURIComponent(vars.colors.primary1)}"/><circle cx="7.99935" cy="8.00002" r="5.33333" fill="${encodeURIComponent(vars.colors.primary1)}"/></svg>');
              }
            }
            &.active{
              border: 1px solid ${vars.colors.primary8};
              label {
                font-weight: ${vars.fonts.weightBold};
              }
            }
          }
          .option-details {
            margin-left: 16px;
            flex: 1;
          }
          .button-container {
            text-align: right;
            padding: 16px 0;
            background: #FFF;
            z-index: 100;
            align-items: center;
            position: sticky;
            bottom: 0;
            width: 100%;
            & button {
             cursor: pointer;
            }        
            .cancel-button {
              background: transparent;
              color: ${vars.colors.primary8};
              border: none;
              border-radius: 0;
              padding: 0 16px;
              display: none;
              span {
                border-bottom: 1px solid ${vars.colors.primary8};
                 ${vars.mixins.body1}
              }
              ${vars.breakpoints.sm} {
                display: inline-block;
              }
            }
            .submit-button {
              width: 100%;  
              padding: 16px; 
              text-transform: uppercase;
              background: ${vars.colors.btnsPrimaryBg};    
              ${vars.breakpoints.sm} {
                width: 180px;
                margin-left: 16px;
              }
              .mantine-Button-icon {
                width: 16px;
              }
              &:disabled{
                 background-color: ${vars.colors.grey00};
                 color: ${vars.colors.grey70};
              }
            }
          }
        `,
    },
    deliveryDateConfirmationModalWrapper: {
      wrapper: `
          .mantine-Modal-content {                
            min-width: 70%;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            min-height: 100vh;
            border-radius: 0;
            ${vars.breakpoints.sm} {
              min-width: 60%;
              border-radius: 12px;  
              position: static;
              min-height: initial;
            }
            & .mantine-Modal-body {
              padding: 0rem 1rem ;
            }
          }
          .modal-content {
            position: relative;
            text-align: center;
            ${vars.breakpoints.sm} {
              padding: 0 16px;
              text-align: initial;
            }          
          }
          .delivery-content {
            position: relative;
            .delivery-icon {
              display: inline-block;
              padding: 1rem;
              background: #C3E9CF;
              border-radius: 3rem;
              margin: 48px 0;
              i {
                border: 1px solid ${vars.colors.grey60};
                padding: 5px;
                border-radius: 3rem;
              }
              ${vars.breakpoints.sm} {
                display: none;
              }
            }
            .title {
              margin-bottom: 24px;
              ${vars.breakpoints.sm} {
                display: none;
              }
            }
            .split {
              display: none;
              ${vars.breakpoints.sm} {
                display: inline;
              }
            }
            .subtitle-details {
              display: block;
              padding-top: 16px;
              ${vars.breakpoints.sm} {
                display: inline;
              }
            }
          }
          .title-content {
            display: flex;
            justify-content: flex-end;
            background: #FFF;
            padding-top: 32px;
            z-index: 100;
            align-items: center;
            position: sticky;
            top: 0;
            width: 100%;
            & i {
              margin-bottom: 16px;
              cursor: pointer;
            }
            .title {
              display: none;
              ${vars.breakpoints.sm} {
                display: block;
              }
            }
            ${vars.breakpoints.sm} {
                justify-content: space-between;
            }
          }
          .subtitle {
            ${vars.mixins.body1}
            span {
              font-weight: ${vars.fonts.weightBold};
            }
          }
          .information-text {
            ${vars.mixins.body1}
            padding:16px 0;
            color: ${vars.colors.secondary15};
            .spinner {
              display: inline-block;
              margin-left: 8px;
              position: relative;
              top: 7px;
            }
          }
          .button-container {
            text-align: right;
            padding: 16px 0;
            background: #FFF;
            z-index: 100;
            align-items: center;
            position: sticky;
            bottom: 0;
            width: 100%;
            & button {
             cursor: pointer;
            } 
            .submit-button {
              width: 100%;  
              text-transform: uppercase;
              ${vars.breakpoints.sm} {
                width: 180px;
                margin-left: 16px;
              }
              .mantine-Button-icon {
                width: 16px;
              }
            }
          }
        `,
    },
    winePlanBeyondLabel: `
      & .mantine-Modal-close{
         color: ${vars.colors.primary8};
         &:focus {
                  outline: none;
           }
      }
      & .mantine-Modal-header{
            padding-bottom: 5px;
      & h2.mantine-Modal-title{
        font-family: ${vars.fonts.oswald};
        font-size: 34px;
        font-weight: 500;
        line-height: 35.7px;
        letter-spacing: -0.03em;
        text-align: left;
        
      }
     }
      .header-container {
        display:flex;
        flex-direction:column;
        .wine-rating{
          display: flex;
          align-items: center;
          gap:10px;
           margin-bottom: 15px;
           .star-icons{
            margin-top: 0.4rem;
           }
           .review-count{
             cursor:pointer;
             text-decoration: underline;
            & a{
               text-decoration: none;
            }
           }
        }
        .appellation-name{
          font-size: 22px;
          text-transform: capitalize;
        }
           .web-description {
            // font-size:16px;
            ${vars.mixins.body1}
              margin-bottom: 10px;
           }
      }
      .beyond-label-case-details{
              margin-top: 20px;
              text-align: left;
              @media (min-width: 768px) {
                display: grid;
                grid-template-columns: 2fr 1fr 2fr;
              }

              & .sub-title-with-border {
                font-family: ${vars.fonts.familyTertiary};
                font-size: 30px;
                font-style: normal;
                text-transform: capitalize;
                font-weight: ${vars.fonts.weightMediumPlus};
                line-height: 120%; /* 36px */
                letter-spacing: -0.3px;
                padding-bottom: 10px;
                border-bottom: 1px solid #ccc;
                margin-bottom: 25px;
                
              }
              & ul{
                  gap:15px;
                 li{
                     font-size: 16px;
                     display: grid;
                     grid-template-columns: 0.1fr 1fr;
                 }
                }
              .image-container{
                display:flex;
                justify-content: center;
               .image-size {
                position:relative;
                 width:120px;
                 height: 370px;
                text-align: center;
              }
            }
            
            }
            & .case-details{
              &  .item-title {
                 font-family: ${vars.fonts.familySecondary};
                ${vars.mixins.h8}
                ~.item-sub-title{
                      font-size: 16px;
                }
              }

            }
            & .wine-details{
              font-family: ${vars.fonts.familySecondary};
              font-size: 16px;
              font-weight: ${vars.fonts.weightNormal};
              line-height: 22.4px;
              text-align: left;
            }
            // mobile
            & .mantine-Accordion-item{
                     box-shadow: 0px -1px 0px 0px ${vars.colors.secondary14} inset;
                     border-bottom: none;
                 }
                   &  .mantine-Accordion-label {
                     font-weight: ${vars.fonts.weightBold};
                     font-size: ${vars.fonts.sizeLarge};
                     font-family: ${vars.fonts.familyTertiary};
                   }
              & .product-details-mobile-view{
                           display:block;
                        ${vars.breakpoints.sm} {
                          display:none;
                          }
                          & .mantine-Accordion-chevron{
                            & svg{
                                  width: 3rem !important;
                                height: 3rem !important;
                            }
                          }
                          .description{
                            margin:15px 0px;
                            font-family: ${vars.fonts.familySecondary};
                             font-size: ${vars.fonts.fontSize16};

                                  font-weight: ${vars.fonts.weightNormal};
                           
                            text-align: center;
                            box-shadow: 0px -1px 0px 0px ${vars.colors.secondary14} inset;
                            border-radius: 3px;
                          }
                            & .see-more-description{
                            background: none;
                            color: ${vars.colors.grey50};
                            line-height: 24px;
                            font-size: ${vars.fonts.sizeH4};
                            padding: 0;
                            text-decoration-line: underline;
                            cursor: pointer;
                            margin: 15px 0;
                            box-shadow: none;
                            border: none;
                            font-weight: 500;
                             font-family: ${vars.fonts.familySecondary};
                          }

                          
                          
                          & .image-container {
                            &.about-producer {
                              position: relative;
                              min-height: 300px;
                            }
                          }

                      }

                    .product-details{
                      text-align:center;
                        
                      .product-name{
                        font-size: 18px;
                        font-weight: ${vars.fonts.weightMediumPlus};
                        margin: 18px 0 15px 0;
                        font-family: ${vars.fonts.familyTertiary};
                        margin-top: 10px;
                      }
                     .product-count{
                        color: ${vars.colors.grey60};
                        font-size: 16px;
                        font-weight: ${vars.fonts.weightNormal};
                        padding-bottom: 15px;
                        align-items: center;
                        display: flex;
                        justify-content: center;

                      }
                    }                     
      `,
    manageAddress: {
      headerWrapper: `
          &.header {
            text-align: center;
            margin-bottom: ${vars.space.lg};
          }
          .header-title {
            text-transform: uppercase;
          }
          .header-information {
            ${vars.mixins.body1}
            span {
              font-weight: ${vars.fonts.weightMediumPlus};
            }
          }
        `,
      wrapper: `      
          .loading-page {
            margin: 2rem 0;
          }
          .add-new-address {
            margin: 24px 0 0;
            cursor: pointer;
            width: 100%;
            font-weight: ${vars.fonts.weightMediumPlus};
            line-height: initial;
            text-transform: uppercase;
            ${vars.breakpoints.sm} {
              width: initial;
            }
          }
          .add-address-success {  
            display: flex;
            align-items: center;
            gap: 8px;
            margin: 12px;
            background: #C3E9CF;
            padding: 12px;
            border-radius: 4px;
            font-size: ${vars.fonts.sizeSmall};
            font-weight: ${vars.fonts.weightMedium};
          }
        `,
      userDefaultAddress: `
          .addresses {
            display: flex;
            gap: 16px;
            flex-direction: column;
            ${vars.breakpoints.sm} {
              width: initial;
              flex-direction: row;
            }
          }
        `,
      defaultAddress: `
          &.address-section {
            background-color: ${vars.colors.primary3};
            flex: 1;
            padding: 24px;
          }
          .loading {
            margin: 24px 0;
          }
          .name {
            ${vars.mixins.body1}
            font-weight: ${vars.fonts.weightBold};
          }
          .address {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }       
          .line1, .line2, .instructions {
            ${vars.mixins.body1}
          }
          .instructions span{
            font-weight: ${vars.fonts.weightBold};
          }
          & a {
            padding: 12px 0;
            display: inline-block;
            &:hover, &:active, &:focus {
              text-decoration: none;
            }
            & .link-text {
              text-decoration: none;
              border-bottom: 1px solid ${vars.colors.primary8};
              font-size: ${vars.fonts.sizeH5};
              font-weight: bold;
              text-transform: uppercase;
            }
          }
        `,
      postalAddressSelector: `
          .address-content {      
              padding: 0 24px;
          }
          .address-selector {
              display: flex;
              align-items: center;
              margin: 12px 0;
              gap: 12px;
              ${vars.mixins.body1}
            }
            .result-count {
              ${vars.mixins.body1}
              font-weight: ${vars.fonts.weightBold};
            }
            .mantine-Menu-item {
              color: ${vars.colors.secondary15};
            } &:focus {
                  outline: none;
                  }
            .address-selector-container{
              flex: 1;
            }
            .address-selector-title {
              color: #767676;
            }
            .mantine-Menu-dropdown {        
                max-height: 200px;
                overflow-y: scroll;  
                width: calc(100% - 70px) !important;       
                .mantine-Menu-itemLabel {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                } &:focus {
                  outline: none;
                  }
              }
            .address-selector-container {
              display: flex;
              align-items: center;
              border: 1px solid ${vars.colors.secondary9};
              cursor: pointer;
              padding: 8px;
              justify-content: space-between;
              .delay-caseoption-details {
                flex: 1;
              }            
              .address-selector-menu-checkmark {
                margin-right: 8px;
                color: green;
              }
            }
            .address-selector-menu {
              display: flex;
              align-items: center;
              gap: 16px;
              white-space: normal;
              cursor: pointer;
              justify-content: space-between;
              .delay-caseoption-menu-details {
                flex: 1;
              }            
              .address-selector-menu-checkmark {
                margin-right: 8px;
                color: green;
              }
            }
        `,
      addAddressModalWrapper: `
          .noResultsError {  
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 24px;
            background: ${vars.colors.primary7};
            padding: 12px;
            border-radius: 4px;
            font-size: ${vars.fonts.sizeSmall};
            font-weight: ${vars.fonts.weightMedium};
          }
          .mantine-Modal-inner {
            z-index: 9999;
          }
          .mantine-Modal-content {
            position: fixed;
            top: 150px;
            left: 0;
            width: 100%;
            height: calc(100vh - 150px);
            scrollbar-color: ${vars.colors.grey40} transparent;
            ${vars.breakpoints.sm} {
              border-radius: 12px; 
              min-width: min(90%, 725px);
              position: static;
              min-height: initial;
              height: initial;
            }
            & .mantine-Modal-body {
              padding: 0;
            }
          }
          .modal-content {
            display: flex;
            flex-direction: column;
            ${vars.breakpoints.sm} {
              display: block;
            }
            .postal-text {
             ${vars.mixins.body1}
             margin-bottom: 24px;
            }
            .content-section {
              padding: 0 24px;
              ${vars.breakpoints.sm} {
                padding: 0 32px;
              }
            }
            .form-collapsed ~ div .address-content {
                padding: 24px 24px;
                ${vars.breakpoints.sm} {
                  padding: 0 24px 24px;
                }
            } 
            .form-collapsed {
              .button-container {
                position: absolute;
                bottom: 0;
                width: calc(100% - 48px);
                ${vars.breakpoints.sm} {
                  position: static;
                  width: initial;
                }
              }      
            }
            .title-content {
              padding: 0 24px;
              ${vars.breakpoints.sm} {
                padding: 32px 32px 0;
              }
              display: flex;
              background: #FFF;
              padding-top: 32px;
              align-items: center;
              z-index: 100;
              position: sticky;
              top: 0;
              width: 100%;
              & i {
                margin-bottom: 16px;
                cursor: pointer;
              }
              .title {
                flex: 1;
                margin-right: 24px;
              }
            }
            .submit-address {
                position: sticky;
                margin: 0;
                bottom: 0;
                left: 0;
                padding: 10px 24px;
                z-index: 2;
                background: white;
                box-shadow: 0px -5px 40px 0px #00141E1A;
                box-shadow: 0px 10px 20px 0px #00141E33;
                ${vars.breakpoints.sm} {
                  box-shadow: none;
                  padding: 10px 32px 32px;
                }
                .mantine-Grid-col {
                  margin-bottom: 8px;
                }
            }
            .button-container {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin: 16px 0;
              gap: 8px;
              flex-direction: column-reverse;
              ${vars.breakpoints.sm} {
                  box-shadow: none;
                  flex-direction: initial;
                  margin: 32px 0;
                  gap: 24px;
              }
              & button {
              cursor: pointer;
              }
              .add-manual-address {
                padding: 1rem;
                cursor: pointer;
                text-decoration: underline;
              }
              .submit-button {
                width: 100%;  
                padding: 16px; 
                text-transform: uppercase;
                background: ${vars.colors.btnsPrimaryBg};    
                ${vars.breakpoints.sm} {
                  width: 180px;
                  margin-left: 16px;
                }
                .mantine-Button-icon {
                  width: 16px;
                }
                &:disabled{
                  background-color: ${vars.colors.grey00};
                  color: ${vars.colors.grey70};
                }
              }
            }         
          }
        `,
      addAddressForm: `
         .submit-button {
              width: 100%;  
              padding: 16px;
              margin: 0 7px;
              text-transform: uppercase;
              background: ${vars.colors.btnsPrimaryBg};
              .mantine-Button-icon {
                width: 16px;
              }
              &:disabled{
                 background-color: ${vars.colors.grey00};
                 color: ${vars.colors.grey70};
              }
          }
         .mantine-InputWrapper-label span.required {
           color: ${vars.colors.primary9}; 
         }
         .mantine-InputWrapper-label span.optional {
            color: ${vars.colors.secondary15};
         }
         .add-address-error {  
            display: flex;
            align-items: center;
            gap: 8px;
            margin: 12px;
            background: ${vars.colors.primary7};
            padding: 12px;
            border-radius: 4px;
            font-size: ${vars.fonts.sizeSmall};
            font-weight: ${vars.fonts.weightMedium};
          }
         .mantine-InputWrapper-error {
           color: #AA182B;
          }
          .mantine-Input-input[data-invalid] {
            color: #AA182B;
            border-color: #AA182B;
          }
          .update-all-delivery-checkbox{    
              margin-right:10px;
              & input:checked{
                border-color: ${vars.colors.secondary6};
                background-color: ${vars.colors.secondary6};
              }
            }
          .add-address-form-section {
            padding: 0 24px;
            margin: 0;  
            margin-bottom: 16px;
            ${vars.breakpoints.sm} {
              margin: 0;   
              padding: 0 32px;   
            }
          }
        `,
      addAddressConfirmationModalWrapper: {
        wrapper: `
          .mantine-Modal-content {                
            min-width: 70%;
            position: fixed;
            top: 150px;
            left: 0;
            width: 100%;
            height: calc(100vh - 150px);
            ${vars.breakpoints.sm} {
              min-width: min(90%, 725px);
              border-radius: 12px;  
              position: static;
              height: initial;       
            }
            & .mantine-Modal-body {
              padding: 0rem;
            }
          }
          .modal-content {
            position: relative;
            text-align: center;
            ${vars.breakpoints.sm} {
              padding: 0;
              text-align: initial;
            }          
          }
          .delivery-content {
            position: relative;
            .delivery-icon {
              display: inline-block;
              padding: 1rem;
              background: #C3E9CF;
              border-radius: 3rem;
              margin: 48px 0;
              i {
                border: 1px solid ${vars.colors.grey60};
                padding: 5px;
                border-radius: 3rem;
              }
              ${vars.breakpoints.sm} {
                display: none;
              }
            }
            .title {
              margin-bottom: 24px;
              ${vars.breakpoints.sm} {
                display: none;
              }
            }
            .split {
              display: none;
              ${vars.breakpoints.sm} {
                display: inline;
              }
            }
            .subtitle-details {
              display: block;
              padding-top: 16px;
              ${vars.breakpoints.sm} {
                display: inline;
              }
            }
          }
          .content-section {
            position: fixed;
            top: 45%;
            left: 50%;
            transform: translate(-50%, -50%);
            overflow: hidden;
            padding: 0 24px;
            ${vars.breakpoints.sm} {
              padding: 0 32px;
            }
            width: 100%;
            ${vars.breakpoints.sm} {
                position: static;
                transform: initial;
                width: initial;
            }
          }
          .title-content {
            display: flex;
            justify-content: flex-end;
            background: #FFF;
            padding: 32px 24px 0;
            ${vars.breakpoints.sm} {
              padding: 32px 32px 0;
            }
            z-index: 100;
            align-items: center;
            position: sticky;
            top: 0;
            width: 100%;
            & i {
              margin-bottom: 16px;
              cursor: pointer;
            }
            .title {
              display: none;
              ${vars.breakpoints.sm} {
                display: block;
              }
            }
            ${vars.breakpoints.sm} {
                justify-content: space-between;
            }
          }
          .subtitle {
            ${vars.mixins.body1}
            span {
              font-weight: ${vars.fonts.weightBold};
            }
          }
          .button-container {
            text-align: center;
            padding: 32px 0;
            background: #FFF;
            z-index: 100;
            align-items: center;
            position: sticky;
            bottom: 0;
            width: 100%;
            ${vars.breakpoints.sm} {
              text-align: right;
              padding: 16px 0;
            }
            & button {
             cursor: pointer;
            } 
            .submit-button {
              text-transform: uppercase;
              width: 180px;
              ${vars.breakpoints.sm} {
                margin-left: 16px;
              }
              .mantine-Button-icon {
                width: 16px;
              }
            }
          }
        `,
      },
      addressBook: `
          .address-book-addresses {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            gap: 0;
            ${vars.breakpoints.sm} {
              grid-template-columns: 1fr 1fr;   
              gap: 16px;
            }       
          }
          .address-section {
            margin: 16px 0;
            ${vars.breakpoints.sm} {
              margin: 32px 0;
            }
          }
          .address-book-subtitle {
            display: flex;
            margin: 16px 0;
            gap: 8px;
          }
          .address-book-card {
            border: none;
            ${vars.breakpoints.sm} {
              border: 1px solid ${vars.colors.secondary9};    
            }
            position: relative;
          }
          .address-divider {
            border-bottom: 1px solid ${vars.colors.secondary9};
            position: absolute;
            left: -16px;
            right: -16px;
            display: block;
            top: 0;
            ${vars.breakpoints.sm} {
              display: none;      
            }
          }       
          .address-book-subtitle-text {
            ${vars.mixins.body1}
            & a {
              text-decoration: underline;
              margin: 4px;
              ${vars.mixins.body1}
              font-weight: ${vars.fonts.weightMedium};
            }
          }
        `,
      addressBookCard: `
          &.address-book-card-section {
            height: 100%;
            padding: 24px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            ${vars.breakpoints.sm} {
              padding: 24px;    
            }
          }
          .address-card-divider {
            border-bottom: 1px solid ${vars.colors.secondary9};
            position: absolute;
            left: -16px;
            right: -16px;
            display: block;
            bottom: 0;
            ${vars.breakpoints.sm} {
              display: none;      
            }
          }
          .loader {
            margin-top: 24px;
            & .loaderContainer {
              justify-content: flex-start;
              & div {
                width: 20px;
                height: 20px;
                border-width: 3px;
              }
            }
          }
          .name {
            ${vars.mixins.body1}
            font-weight: ${vars.fonts.weightBold};
          }
          .address {
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex: 1;
          }       
          .line1, .line2, .instructions {
            ${vars.mixins.body1}
          }
          .instructions span{
            font-weight: ${vars.fonts.weightBold};
          }
          .delivery-address-change {
            cursor: pointer;
            text-decoration: underline;
            display: inline-block;
            text-transform: uppercase;
            margin: 16px 0 0;
            ${vars.mixins.body1}
            font-weight: ${vars.fonts.weightMediumPlus};
            font-size: 14px;
            ${vars.breakpoints.sm} {
              margin: 24px 0 16px;     
            }
          }
        `,
      collectFromStore: `
          margin: 16px 0;
          ${vars.breakpoints.sm} {
              margin: 32px 0;
          }
          h6.title {
            margin-bottom: 16px;
            ${vars.breakpoints.sm} {
              margin-bottom: 24px;
            }
          }
          .store-form-fields {
            display: flex;
            gap: 16px;
            flex-direction: column;
            .mantine-Input-input {
              height: 40px;
            }
            ${vars.breakpoints.sm} {
              flex-direction: row;
            }
          }
          .store-form-field{
            flex: 1;
          }
          .store-address-error {  
            display: flex;
            align-items: flex-start;
            gap: 8px;
            margin-top: 8px;
            background: #B3CFE5;
            padding: 12px;
            border-radius: 4px;
            font-size: ${vars.fonts.sizeSmall};
            font-weight: ${vars.fonts.weightMedium};
            a {
              text-decoration: none;
              border-bottom: 1px solid ${vars.colors.primary8};
              font-weight: ${vars.fonts.weightMediumPlus};
              &:hover {
                text-decoration:
              }
            }
          }
          .submit-button { 
              padding: 12px 24px;
              height: 40px;
              text-transform: uppercase;
              background: ${vars.colors.btnsPrimaryBg};
              width: 100%;
              .mantine-Button-icon {
                width: 16px;
              }
              ${vars.breakpoints.sm} {
                width: initial;
              }
              &:disabled{
                 background-color: ${vars.colors.grey00};
                 color: ${vars.colors.grey70};
              }
          }
      `,
      shopAddress: `
        &.address-section {
          padding-top: 16px;
        }
        .title {
          ${vars.mixins.body1}
          font-weight: ${vars.fonts.weightBold};
          margin-bottom: 8px;
        }
        .address {
          ${vars.mixins.body1}
          margin-bottom: 16px;
        }
      `,
      paymentMethod: `
         margin-bottom: 16px;
         ${vars.breakpoints.sm} {
            margin-bottom: 24px;   
         }
        .payment-message {
          ${vars.mixins.body1}
          margin-bottom: 16px;
          ${vars.breakpoints.sm} {
              margin-bottom: 24px;   
          }       
          span {
            font-weight: ${vars.fonts.weightBold};
          }
        }
      `,
      paymentCard: `
        padding: 16px 16px 12px;
        display: flex;      
        background: ${vars.colors.primary3};
        border-radius: 8px;
        flex-direction: column; 
        justify-content: initial;
        align-items: flex-start;
        ${vars.breakpoints.sm} {
          flex-direction: row; 
          align-items: center;
          justify-content: space-between;
          padding: 0 12px;
          border-radius: 2px;
        }
        ${vars.mixins.body1}
        .card-details {
          display: flex;
          gap: 8px;
          align-items: flex-start;
          padding-bottom: 16px;
          flex-wrap: wrap;
          ${vars.breakpoints.sm} {
            align-items: center;
            padding-bottom: 0;
          }
        }
        .card-details-icon {      
          ${vars.breakpoints.sm} {
            position: relative;
            top: 3px;
          }
        }
        .change-payment-card a {
          font-weight: ${vars.fonts.weightMediumPlus};
          font-size: ${vars.fonts.sizeH5};
          color:${vars.colors.primary8};
          word-break: normal;
          border-bottom: 1px solid ${vars.colors.black};
        }
        .card-type {
          font-weight: ${vars.fonts.weightBold};
        }
        .card-info {
          display: flex;
          gap: 8px;
          flex-direction: column; 
          ${vars.breakpoints.sm} {
            flex-direction: row;
          }
        }
        .card-number-info {
          display: flex;
          gap: 8px;
          flex-direction: row;
        }
      `,
      winePlanCard: `
       margin-bottom: 16px;
       gap: 4px;
       flex-direction: column;
       ${vars.breakpoints.sm} {
          margin-bottom: 24px; 
          gap: 6px;  
          flex-direction: row;
       }
       display: flex;     
       .wine-plan-amount {
         background-color: ${(vars.colors.bgColors as unknown as { [key: string]: string }).whiteWine};
         padding: 32px 16px;
         border-bottom-left-radius: 12px;
         border-bottom-right-radius: 12px;
         ${vars.breakpoints.sm} {
          padding: 32px;
          border-radius: 0;
         }
         display: flex;
         flex: 1;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         gap: 8px;
       }
       h4.wine-plan-balance {
          text-align: center;
          font-size: ${vars.fonts.sizeH3};
          margin-bottom: 8px;
          ${vars.breakpoints.sm} {
            font-size: ${vars.fonts.sizeH9};
            margin-bottom: 16px;
          }
          span {
           color: ${vars.colors.primary9};
          }       
       }
       .wine-plan-opt {
         .mantine-Checkbox-label {
           ${vars.mixins.body2}
         }      
       }
       .wine-plan-opt-checkbox{    
          margin-right:10px;
          border-color: ${vars.colors.secondary6};
          & input{
            border-color: ${vars.colors.secondary15};
          }
          & input:checked{
            border-color: ${vars.colors.secondary6};
            background-color: ${vars.colors.secondary6};
          }
        }
       .wine-plan-logo {
         flex: 1;
         align-self: center;
         img {
           width: 100%;
           ${vars.breakpoints.sm} {
              width: 100%;
           }
         }
       }
      `,
    },
    swapSubscription: {
      swapCaseLinks: `
        .links-wrapper {
          display: flex;
          gap: 12px;
          a {
            text-decoration: none;
            padding: 7px 16px;
            text-transform: uppercase;
            border: 1px solid ${vars.colors.primary8};
            border-radius: 4px;
            ${vars.mixins.body3}
            font-weight: ${vars.fonts.weightMediumPlus};
            line-height: 14px;
          }
        }
      `,
      headerWrapper: `
          &.header {
            text-align: left;
            margin-top: ${vars.space.lg};
            margin-bottom: ${vars.space.lg};
            ${vars.breakpoints.sm} {
              text-align: center;
              margin-top: 0;
            }
          }
          .header-title {
            text-transform: uppercase;
          }
          .header-information {
            ${vars.mixins.body1}
            span {
              font-weight: ${vars.fonts.weightMediumPlus};
            }
          }
        `,
      caseOptionsAccordion: `
        margin-top: 8px;
        button.mantine-UnstyledButton-root {
            padding: initial;
        }
      `,
      filterWrapper: `
         margin: 32px 0;
         display: flex;
         flex-wrap: wrap;
         justify-content: space-between;
         &.filter-web {
           display: none;
           ${vars.breakpoints.md} {
              display: flex;
           }
         }
         &.filter-mobile {
           margin: 16px 0;
           border: 1px solid ${vars.colors.primary8};
           border-radius: 2px;
           display: flex;
           ${vars.breakpoints.md} {
              display: none;
           }
         }
         .filter-section {        
           display: flex;
           gap: 16px;
         }
         .filter {
           button {
             border: 1px solid ${vars.colors.secondary9};
             padding: 8px 16px;
             color: ${vars.colors.primary8};
             ${vars.mixins.body1}
             span {
               height: fit-content;
             }
           }
           button.selected {
             border: 1px solid ${vars.colors.primary8};
             font-weight: ${vars.fonts.weightBold};
           }
         }
         .quantity-selector {
           .quantity-selector-button {
             border: 1px solid ${vars.colors.secondary9};
             ${vars.mixins.body1}
             color: ${vars.colors.facetCheckboxColor};
             .quantity-label {
              font-weight: ${vars.fonts.weightBold};
              color: ${vars.colors.primary8};
             }
           }
         }
        .quantity-selector-mobile {
           flex: 1;
           .quantity-selector-button {
             width: 100%;
             border: none;
             ${vars.mixins.body1}
             color: ${vars.colors.primary8};
             .quantity-label {
              font-weight: ${vars.fonts.weightBold};
              color: ${vars.colors.primary8};
             }
           }      
           .mantine-Button-label {
             display: flex;
             justify-content: space-between;
             width: 100%;
           }
         }
        .filter-section-mobile {
           flex: 1;
           .filter-section-button {
             width: 100%;
             border: none;
             border-radius: 0;
             border-right: 1px solid ${vars.colors.primary8};
             ${vars.mixins.body1}
             color: ${vars.colors.primary8};
             .quantity-label {
              font-weight: ${vars.fonts.weightBold};
              color: ${vars.colors.primary8};
             }
           }
           .mantine-Button-label {
             display: flex;
             justify-content: space-between;
             width: 100%;
           }
         }
      `,
      selectCaseType: `
        margin-top: 16px;
        ${vars.breakpoints.sm} {
          margin-bottom: 24px;
        }
        display: flex;
        justify-content: space-between;
        .case-type-selection {
          display: flex;
          gap: 12px;
          flex: 1;
          align-items: center;
          & div {
            padding: 5px 12px;
            display: inline-block;
            border: 1px solid ${vars.colors.unlimitedButtonColor};
            ${vars.mixins.body1}
            height: fit-content;
            border-radius: 16px;
            cursor: pointer;
            &.selected {
              background: ${vars.colors.unlimitedButtonColor};
              color: #FFF;
            }
          }
        }
      `,
      caseOptionPrice: `
        display: flex;
        gap: 8px;
        margin-bottom: 12px;
        ${vars.breakpoints.sm} {
          margin-bottom: 16px;
        }
        align-items: center;
        .discount-price {
          ${vars.mixins.body3}
          font-weight: ${vars.fonts.weightMediumPlus};
          color: #D7363B;
          background-color: #fbeaea;
          padding: 4px 10px;
          border-radius: 16px;
        }
        h6.base-price {
          margin-bottom: 0;
        }
      `,
      caseOptions: `
        .lazy-load-item-placeholder {
          height: 300px;
          ${vars.breakpoints.sm} {
            height: 200px;
          }
        }
        .case-types {
          .subscription-option-accordion .mantine-Accordion-item::before {
            content: initial;
          }
        }
        .case-details {
          position: relative;
          margin-bottom: 24px;
          &::after {
            position: absolute;
            content: "";
            border-bottom: 1px solid #BCBCBC;
            width: 100vw;
            bottom: 0;
            left: -16px;
            z-index: 2;
            ${vars.breakpoints.sm} {
              width: 100%;
              left: 0;
            }
         }
        }
        .case-description {
          margin-top: 12px;
        }
        .case-fequency {
           ${vars.mixins.body1}
           margin-bottom: 12px;
           ${vars.breakpoints.sm} {
              margin-bottom: 16px;
           } 
        }
        .case-information {     
          margin-top: 264px;  
          padding: 0;
          ${vars.breakpoints.sm} {
              margin-top: 0;
              padding: 0 16px 0 12px;
          }           
          flex: 1;
        }
        .select-subscription { 
          display: none;    
          align-self: flex-end;
          ${vars.breakpoints.lg} {
            display: initial; 
          } 
          .select-subscription-button {
            font-weight: ${vars.fonts.weightMediumPlus};
            border-radius: 2px;
            cursor: pointer;
          }
        }
        .select-subscription-medium-screen {
          display: none;    
          align-self: flex-end;
          ${vars.breakpoints.sm} {
            display: initial; 
          } 
          ${vars.breakpoints.lg} {
            display: none; 
          } 
          .select-subscription-button {
            font-weight: ${vars.fonts.weightMediumPlus};
            border-radius: 2px;
            cursor: pointer;
          }
        }
        .select-subscription-mobile {  
          padding-bottom: 24px;    
          ${vars.breakpoints.sm} {
            display: none; 
          }
          .select-subscription-button {
            font-weight: ${vars.fonts.weightMediumPlus};
            cursor: pointer;
          }
        }
        .case-option {
          position: relative;
          display: flex;
          flex-direction: column;
          ${vars.breakpoints.sm} {
              margin-top: 0;
              flex-direction: row;
          }
          .case-image {  
            position: relative;  
            img {
               height: 248px;
               position: absolute;
               width: calc(100vw);
               left: -16px;
               ${vars.breakpoints.sm} {
                  width: auto;
                  height: 258px;
                  margin-top: 0;
                  position: static;
                  flex-direction: row;
              }
            }
          }
        }    
      `,
    },
  },
  CmsTabs: {
    root: `
        .mantine-Tabs-tabsList {
          flex-wrap: nowrap;
          overflow-x: auto;
          border-bottom: 1px solid ${vars.colors.black};
        }
      `,
    tab: `
        border-bottom: 4px solid transparent;
        padding: 0 0 12px 0;
        margin-right: 16px !important;

        &[data-active] {
          border-color: ${vars.colors.black};
        }

        &[data-active]:active,
        &[data-active]:hover,
        &:hover {
          background: none;
          border-color: ${vars.colors.black};
        }

        &:last-child {
          margin-right: 0 !important;
        }

        &.highlighted span {
          color: ${vars.colors.primary9};
          font-style: italic;
        }
      `,
    tabLabel: `
        color: ${vars.colors.grey50};
        ${vars.mixins.h8}
      `,
    panel: `
        color: ${vars.colors.grey50};
        padding-top: 24px;
         div p {
          ${vars.mixins.body1}
        }
      `,
  },
  CmsTimer: {
    wrapper: `
        position: relative;
        text-align: center;
        text-transform: uppercase;
        padding: ${vars.space.xl} 0;
        z-index: 1;
        ${vars.breakpoints.lg} {
          padding: 40px 0;
        }
        .timer-headline {
          ${vars.mixins.eyebrowRegular};
          display: inline-block;
          margin-bottom: ${vars.space.sm};
        }
        h2.timer-content {
          margin-bottom: 0;
        }
      `,
  },
  CmsExpander: {
    wrapper: `
        position: relative;
        .expander-content {
          max-height: 0;
          overflow: hidden;
          transition: all 0.2s;
          position: relative;
          z-index: 1;
          padding: 0 ${vars.space.lg};
          * {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .expander-action {
          text-align: center;
          position: relative;
          z-index: 1;
          button {
            background: none;
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: 0;
            font-size: 16px;
            font-weight: 400;
            padding: 0;
            margin-top: 8px;
          }
        }
      `,
  },
  CmsTBSPage: {
    wrapper: `
        display: grid;
        max-width: 1920px;
        margin-top: 15px;
        margin-left: auto;
        margin-right: auto;
        ${vars.breakpoints.md} {
          grid-template-columns:380px 1fr;
          grid-gap: 24px;
        }
      `,
    wrapperWithoutSidebar: `
        display: grid;
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
        grid-gap: 24px;
        ${vars.breakpoints.md} {
          grid-template-columns: 1fr;
        }
      `,
    sideBar: `
      display:none;
      ${vars.breakpoints.md} {
        display:grid;
        grid-column: 1;
        padding: 50px 10px 30px 45px;
      }
        & div{
          margin:0;
          padding:0;
        }
        & div.navigation-link{
          ${vars.breakpoints.xs} {
              width:260px;
          }
          a{
            font-size: ${vars.fonts.sizeLarge};
            font-family: ${vars.fonts.familySecondary};
            line-height: 22.4px;
            color: ${vars.colors.grey50};
            padding: 12px 16px;
            margin: 0;
          }
          h4{
            font-size:30px;
            color:${vars.colors.primary1};
            font-family: ${vars.fonts.familyTertiary};
            padding: 0 16px;
            margin: 24px 0;
          }
        }
      `,
    sideBarLegacy: `
        background:white;
        padding-left: 5%;
        display:none;
        ${vars.breakpoints.md} {
         display:flex;
         }

         & .sidebar {
            width: 100%;
            background: ${vars.colors.grey10};
            height: fit-content;
            padding-left: 20px;
            margin-bottom: 10px;

            ${vars.breakpoints.md} {
              max-width: 230px;
              margin-right: 15px;
              padding-left: 12px;
            }

            > img {
              width: 14px;
              height: 14px;
            }
           & .navigation-link {
              max-height: fit-content;
              > h4 {
                font-size: 16px;
                color: ${vars.colors.secondary10};
                margin-top: 12px;
                margin-bottom: 15px;
                text-wrap: nowrap;
                font-family: ${vars.fonts.familySecondary};
                width: fit-content;
                 font-weight: ${vars.fonts.weightNormal};
              }
              & a{
                font-size:13px;
                // color: ${vars.colors.footerPrimaryBg};
                 color: ${vars.colors.secondary10};
                margin-left: 4px;
                margin-bottom: 10px;
                text-wrap: nowrap;
                height: 24px;
                    font-weight: normal;
                font-family: ${vars.fonts.familySecondary};
                width: fit-content;
                display: flex;
                gap: 10px;
                > div {
                  overflow-y: hidden;
                   width: 35px;
                  > img {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
           }
         }
            & .accordion-navigation{
          width:200px;
          span {
            padding: 0;
          }
        & .accordion-panel {
          background-color: ${vars.colors.secondary13};
          margin-top: 10px;
        & a{
           text-decoration-color: ${vars.colors.primary1};
           text-decoration-thickness: 2px;
           margin-bottom: 5px;
        }
        }
        & .mantine-Accordion-item{
            width: 100%;
            border:none;
            font-size:13px;
            padding-bottom:19px;
            margin-left: 4px;
            text-wrap: nowrap;
            gap: 10px;
          & button{
            color: ${vars.colors.secondary10};
            padding: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
          & .mantine-Accordion-content {
            padding:10px 10px;
          }
        }
      }
      `,
    content: `
        display: grid;
        grid-column: 2;
      `,
    contentWithoutSidebar: `
        display: grid;
      `,
    breadcrumbCSS: `
        padding: 12px 24px 12px 0px;
        & a {
          display: flex;
          align-items: center;
          gap: 8px;
          &:hover, &:active, &:focus {
            text-decoration: none;
          }
          & .link-text {
            text-decoration: none;
            border-bottom: 1px solid ${vars.colors.primary8};
            font-size: ${vars.fonts.sizeH5};
            font-weight: bold;
          }
        }
      `,
  },
  CmsMultiCaseSelector: {
    main: `
        position: relative;
        display: grid;
        background: #FAF3E6;

        &::before {
          content: '';
          position: absolute;
          width: 100dvw;
          height: 100%;
          background: #FAF3E6;
          top: 0;
          left: calc(50% - 50dvw);
        }

        ${vars.breakpoints.md} {
          grid-gap: 24px;
          grid-template-columns: 1.28fr 1fr;
          align-items: center;
        }

        .cms-image-container {
          position: relative;
        }

        .mcs-main-row {
          display: grid;
          grid-gap: 8px;
          align-self: baseline;

          h1 {
          color: ${vars.colors.primary9};
            margin: 0;
          }

          .cart-button button {
            width: 100%;
          }
        }

        .mcs-main__details {
          position: relative;
          display: grid;
          grid-gap: 32px;
          padding: 24px;

          ${vars.breakpoints.md} {
            padding: 50px;
          }
        }

        .mcs-main-row--upsell {
          ${vars.mixins.body2};
          color: #494949;

          > div {
            display: flex;
            align-items: center;
            gap: 16px;
          }

          label,
          button {
            width: 100%;
          }

          [type="checkbox"] {
            width: 20px;
            height: 20px;
            accent-color: #117B53;
          }

          button span {
            width: 178px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            ${vars.breakpoints.md} {
              width: auto;
            }
          }
          
          button {
            img + span {
              margin-left: 8px;
            }
          }
        }

        .mcs-main-row--active-info {
          ${vars.mixins.body1};
          font-weight: 700;
        }

        .product-price__original {
          color: ${vars.colors.secondary15};
          font-weight: 400;

          + span {
            padding-left: 4px;
          }
        }

        .mcs-main__sub-headline {
          ${vars.mixins.h5};
        }
      `,
    info: `
        position: relative;
        background: #fff !important;
        padding: 40px 16px;
        max-width: 1348px;
        margin: 0 auto;

        &:before {
          content: '';
          position: absolute;
          width: 100dvw;
          height: 100%;
          background: #ffffff;
          left: calc(50% - 50dvw);
          top: 0;
        }
        
        ${vars.breakpoints.md} {
          padding: 80px 24px;
        }

        #panel-case-selector,
        .case-content-container {
          position: relative;
        }

        .case-content-container .mantine-Tabs-tab {
          opacity: 0.3;

          &[data-active] {
            opacity: 1;

            &::after {
              content: none !important;
            }
          }
        }

        .case-content-container .mantine-Tabs-panel {
          margin: 0;
        }

        .case-content-container {
          margin-top: 30px;
        }

        .point-text-container {
          top: 0 !important;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .point-text-container .point {
          font-size: 12px !important;
        }
        .point-text,
        .rating-container {
          display: none !important;
        }

        .main-title {
          margin: 0 !important;

          a {
            color: inherit !important;
          }
        }

        .web-description,
        [role="tabpanel"] .price {
          display: none;
        }

        .case-content-details-section {
          display: grid;
          grid-gap: 0;
          margin-top: 0 !important;

          ${vars.breakpoints.md} {
            grid-template-columns: 1fr 1fr !important;
            grid-gap: 24px;
          }

          .wine-details,
          .case-details {
            padding: 24px 16px;

            ${vars.breakpoints.md} {
              padding: 40px 0 0;
            }
          }

          .case-image,
          .share-print {
            display: none;
          }

          .sub-title-with-border {
            ${vars.mixins.h3}
            font-family: ${vars.fonts.familyPrimary};
            border: none;
          }
        }

        .case-content-container .mantine-Tabs-panel {
          padding: 24px 16px;

          ${vars.breakpoints.md} {
            padding: 40px 56px;
          }
        }

        .header-container {
          padding-bottom: 16px;
          border-bottom: 1px solid #B79178;
        }

        // Mobile Carousel Style
        .mantine-Carousel-root {
          padding-bottom: 24px;
        }

        .mantine-Carousel-viewport {
          padding-bottom: 40px;
        }
        .mantine-Carousel-slide {
          .mantine-Tabs-tab {
            display: grid;
            grid-gap: 8px;
            width: 100%;
            height: 150px;

            span {
              margin: 0;
              text-align: center;
            }

            &[data-active] {
              border: none;
            }
          }
        }

        .mantine-Carousel-control {
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);

          &:last-child {
            left: auto;
            right: 0;
          }
        }

        .mantine-Carousel-indicators {
          position: static;
          transform: none;
        }
      `,
    caseSelectors: `
        .mcs-selector__header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            font-family: ${vars.fonts.roboto};
            font-size: 12px;
            font-weight: 700;
            line-height: 15.6px;
            text-align: left;

          }

          a {
            text-decoration: underline;
          }
        }
        
        .mcs-selector__list {
          display: grid;
          grid-gap: 8px;

          &.simple {
            display: flex;
            overflow-y: auto;
            padding-bottom: 10px;
          }

          img + span {
            margin-left: 8px;
          }
        }
      `,
    caseSelector: `
        background: #FAF3E6;
        display: flex;
        align-items: center;
        padding: 8px 10px;
        outline: none;
        border: 1.5px solid #B79178;
        border-radius: 4px;
        box-shadow: none;

        &[aria-selected="true"] {
          background: ${vars.colors.white};
          border-color: ${vars.colors.primary9};
        }

        &.simple {
          border: 1px solid #BCBCBC;
          background: ${vars.colors.white};
          font-weight: 400;

          &[aria-selected="true"] {
            background: #FEF5F8;
            color: ${vars.colors.primary9};
          }
        }

        img {
          width: 84px !important;
          height: 43px !important;
          object-fit: contain;
        }
      `,
  },
  CmsLink: {
    button: `
        &.full-width {
          width: 100%;
          button {
            width: 100%;
            text-align: center;
          } 
        }
      `,
  },
  CmsRichText: {
    wrapper: `
        table {
          height: auto !important;
          max-width: 100%;
          display: block;
          overflow-x: auto;
          white-space: nowrap;
          padding-bottom: 10px;
          ${vars.breakpoints.md} {
            display: table;
            overflow-x: visible;
            white-space: normal;
          }
          td, th {
            padding: 12px;
            width: auto !important;
            border: 1px solid transparent;
            &.richtext-background {
              display: table-cell;
            }
          }
        }
      `,
  },
  LandingPage: {
    stickyBanner: `
        position: sticky;
        padding: 18px 0;
        background: ${vars.colors.white};
        top: 0;
        z-index: 1;

        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .lp-case-selectors {
          margin: 0;
        }

        .mcs-selector__list {
          padding: 0 !important;

          button {
            display: none !important;
          }

          ${vars.breakpoints.sm} {
            button {
              display: flex !important;
            }
          }
        }

        .view-product-button {
          padding: ${vars.space.md};
          border: 1px solid ${vars.colors.black};
          padding: 12px 24px 12px 24px;
          font-family: ${vars.fonts.familySecondary};
          font-weight: ${vars.fonts.weightMediumPlus};
          background-color: ${vars.colors.btnsPrimaryBg};
          display: inline-block;
          font-size: ${vars.fonts.sizeH5};
          letter-spacing: 0.42px;
          color: ${vars.colors.white};
          text-transform: uppercase;
          border-radius: 5px;
          margin-left: 24px;

          ${vars.breakpoints.md} {
            margin-left: 56px;
          }
        }
        .view-product-button:hover,
        .view-product-button:focus {
          background-color: ${vars.colors.btnsPrimaryHoverBg};
          color: ${vars.colors.btnsPrimaryHoverColor};
          text-decoration: none;
        }
      `,
    hero: `
        position: relative;
        background: #FAF3E6;

        img {
          max-width: 100%;
        }

        .lp-hero__inner {
          display: grid;
          align-items: center;
          margin: 0 auto;

          ${vars.breakpoints.md} {
            grid-template-columns: 1fr 1fr;
            padding: 40px 80px;
          }

          ${vars.breakpoints.lg} {
            padding: 64px 112px;
            gap: 80px;
          }
        }

        .lp-hero-bullets {
          display: flex;
          flex-flow: column;
          align-items: center;
          padding: 0;
          margin-top: 32px;

          ${vars.breakpoints.sm} {
            flex-flow: row;
            justify-content: center;
          }

          li {
            display: flex;
            flex-flow: column;
            align-items: center;
            list-style: none;

            ${vars.breakpoints.sm} {
              flex-flow: row;
            }
          }
          
          li:not(:first-child) {
            padding-top: 8px;

            &:before {
              content: '';
              width: 4px;
              height: 4px;
              background: ${vars.colors.primary8};
              border-radius: 100%;
              margin-bottom: 8px;
              display: block;
            }

            ${vars.breakpoints.sm} {
              padding: 0 0 0 24px;

              &:before {
                margin: 0 24px 0 0;
              }
            }
          }
        }

        .lp-hero-info {
          order: 1;
          padding: 24px 16px;

          ${vars.breakpoints.sm} {
            padding: 40px;
          }

          ${vars.breakpoints.md} {
            order: 0;
          }
        }

        .lp-hero-eyebrow {
          color: ${vars.colors.primary1};
          font-family: ${vars.fonts.familyPrimary};
          font-size: 34px;
          font-style: normal;
          font-weight: 500;
          line-height: 95%; /* 32.3px */
          letter-spacing: 0.68px;
          text-transform: uppercase;
        }

        .lp-hero-heading {
          color: ${vars.colors.secondary4};
          font-family: ${vars.fonts.familyPrimary};
          font-size: 48px;
          font-style: normal;
          font-weight: 500;
          line-height: 52px; /* 108.333% */
          letter-spacing: -1.44px;
          text-transform: uppercase;
          margin: 12px 0 !important;
        }

        .lp-hero-copy {
          font-family: ${vars.fonts.familyTertiary};
          font-size: 22px;
          font-style: normal;
          font-weight: 800;
          line-height: 28px;
          letter-spacing: -0.22px;

          span {
            color: ${vars.colors.primary1};
          }
        }

        .lp-hero-voucher {
          background: ${(vars.colors.bgColors as unknown as { [key: string]: string }).whiteWine};
          color: #033A6B;
          padding: 8px 16px;
          font-family: ${vars.fonts.familySecondary};
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 32px;
          margin-top: 12px;

          ${vars.breakpoints.sm} {
            padding: 10px 16px;
          }

          img, svg {
            width: 24px;
            height: 24px;
            object-fit: contain;
            margin-right: 16px;
          }
        }

        .view-product-button {
          padding: ${vars.space.md};
          border: 1px solid ${vars.colors.black};
          padding: 12px 24px 12px 24px;
          font-family: ${vars.fonts.familySecondary};
          font-weight: ${vars.fonts.weightMediumPlus};
          background-color: ${vars.colors.btnsPrimaryBg};
          display: inline-block;
          font-size: ${vars.fonts.sizeH5};
          letter-spacing: 0.42px;
          color: ${vars.colors.white};
          text-transform: uppercase;
          border-radius: 5px;
          width: 100%;
          text-align: center;
        }
        .view-product-button:hover,
        .view-product-button:focus {
          background-color: ${vars.colors.btnsPrimaryHoverBg};
          color: ${vars.colors.btnsPrimaryHoverColor};
          text-decoration: none;
        }

        .lp-hero-info-active {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin: 32px 0;

          span {
            margin-left: 4px;
          }

          div + span {
            margin-left: 8px;
          }
        }
        
        .grid-addto-cart-btn {
          width: 100%;
        }

        .mcs-selector__list {
          margin-top: 8px;
          align-items: center;
          max-width: calc(100vw - 32px);
        }
      `,
    caseSelectors: `
        margin-top: 40px;


        button {
          padding: 12px 24px !important;
          border: 1px solid #222222 !important;
          background: transparent !important;
          text-transform: uppercase !important;

          color: ${vars.colors.black};
          text-align: center;

          /* Laithwaites/Desktop/Utility */
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px; /* 114.286% */
          letter-spacing: 0.42px;
          text-transform: uppercase;
          display: flex;
          justify-content: center;
          flex: 1;

          &[aria-selected="true"] {
            background: ${vars.colors.black} !important;
            color: ${vars.colors.white} !important;
          }

          span {
            max-width: 188px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      `,
    benefitsSection: `
        padding: 56px 0 32px;
        
        ${vars.breakpoints.sm} {
          padding: 80px 0;
        }

        h2 {
          text-align: center;
        }

        h3 {
          font-family: ${vars.fonts.familyTertiary};
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px; /* 127.273% */
          letter-spacing: -0.22px;
          text-transform: none;
        }

        .lp-benefits-items {
          display: grid;
          grid-gap: 24px;
          margin-top: 32px;

          ${vars.breakpoints.sm} {
            grid-template-columns: 1fr 1fr;
            grid-gap: 40px 20px;
            margin-top: 40px;
          }

          ${vars.breakpoints.md} {
            grid-template-columns: 1fr 1fr 1fr 1fr;
            grid-gap: 20px;
            margin-top: 40px;
          }
        }

        .lp-benefits-item {
          flex: 1;
          max-width: 345px;
          margin: 0 auto;

          ${vars.breakpoints.sm} {
            max-width: none;
          }

          > div {
            display: flex;
            align-items: center;
            margin-bottom: 12px;
          }

          .image-wrap {
            position: relative;
            width: 56px;
            height: 56px;
            border-radius: 100%;
            padding: 12px;
            background: #FEF5F8;
            margin-right: 12px;
          }

          h3 {
            max-width: 209px;
          }

          p {
            margin-bottom: 0;
          }
        }
      `,
    highlightSection: `
        padding: 40px 0 32px;
        
        ${vars.breakpoints.sm} {
          padding: 0 0 80px;
        }

        h2 {
          text-align: center;
        }

        .lp-highlight-items {
          display: grid;
          grid-gap: 80px;
          margin-top: 32px;

          ${vars.breakpoints.md} {
            display: flex;
            justify-content: center;
            grid-gap: 20px;
            margin-top: 40px;
          }
        }

        .lp-highlight-item {
          position: relative;
          color: ${vars.colors.white};
          background-color: ${vars.colors.secondary4};
          border-radius: 4px;
          padding: 24px 16px;

          ${vars.breakpoints.sm} {
            padding: 32px;
          }

          ${vars.breakpoints.md} {
            padding: 32px;
            width: 50%;
          }

          h3 {
            width: 212px;
            max-width: calc(100% - 120px);

            ${vars.breakpoints.sm} {
              width: 332px;
              max-width: calc(100% - 200px);
            }
          }

          p {
            margin-bottom: 0;

            ${vars.breakpoints.sm} {
              width: 332px;
              max-width: calc(100% - 200px);
            }
          }

          img {
            position: absolute;
            width: 120px;
            height: 120px;
            object-fit: contain;
            right: 0;
            top: 0;
            transform: translateY(-50%);

            ${vars.breakpoints.sm} {
              width: 200px;
              height: 200px;
              right: 16px;
              bottom: 16px;
              top: auto;
              transform: none;
            }
          }
        }
      `,
    contentBanner: `
        background: #E9ECEF;
        
        ${vars.breakpoints.sm} {
          margin-bottom: 140px;
          padding: 40px 0 0;
        }

        > div {
          margin: 0;
          ${vars.breakpoints.sm} {
            margin-left: 32px;
            margin-right: 32px;
          }

          ${vars.breakpoints.lg} {
            margin-left: 72px;
            margin-right: 72px;
          }

          ${vars.breakpoints.xxl} {
            margin: 0 auto;
          }
        }

        .image-wrap {
          ${vars.breakpoints.sm} {
            margin-bottom: -60px;
          }
        }

        img {
          width: 100%;
          object-fit: cover;
        }

        .lp-content-banner-inner {
          ${vars.breakpoints.sm} {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 24px;
            align-items: center;
          }

          ${vars.breakpoints.md} {
            grid-gap: 68px;
          }
        }

        .lp-content-banner-info {
          max-width: 580px;
          padding: 24px 16px;
        }
      `,
    caseTabs: `
        background: ${vars.colors.primary3} !important;
        max-width: 100%;
        padding: 0 0 32px;
        margin-top: 56px;

        ${vars.breakpoints.sm} {
          padding: 24px 0 56px;
        }

        &:before {
          content: none !important;
        }

        .lp-case-selectors {
          margin: 0;
        }

        .mcs-selector__list {
          margin: 0;
          padding: 0 !important;
          border-bottom: 1px solid ${vars.colors.secondary9};


          button {
            padding: 16px 20px !important;
            background: none !important;
            border: none !important;
            border-bottom: 2px solid transparent !important;
            color: ${vars.colors.secondary9} !important;
            border-radius: 0;

            &[aria-selected="true"] {
              background: transparent !important;
              color: ${vars.colors.black} !important;
              border-color: ${vars.colors.black} !important;
            }
          }
        }
      `,
    caseTabPanel: `
        .mantine-Tabs-tab,
        .case-tab-navigation {
          width: 40px !important;
          height: 40px !important;
          border-radius: 4px;
          padding: 0 !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          opacity: 1 !important;

          .mantine-Tabs-tabLabel {
            color: ${vars.colors.black};
            text-align: center;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 24px !important;
            margin: 0 !important;
          }
          
          &[data-active] {
            background-color: ${vars.colors.primary1} !important;

            .mantine-Tabs-tabLabel {
              color: ${vars.colors.white};
            }
          }

          &.mobile-hidden {
            @media screen and (max-width: 1023px) {
              display: none !important;
            }
          }
        }

        .case-tab-info {
          display: flex;
          align-items: center;
        }

        .case-tab-navigation {
          margin-right: 12px;

          &:last-child {
            margin-right: 0;
            margin-left: 12px;
          }

          .mantine-Tabs-tabLabel {
            line-height: 1 !important;
          }
        }
      `,
    caseTabPane: `
        color: ${vars.colors.primary8};
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */

        .lp-tabpane-inner {
          display: grid;
          grid-gap: 24px;
          max-width: 1128px;
          margin: 0 auto;
          padding: 0 16px;
          
          ${vars.breakpoints.sm} {
            grid-template-columns: 360px 1fr;
          }
        }

        .lp-tabpane-title {
          display: grid;
          grid-gap: 20px;
          text-align: center;

          * {
            margin: 0 auto;
          }
        }

        .lp-tabpane-info {
          display: grid;
          grid-gap: 24px;
          align-content: flex-start;

          * {
            max-width: 600px;
            word-wrap: break-word;
          }
        }

        .lp-tabpane-names {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 8px 20px;

          ${vars.breakpoints.sm} {
            grid-template-columns: 1fr 1fr;
            grid-gap: 12px 20px;
          }
        }

        .lp-tabpane-flavor {
          display: grid;
          grid-gap: 12px;

          .item {
            display: flex;
          }

          .item-title,
          .item-subtitle {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
          }

          .item-title:after {
            content: ':';
            margin-right: 4px;
          }
        }
      `,
    testimonialsSection: `
        padding: 56px 0;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */

        ${vars.breakpoints.md} {
          padding: 80px 0;
        }

        p {
          margin: 0 !important;
        }

        .lp-testimonial-items {
          ${vars.breakpoints.sm} {
            display: flex;
            gap: 20px;
            margin-top: 32px;

            > div {
              flex: 1;
              padding: 0 20px;
              text-align: left;
            }
          }

          ${vars.breakpoints.md} {
            margin-top: 40px;
          }
        }

        .mantine-Carousel-controls {
          max-width: 60%;
        }

        .image-wrap {
          width: 64px;
          height: 64px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          background-color: #FEF5F8;
          margin-right: 16px;

          img {
            width: 32px;
            height: 32px;
            object-fit: contain;
          }

          ${vars.breakpoints.md} {
            margin-right: 20px;
          }
        }

        .lp-testimonial-item-info {
          display: flex;
          align-items: center;
        }

        .lp-testimonial-item-name-date {
          display: grid;
          gap: 6px;

          ${vars.breakpoints.md} {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            flex: 1;
          }
        }

        .lp-testimonial-item-content {
          padding-top: 16px;
          margin-top: 16px;
          border-top: 1px solid ${vars.colors.secondary9};

          ${vars.breakpoints.sm} {
            padding-top: 20px;
            margin-top: 20px;
          }
        }

        .lp-testimonial-logo {
          margin-top: 32px;

          ${vars.breakpoints.md} {
            margin-top: 24px;
          }

          img {
            object-fit: contain;
          }
        }

        .lp-review-title {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
          text-transform: capitalize;
        }
      `,
    imageCardsSection: `
        background: #FAF3E6;
        padding: 56px 0;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22.4px;

        ${vars.breakpoints.md} {
          padding: 80px 0;
        }

        .section-header {
          max-width: 1000px;
          margin: 0 auto;
          padding: 0 16px;
        }

        p {
          margin: 0 !important;
        }

        .mantine-Carousel-controls {
          max-width: 60%;
        }

        .lp-imagecards-items {
          ${vars.breakpoints.sm} {
            display: flex;
            gap: 24px;
            margin-top: 32px;
            
            > div {
              flex: 1;
              text-align: left;
            }
          }

          ${vars.breakpoints.md} {
            margin-top: 40px;
          }
        }

        .lp-imagecards-item {
          display: grid;
          grid-gap: 16px;
        }

        .lp-imagecards-item-eyebrow + .lp-imagecards-item-title {
          margin-top: 8px;
        }

        .lp-imagecards-item-eyebrow {
          color: ${vars.colors.primary1};
          font-size: 14px;
          font-weight: 700;
          font-family: ${vars.fonts.familyPrimary};
          line-height: 1.4;
          text-transform: uppercase;
        }

        .lp-imagecards-item-title {
          font-size: 24px;
          font-family: ${vars.fonts.familyTertiary};
        }

        .image-wrap {
          position: relative;
          height: 0;
          padding-top: 126%;
          border-radius: 5px;
          overflow: hidden;

          img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      `,
  },
  CmsVoucherWidget: {
    main: `
      position: relative;
      padding: 80px 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      ${vars.breakpoints.xxl} {
        padding: 5.55vw 24px;
      }

      .cms-image-container img {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      .voucher-widget__card {
        position: relative;
        padding: 60px 24px 32px;
        background: ${vars.colors.white};
        border-radius: 8px;
        display: grid;
        grid-gap: 24px;
        max-width: 496px;
        
        button {
          text-transform: uppercase;
          letter-spacing: 0.03em;
        }

        ${vars.breakpoints.md} {
          grid-gap: 32px;
          padding: 60px 40px 40px;
        }
      }

      .voucher-widget__text {
        display: grid;
        grid-gap: 6px;
        ${vars.breakpoints.md} {
          grid-gap: 8px;
        }
      }

      .voucher-widget__form,
      .voucher-widget__form form {
        display: grid;
        grid-gap: 24px;
        ${vars.breakpoints.md} {
          grid-gap: 32px;
        }
      }

      .voucher-widget__form .field-container input {
        height: 44px;
      }
      
      .voucher-widget__form .field-container input:focus~label, 
      .voucher-widget__form .field-container input:-webkit-autofill~label,
      .voucher-widget__form .field-container input:not(:placeholder-shown)~label {
        top: -5px;
      }
      
      .voucher-widget__eyebrow {
        font-family: ${vars.fonts.roboto};
        font-weight: ${vars.fonts.weightBold};
        font-size: ${vars.fonts.sizeLarge};
        line-height: 1.4;
        text-transform: uppercase;
      }

      h1.voucher-widget__headline {
        margin: 0;
      }

      .voucher-widget__promo-text {
        position: absolute;
        left: 0;
        top: 16px;
        padding: 5px 8px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        font-family: ${vars.fonts.familySecondary};
        background: ${vars.colors.primary9};
        color: ${vars.colors.white};

        ${vars.breakpoints.md} {
          padding: 6px 10px;
        }
      }

      .voucher-widget__subcta * {
        font-size: ${vars.fonts.sizeDefault} !important;

        &:last-child {
          margin: 0 !important;
        }
      }
      .voucher-widget__subcta a {
        text-decoration: underline;
      }
    `,
  },
});

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsLW, buildThemeMixinsLW, buildThemeWidgetStylesLW);
export default createTheme;
