/* eslint-disable @typescript-eslint/no-var-requires */
import React, { createElement, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import type {
  AutocompleteApi,
  AutocompleteCollection,
  AutocompleteOptions,
  AutocompleteRenderer,
  AutocompleteSource,
  AutocompleteState,
  HTMLTemplate,
} from '@algolia/autocomplete-js';
import { autocomplete } from '@algolia/autocomplete-js';
import { productLinkBuilder } from 'common-next/src/components/common-functions';
import type { RecentSearchesItem } from '@algolia/autocomplete-plugin-recent-searches/dist/esm/types';
import '@algolia/autocomplete-theme-classic/dist/theme.min.css';
import { css, useTheme } from '@emotion/react';
import { render } from 'react-dom';
import { useHierarchicalMenu, useSearchBox } from 'react-instantsearch';
import { useRecoilValue, selectedStateAtom } from 'common-state';
import { handleDLClickEvent } from '../../data-layer/handleDLClickEvent';
import {
  Icon,
  Grid,
  Button,
  globals,
  searchClientConfig,
  useAlgoliaAuthenticatedUser,
  useAlgoliaInit,
} from 'common-ui';
import {
  createRecentSearchesPlugin,
  createProductsPlugin,
  createRecommendPlugin,
  createCollectionPlugin,
  createQuerySuggestionsPlugin,
  redirectUrlPlugin,
} from './plugins';

// Utils
import {
  cancelSearch,
  handleSuggestionOpen,
  popularSearch,
  recentSearch,
  redirectionURL,
  suggestedProducts,
  resultsCustomConfig,
} from './utils';

export declare type BaseItem = Record<string, unknown>;
export interface AlgoliaSearchProps extends Partial<AutocompleteOptions<BaseItem>> {
  onCancelSearch?: () => void;
  isSticky?: boolean;
  setSearchOverLay: () => number;
}

export interface TransformSourceParams {
  source: AutocompleteSource<RecentSearchesItem>;
  state: AutocompleteState<RecentSearchesItem>;
  onRemove(id: string): void;
  onTapAhead(item: RecentSearchesItem): void;
}

declare global {
  interface Window {
    aa: (action: string, userToken: string) => void;
    dataLayer: any[];
  }
}

export interface ExtendedAlgoliaSearchResult extends BaseItem {
  skuItemCodes?: string[] | undefined;
  productName?: string;
  threeTierStates?: string[] | undefined;
  wineryDirectStates?: string[] | undefined;
}

export default function AlgoliaSearch({
  onCancelSearch,
  isSticky,
  setSearchOverLay,
  ...autocompleteProps
}: AlgoliaSearchProps): React.JSX.Element {
  const [suggestionPanelTop, setSuggestionPanelTop] = useState<number>(0);
  const panelRef = useRef<HTMLDivElement>(null);
  // const { algoliaAppId, algoliaSearchKey } = globals as Record<string, string>;
  const autocompleteContainer = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const autocompleteInstance = useRef<AutocompleteApi<BaseItem> | null>(null);

  const currentState = useRecoilValue(selectedStateAtom);

  const { query } = useSearchBox();

  const [instantSearchUiState, setInstantSearchUiState] = useState({ query });

  const { items: categories } = useHierarchicalMenu({
    attributes: ['hierarchicalCategories.lvl0', 'hierarchicalCategories.lvl1'],
  });

  const currentCategory = useMemo(() => categories.find(({ isRefined }) => isRefined)?.value, [categories]);

  const wrapper = css`
    ${theme.widgets.Search?.searchBoxWrapper}
  `;
  const autocompletePanel = css`
    ${theme.widgets.Search?.autocompletePanel}
  `;
  const searchClient = searchClientConfig();

  // Init the Algolia search insights
  useAlgoliaInit();

  // Set the Algolia authenticated user token
  useAlgoliaAuthenticatedUser();

  /**
   * Logic to send user to either Endeca or Algolia Search based on
   * the platform the search is rendered on
   */

  /**
   * Fires the Algolia API based on query being available
   * @param {*} query
   * @returns true
   */

  /**
   * Handles older Endeca clicks and conversion
   */
  useEffect(() => {
    const aa = require('search-insights');

    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const queryId = searchParams.get('queryId');

    const handleClick = (e: any) => {
      const eventName = e.target.getAttribute('data-event-name');
      const itemCode = e.target.getAttribute('data-itemcode');
      const position = e.target.getAttribute('data-position');

      if (queryId) {
        aa('clickedObjectIDsAfterSearch', {
          index: globals.algoliaIndexName,
          eventName,
          queryID: queryId,
          objectIDs: [itemCode],
          positions: [+position],
        });
      }
    };

    const handleConverted = (e: any) => {
      const itemCode = e.detail.itemcode;
      if (queryId) {
        aa('convertedObjectIDsAfterSearch', {
          index: globals.algoliaIndexName,
          eventName: 'Algolia Added to Basket',
          queryID: queryId,
          objectIDs: [itemCode],
        });
      }
    };

    const buttons = document.querySelectorAll('.js-algolia-click-objects');

    buttons.forEach(btn => {
      btn.addEventListener('click', handleClick);
    });

    document.addEventListener('item-added-into-cart-successful', handleConverted);

    return () => {
      buttons.forEach(btn => {
        btn.removeEventListener('click', handleClick);
      });

      document.removeEventListener('item-added-into-cart-successful', handleConverted);
    };
  }, []);

  const plugins = useMemo(
    () => [
      createRecentSearchesPlugin(setInstantSearchUiState),
      createQuerySuggestionsPlugin(searchClient),
      createProductsPlugin(searchClient, currentState),
      ...(globals?.algoliaNewView
        ? [createRecommendPlugin(searchClient, currentState), createCollectionPlugin(searchClient)]
        : []),
      redirectUrlPlugin,
    ],
    [currentCategory, currentState, globals?.algoliaNewView],
  );

  const overlayStyles = css`
    ${theme.widgets.Search?.searchOverlay};
  `;

  const viewAllItemsLink = (
    products: AutocompleteCollection<BaseItem> | undefined,
    html: HTMLTemplate,
    stateQuery: string,
  ) => {
    if (products && products.items && products.items.length >= resultsCustomConfig().viewAllItemsLength) {
      return html`
        <div class="view-all-items-container">
          <a href="/wines?Ntt=${stateQuery}">${!globals?.algoliaNewView ? 'See all results' : 'Show more products'}</a>
        </div>
      `;
    }
  };

  useEffect(() => {
    if (!autocompleteContainer.current || !panelRef.current) {
      return;
    }
    autocompleteInstance.current = autocomplete({
      ...autocompleteProps,
      container: autocompleteContainer.current,
      panelContainer: panelRef.current,
      placeholder: 'Search for products',
      detachedMediaQuery: 'none',
      openOnFocus: true,
      insights: true,
      plugins,
      onReset() {
        setInstantSearchUiState({ query: '' });
      },
      onSubmit({ state }) {
        const productLink = productLinkBuilder(state.context.productName, state.context.vintage, state.query);

        if (state?.query && ((state?.context?.skuItemCodes as string[]) || []).includes(state.query)) {
          window.location.href = productLink;
        } else {
          redirectionURL(state.query);
        }

        // data click event to AdobeLayer
        handleDLClickEvent('search', 'search');
      },
      // Update the state of the search results as we type, currently disabled
      onStateChange({ state }) {
        handleSuggestionOpen(state.isOpen, panelRef, setSearchOverLay, setSuggestionPanelTop);
      },
      initialState: {
        query: instantSearchUiState?.query,
      },
      // eslint-disable-next-line @typescript-eslint/no-shadow
      render({ elements, render, html, state }, root) {
        const { products, recommendPlugin, collectionPlugin, querySuggestionsPlugin, recentSearchesPlugin } = elements;
        /**
         * Get the item length for the product index, so we can ad a link if item
         * length is greater than 10
         */
        const productsArray = state.collections.find(item => item.source.sourceId === 'products');
        render(
          <div css={autocompletePanel} className="aa-PanelLayout aa-Panel--scrollable">
            <div className="ui-container">
              <div className="ui-plugins">
                <div
                  id="query-suggestions"
                  className="query-suggestions"
                  onClick={popularSearch}
                  onKeyDown={popularSearch}
                  tabIndex={0}
                  role="button"
                >
                  {querySuggestionsPlugin}
                </div>
                {globals?.algoliaNewView && <div className="collection-search">{collectionPlugin}</div>}
                <div id="recent-searches" onClick={recentSearch} onKeyDown={recentSearch} tabIndex={0} role="button">
                  {recentSearchesPlugin}
                </div>
              </div>
              <div
                className="ui-products"
                onClick={suggestedProducts}
                onKeyDown={suggestedProducts}
                tabIndex={0}
                role="button"
              >
                <div>{products}</div>
                <div>{viewAllItemsLink(productsArray, html, state.query)}</div>
              </div>
              {globals?.algoliaNewView && (
                <div className="ui-recommended">
                  <div>{recommendPlugin}</div>
                </div>
              )}
            </div>
          </div>,
          root,
        );
      },
      renderer: { createElement, Fragment, render } as AutocompleteRenderer,
    });

    return () => autocompleteInstance.current?.destroy();
  }, [plugins]);

  const panelContainerStyle = css`
    .aa-Panel {
      position: fixed;
      top: 112px !important;
    }
    ${theme.breakpoints.lg} {
      position: relative;
      top: 0;
      .aa-Panel {
        position: absolute;
        top: ${suggestionPanelTop}px !important;
        left: 0 !important;
        width: calc(100% + 400px) !important;
      }
    }

    ${globals?.algoliaNewView &&
    `
        .aa-Panel {
          border-radius: 0px 0px 25px 25px;
          .aa-PanelLayout {
            padding: 8px 5px 0;
          }
          ${theme.breakpoints.lg} {
            width: calc(100% + 700px) !important;
            padding-left: 10px;
            left: -350px !important;
            box-shadow: none;
              .aa-PanelLayout {
                padding: 8px 20px 0;
                .ui-container {
                  gap: 80px;
                }
              }
          }
        }
        .ui-plugins {
          .query-suggestions {
            .aa-Item: hover {
              text-decoration: underline;
            }
          }
          .query-suggestions, .collection-search {
            .aa-Item {
              &[aria-selected = "true"] {
                background: transparent;
              }
            }
          }
        }
        .ui-products {
          max-width: 460px;
          .aa-Item {
            margin-bottom: 30px;
            &:hover {
              background: #F8F8F8 !important;
            }
            &[aria-selected = "true"] {
              background: #F8F8F8 !important;
            }
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
        .ui-recommended {
          .aa-Item {
            &:hover {
              background-color: #F8F8F8 !important;
            }
            &[aria-selected = "true"] {
              background-color: #F8F8F8 !important;
            }
          }
        }
        .aa-ItemWrapper {
          .aa-ItemContent {
            .aa-ItemContentBody {
              gap: 10px;
              .aa-ItemContentCountry {
                color: #616161;
              }
              .aa-ItemContentPrice {
                .strike {
                  color: #616161;
                  text-decoration: line-through;
                }
              }
            }
          }
        }
      `}
  `;
  return (
    <>
      <div
        className="search-overlay"
        css={overlayStyles}
        onClick={() => cancelSearch(autocompleteInstance, onCancelSearch)}
        onKeyDown={() => cancelSearch(autocompleteInstance, onCancelSearch)}
        tabIndex={0}
        aria-label="Search"
        role="button"
      ></div>
      <Grid align="center" className="searchbar-grid">
        <Grid.Col className="w-100">
          <div className="searchbar-input-container">
            <div css={wrapper} ref={autocompleteContainer} />
            <span className={isSticky ? 'isSearchBar' : 'prefix-icon'}>
              <Icon kind="search" size="s" />
            </span>
          </div>
        </Grid.Col>
        <Grid.Col
          xs="auto"
          className={isSticky ? 'isStickyCancelContainer searchbar-cancel-container' : 'searchbar-cancel-container'}
        >
          {onCancelSearch && (
            <Button
              className={isSticky ? 'isStickyCancelButton searchbar-cancel-link' : 'searchbar-cancel-link'}
              onClick={() => cancelSearch(autocompleteInstance, onCancelSearch)}
              aria-label="Cancel Search"
            >
              Cancel
            </Button>
          )}
        </Grid.Col>
      </Grid>
      <div id="panel-container" css={panelContainerStyle} ref={panelRef} />
    </>
  );
}
