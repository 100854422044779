import type { TextInputProps } from './types';
import React from 'react';

import { CheckIcon, ErrorIcon, WarningIcon } from '../../svg';
import { StyledTextInput } from './TextInput.styled';

const TextInput: React.FC<TextInputProps> = props => {
  const { loading, label, status, message, name, required, disabled, value, ...rest } = props;

  const disabledState = disabled || loading;

  return (
    <StyledTextInput className={`${status && !disabledState ? status : ''}`} {...props}>
      <div className={`field-container`}>
        <input disabled={disabledState} required={required} name={name} {...rest} value={!loading ? value : ''} />
        {status === 'success' && !disabledState && (
          <span className="suffix-icon">
            <CheckIcon />
          </span>
        )}
        {loading && <span className="loader"></span>}
        {!loading && (
          <label htmlFor={name}>
            {label} {required && <span>*</span>}
          </label>
        )}
      </div>
      {status && !disabledState && message && (
        <p className={`feedback`}>
          {status === 'error' ? <ErrorIcon /> : <WarningIcon />} {message}
        </p>
      )}
    </StyledTextInput>
  );
};

TextInput.defaultProps = {
  type: 'text',
  placeholder: ' ',
  disabled: false,
};

export default TextInput;
