// TODO Remove local json file, once we moved the content to Contentful
import USStateData from '../../../../utils/USStateData';
import { Modal, Select } from '@mantine/core';
import { Button } from '../../../Button/Button';
import { css, useTheme } from '@emotion/react';
import React, { memo, useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Icon } from '../../../Icon/Icon';
import { useRecoilState } from 'recoil';
import { selectedStateAtom } from 'common-state';
import { logger } from 'common-ui';
import { globals } from '../../../../utils/globals';
import addGeolocationScript, { disableStatePopupCookie, getCookie } from '../../../../utils/getGeoLocation';
import { initCommerceApiWithGlobals } from 'common-api';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';
import Link from 'next/link';

interface StateSelectorProps {
  view?: 'mobile' | 'desktop';
}

const StateSelector = memo((props: StateSelectorProps) => {
  const { view = 'desktop' } = props;
  const theme = useTheme();
  const stateSelector = css`
    ${theme.widgets.StateSelector?.default}
  `;
  const location = css`
    ${theme.widgets.StateSelector?.locationWrapper}
  `;
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [stateError, setStateError] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [selectedLocation, setSelectedLocation] = useRecoilState(selectedStateAtom);

  const getShippableBrands = (stCode: string) =>
    USStateData.filter(
      state =>
        state.stateCode === stCode &&
        state?.shippableBrands.length > 0 &&
        (state.shippableBrands.includes(globals?.brandId as never) || state.shippableBrands.includes('all' as never)),
    );

  const handlePageReload = () => {
    const path = window.location.pathname;
    if (path.startsWith('/wine') || path.startsWith('/search')) {
      window.location.reload();
    }
  };

  const postLocationData = async (stCode: string) => {
    try {
      const commerceApi = initCommerceApiWithGlobals();
      const response = await commerceApi.User.userSetLocation({
        stateCode: stCode,
      });
      logger.info('Location :', response);
      // Reload page if State changed from 3T to WD on the PLP page
      handlePageReload();
    } catch (error) {
      logger.error('Location Context:', error);
    }
  };
  const { screen } = useScreenLayout();

  const handleClosePopup = () => {
    close();
  };

  const handleStateSubmit = () => {
    const isShippable = getShippableBrands(selectedValue);
    const getSelectedStateContent = USStateData?.filter(state => state.stateCode === selectedValue).map(state => state);

    setSelectedState(getSelectedStateContent[0]?.stateName);

    if (isShippable.length) {
      //TODO: fix the type error
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setSelectedLocation(selectedValue);
      postLocationData(selectedValue);
      handleClosePopup();
    } else {
      setStateError(true);
    }
  };

  const getDeliveryFaqLink = () => {
    let deliveryLink = '/faq#/shipping-and-delivery/what-states-do-you-deliver-to';

    if (['law', 'tcm'].includes(globals?.brandId as never)) {
      deliveryLink += '-law';
    } else if (['osw', 'npr'].includes(globals?.brandId as never)) {
      deliveryLink += `-${globals?.brandId}`;
    }

    return deliveryLink;
  };

  useEffect(() => {
    const shoppinglocation = getCookie('shoppinglocation');
    const showEmbeddedStatePopup = getCookie('showEmbeddedStatePopup');
    const stateLocation = shoppinglocation ? shoppinglocation?.split('-')?.[1] : null;

    const setState = (state: string) => {
      setSelectedLocation(state);
      setSelectedValue(state);
    };
    const openPopup = () => {
      const isDesktopView = ['lg', 'xl', 'xxl'].includes(screen);
      if ((view === 'desktop' && isDesktopView) || (view === 'mobile' && !isDesktopView)) {
        open();
        disableStatePopupCookie();
      }
    };
    if (stateLocation) {
      setState(stateLocation);
      if (showEmbeddedStatePopup === 'true') {
        openPopup();
      }
    } else {
      addGeolocationScript(setState);
      openPopup();
    }
  }, []);

  return (
    <>
      <div onClick={open} css={location} tabIndex={0} role="button" onKeyDown={e => e.key === 'Enter' && open()}>
        <Icon kind="location" size="m" />
        <div className="location">
          <span className="location-text">
            Shipping to <strong>{selectedLocation}</strong>
          </span>
          <Icon kind="chevron-down" size="xs" />
        </div>
      </div>

      <Modal
        zIndex={10}
        className="state-selector-modal vine-react-app" // Added vine-react-app class to apply typography in state selector in ak sites
        size={785}
        opened={opened}
        onClose={close}
        centered
        withCloseButton={false}
        css={stateSelector}
        closeOnClickOutside={false}
      >
        <div className="modal-body-inner text-center">
          <div className="selector-title">Before your wine adventure begins...</div>
          <div className="selector-subtitle">
            {stateError ? (
              <>
                <span className="error-text">
                  We're sorry. Due to regulations, we cannot ship wine to {selectedState}.
                </span>
                <br /> <span>Please change your selection to proceed.</span>
              </>
            ) : (
              'Tell us where to send your wine.'
            )}
          </div>
          <div className="state-selection">
            <Select
              data-testid="state-selector-field"
              maxDropdownHeight={250}
              placeholder="Select your shipping state"
              data={USStateData?.map(state => ({
                label: state.stateName,
                value: state.stateCode,
              }))}
              styles={{ rightSection: { pointerEvents: 'none' } }}
              rightSection={<Icon kind="chevron-down" size="m" />}
              dropdownPosition="flip"
              withinPortal={false}
              shadow="md"
              zIndex={1000}
              transitionProps={{ transition: 'scale-y', duration: 80, timingFunction: 'ease' }}
              classNames={{
                item: 'dropdown-item',
              }}
              value={selectedValue}
              onChange={(value: string) => {
                setSelectedValue(value);
                setStateError(false);
              }}
            />
            <Button
              kind="primary"
              disabled={!selectedValue}
              className="cursor-pointer verify-state-btn"
              onClick={handleStateSubmit}
              data-testid="state-selector-verify-button"
            >
              Verify
            </Button>
          </div>
          <div className="state-selector-terms">
            By clicking "Verify," you acknowledge that you are at least 21 years old.
          </div>
          <div className="state-selector-compliance">
            All orders are accepted and fulfilled by a{' '}
            <Link
              href={getDeliveryFaqLink()}
              data-testid="faq-link"
              title="What states do you deliver to?"
              target="_blank"
              className="text-center"
              prefetch={false}
            >
              licensed retailer or winery
            </Link>{' '}
            in your selected delivery state.
          </div>
        </div>
      </Modal>
    </>
  );
});

export default StateSelector;
