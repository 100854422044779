import React from 'react';
import { inspectorMode } from 'common-ui';

export interface RichTextProps {
  sysId: string;
  content: string;
}

const RichText: React.FC<RichTextProps> = ({ sysId, content }) => (
  <div dangerouslySetInnerHTML={{ __html: content }} {...inspectorMode(sysId, 'content')} />
);

export default RichText;
