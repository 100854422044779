import React from 'react';
import { PRODUCTTYPE_TO_NOT_SHOW_BOTTLE_COUNT } from 'common-ui';

interface BottleCountProps {
  productType?: string;
  bottleType?: string;
  numberOfBottles?: number;
}

export const getBottleLabel = (bottleType: string) => {
  let bottleLabel: string;
  if (bottleType === 'Can') {
    bottleLabel = 'Can';
  } else if (bottleType === 'Bag in Box' || bottleType === 'Tetra Pack') {
    bottleLabel = 'Box';
  } else {
    bottleLabel = 'Bottle';
  }
  return bottleLabel;
};

export const BottleCount: React.FC<BottleCountProps> = ({ productType, bottleType = 'Wine', numberOfBottles }) => {
  if (
    !productType ||
    numberOfBottles === 0 ||
    numberOfBottles === undefined ||
    PRODUCTTYPE_TO_NOT_SHOW_BOTTLE_COUNT.map(type => type.toLowerCase()).includes(productType.toLowerCase())
  ) {
    return null;
  }

  const bottleLabel = getBottleLabel(bottleType);
  const pluralSuffix = (numberOfBottles || 0) > 1 ? (bottleLabel === 'Box' ? 'es' : 's') : '';

  return (
    <div className="num-bottles">
      {numberOfBottles} {bottleLabel}
      {pluralSuffix}
    </div>
  );
};
