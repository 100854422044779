import { globals } from 'common-ui';
import { getQueryFilters, typeaheadCheck } from '../utils';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import type { GetSourcesParams, OnSelectParams } from '@algolia/autocomplete-core';
import { productLinkBuilder } from 'common-next/src/components/common-functions';
import type { SearchClient } from 'algoliasearch/lite';
import { RecommendedItem } from '../productCard/recommendedItem';
import type { BaseItem } from './types';
interface GetItemsParams {
  setContext: (context: any) => void;
  query: string;
}

const RecommendPluginHeader = () => <span className="aa-SourceHeaderTitle"> Recommended </span>;
const RecommendedPluginItem = ({ item, components }: any) => <RecommendedItem hit={item} components={components} />;

export function createRecommendPlugin(searchClient: SearchClient, currentState: string) {
  return {
    getSources({ query: sourceQuery }: GetSourcesParams<BaseItem>) {
      if (!sourceQuery || !typeaheadCheck(sourceQuery)) {
        return [];
      }
      return [
        {
          sourceId: 'recommendPlugin',
          getItems({ query: searchQuery }: GetItemsParams) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: globals.algoliaIndexName as string,
                  query: searchQuery,
                  params: {
                    clickAnalytics: true,
                    hitsPerPage: 1,
                    attributesToSnippet: ['name:10', 'Description:20'],
                    snippetEllipsisText: '…',
                    filters: getQueryFilters(currentState),
                  },
                },
              ],
              transformResponse: ({ hits }) => (hits[0]?.length ? [hits[0][0]] : []),
            });
          },
          onSelect({ item, state }: OnSelectParams<BaseItem>) {
            window.location.href = productLinkBuilder(
              item?.productName as string,
              item?.vintage as string,
              item?.itemCode as string,
              state.context.queryID?.toString() as string,
            );
          },
          templates: {
            header: RecommendPluginHeader,
            item: RecommendedPluginItem,
          },
        },
      ];
    },
  };
}
