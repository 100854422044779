import React, { useEffect, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { globals } from 'common-ui';
import Image from 'next/image';

export const CaseSelector = (props: any) => {
  const { from, view, type, activeCase, activeBottomCase, index, item, upsellSelected, handleClick } = props;
  const [imageURL, setImageURL] = useState('');
  const theme = useTheme();

  const cssCaseSelector = css`
    ${theme.widgets?.CmsMultiCaseSelector?.caseSelector}
  `;

  useEffect(() => {
    setImageURL(item?.largeImage as string);
  }, [item]);

  const handleImageError = () => {
    setImageURL(`${globals.publicHost}${globals.defaultImagePath}/default_case.png`);
  };

  const checkSelectedState = () => {
    let selected = false;
    if (from === 'target-panel') {
      if (activeBottomCase === index) {
        selected = true;
      }
    } else if ((type !== 'upsell' && activeCase === index) || upsellSelected) {
      selected = true;
    }
    return selected;
  };

  return (
    <button
      css={cssCaseSelector}
      data-test={index}
      className={view}
      key={`case-tab-${index}`}
      role="tab"
      aria-selected={checkSelectedState()}
      onClick={() => handleClick(index)}
    >
      {view !== 'simple' && (
        <Image
          src={imageURL}
          alt={item?.largeImage || 'Product Image'}
          className={'product-case-default-image'}
          width="168"
          height="86"
          onError={handleImageError}
          layout="responsive"
          objectFit="cover"
        />
      )}

      <span>{item?.name}</span>
    </button>
  );
};
