import { Noto_Serif, Roboto, Oswald } from 'next/font/google';

const notoSerifFont = Noto_Serif({
  weight: ['400', '600', '700'],
  style: ['normal'],
  subsets: ['latin'],
});
const robotoFont = Roboto({
  weight: ['400', '700', '900'],
  style: ['normal'],
  subsets: ['latin'],
});
const oswaldFont = Oswald({
  weight: ['500'],
  style: ['normal'],
  subsets: ['latin'],
});

export { notoSerifFont, robotoFont, oswaldFont };
