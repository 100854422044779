import React, { useState, useEffect } from 'react';
import { css, useTheme } from '@emotion/react';
import type { TimerProps } from 'tsconfig/types';
import { getCountDownTime } from '../../../utils/helperFunction';
import TimerSkeleton from '../../Skeleton/Timer/Timer';

export const CmsTimer = ({ contentItem }: TimerProps) => {
  const { headline, endTime, textColor } = contentItem?.fields || {};
  const theme = useTheme();
  const [text, setText] = useState('');
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    setIsPageLoaded(true);
  }, []);

  const getTime = (dt: string) => {
    setText(getCountDownTime(dt));
  };

  useEffect(() => {
    getTime(endTime);
    const interval = setInterval(() => getTime(endTime), 1000);
    return () => clearInterval(interval);
  }, []);

  const timerCss = css`
    color: ${textColor};
    ${theme.widgets.CmsTimer?.wrapper};
  `;
  if (!isPageLoaded) {
    return <TimerSkeleton />;
  }
  return text !== '' ? (
    <div data-test="widget-timer" css={timerCss}>
      {headline && <span className="timer-headline">{headline}</span>}
      <h2 className="timer-content">{text}</h2>
    </div>
  ) : null;
};
