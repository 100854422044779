import { globals } from 'common-ui';
import type { DiscountedPricePerQuantity, IncludedDiscount, LineItem, CartProps } from 'tsconfig/types.d';

export const transformAwsCartToVineCart = (vineCartData: CartProps | undefined): CartProps => {
  const version = vineCartData?.version || 0;
  const id = vineCartData?.id || '';
  const totalLineItems = vineCartData?.totalLineItems || 0;
  const numberOfBottles = vineCartData?.numberOfBottles || 0;
  const responseCode = vineCartData?.responseCode || '';
  const totalPrice = {
    amount: vineCartData?.totalPrice?.amount || 0,
    currencyCode: vineCartData?.totalPrice?.currencyCode || '',
  };
  const shippingInformation = {
    shippingMethodName: vineCartData?.shippingInformation?.shippingMethodName || '',
    price: {
      amount: vineCartData?.shippingInformation?.price?.amount || 0,
      currencyCode: vineCartData?.shippingInformation?.price?.currencyCode || '',
    },
    shippingMethodKey: vineCartData?.shippingInformation?.shippingMethodKey || '',
  };
  const lineItemSavings = {
    totalProductDiscountSavings: vineCartData?.lineItemSavings?.totalProductDiscountSavings ?? 0,
    totalCartDiscountSavings: vineCartData?.lineItemSavings?.totalCartDiscountSavings ?? 0,
    totalSavings: vineCartData?.lineItemSavings?.totalSavings ?? 0,
  };

  return {
    version,
    id,
    totalLineItems,
    numberOfBottles,
    responseCode,
    lineItems: setLineItems(vineCartData),
    totalPrice,
    shippingInformation,
    lineItemSavings,
  };
};

export function setLineItems(data: any): LineItem[] {
  const lineItems = data?.lineItems || data?.response?.lineItems || [];
  const lineItem: LineItem[] = lineItems.map((item: any) => {
    const baseItem: LineItem = {
      itemType: item?.itemType || '',
      lineItemId: item?.lineItemId || item?.id || '',
      quantity: item?.quantity || data?.response?.numItems || 0,
      sku: {
        productItemCode: item?.sku?.productItemCode || item?.sku?.itemCode || '',
        productName: [
          {
            value: item?.sku?.productName?.[0]?.value || item?.product?.name || '',
            key: item?.sku?.productName?.[0]?.key || item?.product?.name || '',
          },
        ],
        itemCode:
          globals?.country !== 'uk' && item?.lineItemIdentification?.depositInformation?.depositType === 'PRESELL'
            ? item?.lineItemIdentification?.depositInformation?.associatedItemCode
            : item?.sku?.itemCode || '',
        numberOfBottles: item?.sku?.numberOfBottles || item?.bottleCount || 0,
        schemeDetails: item?.sku?.schemeDetails || [],
      },
      unitPrice: {
        value: {
          amount: item?.unitPrice?.value?.amount || item?.itemPriceInfo?.amount || 0,
          currencyCode: item?.unitPrice?.value?.currencyCode || data?.response?.orderPriceInfo?.currencyCode || '',
        },
        discounted: {
          value: {
            amount: item?.unitPrice?.discounted?.value?.amount || 0,
            currencyCode: item?.unitPrice?.discounted?.value?.currencyCode || '',
          },
          discountReason: item?.unitPrice?.discounted?.discountReason || '',
        },
        listPrice: {
          amount: item?.unitPrice?.listPrice?.amount || item?.itemPriceInfo?.listPrice || 0,
          currencyCode: item?.unitPrice?.listPrice?.currencyCode || data?.response?.orderPriceInfo?.currencyCode || '',
        },
        priceType:
          item?.unitPrice?.priceType ||
          item?.itemPriceInfo?.pricingBreakdown?.[item?.itemPriceInfo?.pricingBreakdown?.length - 1]?.adjustmentType ||
          '',
      },
      discountedPricePerQuantity: setDiscountedPricePerQuantity(item),
      totalPrice: {
        amount: item?.totalPrice?.amount || 0,
        currencyCode: item?.totalPrice?.currencyCode || '',
      },
      savings: {
        totalCartDiscountSavings: item?.savings?.totalCartDiscountSavings || 0,
        productDiscountSavings: item?.savings?.productDiscountSavings || 0,
        totalProductDiscountSavings: item?.savings?.totalProductDiscountSavings || item?.itemPriceInfo?.savings || 0,
      },
      itemClassifications:
        item?.itemClassifications ||
        item?.lineItemIdentification?.itemClassifications?.contextClassificationDetails ||
        [],
    };

    if (!globals.useNewCartAPI) {
      baseItem.lineItemIdentification = {
        depositInformation: {
          associatedItemCode: item?.lineItemIdentification?.depositInformation?.associatedItemCode || '',
          depositType: item?.lineItemIdentification?.depositInformation?.depositType || '',
        },
      };
    }

    if (data?.response) {
      baseItem.product = {
        itemCode: item?.product?.itemCode || '',
        productType: item?.product?.productType || '',
        productWebType: '',
        productName: [{ key: '', value: '' }],
        productDescription: [{ key: '', value: '' }],
        productLongDescription: [{ key: '', value: '' }],
        webHeadline: null,
        altShortdescription: '',
        altMediumdescription: '',
        altLongdescription: '',
        appellationRegionDescription: [{ key: '', value: '' }],
        styleDescription: [{ key: '', value: '' }],
        genGrapeDescription: [{ key: '', value: '' }],
        countryRegionDescription: [{ key: '', value: '' }],
        genericRegionDescription: [{ key: '', value: '' }],
        shortTastingNotesColor: '',
        shortTastingNotesAroma: '',
        shortTastingNotesTaste: '',
        averageOverallRating: 0,
        totalReviewCount: 0,
        accolades: [
          {
            accoladeId: '',
            awardingCountry: '',
            accoladeTypeId: '',
            accoladeTypeDescription: '',
            awardTypeId: '',
            awardTypeDescription: '',
            accoladeDate: '',
            accoloadeYear: '',
            accoladeLevelId: '',
            accoladeLevelDescription: '',
            accoladeOrganizationId: '',
            accoladeOrgDescription: '',
            accoladeImageFile: '',
            bestQuoteAuthor: '',
          },
        ],
        itemStemId: '',
        kosher: false,
        vegan: false,
        vegetarian: false,
        awardWinner: false,
        packagePioneer: false,
        biodynamic: false,
        organic: false,
        fairTrade: false,
        oaked: false,
        drinkByDate: '',
        alcoholPercent: 0,
        alcoholUnits: 0,
        allergens: [],
        colourCode: '',
        vintage: item?.product?.vintage || '',
        fineWineFlag: false,
        bottleType: item?.product.bottleType || '',
        inventoryInfo: {
          availabilityStatus: '',
          summaryAvailabilityStatus: '',
          stockQuantity: 0,
          backOrderLevel: 0,
          happyToWaitEnabled: false,
          availabilityDate: '',
          stockAlgorithm: '',
        },
        mapImageFile: '',
        otherImageFile: '',
        skus: [],
        styleName: '',
        grapeName: '',
        countryName: '',
        colourName: '',
        name: '',
        thumbnailImage: item?.product?.thumbnailImage || '',
        smallImage: item?.product?.smallImage || '',
        largeImage: item?.product?.largeImage || '',
        description: '',
        mixed: item?.product?.mixed,
        isMixed: item?.product?.isMixed,
        enPrimeur: item?.product?.enPrimeurFlag,
        giftFlag: item?.sku?.giftFlag,
        favourited: false,
        longDescription: '',
        appellationName: '',
        subProductType: '',
        salesActivity: '',
        packaging: '',
      };
    }

    return baseItem;
  });
  return lineItem;
}

function setDiscountedPricePerQuantity(item: any): DiscountedPricePerQuantity[] {
  const discountedPricePerQuantity = item?.discountedPricePerQuantity || [{}];
  return discountedPricePerQuantity.map((price: DiscountedPricePerQuantity) => ({
    quantity: price?.quantity || 0,
    discountedPrice: {
      value: {
        amount: price?.discountedPrice?.value?.amount || 0,
        currencyCode: price?.discountedPrice?.value?.currencyCode || '',
      },
      includedDiscounts: (price?.discountedPrice?.includedDiscounts || [{}])?.map((discount: IncludedDiscount) => ({
        discount: {
          typeId: discount?.discount?.typeId || '',
          id: discount?.discount?.id || '',
          name: discount?.discount?.name || '',
        },
        discountedAmount: {
          amount: discount?.discountedAmount?.amount || 0,
          currencyCode: discount?.discountedAmount?.currencyCode || '',
        },
      })),
    },
  }));
}
