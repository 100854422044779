import aa from 'search-insights';
import { globals } from './globals';
import { logger } from './logger';

const getUserToken = async () =>
  new Promise((resolve, reject) => {
    aa('getUserToken', {}, (err, userToken) => {
      if (err) {
        logger.error('Error fetching algolia anon user token:', err);
        reject(err); // Reject the promise if there's an error
        return;
      }
      resolve(userToken); // Resolve the promise with the token
    });
  });

const getAuthenticatedUserToken = async () =>
  new Promise((resolve, reject) => {
    aa('getAuthenticatedUserToken', {}, (err, authenticatedUserToken) => {
      if (err) {
        logger.error('Error fetching algolia authenticated user token:', err);
        reject(err); // Reject the promise if there's an error
        return;
      }
      resolve(authenticatedUserToken); // Resolve the promise with the token
    });
  });

export const getBasePrice = (skus: any, itemQty: number, isUnlimited: boolean) => {
  if (!Array.isArray(skus) || skus.length === 0) {
    return '';
  }

  const memberPrice = skus?.[0].memberPrice;
  const vppPrice = skus?.[0].vppPrice;

  let basePrice = skus[0]?.salePrice ? skus[0]?.salePrice : skus[0]?.buyersRRP;

  if (vppPrice && itemQty >= globals.vppThreshold) {
    basePrice = vppPrice;
  } else if (memberPrice && isUnlimited && itemQty >= 12) {
    basePrice = memberPrice;
  }
  return basePrice.toFixed(2);
};

export const getDiscount = (buyersRRP: number, price: number, itemQty: number) =>
  ((buyersRRP - price) * itemQty).toFixed(2);
export const getTotal = (price: number, itemQty: number) => (Number(price) * itemQty).toFixed(2);

const handleObjectData = (quantity: string, hit: any, isUnlimited: boolean) => {
  const itemQty = Number(quantity);

  // Get the skus from the hit
  const { skus } = hit;

  // Get the buyersRRP
  const buyersRRP = skus?.[0]?.buyersRRP;

  // Get the base price
  const price = getBasePrice(skus, itemQty, isUnlimited);

  // Calculate the discount
  const discount = getDiscount(buyersRRP, price, itemQty);

  // Calculate the total
  const total = getTotal(price, itemQty);

  return { itemQty, discount, price, total };
};

const getQueryIDfromURL = (): string => {
  const url = new URL(window.location.href);
  const { searchParams } = url;

  return searchParams.get('queryID') || '';
};

export const handleAlgoliaCartEvent = async (
  itemCode: string,
  quantity: string,
  hit: any,
  isUnlimitedWithMember = false,
): Promise<void> => {
  const userToken = await getUserToken();
  const authenticatedUserToken = await getAuthenticatedUserToken();

  const index = `${globals.algoliaIndexName}`;
  const { itemQty, discount, price, total } = handleObjectData(quantity, hit, isUnlimitedWithMember);

  const currency = globals.country === 'us' ? 'USD' : 'GBP';

  const searchQueryID = getQueryIDfromURL() || hit.__queryID;

  if (!searchQueryID) {
    return aa('addedToCartObjectIDs', {
      userToken: `${userToken}`,
      authenticatedUserToken: `${authenticatedUserToken}`,
      eventName: 'addedToCartObjectIDs',
      index,
      objectIDs: [itemCode],
      objectData: [
        {
          discount,
          price,
          quantity: itemQty,
        },
      ],
      value: total,
      currency,
    });
  }

  aa('addedToCartObjectIDsAfterSearch', {
    userToken: `${userToken}`,
    authenticatedUserToken: `${authenticatedUserToken}`,
    eventName: 'addedToCartObjectIDsAfterSearch',
    index,
    queryID: `${searchQueryID}`,
    objectIDs: [itemCode],
    objectData: [
      {
        discount,
        price,
        quantity: itemQty,
      },
    ],
    value: total,
    currency,
  });
};
