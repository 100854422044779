// Contentful API data is bloated with unnecessary metadata, so this method removes it
// While NOT changing any data or structures we still use
export function compressContentfulJson(dataRaw: any) {
  if (!dataRaw) {
    return dataRaw;
  }

  const dataCopy = JSON.parse(JSON.stringify(dataRaw));

  function isContentType(node: any) {
    return node instanceof Object && node.sys && node.sys.id;
  }

  function processFields(fields: any) {
    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        const fieldNode = fields[key];
        if (Array.isArray(fieldNode)) {
          fieldNode.forEach(arrayNode => squash(arrayNode));
        } else if (fieldNode instanceof Object) {
          squash(fieldNode);
        }
      }
    }
  }

  function squash(node: any) {
    if (isContentType(node)) {
      if (node.sys.contentType) {
        const { id } = node.sys.contentType.sys!;
        node.sys = {
          id: node.sys.id,
          contentType: {
            sys: {
              id,
            },
          },
        };
      }
      delete node.metadata;

      if (node.fields && typeof node.fields === 'object') {
        processFields(node.fields);
      }
    }
  }

  squash(dataCopy);
  return dataCopy;
}
