export const saleTag = (product: any) => {
  if (product?.productType === 'Case' && product.skus.length > 0) {
    return product.skus[0]?.buyersRRP !== product.skus[0]?.salePrice;
  }

  return product?.skus?.some((item: { itemCode: any[] }) => {
    const [itemCodePrefix] = item.itemCode;
    return ['B', 'C', 'T'].includes(itemCodePrefix);
  });
};
