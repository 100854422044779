import { css, useTheme } from '@emotion/react';
import React, { memo, useState } from 'react';
import { Button, TextInput, globals, CmsImage, CmsRichText } from 'common-ui';
import type { VoucherWidgetEntryType } from 'tsconfig/types';

export type CmsVoucherWidgetProps = {
  contentItem: VoucherWidgetEntryType;
};

export const CmsVoucherWidget: React.FC<CmsVoucherWidgetProps> = memo(({ contentItem: { fields } }) => {
  const { backgroundImage, promoText, eyebrow, headline, description, voucherCodesList, ctaText, subCtaText } =
    fields || {};

  const theme = useTheme();
  const cssMain = css`
    ${theme.widgets?.CmsVoucherWidget?.main}
  `;

  const [voucherCode, setVoucherCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    if (voucherCode && voucherCodesList.includes(voucherCode)) {
      setErrorMessage('');
      location.href = `${globals.publicHost}${voucherCode}`;
    } else {
      setErrorMessage("That code isn't working-please double check the number");
    }

    if (!voucherCode) {
      setErrorMessage('');
    }
  };

  const handleInputChange = (e: any) => {
    setVoucherCode(e.target.value);
  };

  return (
    <div className="voucher-widget" css={cssMain}>
      <CmsImage contentItem={backgroundImage} fill={true} isNextImg={false} placeholder="blur" />
      <div className="voucher-widget__card">
        <div className="voucher-widget__text">
          {promoText && <span className="voucher-widget__promo-text">{promoText}</span>}
          {eyebrow && <span className="voucher-widget__eyebrow">{eyebrow}</span>}
          {headline && <h1 className="voucher-widget__headline">{headline}</h1>}
          {description && <span className="voucher-widget__description">{description}</span>}
        </div>

        <div className="voucher-widget__form">
          <form onSubmit={handleFormSubmit} data-testid="voucher-form">
            <div>
              <TextInput
                value={voucherCode}
                type="text"
                onChange={handleInputChange}
                autoComplete="off"
                status={errorMessage !== '' ? 'error' : undefined}
                message={errorMessage}
                disabled={false}
                loading={false}
                label={'Voucher Code'}
                name={'voucher-form-input'}
                data-testid="voucher-form-input"
              />
            </div>

            <Button type="submit" kind="primary" data-testid="voucher-form-button">
              {ctaText || 'Submit'}
            </Button>
          </form>

          {subCtaText && (
            <div className="voucher-widget__subcta">
              <CmsRichText contentItem={subCtaText} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
