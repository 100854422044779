import { css, useTheme } from '@emotion/react';
import type { ReactNode, Ref } from 'react';
import React, { memo } from 'react';
import type { OffsetProps } from 'tsconfig/types';
import { generateUtilCss } from '../../utils/grid.utils';

export type ColProps = {
  /**
   * Controls the column `size`, based on breakpoint
   */
  xs?: number | 'auto';
  sm?: number | 'auto';
  md?: number | 'auto';
  lg?: number | 'auto';
  xl?: number | 'auto';
  xxl?: number | 'auto';

  /**
   * Controls the column `offset`
   */
  offset?: OffsetProps;
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children nodes
   */
  children: ReactNode;

  /**
   * custom class
   */
  className?: string;
};

type UtilPropType = Pick<ColProps, 'offset'>;

export const ColComponent = ({ className, offset, ...props }: ColProps) => {
  const theme = useTheme();

  const colResponsiveCss = generateUtilCss<ColProps>(props, 'col', theme);
  const offsetCss = generateUtilCss<UtilPropType['offset']>(offset, 'offset', theme);

  return (
    <div className={className} css={css([theme.mixins.col, colResponsiveCss, offset && offsetCss])}>
      {props.children}
    </div>
  );
};

const Col = memo(ColComponent);

export default Col;
