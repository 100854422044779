import { globals } from 'common-ui';

type FlavourProfileProps = {
  displayHeading: boolean;
};

export const FlavourHeading: React.FC<FlavourProfileProps> = ({ displayHeading }) => {
  if (!displayHeading) {
    return null;
  }

  if (globals.country === 'us') {
    return <h2 className="sub-title-with-border">Flavor Profile</h2>;
  }

  return <h2 className="sub-title-with-border">Flavour Profile</h2>;
};
