import { globals, handleDLClickEvent } from 'common-ui';

export const getQueryFilters = (currentState: string): string =>
  globals.country === 'us'
    ? `((threeTierStates: '${currentState}' OR threeTierStates: 'ALL') OR (wineryDirectStates: '${currentState}' OR threeTierStates: 'ALL'))`
    : '';

export const resultsCustomConfig = (): {
  hitsPerPage: number;
  viewAllItemsLength: number;
} => ({
  hitsPerPage: (() => {
    if (globals.country === 'us') {
      return 20;
    }
    if (globals?.algoliaNewView) {
      return 6;
    }
    return 10;
  })(),
  viewAllItemsLength: (() => {
    if (globals.country === 'us') {
      return 4;
    }
    if (globals?.algoliaNewView) {
      return 5;
    }
    return 9;
  })(),
});

export const typeaheadCheck = (checkQuery: string): boolean => checkQuery.length > 2;

export const recentSearch = (): void => {
  handleDLClickEvent('recentSearch', 'search');
};
export const popularSearch = (): void => {
  handleDLClickEvent('popularSearch', 'search');
};
export const suggestedProducts = (): void => {
  handleDLClickEvent('suggestedProducts', 'search');
};

export const redirectionURL = (query: string): void => {
  if (query) {
    window.location.href = `/search?query=${encodeURIComponent(query)}`;
  }
};

export const handleSuggestionOpen = (
  isSuggestionOpen: boolean,
  panelRef: React.RefObject<HTMLDivElement>,
  setSearchOverLay: { (): number; (): number },
  setSuggestionPanelTop: { (value: React.SetStateAction<number>): void; (arg0: number): void },
) => {
  if (isSuggestionOpen) {
    const panelContainer = panelRef.current as any;
    const panelContainerBottom = Math.ceil(panelContainer?.getBoundingClientRect().bottom);
    const aaPanelTop = setSearchOverLay() - panelContainerBottom - 1;
    setSuggestionPanelTop(aaPanelTop);
  }
};

export const cancelSearch = (autocompleteInstance: any, onCancelSearch: any) => {
  autocompleteInstance.current?.refresh();
  autocompleteInstance.current?.setQuery('');
  onCancelSearch?.();
};
