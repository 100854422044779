import { useState } from 'react';
import { globals, Icon } from 'common-ui';
import { CartButton } from './Button';
import { css, useTheme } from '@emotion/react';
import type { ProductProps } from 'tsconfig/types';

export type CartButtonProps = {
  itemCode: string;
  showQtySelector: boolean;
  hit?: ProductProps;
  isMiniCart?: boolean;
};
export const CartButtonWithQuantitySelector = ({
  itemCode,
  showQtySelector = false,
  hit,
  isMiniCart = false,
}: CartButtonProps) => {
  const [count, setCount] = useState('1');
  const theme = useTheme();

  const orderformWrapper = css`
    ${theme.widgets.Product?.compactOrderForm}
  `;

  const decrementCount = () => {
    setCount(prevCount => {
      const newCount = parseInt(prevCount, 10) - 1;
      return newCount > 0 ? newCount.toString() : '1';
    });
  };

  const incrementCount = () => {
    setCount(prevCount => {
      const newCount = parseInt(prevCount, 10) + 1;
      return newCount.toString();
    });
  };

  const handleInputChange = (e: { target: { value: string } }) => {
    const inputValue = e.target.value.replace(/\D/g, '').substring(0, 2);
    setCount(inputValue || '1');
  };

  return (
    <div css={orderformWrapper}>
      <div className="add-to-cart-section">
        {showQtySelector && (
          <div className="quantityFields">
            <div className="input-group-prepend">
              <button
                onClick={decrementCount}
                className="btn btn-outline-secondary btn-minus"
                disabled={Number(count) <= 1}
              >
                <Icon kind={'minus'} size="xs" />
              </button>
            </div>
            <div>
              <input
                className="form-control quantity"
                type="text"
                name="quantity"
                value={count}
                maxLength={2}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group-append">
              <button
                onClick={incrementCount}
                className="btn btn-outline-secondary btn-plus"
                disabled={Number(count) >= 99}
              >
                <Icon kind={'add'} size="xs" />
              </button>
            </div>
          </div>
        )}

        <div className="add-to-cart-btn-container">
          <CartButton
            itemCode={itemCode}
            qty={count}
            buttonText={globals.country === 'us' || globals.country === 'au' ? 'ADD TO CART' : 'ADD TO BASKET'}
            hit={hit}
            isMiniCart={isMiniCart}
          />
        </div>
      </div>
    </div>
  );
};
