import { ScrollArea } from '@mantine/core';
import React from 'react';
import type { DLClickEventHandlerProps, LinkEntryType, TabEntryType } from 'tsconfig/types';
import { CmsContentSection } from '../../contentful/CmsContentSection/CmsContentSection';
import { isBrowser } from '../../../utils/isBrowser';
import { CmsLink } from '../../contentful/CmsLink/CmsLink';
import { css, useTheme } from '@emotion/react';

export type MainLargeNavGroupProps = {
  globalHeaderHeight: number;
  menuContent: TabEntryType['fields']['content'];
  stickyContent: TabEntryType['fields']['stickyContent'];
  dlClickEventHandler: DLClickEventHandlerProps;
};

export const MainLargeNavGroup: React.FC<MainLargeNavGroupProps> = ({
  menuContent,
  globalHeaderHeight,
  stickyContent,
  dlClickEventHandler,
}) => {
  const theme = useTheme();
  const wrapper = css`
    ${theme.widgets.GlobalHeader?.navStickyButton}
  `;
  const windowHeight = Math.max(isBrowser() ? window.innerHeight : 0);

  // 32px is a hover dropdown padding. Subtracted to avoid the extra spacing
  const menuHeight: number = windowHeight - globalHeaderHeight - 32;

  return (
    <ScrollArea.Autosize mah={`${menuHeight}px`}>
      <CmsContentSection contentJsonList={menuContent} dlClickEventHandler={dlClickEventHandler} />
      {stickyContent && (
        <div css={wrapper}>
          {(stickyContent as LinkEntryType[]).map((link, index) => (
            <CmsLink
              key={`stickyContent${index + 1}`} // Use a unique key, assuming link.id is available
              contentItem={link}
              className="desktop-sticky-content"
              dlClickEventHandler={dlClickEventHandler}
            />
          ))}
        </div>
      )}
    </ScrollArea.Autosize>
  );
};
