import Image from 'next/image';
import { useState } from 'react';

/**
 * Props for the ProductImage component.
 */
interface ProductImageProps {
  src: string; // The source URL of the image.
  alt: string; // The alternative text for the image.
  width?: number; // The width of the image.
  height?: number; // The height of the image.
  fill?: boolean; // Whether the image should fill its container.
  priority?: boolean; // Whether the image is considered high priority.
  productType?: string; // The type of the product.
  genericImage?: string; // Optional fallback generic image.
  objectFit?: 'contain' | 'cover'; // The object-fit property of the image.
}

/**
 * A component for displaying product images.
 */
export const ProductImage: React.FC<ProductImageProps> = ({
  src,
  alt,
  fill = false,
  width,
  height,
  productType,
  priority = false,
  genericImage = '',
  objectFit = 'contain',
}) => {
  const [error, setError] = useState(false);

  // Determine the default fallback image
  const defaultImage =
    productType === 'Case' ? '/next/images/default_images/case.png' : '/next/images/default_images/bottle.png';

  // Choose the correct image based on error state
  const imageSrc = error ? genericImage || defaultImage : src;

  // Handle error state update
  const handleError = () => setError(true);

  return (
    <Image
      src={imageSrc}
      alt={alt}
      title={alt}
      onError={handleError}
      priority={priority}
      loading={priority ? 'eager' : 'lazy'}
      {...(fill ? { fill: true, sizes: '100vw', style: { objectFit } } : { width, height })}
    />
  );
};
