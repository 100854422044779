import React, { memo } from 'react';
import { css, useTheme } from '@emotion/react';
import type { MenuBannerProps } from 'tsconfig/types';
import { inspectorMode } from 'common-ui';

export const CmsMenuBanner = memo(({ contentItem, dlClickEventHandler }: MenuBannerProps) => {
  const theme = useTheme();
  const { menuBannerImage, menuBannerUrl } = contentItem?.fields || {};
  const widgetTheme = theme.widgets.CmsMenuBanner || {};
  const menuBannerContainer = css`
    ${widgetTheme.wrapper}
  `;

  return (
    <div css={menuBannerContainer} className="menu-banner-image">
      <a
        href={menuBannerUrl}
        data-event="menuBannerImageClick"
        data-section={`menu banner image:${menuBannerImage?.fields?.title}`}
        data-action="menu banner image"
        data-name="navigation menu banner image"
        data-text={menuBannerImage?.fields?.title}
        onClick={() => dlClickEventHandler && dlClickEventHandler(menuBannerImage?.fields?.title ?? '')}
      >
        <img
          src={menuBannerImage?.fields?.file?.url}
          alt={menuBannerImage?.fields?.title ?? 'Banner Image'}
          title={menuBannerImage?.fields?.title ?? ''}
          className="nav-banner-img"
          loading="lazy"
          {...inspectorMode(contentItem?.sys?.id, 'menuBannerImage')}
        />
      </a>
    </div>
  );
});
