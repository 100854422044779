import { adobeDataLayer } from '../data-layer/AdobeDataLayer';
import type { DLProductProps, DLBasketDetailsProps, LineItem } from 'tsconfig/types';
import { cartAtom } from 'common-state';
import { getRecoil } from 'recoil-nexus';
import { adobeProductMapData, ITEMCLASSIFICATION_TYPE_GIFT } from 'common-ui';

export const useAdobeBasketDetails = () => () => {
  const { cartItems = null } = getRecoil(cartAtom) || {};

  if (!cartItems) {
    return null;
  }

  const cartTotalValue = cartItems?.totalPrice?.amount ?? 0,
    totalLineItems = cartItems?.totalLineItems ?? 0,
    shippingPrice = cartItems?.shippingInformation?.price?.amount ?? 0,
    cartValue = Number((cartTotalValue - shippingPrice).toFixed(2)),
    productDetails =
      cartItems?.lineItems?.map(
        (item: LineItem) =>
          item.product && {
            ...adobeProductMapData(item.product, item.quantity),
            isGift: (item?.itemClassifications || []).some(classification =>
              classification.toLowerCase().includes(ITEMCLASSIFICATION_TYPE_GIFT),
            ),
          },
      ) || [],
    numberOfBottles =
      cartItems?.lineItems?.reduce((numBottles, item: LineItem) => {
        const { product, quantity } = item,
          sku = product?.skus[0];
        if (sku && !sku.preSellItem && !sku.enPrimeurPrice && product?.productType?.toLocaleLowerCase() !== 'service') {
          return numBottles + (sku.numberOfBottles ?? 0) * (quantity ?? 0);
        }
        return numBottles;
      }, 0) ?? 0;

  const basketDetails: DLBasketDetailsProps = {
    totalLineItems,
    cartValue,
    cartTotalValue,
    cartDiscounts: cartItems?.lineItemSavings?.totalSavings ?? 0,
    numberOfBottles,
    product: productDetails as DLProductProps[],
  };

  if (cartTotalValue && totalLineItems) {
    adobeDataLayer.basketDetails(basketDetails);
  }
};
