const COLUMN_MAP: Record<string, number> = {
  'Large (6 Col)': 6,
  'Medium (5 Col)': 5,
  'Small (4 Col)': 4,
  'X Small (3 Col)': 3,
};

const bannerFirstgrid = 'banner-firstgrid',
  bannerSecondgrid = 'banner-secondgrid';
const VARIANT_CLASS_MAP: Record<string, [string, string]> = {
  TextFirst: [bannerFirstgrid, bannerSecondgrid],
  'Content Left / Image Right': [bannerFirstgrid, bannerSecondgrid],
  'Multipurpose banner with Calls to Action': [bannerFirstgrid, bannerSecondgrid],
  'Full Background - Tinted Left': [bannerFirstgrid, bannerSecondgrid],
  TextSecond: [bannerSecondgrid, bannerFirstgrid],
  'Content Right / Image Left': [bannerSecondgrid, bannerFirstgrid],
  'Full Background - Tinted Right': [bannerSecondgrid, bannerFirstgrid],
};

export const getClassNameBasedOnVariant = (variant: string, isContent: boolean): string => {
  const [contentClass, imageClass] = VARIANT_CLASS_MAP[variant] || ['', ''];
  return isContent ? contentClass : imageClass;
};

const VARIANT_TEST_ID_MAP: Record<string, string> = {
  TextFirst: 'text-first',
  'Content Left / Image Right': 'content-left-image-right',
  'Multipurpose banner with Calls to Action': 'multi-purpose',
  TextSecond: 'text-second',
  'Content Right / Image Left': 'content-right-image-left',
  'Full Background - Tinted Left': 'full-background-tinted-left',
  'Full Background - Tinted Right': 'full-background-tinted-right',
  'Full Background - Tinted Centre': 'full-background-tinted-center',
};

export const getContentWidth = (contentWidth: string) => COLUMN_MAP[contentWidth] || 6;

export const getDataTestId = (variant: string) => VARIANT_TEST_ID_MAP[variant] || 'content-left-image-right';

export const hasTintedBackground = (variant: string) => variant.startsWith('Full Background - Tinted');

export const getGradient = (alignment: string, isMobile: boolean): string => {
  const gradients: Record<string, string> = {
    Right: isMobile
      ? 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(195, 195, 195, 0.165666) 25%, rgba(0, 0, 0, 0.7) 100%)'
      : 'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 40.2%, rgba(195, 195, 195, 0.17) 45.22%, rgba(0, 0, 0, 0.70) 100%)',
    Left: isMobile
      ? 'linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(195, 195, 195, 0.165666) 40%, rgba(0, 0, 0, 0.7) 100%)'
      : 'linear-gradient(270deg, rgba(255, 255, 255, 0.00) 40.2%, rgba(195, 195, 195, 0.17) 45.22%, rgba(0, 0, 0, 0.70) 100%)',
    Center: isMobile
      ? 'linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(255, 255, 255, 0) 100%)'
      : 'radial-gradient(45% 70% at 50% 50%, rgba(0, 0, 0, 0.63) 0%, rgba(0, 0, 0, 0.09) 65%, rgba(0, 0, 0, 0.00) 100%)',
  };

  return gradients[alignment] || gradients['Center'];
};

export const getTintedBackground = (
  variant: string,
  backgroundImage: string | undefined,
  breakpoint: string,
  addGradient: boolean,
): string => {
  if (!hasTintedBackground(variant)) {return '';}

  const alignment = variant.replace('Full Background - Tinted ', '');
  const colorCss = 'color: #FFF;';
  const bgImageCss = backgroundImage ? `, url(${backgroundImage}) lightgray 50% / cover no-repeat;` : '';

  const gradientXS = getGradient(alignment, true); // Mobile
  const gradientMd = getGradient(alignment, false); // Desktop

  return `
    ${colorCss}
    background: ${addGradient ? `${gradientXS}` : ''}${bgImageCss};
    ${breakpoint} {
      background: ${addGradient ? `${gradientMd}` : ''}${bgImageCss};
    }
  `.trim();
};
