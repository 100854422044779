import React, { memo } from 'react';
import ReactPlayer from 'react-player/lazy';
import { CmsImage, useScreenLayout } from 'common-ui';
import type { ImageEntryType } from 'tsconfig/types';

export type ReactPlayerProps = {
  videoLink: string;
  config?: object;
  showThumbnail?: boolean;
  image?: ImageEntryType;
  onPlayPause?: () => void;
  onReadyFn?: (player: any) => void;
  smallDeviceVideoLink?: string;
  className?: string;
  testId?: string;
  loop?: boolean;
  width?: string;
  height?: string;
  controls?: boolean;
};

export const VideoPlayer = memo(
  ({
    config,
    showThumbnail,
    image,
    onPlayPause,
    onReadyFn,
    videoLink,
    smallDeviceVideoLink,
    className,
    testId,
    loop,
    width,
    height,
    controls,
  }: ReactPlayerProps) => {
    const { isNotDesktop } = useScreenLayout();

    const extraPlayerProps = {
      ...(config && { config }),
      ...(onPlayPause && { onEnded: () => onPlayPause() }),
      ...(onReadyFn && { onReady: (player: any) => onReadyFn(player) }),
      ...(width && { width }),
      ...(height && { height }),
      ...(image && { light: showThumbnail && <CmsImage contentItem={image} /> }),
      ...(controls && { controls }),
      ...(loop && { loop }),
      ...(className && { className }),
      ...(testId && { 'data-testid': testId }),
      ...(image && { fallback: <CmsImage contentItem={image} /> }),
    };

    return (
      <ReactPlayer
        url={isNotDesktop ? smallDeviceVideoLink || videoLink : videoLink}
        playing={true}
        {...extraPlayerProps}
      />
    );
  },
);
