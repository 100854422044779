
import { useEffect } from 'react';
import aa from 'search-insights';
import { useRecoilValue } from 'recoil';
import { selectUserDetails } from 'common-state';
import { globals } from 'common-ui';

/**
 * Custom hook that sets the authenticated user token for Algolia.
 * It listens for updates in cookie consent categories and sets the token accordingly.
 */
export const useAlgoliaAuthenticatedUser = (): void => {
  const userDetails = useRecoilValue(selectUserDetails);
  const brandAccountNumber = userDetails?.brandAccountNumber;

  const oneTrustCookieConsentCategory = '4';
  const osanaCookieConsentCategory = 'osano-cm-marketing';

  useEffect(() => {
    if (!brandAccountNumber) { return; }

    const handleOneTrustUpdate = (event: CustomEvent) => {
      if (event?.detail?.includes(oneTrustCookieConsentCategory)) {
        aa('setAuthenticatedUserToken', brandAccountNumber);
      }
    };

    const handleOsanaUpdate = () => {
      aa('setAuthenticatedUserToken', brandAccountNumber);
    };

    if (globals.country === 'uk') {
      window.addEventListener("OneTrustGroupsUpdated", handleOneTrustUpdate as EventListener);
      return () => {
        window.removeEventListener("OneTrustGroupsUpdated", handleOneTrustUpdate as EventListener);
      };
    }

    if (globals.country === 'us') {
      window.Osano?.cm?.addEventListener?.(
        osanaCookieConsentCategory,
        handleOsanaUpdate
      );
      return () => {
        window.Osano?.cm?.removeEventListener?.(
          osanaCookieConsentCategory,
          handleOsanaUpdate
        );
      };
    }

    // Default behaviour for any other country
    aa('setAuthenticatedUserToken', brandAccountNumber);
  }, [brandAccountNumber]);

};