import { atom, selector } from 'recoil';
import { initCommerceApiWithGlobals } from 'common-api';
import { logger, globals } from 'common-ui';
import { effectConsoleLogAtomUpdates } from '../helpers/debug';
import type { AuthDetails, TokenProps } from 'tsconfig/types.d';
import { isBrowser } from '../helpers/utils';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { notifications } from '@mantine/notifications';

const commerceApiExtraConfig = (publicCsrfValue = '') => ({
  webApiExtraHeaders: {
    [globals.authAndWebApiCsrfHeaderName as string]: publicCsrfValue,
  },
});

//Atom
//----------------------------------------------------------------------
export const authAtom = atom<AuthDetails>({
  key: 'authAtom',
  default: {
    isInitialised: false,
    publicCsrf: '',
  },
  effects: [effectConsoleLogAtomUpdates],
});

//Selectors
//----------------------------------------------------------------------
export const selectAuthDetails = selector({
  key: 'selectAuthDetails',
  get: ({ get }) => get(authAtom),
});

//Hooks
//----------------------------------------------------------------------
export const useInitAuthState = () => async () => fetchAuthToken();

//Helper functions
//----------------------------------------------------------------------
// TODO: This function is not used anywhere in the codebase. It should be removed.
export async function fetchAuthToken() {
  if (!isBrowser()) {
    logger.log('useGetAuthToken skipping as not in browser');
    return;
  }

  try {
    const authAtomState = getRecoil(authAtom);
    if (!authAtomState.isInitialised) {
      const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig());
      let token = await commerceApi.Auth.reload();

      if (!token || !token.csrf) {
        //TODO: not when logged in
        token = await commerceApi.Auth.getAnonymousToken();
      }
      const newAuthValue = formatTokenResponseToAuthAtomState(token);
      setRecoil(authAtom, newAuthValue);
      return newAuthValue;
    }
  } catch (e) {
    logger.log('API ERROR', e);
  }
}

export const reloadAuthToken = async () => {
  if (!isBrowser()) {
    logger.log('useReloadToken skipping as not in browser');
    return;
  }

  try {
    const commerceApi = initCommerceApiWithGlobals();
    const token = await commerceApi.Auth.reload();
    const newAuthValue = formatTokenResponseToAuthAtomState(token);
    setRecoil(authAtom, newAuthValue);
    return newAuthValue;
  } catch (e) {
    logger.log('API ERROR', e);
    throw e;
  }
};

export const getAnonymousToken = async () => {
  if (!isBrowser()) {
    logger.log('getAnonymousToken skipping as not in browser');
    return;
  }

  try {
    const commerceApi = initCommerceApiWithGlobals();
    const token = await commerceApi.Auth.getAnonymousToken();
    const newAuthValue = formatTokenResponseToAuthAtomState(token);
    setRecoil(authAtom, newAuthValue);
    return newAuthValue;
  } catch (e) {
    logger.log('API ERROR', e);
    throw e;
  }
};

export const refreshAuthToken = async () => {
  if (!isBrowser()) {
    logger.log('refreshAuthToken skipping as not in browser');
    return;
  }

  try {
    const authAtomState = getRecoil(authAtom);
    const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));
    // call refresh endpoint
    await commerceApi.Auth.refresh();
    return true;
  } catch (error: any) {
    logger.log('API ERROR', error);
    notifications.show({
      message: 'Your session has expired. Please log in again.',
      autoClose: 3000,
      top: 15,
      color: 'red',
    });
    // If the error is a 401, redirect the user to the login page
    if (error.response && error.response.status === 401 && location.pathname !== '/jsp/curity/signin.jsp') {
      logger.error('User session has timed out. Redirecting user to login page.');
      window.location.href = `/jsp/curity/signin.jsp?redirectionURL=${window.location.href}`;
    } else {
      throw error;
    }
  }
};

export function formatTokenResponseToAuthAtomState(token: TokenProps): AuthDetails {
  return {
    isInitialised: true,
    publicCsrf: token.csrf,
    expiryDate: token.expires_in ? new Date(Date.now() + token.expires_in) : undefined,
  };
}
