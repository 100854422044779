import { useState } from 'react';
import { Button, Alert, Loading, handleDLProductClickEvent, globals, Icon, handleAlgoliaCartEvent } from 'common-ui';
import { useActionAddToCart, useActionBatchAddToCart, useRecoilValue, selectCartDetails, cartAtom } from 'common-state';
import type { AxiosErrorResponse } from 'tsconfig/types';

export type CartProps = {
  itemCode: string;
  qty: string;
  buttonText?: string;
  sendEvent?: any;
  hit?: any;
  isDisable?: boolean;
  method?: string;
  cartItems?: any;
  fontWeight?: string;
  isUnlimitedWithMember?: boolean;
  upperCase?: boolean;
  closeModal?: () => void;
  buttonStyle?: 'primary' | 'secondary' | 'tertiary' | 'transparent';
  isMiniCart?: boolean;
  showSuccessAlert?: boolean;
};

export const CartButton = (props: CartProps) => {
  const {
    itemCode,
    qty,
    buttonText = 'ADD TO BASKET',
    isDisable,
    method,
    cartItems,
    fontWeight,
    isUnlimitedWithMember,
    upperCase,
    closeModal,
    buttonStyle = 'primary',
    isMiniCart,
    showSuccessAlert = false,
    hit,
  } = props;
  const { itemsCount } = useRecoilValue(selectCartDetails);
  const {
    cartError: { hasCartError, cartErrorMessage },
  } = useRecoilValue(cartAtom);

  const [apiCallStatus, setApiCallStatus] = useState('idle');
  const [apiCallMessage, setApiCallMessage] = useState('');

  const addItemToCart = useActionAddToCart();
  const batchAddItemToCart = useActionBatchAddToCart();
  const loadingState = apiCallStatus === 'loading';

  const handleApiError = (error: AxiosErrorResponse) => {
    const errorMessage =
      error?.response?.data?.errorResponse?.message ||
      error?.response?.data?.message ||
      error.message ||
      'There is an error adding to cart. Please try again.';
    setApiCallMessage(errorMessage);
  };

  const handleAddToCartClick = async (cartItemCode: string, itemQty: string) => {
    if (hasCartError) {
      setApiCallStatus('error');
      setApiCallMessage(cartErrorMessage);
      setTimeout(() => {
        setApiCallStatus('');
      }, 3000);
      return;
    }

    if (itemQty === '' || cartItemCode === '') {
      setApiCallStatus('error');
      setApiCallMessage(itemQty === '' ? "Minimum quantity of '1' required" : 'Item number is required');
      setTimeout(() => {
        setApiCallStatus('');
      }, 3000);

      return;
    }

    setApiCallStatus('loading'); // Set loading status while the API call is in progress

    try {
      // Add multiple items to the cart by including an additional object in the array for AWS cart API
      const cartItemsToAdd = [{ itemCode: cartItemCode, quantity: itemQty }];

      await addItemToCart(cartItemsToAdd);

      // If hit is not passed in as a prop, then we don't fire the cart events as the product data is required to be passed as part of the event
      if (hit) {
        handleAlgoliaCartEvent(cartItemCode, itemQty, hit, isUnlimitedWithMember);

        // Adobe DataLayer
        const convertQtyToInt = parseInt(itemQty);
        if (itemsCount <= 0) {
          handleDLProductClickEvent('InitiateCart', hit, convertQtyToInt, undefined, cartItemCode);
        } else {
          handleDLProductClickEvent(
            'addToCart',
            hit,
            convertQtyToInt,
            isMiniCart ? 'miniCart' : undefined,
            cartItemCode,
          );
        }
      }

      setApiCallStatus('success');

      if (showSuccessAlert) {
        setApiCallMessage(`Item ${cartItemCode} added to your cart`);
        setTimeout(() => {
          setApiCallStatus('');
        }, 3000);
      }

      if (closeModal) {
        closeModal();
      }
    } catch (error) {
      if (error) {
        handleApiError(error as AxiosErrorResponse);
      } else {
        setApiCallMessage('An error occurred.');
      }

      setApiCallStatus('error');
      setTimeout(() => {
        setApiCallStatus('');
      }, 5000);
    }
  };

  const handleBatchAddToCartClick = async (cartAddedItems: any) => {
    setApiCallStatus('loading'); // Set loading status while the API call is in progress

    try {
      if (globals?.useNewCartAPI) {
        await addItemToCart(cartAddedItems.cartItems);
      } else {
        await batchAddItemToCart(cartAddedItems);
      }

      // Adobe DataLayer
      const convertQtyToInt = parseInt(qty);

      // If hit is not passed in as a prop, then we don't fire the cart events as the product data is required to be passed as part of the event
      if (hit) {
        if (itemsCount <= 0) {
          handleDLProductClickEvent('InitiateCart', hit, convertQtyToInt);
        } else {
          handleDLProductClickEvent('addToCart', hit, convertQtyToInt, isMiniCart ? 'miniCart' : undefined);
        }
      }
      setApiCallStatus('success'); // Set success status after a successful API call

      // Close the modal if it exists, after adding items to the cart
      if (closeModal) {
        closeModal();
      }
    } catch (error) {
      if (error) {
        handleApiError(error as AxiosErrorResponse);
      } else {
        // Handle the case where 'error' is not of type 'Error'
        setApiCallMessage('An error occurred.');
      }
      setApiCallStatus('error');
      setTimeout(() => {
        setApiCallStatus('');
      }, 7500);
    }
  };

  const shouldShowAlert =
    apiCallMessage !== '' && (apiCallStatus === 'error' || (apiCallStatus === 'success' && showSuccessAlert));

  return (
    <>
      <div className="cart-button">
        <Button
          className={`${loadingState ? 'loading-add-to-cart-btn' : 'grid-addto-cart-btn'}`}
          // className="loadIcon"
          kind={isUnlimitedWithMember ? 'primaryUnlimited' : buttonStyle}
          style={{ fontWeight }}
          onClick={() => {
            if (method === 'batch') {
              handleBatchAddToCartClick(cartItems);
            } else {
              handleAddToCartClick(itemCode, qty);
            }
          }}
          disabled={isDisable || loadingState}
        >
          {loadingState ? (
            <>
              <span className="loadIcon">
                <Loading />
              </span>
              <span className="addingText">Adding</span>
            </>
          ) : (
            <span>
              {isUnlimitedWithMember && (
                <span className="unlimited-icon">
                  <Icon kind="unlimited" size="xs" />
                  &nbsp;&nbsp;
                </span>
              )}
              {upperCase ? buttonText.toUpperCase() : buttonText}
            </span>
          )}
        </Button>
      </div>
      {shouldShowAlert && (
        <div>
          <Alert type={apiCallStatus}>{apiCallMessage}</Alert>
        </div>
      )}
    </>
  );
};
