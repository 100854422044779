import type { HeaderFooterAtgInitData } from 'tsconfig/types';

const { host } = location;

//Note: all will be visible in browser so do not set any sensetive config here
export const commonHeaderFooterAtgConfigAndEnvVars = (atgConfig: HeaderFooterAtgInitData, buildVersion?: string) => ({
  //general
  isEmbedded: true,
  assetsPath: process.env.REACT_APP_ASSETS_FOLDER_PATH,
  //algolia
  algoliaAppId: atgConfig.algoliaConfig?.algoliaAppId,
  algoliaSearchKey: atgConfig.algoliaConfig?.algoliaSearchKey,
  algoliaIndexName: atgConfig.algoliaConfig?.algoliaIndexName,
  algoliaQuerySuggestions: atgConfig.algoliaConfig?.algoliaQuerySuggestions,
  insightAPIKey: atgConfig.algoliaConfig?.insightAPIKey,
  //API
  atgApiPrefix: process.env.REACT_APP_ATG_API_PREFIX,
  awsApiPrefix: process.env.REACT_APP_AWS_API_PREFIX,
  authAndWebApiPrefix: process.env.REACT_APP_AUTH_AND_WEB_API_PREFIX,
  authAndWebApiCsrfHeaderName: process.env.REACT_APP_AUTH_AND_WEB_API_CSRF_HEADER_NAME,
  useNewCartAPI: atgConfig.useNewCartAPI,
  enableNewMiniCart: atgConfig.enableNewMiniCart,
  algoliaNewView: atgConfig?.algoliaNewView,
  authAndWebApiHost: host,
  host,
  buildVersion,
  publicHost: process.env.REACT_APP_PUBLIC_DW_URL,
  defaultImagePath: '/images/uk/en/law/default_images/',
});
