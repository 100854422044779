import React, { memo, useEffect, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import sanitizeHtml from 'sanitize-html';
import type { ColourField } from 'tsconfig/types';
import { globals } from '../../../utils/globals';
import { inspectorMode } from 'common-ui';

export type CmsStripBannerProps = {
  cmsStripBanner?: string;
  cmsStripBannerBg?: ColourField;
  id?: string;
};

export const StripBanner = memo((props: CmsStripBannerProps) => {
  const { cmsStripBannerBg, cmsStripBanner } = props || {};
  const theme = useTheme();
  const widgetTheme = theme.widgets.GlobalHeader || {};
  const wrapper = css`
    ${widgetTheme.stripBanner}
    ${cmsStripBannerBg && `background-color: ${cmsStripBannerBg.value};`}
  `;
  const [isDWAPPMobile, setIsDWAPPMobile] = useState<boolean>(true);

  useEffect(() => {
    // DSP-1478 - hide stripe banner for userAgent=DWAPP and mobile only
    if (typeof window !== 'undefined') {
      const { userAgent } = navigator;

      // Check if the user agent contains "DWAPP"
      const isDWAPP = userAgent.includes('DWAPP');

      // Basic mobile check
      const isMobile = /Mobi|Android|iPhone|iPad|iPod|BlackBerry|Windows Phone|webOS|Opera Mini|IEMobile/.test(
        userAgent,
      );

      // Set state based on the checks
      setIsDWAPPMobile(isDWAPP && isMobile);
    }
  }, []);

  if (!cmsStripBanner || globals.isEmbedded || isDWAPPMobile) {
    return null;
  }

  const contentToSanitize =
    typeof cmsStripBanner === 'string' && (cmsStripBanner as string).replace(/<[^>]*>/g, '').trim() && cmsStripBanner;
  const sanitizedHTML = sanitizeHtml(contentToSanitize as string, { allowedAttributes: false });

  return (
    <div data-test="widget-StripBanner" css={wrapper}>
      <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} {...inspectorMode(props.id, 'stripBanner')} />
    </div>
  );
});
