import React, { memo, useEffect, useRef, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { SearchNavBar } from './SearchNavBar/SearchNavBar';
import type { SiteEntryType } from 'tsconfig/types';
import { useScreenLayout } from '../../hooks/useScreenLayout';
import { Paper } from '@mantine/core';
import ResizeObserver from 'resize-observer-polyfill';
import ContainerLayout from '../../layouts/ContainerLayout';
import { MainNavBar } from './MainNavBar/MainNavBar';
import { StripBanner } from './StripBanner/StripBanner';
import { globals } from '../../utils/globals';
import StateSelector from './SearchNavBar/StateSelector/StateSelector';
import { handleDLClickEvent } from '../../data-layer/handleDLClickEvent';
import { trackCIDTags } from 'common-state/src/helpers/user.utils';
export type GlobalHeaderProps = {
  cmsSiteConfig?: SiteEntryType;
  enableCurityLogin?: boolean;
};

export const GlobalHeader = memo((props: GlobalHeaderProps) => {
  const {
    accountNavbar,
    socialLinks,
    badgeImages,
    mainNavbar,
    stickyHeader,
    footerNavBar,
    favorites,
    stripBanner,
    stripBannerBackground,
  } = props?.cmsSiteConfig?.fields || {};
  const mainHeaderRef = useRef<HTMLDivElement>(null);
  const stickyPlaceHolderRef = useRef<HTMLDivElement>(null);
  const globalHeaderRef = useRef<HTMLDivElement>(null);
  const [globalHeaderHeight, setGlobalHeight] = useState<number>(0);
  const [prevHeights, setPrevHeight] = useState<Record<string, number>>({
    global: 0,
    main: 0,
  });
  const [isSticky, setIsSticky] = useState(false);
  const theme = useTheme();
  const widgetTheme = theme.widgets.GlobalHeader || {};
  const { screen } = useScreenLayout();

  const wrapper = css`
    ${widgetTheme.wrapper}
  `;
  const calcHeaderHeight = (): void => {
    const headerHeight = mainHeaderRef.current?.offsetHeight || 0;
    let stickable = isSticky;
    if (isSticky && stickyPlaceHolderRef.current) {
      stickable = stickyPlaceHolderRef.current?.getBoundingClientRect().top <= prevHeights?.main;
    } else if (!isSticky && mainHeaderRef.current) {
      stickable = mainHeaderRef.current?.getBoundingClientRect().bottom < 0;
      const PADDING = 24;
      setPrevHeight({
        global: (globalHeaderRef.current?.offsetHeight || 0) + PADDING,
        main: -Math.ceil(mainHeaderRef.current?.offsetHeight),
      });
    }
    // Global header height includes strip banner, tertiary nav, search section and main nav heights
    const fullHeaderHeight = stickable ? headerHeight : globalHeaderRef.current?.getBoundingClientRect().bottom;
    setGlobalHeight(Math.ceil(fullHeaderHeight || 0));
    setIsSticky(stickable);
  };

  useEffect(() => {
    if (globalHeaderRef.current) {
      const fullHeaderHeight = globalHeaderRef.current?.offsetHeight || 0;
      setGlobalHeight(fullHeaderHeight);
    }
  }, [globalHeaderRef.current]);

  useEffect(() => {
    if (!mainHeaderRef.current || !stickyHeader) {
      return;
    }
    const mainHeaderObserver = new ResizeObserver(calcHeaderHeight);
    mainHeaderObserver.observe(mainHeaderRef.current);
    window.addEventListener('scroll', calcHeaderHeight);
    return () => {
      window.removeEventListener('scroll', calcHeaderHeight);
      mainHeaderObserver?.disconnect();
    };
  }, [screen, stickyHeader, isSticky]);

  //To get the DL attributes and pass it to the HandleDLEvent
  const dlClickEventHandler = (data: string, clickType?: string) => {
    handleDLClickEvent(data, clickType || 'click');
  };

  /* CID Tags trigger Starts */
  /** This is to trigger CID tag tracking script from Netlify(Nextjs) sites to ATG sites */
  /** Note: This will be removed once the new API is ready */
  useEffect(() => {
    if (globals.country === 'us') {
      trackCIDTags();
    }
  }, []);
  /* CID Tags trigger End */

  return (
    <>
      <Paper
        id="mainHeaderNavBar"
        className="mainHeader"
        data-test="widget-GlobalHeader"
        css={wrapper}
        shadow="sm"
        ref={globalHeaderRef}
      >
        <div ref={mainHeaderRef} className={`${isSticky ? 'sticky-header active' : ''} no-print`}>
          <StripBanner
            cmsStripBanner={stripBanner}
            cmsStripBannerBg={stripBannerBackground}
            id={props.cmsSiteConfig?.sys.id}
          />
          {/* State Selector only for US */}
          {globals.country === 'us' && (
            <div className="state-selector-mobile">
              <StateSelector />
            </div>
          )}
          <SearchNavBar
            cmsAccountNav={accountNavbar}
            cmsMainNav={mainNavbar}
            cmsFavourite={favorites}
            footerNavBar={footerNavBar}
            socialLinks={socialLinks}
            badgeImages={badgeImages}
            globalHeaderHeight={globalHeaderHeight}
            sticky={isSticky}
            enableCurityLogin={props?.enableCurityLogin}
            dlClickEventHandler={dlClickEventHandler}
          />
        </div>
        {!isSticky && (
          <ContainerLayout>
            <MainNavBar
              cmsMainNav={mainNavbar}
              globalHeaderHeight={globalHeaderHeight}
              dlClickEventHandler={data => {
                dlClickEventHandler(data, 'mainNavClick');
              }}
            />
          </ContainerLayout>
        )}
        <div className="nav-overlay"></div>
      </Paper>
      <div
        ref={stickyPlaceHolderRef}
        style={{ height: `${isSticky ? prevHeights?.global : 0}px` }}
        className="no-print"
      />
    </>
  );
});
