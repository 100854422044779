/*
  Simple global object to store shared settings
  Note: remember that this object can be used on server and in client so if you've using this 
  object to store environemental variables, make sure those vars are being passed to browser.
  i.e.
    In Nextjs, prefix vars with NEXT_PUBLIC_
    In CreateReactApp, prefix vars with REACT_APP_
*/
import type { GlobalSettings } from 'tsconfig/types.d';

export let globals: GlobalSettings = {};

export const setGlobals = (newGlobals: GlobalSettings) => {
  globals = {
    ...globals,
    ...newGlobals,
  };
};
